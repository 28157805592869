import myMenu from './menu';

MenuLeftCtrl.$inject = ['$location', '$rootScope'];
export default function MenuLeftCtrl($location, $rootScope) {
  var vm = this;
  vm.testActive = testActive;

  $rootScope.$on('event:auth:permissions-ready', () => {
    vm.menu = myMenu;
  });

  // INTERNAL METHODS
  function checkActiveElm(menuElm) {
    if (!menuElm.url) return false;
    return $location.path().includes(unifyUrl(menuElm.url));
  }
  function unifyUrl(myUrl) {
    var url = myUrl;
    if (url[url.length - 1] == '/') url = url.substring(0, url.length - 1);
    if (url[0] == '#') url = url.substring(1, url.length);
    return url;
  }

  // test an menu element is active
  function testActive(menuElm) {
    if (!menuElm || menuElm.url == '#/') return false;
    if (menuElm.url) return checkActiveElm(menuElm);
    else if (menuElm.elms) return menuElm.elms.some(e => checkActiveElm(e));
    return false; // Any other case NO ACTIVE!!!
  }
}
