ConfirmChangeFlightSentencesCtrl.$inject = [
  '$http',
  '$scope',
  'serverAddress',
  'dialog',
  'parentScope',
  'notifications'
];

export default function ConfirmChangeFlightSentencesCtrl(
  $http,
  $scope,
  serverAddress,
  dialog,
  parentScope,
  notifications
) {
  /* Functions */
  $scope.change = change;
  $scope.dismiss = dismiss;

  /* Function implementations */
  function change() {
    $http
      .post(serverAddress.getBaseUrl() + 'airdata/flight/sentences/update/', {
        flight_id: parentScope.claim.flight.id,
        has_favorable_sentences: parentScope.claim.flight.has_favorable_sentences,
        has_unfavorable_sentences: parentScope.claim.flight.has_unfavorable_sentences
      })
      .then(function(response) {
        if (response.data.error) {
          notifications.addCurrentView('error', response.data.error[0]);
        } else {
          notifications.addCurrentView('success', response.data.msg);
        }
        dialog.close();
      });
  }

  function dismiss() {
    dialog.close();
  }
}
