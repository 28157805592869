NotificationService.$inject = [
  '$http',
  'serverAddress'
];

export default function NotificationService($http, serverAddress) {
  return {
    manageNotification,
    updateNotification
  };
  function manageNotification(resourceName, notificationId){
    return $http.post(`${serverAddress.getBaseUrl()}api2/v1/${resourceName}/${notificationId}/manage/`);
  }
  function updateNotification(notificationUri, dataToSend) {
    const baseUrl = serverAddress.getBaseUrl().slice(0, -1);
    return $http.patch(`${baseUrl}${notificationUri}`, dataToSend);
  }

}
