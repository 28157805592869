LaboralClaim.$inject = ['$resource', 'serverAddress'];

export default function LaboralClaim($resource, serverAddress) {
  const LABORAL_CLAIM_PATH = 'api2/v1/laboralclaim/:id/';
  return $resource(
    serverAddress.getBaseUrl() + LABORAL_CLAIM_PATH,
    {},
    {
      update: {
        method: 'PATCH',
        params: {},
        isArray: false
      }
    }
  );
}
