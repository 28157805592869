AccidentClaim.$inject = ['$resource', 'serverAddress'];

export default function AccidentClaim($resource, serverAddress) {
  const ACCIDENT_CLAIM_PATH = 'api2/v1/accidentclaim/:id/';
  return $resource(
    serverAddress.getBaseUrl() + ACCIDENT_CLAIM_PATH,
    {},
    {
      update: {
        method: 'PATCH',
        params: {},
        isArray: false
      }
    }
  );
}
