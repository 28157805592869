AirlineRoutes.$inject = ['$routeProvider', 'AirlineConstants'];

export default function AirlineRoutes($routeProvider, AirlineConstants) {
  $routeProvider.when('/airlines/lawyers/counts', {
    title: 'Panel Abogados Aerolíneas',
    templateUrl: 'js/modules/segments/airline/partials/lawyers.html',
    data: {
      permissions: {
        only: 'segment-perobject-AE-crm_view_lawyers_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Aerolíneas',
      iconClass: 'fas fa-plane',
      cardHead: 'Panel Abogados Aerolíneas'
    },
    controller: 'AirlinesLawyersPanelCtrl',
    controllerAs: 'vm',
    segmentID: AirlineConstants.SEGMENT_ID
  });

  $routeProvider.when('/airlines/lawyers/goals', {
    title: 'Objetivos Abogados Aerolíneas',
    data: {
      permissions: {
        only: 'segment-perobject-AE-crm_view_lawyers_goals_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    templateUrl: 'js/modules/segments/airline/partials/lawyergoalscounts.html',
    layoutSetting: {
      contentTitle: 'Aerolíneas',
      iconClass: 'fas fa-plane',
      cardHead: 'Objetivos Abogados Aerolíneas'
    },
    controller: 'AirlinesLawyersGoalsPanelCtrl',
    controllerAs: 'vm',
    segmentID: AirlineConstants.SEGMENT_ID
  });

  $routeProvider.when('/airlines/salespeople/counts', {
    title: 'Panel Comerciales Aerolíneas',
    templateUrl: 'partials/common/panels/sales.html',
    data: {
      permissions: {
        only: 'segment-perobject-AE-crm_view_salespeople_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Aerolíneas',
      iconClass: 'fas fa-plane',
      cardHead: 'Panel Comerciales Aerolíneas'
    },
    controller: 'CommonSalesDashboardCtrl',
    controllerAs: 'vm',
    claimListBaseURL: '#/airline-claims/list/',
    SalesmanService: 'AirlineSalesman',
    salesPeopleCountsURL: 'claim/salesstate/counts/',
    segmentName: 'Aerolíneas',
    segmentID: AirlineConstants.SEGMENT_ID
  });

  $routeProvider.when('/airlines/salespeople/goals', {
    title: 'Objetivos Comerciales Aerolíneas',
    templateUrl: 'js/modules/segments/airline/partials/salesmengoalscount.html',
    data: {
      permissions: {
        only: 'segment-perobject-AE-crm_view_salespeople_goals_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Aerolíneas',
      iconClass: 'fas fa-plane',
      cardHead: 'Objetivos Comerciales Aerolíneas'
    },
    controller: 'AirlinesSalespeopleGoalsPanelCtrl',
    controllerAs: 'vm',
    segmentID: AirlineConstants.SEGMENT_ID
  });

  $routeProvider.when('/airlines/flights/:filter?', {
    title: 'Listados de vuelos',
    templateUrl: 'partials/common/baseList/claim-list.html',
    data: {
      permissions: {
        only: 'segment-global-crm_view_flights_list',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Aerolíneas',
      iconClass: 'fas fa-plane'
    },
    baseListSettings: {
      typeList: 'airline',
      typeTitle: 'aerolíneas',
      templateListTable: 'js/modules/segments/airline/partials/flights/claim-list-table.html',
      templateListFilter: 'js/modules/segments/airline/partials/flights/claim-list-filters.html',
      title: 'Listado de vuelos',
      listResultsText: 'Listado de vuelos'
    },
    controller: 'FlightsListCtrl',
    controllerAs: 'vm',
    segmentID: AirlineConstants.SEGMENT_ID
  });

  $routeProvider.when('/airlines/pending-validation', {
    title: 'Reclamaciones Pendientes de Validación Aerolíneas',
    templateUrl: 'js/modules/segments/airline/partials/pending-validation.html',
    data: {
      permissions: {
        only: 'segment-perobject-AE-crm_view_pending_validation_panel',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Aerolíneas',
      iconClass: 'fas fa-plane',
      cardHead: 'Reclamaciones Pendientes de Validación Aerolíneas'
    },
    controller: 'AirlinePendingValidationPanelCtrl',
    controllerAs: 'vm',
    segmentID: AirlineConstants.SEGMENT_ID
  });

  const permissions = {
    only: 'segment-perobject-AE-crm_view_claims_list',
    redirectTo: 'no-permissions'
  };

  const claimListConfig = {
    title: 'Reclamaciones Aerolíneas',
    templateUrl: 'partials/common/baseList/claim-list.html',
    data: { permissions },
    layoutSetting: {
      contentTitle: 'Aerolíneas',
      iconClass: 'fas fa-plane'
    },
    baseListSettings: {
      typeList: 'airline',
      typeTitle: 'aerolíneas',
      templateListTable: 'js/modules/segments/airline/partials/claim-list-table.html',
      templateListFilter: 'js/modules/segments/airline/partials/claim-list-filters.html',
      title: 'Reclamaciones vuelos',
      listResultsText: 'reclamaciones de vuelos'
    },
    controller: 'AirlineClaimListCtrl',
    controllerAs: 'vm',
    segmentID: AirlineConstants.SEGMENT_ID
  };

  $routeProvider.when('/airline-claims', claimListConfig);
  $routeProvider.when('/airline-claims/list/filter/:filter', claimListConfig);

  $routeProvider.when('/airline-claims/:id', {
    title: 'Detalle reclamación aerolíneas',
    templateUrl: 'js/modules/segments/airline/partials/detail.html',
    data: {
      permissions: {
        only: 'segment-perobject-AE-crm_view_claim_detail',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Aerolíneas',
      iconClass: 'fas fa-plane'
    },
    controller: 'AirlineClaimDetailCtrl',
    controllerAs: 'vm',
    segmentID: AirlineConstants.SEGMENT_ID,
    reloadOnSearch: false
  });
}
