BankDictionaryFactory.$inject = ['$resource', 'serverAddress'];
export default function BankDictionaryFactory($resource, serverAddress) {
  const BANK_PATH = 'api2/v1/bank/';
  return $resource(
    serverAddress.getBaseUrl() + BANK_PATH,
    {},
    {
      get: {
        params: { limit: 700 }
      }
    }
  );
}
