ConfirmAmountChangeCtrl.$inject = [
  '$http',
  'serverAddress',
  '$scope',
  'claimId',
  'legalfileId',
  'amountData',
  'parentScope',
  'dialog',
  'notifications'
];

export default function ConfirmAmountChangeCtrl(
  $http,
  serverAddress,
  $scope,
  claimId,
  legalfileId,
  amountData,
  parentScope,
  dialog,
  notifications
) {
  const AMOUNT_CHANGE_PATH = 'case/amount/change/';
  /* Functions */
  $scope.change = change;
  $scope.overwrite = overwrite;
  $scope.dismiss = dismiss;

  /* Function implementations */
  function change() {
    var params = getPostParams();
    params.overwrite = false;
    $http
      .post(serverAddress.getBaseUrl() + AMOUNT_CHANGE_PATH, params)
      .then(successCallback)
      .catch(errorCallback);
  }

  function overwrite() {
    var params = getPostParams();
    params.overwrite = true;
    $http
      .post(serverAddress.getBaseUrl() + AMOUNT_CHANGE_PATH, params)
      .then(successCallback)
      .catch(errorCallback);
  }

  function dismiss() {
    dialog.close();
  }

  /* Private functions */
  function successCallback() {
    notifications.clear();
    parentScope.comments.changeState = '';
    notifications.addCurrentView('info', 'Cambio de indemnización exitoso');
    dialog.close();
  }

  function errorCallback() {
    notifications.addCurrentView('error', 'Error guardando datos. Revista el formato de los números');
  }

  function getPostParams() {
    return {
      claim_id: claimId,
      legalfile_id: legalfileId,
      type: amountData.amountType,
      regulation: amountData.amountRegulation,
      moral: amountData.amountMoral,
      others: amountData.others
    };
  }
}
