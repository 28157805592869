'use strict';

import angular from 'angular';

let env = angular.module("reclamador.version", [])

.constant("version", "112.6.4")

;

export default { env, version: "112.6.4" };
