AirlineClaim.$inject = ['$resource', 'serverAddress'];

export default function AirlineClaim($resource, serverAddress) {
  const AIRLINE_CLAIM_PATH = 'api2/v1/claim/:id/';
  return $resource(
    serverAddress.getBaseUrl() + AIRLINE_CLAIM_PATH,
    {},
    {
      update: {
        method: 'PATCH',
        params: {},
        isArray: false
      }
    }
  );
}
