import moment from 'moment';

/* generates a aproximate conversion fron seconds count to human text */

export default function secondsToDate() {
  return function(seconds) {
    const duration = moment.duration(seconds * 1000);

    const years = duration.years();
    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    if(!hours && !days && !months && !years) {
      return minutes + ' minutos';
    }

    let output = '';

    if(minutes && !days && !months && !years) {
      output = minutes + (minutes == 1 ? ' minuto' : ' minutos');
    }

    if(hours && !years && !months) {
      output = hours + (hours == 1 ? ' hora' : ' horas') + (output == '' ? '' : (' y ' + output));
    }

    if(days && !years) {
      output = days + (days == 1 ? ' día' : ' días') + (output == '' ? '' : (' y ' + output));
    }

    if(months) {
      output = months + (months == 1 ? ' mes' : ' meses') + (output == '' ? '' : (' y ' + output));
    }

    if(years) {
      output = years + (years == 1 ? ' año' : ' años') + (output == '' ? '' : (' y ' + output));
    }

    return output;
  };
}
