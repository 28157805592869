UtilsFactory.$inject = ['$window'];

export default function UtilsFactory($window) {
  return {
    redirect: redirect,
    normalizeString: normalizeString,
    reduceObject,
    formatObjectWithNullValue,
    objectToQueryString,
    reduceArrayToObject
  };

  function redirect(path) {
    $window.location = path;
  }

  function normalizeString(inString = '') {
    // Start with lowercase version
    var retString = inString.toLowerCase();

    // Replace whitespaces with underscore
    retString = retString.replace(/\s+/g, '_');

    // Replace ñ character with n
    retString = retString.replace(/ñ/g, 'n');

    // Remove exclamation/interrogation characters
    retString = retString.replace(/\?|\¿|\!|\¡/g, '');

    // Remove accents and asteriscs
    retString = retString
      .replace(/á|à|ä/g, 'a')
      .replace(/é|è|ë/g, 'e')
      .replace(/ó|ò|ö/g, 'o')
      .replace(/í|ì|ï/g, 'i')
      .replace(/ú|ù|ü/g, 'u')
      .replace(/ç/g, 'c')
      .replace(/\*/g, '');

    // Remove parentheses, percent signs and other characters
    retString = retString.replace(/\(|\)|%|{|}|#|@|\"|\'|&|\/|=|\$/g, '');

    return retString;
  }

  /**
  * Convert object {a: {keyName: 'column_a', valueName: 1}} to {column_a: 1}
  **/
  function reduceObject(obj, keyName = 'column', valueName = 'value') {
    return Object.keys(obj).reduce((memo, key) => {
      memo[obj[key][keyName]] = obj[key][valueName];
      return memo;
    }, {});
  }

  /**
  * Convert array [{keyName: 'column_a', valueName: 1}, {keyName: 'column_b', valueName: 2}] to
  * {column_a: 1, column_b: 2}
  **/
  function reduceArrayToObject(array, keyName = 'column', valueName = 'value') {
    return array.reduce((memo, obj) => {
      memo[obj[keyName]] = obj[valueName];
      return memo;
    }, {});
  }

  /**
  * Convert object {'a': null} into {'a__isnull': true}
  **/
  function formatObjectWithNullValue(obj){
    Object.keys(obj).forEach(filterKey => {
      // Special case when we explicitly want to get columns with null value
      if (obj[filterKey] === null){
        const nullFilterName = `${filterKey}__isnull`;
        obj[nullFilterName] = true;
        delete obj[filterKey];
      }
    });
    return obj;
  }

  /**
  * Convert object into query string parameters.
  * Example: {'a': 1, 'b': 2} => 'a=1&b=2'
  */
  function objectToQueryString(obj) {
    const keys = Object.keys(obj);
    const keyValuePairs = keys.map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
    });
    return keyValuePairs.join('&');
  }
}
