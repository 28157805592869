AirlinesSalespeopleGoalsPanelCtrl.$inject = [
  '$scope',
  '$http',
  '$window',
  '$route',
  'serverAddress',
  'AirlineSalesman',
  'notifications',
  'PanelTools',
  '$q',
  '$timeout'
];

export default function AirlinesSalespeopleGoalsPanelCtrl(
  $scope,
  $http,
  $window,
  $route,
  serverAddress,
  AirlineSalesman,
  notifications,
  PanelTools,
  $q,
  $timeout
) {
  var vm = this;


  /* Variables */
  vm.layoutSettings = $route.current.$$route.layoutSetting;
  vm.sales_goal_period = 'month';
  vm.currentMonth = true;
  vm.goalsSalesSteps = [
    { name: 'Clientes nuevos', filter: 'paid' },
    { name: 'Clientes a descontar', filter: 'discounted' },
    { name: 'Clientes recuperados', filter: 'recovered' },
    { name: 'Total', filter: 'total' }
  ];
  vm.busy = true;
  vm.updatePeriod = updatePeriod;
  vm.consolidateMonth = consolidateMonth;
  vm.downloadHistoricExcel = downloadHistoricExcel;


  /* Startup */
  PanelTools.last12Months(vm);


  $scope.$on('ngRepeatFinished', $window.rclmng_globals.ux.pepinColFixer.resizeTables);


  $q.all([
    AirlineSalesman.get().$promise,
    PanelTools.getSalesmenGoalsCounts(vm)
  ]).then(
    function(responses) {
      vm.salesman = responses[0];
      vm.salesGoalsCounts = responses[1].data;

      vm.busy = false;
      $timeout($window.rclmng_globals.ux.pepinColFixer.init);
    },
    function() {
      notifications.addCurrentView('error', 'Lo sentimos, se produjo un error solicitando la informe.');
      vm.busy = false;
    }
  );


  /* Functions */
  function updateSalesGoalsCounts() {
    return PanelTools.getSalesmenGoalsCounts(vm).then(function(response) {
      vm.salesGoalsCounts = response.data;
    });
  }


  function updatePeriod(period) {
    // reset tables
    vm.busy = true;
    $window.rclmng_globals.ux.pepinColFixer.clearTables();

    vm.sales_goal_period = period;
    vm.currentMonth = false;

    if (vm.sales_goal_period.split('-')[0] === '00') {
      vm.currentMonth = true;
    }

    updateSalesGoalsCounts().then(
      function() {
        // ux update
        vm.busy = false;
        $timeout($window.rclmng_globals.ux.pepinColFixer.init);
      },
      function() {
        notifications.addCurrentView('error', 'Lo sentimos, se produjo un error solicitando la informe.');
        vm.busy = false;
      }
    );
  }


  function consolidateMonth(goalPeriod) {
    vm.busy = true;
    $window.rclmng_globals.ux.pepinColFixer.clearTables();

    $http({
      url: serverAddress.getBaseUrl() + 'sales/consolidatemonth/',
      method: 'POST',
      data: { goal_period: goalPeriod }
    }).then(function() {
      updateSalesGoalsCounts().then(
        function() {
          // ux update
          vm.busy = false;
          $timeout($window.rclmng_globals.ux.pepinColFixer.init);
        },
        function() {
          notifications.addCurrentView('error', 'Lo sentimos, se produjo un error solicitando la informe.');
          vm.busy = false;
        }
      );
    });
  }


  function downloadHistoricExcel(goalPeriod) {
    $http({
      url: serverAddress.getBaseUrl() + 'sales/historicExcel/',
      method: 'GET',
      params: { goal_period: goalPeriod }
    }).then(function(response) {
      $window.location = response.data;
    });
  }
}
