TaxesRoutes.$inject = ['$routeProvider', 'TaxesConstants'];

export default function TaxesRoutes($routeProvider, TaxesConstants) {
  $routeProvider.when('/taxes/lawyers/counts', {
    title: 'Panel Abogados Impuestos',
    templateUrl: 'partials/common/panels/lawyers.html',
    data: {
      permissions: {
        only: 'segment-perobject-TA-crm_view_lawyers_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Impuestos',
      iconClass: 'far fa-money-bill-alt',
      cardHead: 'Panel Abogados Impuestos'
    },
    controller: 'TaxesLawyerPanelCtrl',
    controllerAs: 'vm',
    segmentID: TaxesConstants.SEGMENT_ID
  });

  $routeProvider.when('/taxes/salespeople/counts', {
    title: 'Panel Comerciales Impuestos',
    templateUrl: 'partials/common/panels/sales.html',
    data: {
      permissions: {
        only: 'segment-perobject-TA-crm_view_salespeople_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Impuestos',
      iconClass: 'far fa-money-bill-alt',
      cardHead: 'Panel Comerciales Impuestos'
    },
    controller: 'CommonSalesDashboardCtrl',
    controllerAs: 'vm',
    claimListBaseURL: '#/taxes-claims/list/',
    SalesmanService: 'TaxesSalesman',
    salesPeopleCountsURL: 'taxes/salespeople/counts/',
    segmentName: 'Impuestos',
    segmentID: TaxesConstants.SEGMENT_ID
  });

  const permissions = {
    only: 'segment-perobject-TA-crm_view_claims_list',
    redirectTo: 'no-permissions'
  };

  const listConfig = {
    title: 'Listado de Reclamaciones de Impuestos',
    templateUrl: 'partials/common/baseList/claim-list.html',
    data: { permissions },
    layoutSetting: {
      contentTitle: 'Impuestos',
      iconClass: 'far fa-money-bill-alt'
    },
    baseListSettings: {
      typeList: 'taxes',
      typeTitle: 'impuestos',
      templateListTable: 'js/modules/segments/taxes/partials/claim-list-table.html',
      templateListFilter: 'js/modules/segments/taxes/partials/claim-list-filters.html',
      title: 'Reclamaciones Impuestos',
      listResultsText: 'reclamaciones de impuestos'
    },
    controller: 'TaxesClaimListCtrl',
    controllerAs: 'vm',
    segmentID: TaxesConstants.SEGMENT_ID
  };

  $routeProvider.when('/taxes-claims', listConfig);
  $routeProvider.when('/taxes-claims/list/filter/:filter', listConfig);

  $routeProvider.when('/taxes-claims/:id', {
    /* TODO */
    title: 'Detalle de Reclamacion de Impuestos',
    templateUrl: 'js/modules/segments/taxes/partials/detail.html',
    data: {
      permissions: {
        only: 'segment-perobject-TA-crm_view_claim_detail',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Impuestos',
      iconClass: 'far fa-money-bill-alt'
    },
    controller: 'TaxesClaimDetailCtrl',
    controllerAs: 'vm',
    segmentID: TaxesConstants.SEGMENT_ID,
    reloadOnSearch: false
  });
}
