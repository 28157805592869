fitlerListFormatCell.$inject = ['$filter', '$compile'];

export default function fitlerListFormatCell($filter, $compile) {
  return {
    link: function($scope, $element) {
      const colDef = $scope.coldef.find(it => it.fieldName == $scope.data.key);
      if (!colDef) throw new Error('Wrong colDef configuration');
      const preCompiled = formatValue($scope.data.value, colDef);
      const linkFn = $compile(preCompiled);
      linkFn($scope, clone => $element.append(clone));
      $element.addClass(colDef.class);
    },
    scope: {
      coldef: '<',
      data: '<'
    },
    restrict: 'A',
    template: ''
  };
  function formatValue(value, columnDef) {
    if (columnDef.type == 'date') {
      return `<span>${$filter('date')(value, 'shortDate') || ''}</span>`;
    } else if (columnDef.type == 'link') {
      return `<a href="${columnDef.href}/${value}" target="blank" class="btn-link">${value}</a>`;
    }
    return value ? `<span>${value}</span>` : '';
  }
}
