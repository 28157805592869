MassiveRoutes.$inject = ['$routeProvider'];

export default function MassiveRoutes($routeProvider) {
  $routeProvider.when('/massive-changes', {
    title: 'Cambios masivos',
    templateUrl: 'js/modules/massive/massive-changes/index.html',
    data: {
      permissions: {
        only: 'segment-global-crm_execute_bulk_changes',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Cambios Masivos',
      iconClass: 'fas fa-file',
      cardHead:
        'Sube un fichero CSV con IDs de reclamaciones o de expedientes, un ID en cada línea.'
    },
    controller: 'MassiveChangesCtrl',
    controllerAs: 'vm'
  });

  $routeProvider.when('/documents-inbox', {
    title: 'Gestión masiva de documentos',
    templateUrl: 'js/modules/massive/documents-inbox/index.html',
    data: {
      permissions: {
        only: 'segment-global-crm_manage_bulk_documentation',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Cambios masivos',
      iconClass: 'fas fa-file',
      cardHead: ''
    },
    controller: 'DocumentsInboxCtrl',
    controllerAs: 'vm'
  });

  $routeProvider.when('/massive-doc-download', {
    title: 'Descarga masiva de documentos',
    templateUrl: 'js/modules/massive/massive-doc-download/index.html',
    data: {
      permissions: {
        only: 'segment-global-crm_execute_bulk_changes',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Descarga masiva de documentos',
      iconClass: 'fas fa-file',
      cardHead: 'Descarga masiva de documentos'
    },
    controller: 'MassiveDocDownloadCtrl',
    controllerAs: 'vm'
  });
  $routeProvider.when('/bought-portfolio/', {
    title: 'Compra de cartera',
    templateUrl: 'js/modules/massive/bought-porfolio/index.html',
    data: {
      permissions: {
        only: 'segment-global-crm_execute_bulk_changes',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Compra de cartera',
      iconClass: 'fas fa-file',
      cardHead: 'Compra de cartera'
    },
    controller: 'BoughtPortfolioCtrl',
    controllerAs: 'vm'
  });
};
