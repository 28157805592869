pendingDocumentsCtrl.$inject = ['$scope', 'serverAddress', 'notifications', '$http'];

export default function pendingDocumentsCtrl($scope, serverAddress, notifications, $http) {
  const vm = this;

  Object.assign(vm, {
    newDocs: [],
    pendingDocuments: [],

    // bindings
    djangoModel: null,
    claimId: null,
    documentTypes: [],
    updateClaimCb: null,
    documents: [],
    busy: false,

    // methods
    addPendingDocument,
    deletePendingDocument,
    sendPendingDocumentsEmail
  });

  $scope.$watch('vm.documents', docs => {
    vm.pendingDocuments = docs && docs.length ? docs.filter(d => d.is_missing) : [];
  });

  // method definitions
  function addPendingDocument(file, index) {
    vm.busy = true;

    var data = Object.assign({}, file, {
      claim_id: vm.claimId,
      django_model: vm.djangoModel
    });

    $http.post(`${serverAddress.getBaseUrl()}claim/documents/pending/add/`, data)
      .then(() => {
        vm.newDocs.splice(index, 1);
        if(vm.updateClaimCb) {
          vm.updateClaimCb('Documento pendiente añadido');
        } else {
          vm.busy = false;
        }
      })
      .catch(e => {
        notifications.addCurrentView('error', e.data);
        vm.busy = false;
      });
  }

  function deletePendingDocument(document) {
    vm.busy = true;

    var data = Object.assign({}, document, {
      django_model: vm.djangoModel
    });

    $http.post(`${serverAddress.getBaseUrl()}claim/documents/pending/delete/`, data)
      .then(() => {
        if(vm.updateClaimCb) {
          vm.updateClaimCb('Documento pendiente eliminado');
        } else {
          vm.busy = false;
        }
      })
      .catch(e => {
        notifications.addCurrentView('error', e.data);
        vm.busy = false;
      });
  }

  function sendPendingDocumentsEmail() {
    vm.busy = true;

    const data = {
      claim_id: vm.claimId,
      django_model: vm.djangoModel
    };

    notifications.addCurrentView('info', 'Preparando el correo. No pulses el botón otra vez');

    $http.post(`${serverAddress.getBaseUrl()}claim/documents/pending/send/`, data)
      .then(() => {
        if(vm.updateClaimCb) {
          vm.updateClaimCb('Correo de solicitud de documentación pendiente enviado al cliente');
        } else {
          vm.busy = false;
        }
      })
      .catch(e => {
        notifications.addCurrentView('error', e.data);
        vm.busy = false;
      });
  }
}