FlightDocuments.$inject = ['$resource', 'serverAddress'];

var FLIGHT_DOCUMENTS_PATH = 'api2/v1/flightdocument/:flightDocumentId/?format=json';

// TODO: add test case to check that the $update function is defined
export default function FlightDocuments($resource, serverAddress) {
  return $resource(
    serverAddress.getBaseUrl() + FLIGHT_DOCUMENTS_PATH,
    {},
    {
      update: {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json;' }
      }
    }
  );
}
