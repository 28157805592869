onFinishRender.$inject = ['$timeout'];

export default function onFinishRender($timeout) {
  return {
    restrict: 'A',
    scope: false,
    link: function(scope) {
      if (scope.$last === true) {
        $timeout(() => scope.$emit('ngRepeatFinished'));
      }
    }
  };
}
