ClientListCtrl.$inject = ['$scope', '$controller', 'serverAddress', '$location', 'ClientConstants'];

export default function ClientListCtrl(
  $scope,
  $controller,
  serverAddress,
  $location,
  ClientConstants
) {
  const vm = this;

  // redirect old ?s=SEARCH urls
  var search = $location.search();
  if (search['s']) {
    $location.search({}).path('/clients/list/filter/filterText=' + search['s']);
  }

  const METHODS = {
    getImpersonateUrl
  };

  const INITIAL_STATE = {
    itemsPath: 'client/list/',
    listPath: 'clients/list/filter/',
    listPathNoFIlter: 'clients',
    isClientList: true,
    defaultColumnDef: ClientConstants.defaultColumnDef,
    filterFieldInfo: ClientConstants.filterFieldInfo
  };

  $controller('BaseListCtrl', { vm: vm, $scope: $scope });

  Object.assign(vm, METHODS, INITIAL_STATE);

  /* GO */
  vm.initBaseList(
    ClientConstants.SEGMENT_ID,
    ClientConstants.ALIAS,
    ClientConstants.permissions
  );

  // Exclusive client method
  function getImpersonateUrl(clientId) {
    return serverAddress.getImpersonateUrl() + 'impersonate/' + clientId;
  }
}
