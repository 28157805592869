/**
 * For arrays of dictionaries, find a element (the first occurency if any) and returns a given field
 *
 * Usage: {{ myArray | getArrayElementField:arrayIdentifier:fieldToReturn:keyIdentifier }}
 *
 * The filter loops myArray throguht myArray looking for an element with keyIdentifier
 * (or 'id' if not set) equals to elementValue and returns the value of fieldName key.
 *
 * Example: If myArray is [{id: 1, name: 'dict1', value: 'val1'}, {id: 2, name: 'dict2', value: 'val2'}]
 * {{ myArray | getArrayElementField:2:'value' }} will return 'val2'
 *
 * or using a differnent key than 'id'
 * {{ myArray | getArrayElementField:'dict2':'value':'name' }} will return also 'val2'
 */

export default function getArrayElementField() {
  return (array, elementValue, fieldName, key) => {
    if(!Array.isArray(array) || !fieldName) throw new Error('getArrayElementField wrong initialization');

    if(key) {
      return array.filter(e => e[key] == elementValue).map(e => e[fieldName])[0];
    }
    return array.filter(e => e.id == elementValue).map(e => e[fieldName])[0];
  };
}
