PhoneClaim.$inject = ['$resource', 'serverAddress'];
export default function PhoneClaim($resource, serverAddress) {
  const PHONE_CLAIM_PATH = 'api2/v1/phoneclaim/:id/';
  return $resource(
    serverAddress.getBaseUrl() + PHONE_CLAIM_PATH,
    {},
    {
      update: {
        method: 'PATCH',
        params: {},
        isArray: false
      }
    }
  );
}
