/* eslint-disable angular/di */
import angular from 'angular';
import './index.css';

import './http-auth-interceptor';
import routes from './routes';
import AuthService from './auth.service';
import PermissionsService from './permissions.service';
import User from './user.service';
import Profiles from './Profiles';
import { LoginCtrl, LogoutCtrl } from './login.controller';
import { PasswordResetCtrl } from './password-reset.controller';
import { PasswordResetConfirmCtrl } from './password-reset-confirm.controller';
import { ActivateAccountCtrl } from './activate-account.controller';

angular
  .module('reclamador.Auth', ['http-auth-interceptor', 'reclamador.worker', 'permission'])
  .factory('User', User)
  .factory('Profiles', Profiles)
  .factory('AuthService', AuthService)
  .factory('PermissionsService', PermissionsService)
  .controller('LoginCtrl', LoginCtrl)
  .controller('LogoutCtrl', LogoutCtrl)
  .controller('PasswordResetCtrl', PasswordResetCtrl)
  .controller('PasswordResetConfirmCtrl', PasswordResetConfirmCtrl)
  .controller('ActivateAccountCtrl', ActivateAccountCtrl)
  .config(routes)
  .constant('TODAY', new Date())
  .run(['AuthService', 'PermissionsService', runPerm]);

function runPerm(AuthService, PermissionsService) {
  AuthService.init()
    .then(data => {
      PermissionsService.setAll(data.permissions);
      AuthService.restoreSesionUrl();
    });
}
