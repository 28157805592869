/* eslint-disable angular/di */
import angular from 'angular';

import './styles.css';

import Worker from './Worker';
import WorkerProfileCtrl from './WorkerProfileCtrl';
import BaseCtrl from '../../controllers/BaseCtrl';

angular
  .module('reclamador.worker', [])
  .controller('WorkerProfileCtrl', WorkerProfileCtrl)
  .component('workerProfileComponent', {
    template: `
    <div class="card-body">
      <div class="row justify-content-center">
        <div class="col-md-6 col-lg-4 col-xl-2 text-center">
          <picture id="worker_avatar" class="worker_avatar">
            <button class="worker_avatar-button btn green" ng-click="vm.updateAvatar($event)"><i class="fa fa-edit"></i></button>
            <img ng-src="{{vm.worker.avatar}}" class="rounded" alt="avatar" />
            <div class="worker_avatar-spinner" ng-class="{'show': vm.avatarLoading}"><i class="fas fa-spinner fa-5x fa-spin green"></i></div>
          </picture>
          <input id="worker_avatar-input" type="file" class="file-upload d-none" nv-file-select uploader="vm.uploader" accept="image/*" />
        </div>
        <div class="col-md-6 col-lg-6 col-xl-4">
          <div class="row justify-content-center">
            <form class="col-lg-12 col-xl-10" name="profileForm" ng-submit="vm.updateProfile()">
              <div class="form-group">
                <label for="first_name">Nombre:</label>
                <input type="text" class="form-control" name="first_name" id="first_name" placeholder="Introduce tu nombre" ng-model="vm.worker.name">
              </div>
              <div class="form-group">
                <label for="last_name">Apellidos:</label>
                <input type="text" class="form-control" name="last_name" id="last_name" placeholder="Introduce tus apellidos" ng-model="vm.worker.surnames">
              </div>
              <div class="form-group">
                <label for="email">Email:</label>
                <input type="email" class="form-control" name="email" id="email" ng-model="vm.worker.email" disabled>
              </div>

              <div class="form-group">
                <button class="btn btn-primary" type="submit" ng-disabled="!profileForm.$dirty">Guardar</button>
              </div>
            </form>

            <div class="col-lg-12 col-xl-10">
              <div class="mt-4 mb-4 p-0">
                <button class="btn btn-outline-primary" ng-click="vm.passwordReset()"><i class="fas fa-envelope"></i> Redefinir contraseña</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    `,
    controller: WorkerProfileCtrl,
    controllerAs: 'vm'
  })
  .factory('Worker', Worker)
  .config([
    '$routeProvider',
    function($routeProvider) {
      $routeProvider.when('/my-profile', {
        title: 'Mi perfil',
        template: '<worker-profile-component></worker-profile-component>',
        layoutSetting: {
          iconClass: 'fas fa-briefcase',
          cardHead: 'Mi perfil'
        },
        controller: BaseCtrl,
        controllerAs: 'vm'
      });
    }
  ]);
