PasswordResetCtrl.$inject = ['Profiles', 'TODAY'];
export function PasswordResetCtrl(Profiles, TODAY) {
  const vm = this;
  vm.busy = false;
  vm.TODAY = TODAY;
  vm.error = false;
  vm.errorMsg = '';
  vm.done = false;
  vm.email = '';

  vm.requestPasswordReset = function() {
    vm.busy = true;
    Profiles.reset(vm.email)
      .then(() => {
        vm.done = true;
      })
      .catch(() => {
        vm.error = true;
        vm.errorMsg = 'Se ha producido un error inesperado.';
      })
      .finally(() => {
        vm.busy = false;
      });
  };
}
