import angular from 'angular';

import pendingDocumentsCtrl from './pendingDocumentsCtrl';

angular
  .module('reclamador.components.pendingDocuments', [])
  .controller('pendingDocumentsCtrl', pendingDocumentsCtrl)
  .component('pendingDocuments', {
    template: require('./template.html'),
    bindings:{
      djangoModel: '<',
      claimId: '<',
      documentTypes: '<',
      documents: '<',
      updateClaimCb: '<'
    },
    controller: pendingDocumentsCtrl,
    controllerAs: 'vm'
  });
