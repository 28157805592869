LoginCtrl.$inject = ['AuthService', 'TODAY'];
export function LoginCtrl(AuthService, TODAY) {
  const vm = this;
  vm.TODAY = TODAY;

  vm.credentials = {
    username: '',
    password: ''
  };

  vm.busy = false;
  vm.shake = false;

  vm.login = function() {
    vm.busy = true;
    vm.shake = false;
    AuthService.login(vm.credentials)
      .then()
      .catch(() => {
        vm.busy = false;
        vm.shake = true;
      });
  };
}

LogoutCtrl.$inject = ['AuthService'];
export function LogoutCtrl(AuthService) {
  const vm = this;
  vm.logout = () => AuthService.logout();
}
