ActivateAccountCtrl.$inject = ['$routeParams', 'Profiles', 'TODAY'];
export function ActivateAccountCtrl($routeParams, Profiles, TODAY) {
  const vm = this;
  vm.busy = false;
  vm.TODAY = TODAY;
  vm.invalidLinkError = false;
  vm.invalidLinkErrorMsg = '';
  vm.formError = false;
  vm.formErrorMsg = '';
  vm.done = false;
  vm.new_password1 = '';
  vm.new_password2 = '';

  var clearForm = function() {
    vm.new_password1 = '';
    vm.new_password2 = '';
  };

  vm.activateAccount = function() {
    vm.busy = true;
    vm.formError = false;
    Profiles.activateAccount($routeParams.uidb36, $routeParams.token, vm.new_password1, vm.new_password2)
      .then(response => {
        if (response.data.status == 'ok') {
          vm.done = true;
        } else if (response.data.status == 'error') {
          switch (response.data.error_code) {
            case 'invalid_link':
              vm.invalidLinkErrorMsg = response.data.message;
              vm.invalidLinkError = true;
              break;
            default:
              vm.formErrorMsg = response.data.message;
              vm.formError = true;
              clearForm();
          }
        }
      })
      .catch(() => {
        vm.formError = true;
        vm.formErrorMsg = 'Se ha producido un error inesperado.';
        clearForm();
      })
      .finally(() => {
        vm.busy = false;
      });
  };
}
