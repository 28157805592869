import angular from 'angular';
import tabs from 'ui-bootstrap4/src/tabs';

import './styles.css';

angular
  .module('reclamador.components.claim.tabs', [tabs])
  .component('claimTabs', {
    bindings: {
      tabConfig: '<',
      tabConfigIndex: '='
    },
    scope: false,
    template: `
    <uib-tabset active="activePill" vertical="true" type="pills" active="vm.tabConfigIndex" class="claim-tabs">
      <uib-tab 
        ng-repeat="(key, tab) in vm.tabConfig"
        index="key"
        select="vm.auxTabSelect(tab.indexKey, tab.select)"
        permission 
        permission-only="tab.permission"
      >
        <uib-tab-heading>
          {{ tab.heading }}
          <span class="badge {{ tab.headingBadge ? 'badge-primary' : 'badge-secondary' }}">{{tab.headingBadge}}</span>
        </uib-tab-heading>
        <div 
          ng-include="tab.template"         
          permission 
          permission-only="tab.permission"
        ></div>
      </uib-tab>
    </uib-tabset>
    `,
    controllerAs: 'vm'
  });
