/* eslint-disable angular/di */
import angular from 'angular';
import MassiveRoutes from './routes';
import MassiveChangesCtrl from './massive-changes/MassiveChangesCtrl';
import DocumentsInboxCtrl from './documents-inbox/DocumentsInboxCtrl';
import SearchModalCtrl from './documents-inbox/SearchModalCtrl';
import ExtraInfoModalCtrl from './documents-inbox/ExtraInfoModalCtrl';
import MergeModalCtrl from './documents-inbox/MergeModalCtrl';
import SliceModalCtrl from './documents-inbox/SliceModalCtrl';
import AssignToLawyerModalCtrl from './documents-inbox/AssignToLawyerModalCtrl';
import ClaimsandlegalfilesService from './documents-inbox/ClaimsandlegalfilesService';
import {DocumentsInboxService, ProcessedDocumentsInboxService} from './documents-inbox/DocumentsInboxService';
import MassiveDocDownloadCtrl from './massive-doc-download/MassiveDocDownloadCtrl';
import BoughtPortfolioCtrl from './bought-porfolio/BoughtPortfolioCtrl';

angular
  .module('reclamador.massive', [])
  .controller('MassiveChangesCtrl', MassiveChangesCtrl)
  .controller('DocumentsInboxCtrl', DocumentsInboxCtrl)
  .controller('SearchModalCtrl', SearchModalCtrl)
  .controller('ExtraInfoModalCtrl', ExtraInfoModalCtrl)
  .controller('MergeModalCtrl', MergeModalCtrl)
  .controller('SliceModalCtrl', SliceModalCtrl)
  .controller('AssignToLawyerModalCtrl', AssignToLawyerModalCtrl)
  .controller('MassiveDocDownloadCtrl', MassiveDocDownloadCtrl)
  .controller('BoughtPortfolioCtrl', BoughtPortfolioCtrl)
  .factory('DocumentsInboxService', DocumentsInboxService)
  .factory('ProcessedDocumentsInboxService', ProcessedDocumentsInboxService)
  .factory('ClaimsandlegalfilesService', ClaimsandlegalfilesService)
  .config(MassiveRoutes);
