/* eslint-disable angular/di */
import angular from 'angular';

import GenericClaimDetailCtrl from './claimDetailCtrl';
import GenericClaimListCtrl from './claimListCtrl';
import GenericClaimUploadCtrl from './claimUploadCtrl';
import ClaimResource from './claimResource';

import OtherLawyer from './otherLawyer';
import GenericConstants from './constants';

import GenericClaimRoutes from './routes';

angular
  .module('reclamador.segments.generic', [])
  .controller('GenericClaimDetailCtrl', GenericClaimDetailCtrl)
  .controller('GenericClaimListCtrl', GenericClaimListCtrl)
  .controller('GenericClaimUploadCtrl', GenericClaimUploadCtrl)
  .factory('GenericClaim', ClaimResource)
  .factory('OtherLawyer', OtherLawyer)
  .constant('GenericConstants', GenericConstants)

  .config(GenericClaimRoutes);
