export default function datetimePicker() {
  return {
    restrict: 'A',
    require: '?ngModel',
    link: function(scope, elem, attrs, ngModel) {
      function changeDate(event) {
        var date = event.date;
        scope.$apply(function() {
          ngModel.$setViewValue(date);
        });
      }
      elem
        .datetimepicker({
          autoclose: true,
          weekStart: 1,
          format: 'dd/mm/yy - hh:ii',
          language: 'es'
        })
        .on('changeDate', changeDate);
    }
  };
}
