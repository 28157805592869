Agency.$inject = ['$resource', 'serverAddress'];

var AGENCY_PATH = 'api2/v1/agency/?client=:clientId&format=:format&limit=:limit&offset=:offset&q=:filter&:query';

export default function Agency($resource, serverAddress) {
  return $resource(serverAddress.getBaseUrl() + AGENCY_PATH, {
    format: 'json',
    offset: 0,
    limit: 0,
    filter: '',
    query: ''
  });
}
