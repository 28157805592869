CommentEditService.$inject = ['$http', 'serverAddress'];

export default function CommentEditService($http, serverAddress) {
  return {
    editComment
  };

  function editComment(resourceUri, newComment) {
    const baseUrl = serverAddress.getBaseUrl().slice(0, -1);

    return $http.patch(`${baseUrl}${resourceUri}`, newComment);
  }
}
