import firebase from 'firebase/app';

/* @ngInject */
FirebaseFactory.$inject = ['AuthService'];
export default function FirebaseFactory(AuthService) {
  const FCMCollection = 'fcmTokensCRM';
  let firestore, messaging;

  const config = {
    apiKey: process.env.FIREBASE_apiKey,
    authDomain: process.env.FIREBASE_authDomain,
    databaseURL: process.env.FIREBASE_databaseURL,
    projectId: process.env.FIREBASE_projectId,
    storageBucket: process.env.FIREBASE_storageBucket,
    messagingSenderId: process.env.FIREBASE_messagingSenderId,
    appId: process.env.FIREBASE_appId
  };

  function init() {
    const app = firebase.initializeApp(config);

    messaging = app.messaging();
    firestore = app.firestore();

    // Load reclamador SW parametrized by environment
    navigator.serviceWorker
      .register(
        `rclm-sw.js?messagingSenderId=${config.messagingSenderId}&appId=${config.appId}&projectId=${config.projectId}&apiKey=${config.apiKey}`
      )
      .then(function(registration) {
        console.log('FCM initiated');
        messaging.useServiceWorker(registration);
      });

    // Request permissions for token assignation
    Notification
      .requestPermission()
      .then(() => messaging.getToken())
      .then(token => updateToken(token))
      .catch(function(err) {
        console.log('Unable to get permission to notify.', err);
      });

    return messaging;
  }

  /**
   * update token in firebase database
   * @param token token as a value
   */
  function updateToken(currentToken) {
    if (currentToken) {
      console.log(currentToken);
      AuthService.getUser().then(data => {
        const userId = data.user.id;
        const tokenDoc = firestore.doc(`${FCMCollection}/${userId}`);
        tokenDoc.set({ token: currentToken });
      });
    } else {
      console.log('No Instance ID token available. Request permission to generate one.');
    }
  }

  return {
    init
  };
}
