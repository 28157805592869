export default function activeOrSelected() {
  return function(lawyers, id) {
    var filtered = [];
    (lawyers || []).forEach(lawyer => {
      if (lawyer.is_active || lawyer.id === id) {
        filtered.push(lawyer);
      }
    });
    return filtered;
  };
}
