import angular from 'angular';

import emptyObject from './emptyObject.filter';
import yesno from './yesno.filter';
import sentnotsent from './sentnotsent.filter';
import activeOrSelected from './active-or-selected.filter';
import capitalize from './/capitalize.filter';
import fillZeros from './fill-zeros.filter';
import minutesToDays from './minutes-to-days.filter';
import rc from './rc.filter';
import type_of_clinic from './type_of_clinic.filter';
import expressorpresumed from './express-or-presumed.filter';
import formatFileSizeFilter from './formatFileSizeFilter.filter';
import clickableText from './clickable-text.filter';
import formatCurrency from './format-currency.filter';
import secondsToDate from './seconds-to-date.filter';
import reverse from './reverse.filter';
import getArrayElementField from './get-array-element-field.filter';
import truncatefilename from './truncatefilename.filter';
import propsFilter from './propsFilter.filter';
import expenseType from './expense-type.filter';

angular.module('reclamador.filters', [])
  .filter('emptyObject', emptyObject)
  .filter('activeOrSelected', activeOrSelected)
  .filter('yesno', yesno)
  .filter('expressorpresumed', expressorpresumed)
  .filter('formatFileSize', formatFileSizeFilter)
  .filter('sentnotsent', sentnotsent)
  .filter('capitalize', capitalize)
  .filter('fillZeros', fillZeros)
  .filter('minutesToDays', minutesToDays)
  .filter('rc', rc)
  .filter('type_of_clinic', type_of_clinic)
  .filter('clickableText', clickableText)
  .filter('formatCurrency', formatCurrency)
  .filter('secondsToDate', secondsToDate)
  .filter('reverse', reverse)
  .filter('getArrayElementField', getArrayElementField)
  .filter('truncatefilename', truncatefilename)
  .filter('propsFilter', propsFilter)
  .filter('expenseType', expenseType);
