SOClaimListCtrl.$inject = ['$scope', '$controller', 'SOConstants'];

export default function SOClaimListCtrl($scope, $controller, SOConstants) {
  const vm = this;
  const claimDetailTabs = {};
  SOConstants.tabConfig.forEach(element => {
    claimDetailTabs[element.indexKey] = element.heading;
  });
  const INITIAL_STATE = {
    claimDetailTabs,
    ALIAS: SOConstants.ALIAS + '-claims',

    itemsPath: 'so-claim/list/',
    listPath: 'so-claims/list/filter/',
    listPathNoFIlter: 'so-claims',
    defaultColumnDef: SOConstants.defaultColumnDef,
    filterFieldInfo: SOConstants.filterFieldInfo
  };
  $controller('BaseListCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE);

  /** Initiate List! */
  vm.initBaseList(
    SOConstants.SEGMENT_ID,
    SOConstants.ALIAS,
    SOConstants.permissions
  );
}
