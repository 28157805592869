import angular from 'angular';

import FilterListCtrl from './FilterListCtrl';
import fitlerListFormatCell from './FilterListFormatCell.directive';

angular
  .module('reclamador.components.filterList', [])
  .controller('FilterListCtrl', FilterListCtrl)
  .directive('fitlerListFormatCell', fitlerListFormatCell)
  .component('filterListComponent', {
    template: `
    <div busy-directive busy="vm.busy" busy-msj="'Cargando el listado. Espere unos segundos'">
      <div ng-include="'js/components/filter-list/filter-list-header.html'"></div>
        <div class="card-body pt-0">
          <div class="baseListTable">
            <div class="baseListTableTopScrollBarWrap">
              <div class="baseListTableTopScrollBar"></div>
            </div>
          <div ng-include="'js/components/filter-list/filter-list-table.html'"></div>
        </div>
        <div class="card-footer">
          <div ng-include="'js/components/filter-list/filter-list-footer.html'"></div>
        </div>
      </div>
    </div>
    `,
    bindings:{
      filter: '<',
      settings: '<'
    },
    controller: FilterListCtrl,
    controllerAs: 'vm'
  });
