ConfirmLawyerChangeCtrl.$inject = [
  '$http',
  '$scope',
  'serverAddress',
  'legalfileId',
  'lawyer',
  'legalfilePrefix',
  'parentScope',
  'dialog',
  'notifications'
];

export default function ConfirmLawyerChangeCtrl(
  $http,
  $scope,
  serverAddress,
  legalfileId,
  lawyer,
  legalfilePrefix,
  parentScope,
  dialog,
  notifications
) {
  /* Functions */
  $scope.change = change;
  $scope.dismiss = dismiss;

  /* Function implementation */
  function change() {
    $http
      .post(serverAddress.getBaseUrl() + 'legalfile/lawyer/change/', {
        legalfile_id: legalfileId,
        lawyer: lawyer,
        legalfile_prefix: legalfilePrefix
      })
      .then(function() {
        parentScope.stateChangeComment = '';
        parentScope.displayLawyerChange = false;
        notifications.addCurrentView('info', 'Cambio de abogado exitoso');
        parentScope.updateFile();
        dialog.close();
      })
      .catch(function() {
        notifications.addCurrentView('error', 'No se ha podido cambiar de abogado');
        dialog.close();
      });
  }

  function dismiss() {
    dialog.close();
  }
}
