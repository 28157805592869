import angular from 'angular';
import $ from 'jquery';

import './styles.css';

FlightPanelsCtrl.$inject = ['$scope', '$timeout', 'FlightFactory', 'notifications'];

angular
  .module('reclamador.components.flightPanels', [])
  .controller('FlightPanelsCtrl', FlightPanelsCtrl)
  .component('flightPanels', {
    bindings: {
      flight: '<',
      flightStatsPath: '<',
      busy: '=',
      updateClaimCb: '<'
    },
    controller: 'FlightPanelsCtrl',
    controllerAs: 'vm',
    template: require('./template.html')
  });

function FlightPanelsCtrl ($scope, $timeout, FlightFactory, notifications) {
  const vm = this;

  Object.assign(vm, {
    // bindings
    flight: null,
    flightStatsPath: null,
    updateClaimCb: null,

    // Internal state
    updatingFlightWeather: false,
    loadingDepartureAirportFlights: false,
    loadingArrivalAirportFlights: false,
    loadingFlightStatistics: false,

    // data from to endpoints
    departureAirportFlights: null,
    arrivalAirportFlights: null,
    arrival_statistics: null,
    departure_statistics: null,

    // Validation constants
    flightValidationConstants: {
      dailyPercentaje: 20, // percentaje of flights from airpoirt to display warning
      delayTime: 181, // minimum delay to display warning
      minVisibility: 3, // meters
      maxWindBase: 60,
      maxWindClass2: 40,
      maxWindClass3: 25,
      //Coruña, Santiago, Vigo, Asturias, Santander, Bilbao, San Sebastián 40 km/hora
      class2Airports: ['LCG', 'SCQ', 'VGO', 'OVD', 'SDR', 'BIO', 'EAS'],
      // Florencia ('FLR') y Madeira ('FNC') 25 km/h
      class3Airports: ['FNC', 'FLR']
    },

    // processed validation data
    flightValidationConstantsFixed: {
      maxWindDeparture: null,
      maxWindArrival: null
    },

    // methods
    getWeatherRowClasses,
    prepareValidationConstants,
    updateFlightWeather,
    initFlightData,
    formatMinutesDelay,
    centerFlightsTables,
    diffMinutes
  });

  // set initial watch to update data if flight binding changes
  vm.$onInit = () => {
    $scope.$watch('vm.flight', function(flight){
      if(!flight) return;

      vm.initFlightData(flight);
      vm.flightValidationConstantsFixed = vm.prepareValidationConstants(flight, vm.flightValidationConstants);

      vm.centerFlightsTables('.flight-table-weather');
    });
  };

  /**
   * Initiate meterological constants based on flight arirports
   * @return {} object with max Wind for departure and arrival airport
   */
  function prepareValidationConstants(flight, constants) {
    const departureIata = flight.airport_departure.iata_code;
    const arrivalIata = flight.airport_arrival.iata_code;

    const constatsFixed = {
      maxWindDeparture: constants.maxWindBase,
      maxWindArrival: constants.maxWindBase
    };

    if(constants.class2Airports.indexOf(arrivalIata) != -1) constatsFixed.maxWindArrival = constants.maxWindClass2;
    if(constants.class3Airports.indexOf(arrivalIata) != -1) constatsFixed.maxWindArrival = constants.maxWindClass3;
    if(constants.class2Airports.indexOf(departureIata) != -1) constatsFixed.maxWindDeparture = constants.maxWindClass2;
    if(constants.class3Airports.indexOf(departureIata) != -1) constatsFixed.maxWindDeparture = constants.maxWindClass3;

    return constatsFixed;
  }

  /**
   * Center active rows in flight tables (jquery shit)
   * @return void
   */
  function centerFlightsTables(tableClass) {
    $timeout(() => {
      $(tableClass).each(function() {
        const $table = $(this); // eslint-disable-line
        const $activeRow = $table.find('.current:eq(0)');

        if(!$activeRow.length) return;

        $table[0].scrollTop = $activeRow.position().top + ($activeRow.height() / 2) - ($table.height() / 2);
      });
    });
  }

  /**
   * Process weather row information and return clasess for the html element
   * @param object row - A row with meteorological data
   * @param string time - Flight time to compare with meteorological data
   * @param boolean departure - ¿Is departure information?
   * @return string - class names to apply to element
   */
  function getWeatherRowClasses(row, time, departure) {
    let classes = [];
    const maxWind = departure ? vm.flightValidationConstantsFixed.maxWindDeparture : vm.flightValidationConstantsFixed.maxWindArrival;

    // compare row hour with flight hour
    if (time && diffMinutes(time, row.observation_time) <= 30) {
      classes.push('current');
    }

    if ((row.visibility && row.visibility != -9999 && row.visibility < vm.flightValidationConstants.minVisibility) ||
        (row.wind_speed && row.wind_speed > maxWind) ||
        (row.wind_gust && row.wind_gust > maxWind)) {
      classes.push('danger-row');
    }

    return classes.join(' ');
  }

  // request new data fron weather api
  function updateFlightWeather() {
    vm.updatingFlightWeather = true;
    vm.busy = true;

    FlightFactory.updateFlightWeather(vm.flight.id).then(function () {
      vm.updateClaimCb('Información meteorológica actualizada con éxito');
      vm.updatingFlightWeather = false;
      vm.centerFlightsTables('.flight-table-weather');
    });
  }

  // request airports and weather data
  function initFlightData(flight) {
    vm.loadingDepartureAirportFlights = true;
    FlightFactory.getDepartureAirportFlights(flight.id)
      .then(function(response) {
        vm.departureAirportFlights = response.data.same_date_and_departure_airport_flights.sort((a, b) => {
          return a.scheduled_departure > b.scheduled_departure ? 1 : -1;
        });
        vm.centerFlightsTables('.flight-table-departure-delay');
        vm.loadingDepartureAirportFlights = false;
      })
      .catch(function() {
        notifications.addCurrentView('error', 'Se produjo un error cargando la información del aeropuerto de salida.');
      });

    vm.loadingArrivalAirportFlights = true;
    FlightFactory.getArrivalAirportFlights(flight.id)
      .then(function(response) {
        vm.arrivalAirportFlights = response.data.same_date_and_arrival_airport_flights.sort((a, b) => {
          return a.scheduled_arrival > b.scheduled_arrival ? 1 : -1;
        });
        vm.centerFlightsTables('.flight-table-arrival-delay');
        vm.loadingArrivalAirportFlights = false;
      })
      .catch(function() {
        notifications.addCurrentView('error', 'Se produjo un error cargando la información del aeropuerto de llegada.');
      });

    vm.loadingFlightStatistics = true;
    FlightFactory.getAirportsFlightStatistics(flight.id)
      .then(function(response) {
        vm.departure_statistics = response.data.departure;
        vm.arrival_statistics = response.data.arrival;
        vm.loadingFlightStatistics = false;
      })
      .catch(function() {
        notifications.addCurrentView('error', 'Se produjo un error obteniendo las estadísticas de los aeropuertos.');
      });
  }

  // returns the difference between two dates in minutes
  function diffMinutes(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    const diffTime = Math.abs(d2.getTime() - d1.getTime());

    return Math.ceil(diffTime / (1000 * 60));
  }

  function formatMinutesDelay(minutes) {
    if(!minutes || minutes == 0) return '';

    const sign = minutes > 0;
    const absMinutes = minutes > 0 ? minutes : -minutes;
    const hours = parseInt(absMinutes / 60, 10);
    const minut = absMinutes % 60;

    return `${sign ? '+' : '-'}${hours ? (hours + 'h ') : ''}${minut} min`;
  }
}