import angular from 'angular';

import './styles.css';

angular
  .module('reclamador.components.documentsInboxCounter', [])
  .controller('documentsInboxCounterCtrl', documentsInboxCounterCtrl)
  .component('documentsInboxCounter', {
    template: `
      <div class="row-fluid"
          permission
          permission-only="'segment-global-crm_manage_bulk_documentation'">
        <div class="card">
          <div class="card-header"><h5>Documentación pendiente de gestionar</h5></div>
          <div class="card-body mb-0 pb-0 pt-5">
            <div class="ng-binding h1 text-center">
              <div class="d-inline-block position-relative green" style="font-size: 4rem;">
                <i class="far fa-file icon-one"></i>
                <i class="far fa-file icon-two"></i>
                <span class="pl-3">{{ vm.pendingDocs }}</span>
              </div>
              <div class="h1 pt-4 mb-0">
                <p ng-if="!vm.pendingDocs" class="no-animate mb-4" style="font-size: 1.2rem;">Todo en orden. ¡Buen trabajo!</p>
                <a href="#/documents-inbox" ng-if="vm.pendingDocs" style="font-size: 1rem;font-weight: 100;" class="no-animate">Ir a la gestión masiva de documentos</p>
              </div>
            </div>
          </div>
        </div>
      </div>`,
    controller: documentsInboxCounterCtrl,
    controllerAs: 'vm'
  });

documentsInboxCounterCtrl.$inject = ['DocumentsInboxService'];

function documentsInboxCounterCtrl(DocumentsInboxService) {
  const vm = this;
  vm.pendingDocs = 0;

  // The easiest way of getting the count: ask for only 1 doc
  // NOT AWESOME
  DocumentsInboxService.get({ limit: 1 }).$promise.then(response => {
    vm.pendingDocs = response.meta.total_count;
  });
}
