const SEGMENT_ID = 11,
  MODEL_PROVIDER = 'LegalService',
  CLAIM_TYPE = 'legalservices';

const CONTACT_PREFERENCES = [
  { id: 0, value: 0, label: 'Mañana' },
  { id: 1, value: 1, label: 'Tarde' },
  { id: 2, value: 2, label: 'Indiferente' }
];

const PAYMENT_TYPE = [
  { id: 0, value: 0, label: 'Link' },
  { id: 1, value: 1, label: 'Transferencia' }
];

const PAYMENT_MODE = [
  { id: 0, value: 0, label: 'Único' },
  { id: 1, value: 1, label: 'Prov. de fondos' }
];

const PAYMENT_BOOLEAN_TYPE = [
  { value: null, label: 'N/A' },
  { value: true, label: 'Pagado' },
  { value: false, label: 'No pagado' }
];

export default {
  SEGMENT_ID,
  MODEL_PROVIDER,
  DJANGO_MODEL: MODEL_PROVIDER,
  SEGMENT_PREFIX: 'SG',
  ALIAS: 'legalservices',
  VERBOSE_NAME: 'Servicio Genérico', // optional
  permissions: {
    downloadCSVPermission: 'segment-perobject-SG-crm_download_csv'
  },
  CONTACT_PREFERENCES,
  CLAIM_TYPE,
  CLAIM_TYPES: {
    59: [
      {
        id: 'payment_mode',
        type: 'customboolean',
        label: 'Modo de pago',
        dictionary: PAYMENT_MODE
      },
      {
        id: 'initial_payment_amount',
        type: 'currency',
        label: 'Provisión de fondos (solo aplica a este modo)'
      },
      {
        id: 'service_payment_type',
        type: 'customboolean',
        label: 'Tipo de pago',
        dictionary: PAYMENT_TYPE
      },
      {
        id: 'init_payment_state',
        type: 'boolean',
        label: 'Estado del pago (Único/Prov.fondos)',
        text: 'Pagado',
        dictionary: PAYMENT_BOOLEAN_TYPE
      },
      {
        id: 'init_payment_link',
        type: 'label',
        label: 'URL del pago inicial/prov. fondos',
        disabled: true
      },
      {
        id: 'final_payment_link',
        type: 'label',
        label: 'URL del pago final',
        disabled: true
      },
      {
        id: 'category',
        type: 'select',
        label: 'Tipo de consulta',
        service: 'videocalltype'
      }
    ]
  },
  CLAIM_DICTIONARIES: {
    lawyers: 'legalserviceslawyers',
    salesman: 'legalservicessalesmen',
    catalog: 'videocallinternaltype',
    claimTypes: {
      entity: 'claimtypes',
      data: { type: CLAIM_TYPE }
    },
    claimStates: {
      entity: 'claimstates',
      data: { segments__id: SEGMENT_ID, costs: false }
    },
    saleStates: {
      entity: 'salesclaimstates',
      data: { segments__id: SEGMENT_ID }
    },
    statesWithEmail: {
      entity: 'statesWithPreviewEmail',
      data: { django_class: 'LegalService' }
    }
  },

  tabConfig: [
    {
      heading: 'Resumen',
      indexKey: 'resumen',
      template: 'js/modules/segments/legalservices/partials/detail/summary.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Detalles',
      indexKey: 'detalles',
      template: 'js/modules/segments/legalservices/partials/detail/details.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Presupuesto',
      indexKey: 'presupuesto',
      template: 'js/modules/segments/legalservices/partials/detail/budget.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Documentación',
      indexKey: 'documentacion',
      template: 'js/modules/segments/legalservices/partials/detail/documentation.html'
    },
    {
      heading: 'Historial',
      indexKey: 'historial',
      template: '/partials/common/detail/historic.html',
      headingBadge: null
    },
    {
      heading: 'Mismo cliente',
      indexKey: 'mismo-cliente',
      template: '/partials/common/detail/same_client/base.html'
    },
    {
      heading: 'Administración',
      indexKey: 'administracion',
      template: 'js/modules/segments/legalservices/partials/administration.html',
      select: null,
      headingBadge: null,
      permission: 'segment-global-crm_view_administration_tab'
    }
  ],
  defaultColumnDef: [
    { headerName: 'ID', fieldName: 'id', sortable: true, alwaysVisible: true },
    { headerName: 'Tipo', fieldName: 'type_name', sortable: true,alwaysVisible: true },
    { headerName: 'ID Cliente', fieldName: 'client_id', sortable: true, alwaysVisible: true },
    { headerName: 'Nombre Cliente', fieldName: 'client_name', sortable: true },
    { headerName: 'Correo cliente', fieldName: 'client_email', sortable: true },
    {
      headerName: 'Tlfno',
      fieldName: 'client_phone',
      sortable: true,
      hidden: true
    },
    { headerName: 'Estado', fieldName: 'state_name', sortable: true },
    { headerName: 'Estado C.', fieldName: 'sales_state_name', sortable: true },
    { headerName: 'Fuente', fieldName: 'source_name', sortable: true },
    { headerName: 'Fuente invitador', fieldName: 'source_claim_type', sortable: true },
    { headerName: 'Abogado', fieldName: 'lawyer', sortable: true },
    { headerName: 'Comercial', fieldName: 'salesman', sortable: true },
    { headerName: 'Campaña', fieldName: 'campaign_name', sortable: true },
    { headerName: 'SEM', fieldName: 'sem_campaign', sortable: true },
    { headerName: 'SEM (Valor)', fieldName: 'sem_gclid', sortable: true },
    {
      headerName: 'Network',
      fieldName: 'sem_network',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Placement',
      fieldName: 'sem_placement',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Usuario cambio estado',
      fieldName: 'laststatechangeuser',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Comentario cambio estado',
      fieldName: 'laststatecomment',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Ultimo cambio estado comercial',
      fieldName: 'lastsalesstatechange',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Usuario cambio estado comercial',
      fieldName: 'lastsalesstatechangeuser',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Último comentario',
      fieldName: 'last_comment',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Ultimo cambio estado',
      fieldName: 'laststatechange',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Usuario últ. comentario ',
      fieldName: 'last_comment_user',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha últ. comentario',
      fieldName: 'last_comment_date',
      sortable: true,
      hidden: true
    },

    { headerName: 'Tipo de consulta', fieldName: 'category', sortable: true },
    { headerName: 'Tipo de contacto', fieldName: 'contact_type', sortable: true },
    {
      headerName: 'Preferencia de contacto',
      fieldName: 'contact_preferences',
      sortable: true
    },
    {
      headerName: 'Fecha de contacto',
      fieldName: 'appointment_date',
      sortable: true
    },
    { headerName: 'Finalizada', fieldName: 'finished', sortable: true },
    { headerName: 'Validado por', fieldName: 'validator_lawyer', sortable: true },
    { headerName: 'Fase', fieldName: 'phase_name', sortable: true },
    { headerName: 'Creado', fieldName: 'date_added', sortable: true },
    { headerName: 'Modificado', fieldName: 'date_updated', sortable: true },
    {
      headerName: 'Fecha Finalizada',
      fieldName: 'date_finished',
      sortable: true
    },
    {
      headerName: 'Numero documentos',
      fieldName: 'num_docs',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha alta cliente',
      fieldName: 'client_date_added',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha primera gestión válida',
      fieldName: 'first_claim_date_added',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Cedida',
      fieldName: 'transfered_to_investor',
      sortable: true
    },
    {
      headerName: 'Fecha cesión',
      fieldName: 'transfered_to_investor_date',
      sortable: true
    },
    {
      headerName: 'Cartera',
      fieldName: 'investor_business_name',
      sortable: true
    },
    {
      headerName: 'Comprada',
      fieldName: 'bought_from_portfolio',
      sortable: true
    },
    {
      headerName: 'Comprado a',
      fieldName: 'bought_portfolio_business_name',
      sortable: true
    },
    {
      headerName: 'Fecha compra',
      fieldName: 'bought_date',
      sortable: true
    },
    {
      headerName: 'Email cartera cliente',
      fieldName: 'email_bought_client',
      sortable: true
    },
    {
      headerName: 'Teléfono cartera cliente',
      fieldName: 'phone_bought_client',
      sortable: true
    }
  ],
  filterFieldInfo: {
    category: { label: 'Tipo de consulta', select_id: 'contact_type-select' },
    appointment_date__gte: {
      label: 'Fecha cita desde',
      type: 'date',
      column: 'claim.appointment_date',
      order: '>='
    },
    appointment_date__lte: {
      label: 'Fecha cita hasta',
      type: 'date',
      column: 'claim.appointment_date',
      order: '<='
    }
  }
};
