import angular from 'angular';
import MenuLeftCtrl from './menu.controller';

angular
  .module('reclamador.components.sidemenu', [])
  .controller('MenuLeftCtrl', MenuLeftCtrl)
  .component('sideMenu', {
    controller: 'MenuLeftCtrl',
    controllerAs: 'vm',
    template: require('./index.html') 
  });
