/* eslint-disable angular/di */

import angular from 'angular';
import firebase from 'firebase/app';
import 'firebase/firebase-messaging';
import 'firebase/firebase-firestore';

import './index.css';

import FirebaseFactory from './firebaseFactory';

angular
  .module('reclamador.fcm', [])
  .factory('firebaseFactory', FirebaseFactory)
  .run([
    'firebaseFactory',
    'toastr',
    (firebaseFactory, toastr) => {
      try {
        if (firebase.messaging.isSupported()) {
          const messaging = firebaseFactory.init();
          messaging.onMessage(payload => {
            let type = payload.data.type ? payload.data.type : 'info';

            if (payload.data.link_url) {
              let link_text = payload.data.link_text ? payload.data.link_text : 'Link';
              toastr[type](
                `<p class="body">${payload.notification.body}</p><a href="${payload.data.link_url}" target="blank" class="btn btn-${type} h4">${link_text}</a>`,
                payload.notification.title,
                {
                  allowHtml: true,
                  timeOut: 0
                }
              );
            } else {
              /* Normal FCM notification */
              toastr[type](payload.notification.body, payload.notification.title);
            }
          });
        }
      } catch (error) {
        console.warn(error);
        toastr.error(
          'Tu navegador no soporta Notificaciones Push y no vas a poder recibir avisos. Prueba a instalar un navegador más moderno para acceder a esta funcionalidad',
          'Limitación de compatibilidad',
          { timeOut: 0 }
        );
      }
    }
  ]);
