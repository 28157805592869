TastypieBaseListCtrl.$inject = [
  'vm', '$scope', '$route', 'serverAddress', '$http', '$location', '$window', 'DownloadCSVService', '$log','UtilsFactory'
];

export default function TastypieBaseListCtrl(
  vm, $scope, $route, serverAddress, $http, $location, $window, DownloadCSVService, $log, UtilsFactory
) {

  const baseListSettings = $route.current.$$route.baseListSettings;

  const INITIAL_STATE = {
    baseUrl: '',
    data: {
      totalCount: 0,
      items: []
    },
    baseListSettings,
    settings: {
      title: 'Filter list unnamed',
      enableFiltering: false,
      displaycolumnsFilters: false,
      resourceName: '',
      pageSize: '20',
      columnDef: [],
      filterFieldInfo: {}
    },
    currentPage: 0,
    nPages: 0,
    filterText: null,
    filter: {},
    next: null,
    previous: null,

    currentFilters: {}, // filters object used by filter badges
    newFilters: {}, // filters object used by filter modal
    segmentToFilter: '',
    sortedColumn: '',
    filtersDict: {}

  };

  const METHODS = {
    initResource,
    updateItems,
    clearFilters,
    removeFilter,
    prevPage,
    nextPage,
    applyFilters,
    prepareFilters,
    fixFilterDisplay,
    segmentToFilterHandler,
    getResourceUrl,
    sortColumn,
    getExtraFilterParams,
    parseUrlParameters,
    getFilterParams,
    getTotalCountBySegment
  };

  Object.assign(vm, INITIAL_STATE, METHODS);

  vm.$onInit = () => {
    vm.baseUrl = vm.getResourceUrl();

    if ($window.rclmng_globals) {
      $scope.$on('ngRepeatFinished', () => $window.rclmng_globals.ux.baseListScroll.init());
    }
  };

  function getResourceUrl(){
    return `${serverAddress.getBaseUrl()}api2/v1/${vm.settings.resourceName}/`;
  }

  function initResource() {
    vm.parseUrlParameters();
    vm.baseUrl = vm.getResourceUrl();
    vm.applyFilters();

    if ($window.rclmng_globals) {
      $scope.$on('ngRepeatFinished', () => $window.rclmng_globals.ux.baseListScroll.init());
    }
  }

  $scope.$watch('vm.segmentToFilter', function(newSegmentToFilter, oldSegmentToFilter) {
    if (newSegmentToFilter !== oldSegmentToFilter) {
      vm.segmentToFilterHandler(newSegmentToFilter);
    }
  });

  function segmentToFilterHandler(segment) {
    vm.segmentToFilter = segment;
    vm.updateItems();
  }

  function getColumnToSortName(fieldName){
    // Currently sorting column ascending. Change it to descending
    if (vm.sortedColumn === fieldName){
      return `-${fieldName}`;
    }
    // Currently sorting column descending. Remove sorting
    if (vm.sortedColumn === `-${fieldName}`){
      return '';
    }
    // Currently no sorting or sorting by other field. Change it to selected column
    return fieldName;

  }

  function sortColumn(column) {
    if (column.sortable == false) return;
    vm.sortedColumn = getColumnToSortName(column.fieldName);
    vm.updateItems();
  }

  /**
  * Returns extra filters that do not need to be displayed in labels but are sent to the backend.
  * Override when necessary.
  **/
  function getExtraFilterParams(){
    return {};
  }

  function getFilterParams() {
    let currentFilters = UtilsFactory.reduceObject(vm.currentFilters);
    currentFilters = UtilsFactory.formatObjectWithNullValue(currentFilters);
    return currentFilters;
  }

  /**
  * Override in controllers that need specific behaviour
  */
  function getTotalCountBySegment(){}

  function updateItems(url = null, addParams = true) {

    if (!vm.settings.resourceName) return;

    let params = {};

    if(addParams){
      params = {
        'limit': vm.settings.pageSize,
        ...getFilterParams(),
        ...vm.getExtraFilterParams()
      };
      // Add sortBy only if supported
      if(vm.sortedColumn){
        params['sort_by'] = vm.sortedColumn;
      }
    }

    const baseUrl = url || vm.getResourceUrl();

    vm.busy = true;

    vm.getTotalCountBySegment();

    $http
      .get(baseUrl, { params })
      .then(res => {
        vm.data.items = res.data.objects;
        vm.data.totalCount = res.data.meta.total_count;
        vm.nPages = Math.ceil(vm.data.totalCount / vm.settings.pageSize || 1);
        vm.next = res.data.meta.next;
        vm.previous = res.data.meta.previous;
      })
      .catch(error => $log.error(error))
      .finally(() => {
        vm.busy = false;
      });
  }

  function applyFilters() {
    prepareFilters();
    vm.currentPage = 0;
    vm.updateItems();

    $window.rclmng_globals.ux.clearModals();
  }

  /**
   * Update CurrentFilters (real) with newFiltersdata (modal)
   * @return void
   */
  function prepareFilters() {
    var newFilters = vm.newFilters;
    var currentFilters = vm.currentFilters;

    Object.keys(newFilters).forEach(filterName => {
      if (!newFilters.hasOwnProperty(filterName)) return;

      // Some of the items in newFilters can be set to undefined or null. Skip them
      if (!newFilters[filterName]) {
        delete currentFilters[filterName];
        return;
      }

      // Add the new filter to the list of current filters
      currentFilters[filterName] = {
        name: filterName,
        value: newFilters[filterName]
      };

      // get config from initial definition
      const filterConfig = vm.settings.filterFieldInfo[filterName];
      if (!filterConfig) return;

      // Get real value for selects
      if (filterConfig.select_id) {
        currentFilters[filterName].fixed_value = filterConfig.filterDisplay(newFilters[filterName]);
      }

      // Fix Objects => get use_field
      if (filterConfig.json_value) {
        var useField = filterConfig.use_field || 'id';

        currentFilters[filterName].value = currentFilters[filterName].value[useField];
      }

      if (filterConfig.column) {
        currentFilters[filterName].column = filterConfig.column;
      }


    });

  }

  /**
   * Process active Filters display
   * @todo refactor
   * @param  filter The filter to process from vm.currentFilters
   * @return {string} The text displayed in the filter bage
   */
  function fixFilterDisplay(filter) {
    /**
     * Fix some string replacements
     */
    const defaults = {
      true: 'Si',
      false: 'No',
      True: 'Si',
      False: 'No',
      null: '--'
    };

    // Use data set in prepareFilters
    if (filter.fixed_value) {
      return filter.fixed_value;
    }

    // Standard values
    if (defaults[filter.value]) {
      return defaults[filter.value];
    }

    return filter.value;
  }

  function clearFilters() {
    vm.newFilters = {};
    vm.currentFilters = {};
    vm.updateItems();
  }

  // Remove a filter when clicking it the alert div
  function removeFilter(filter) {
    // Empty the form value
    vm.newFilters[filter] = '';
    // Remove the actual filter
    delete vm.currentFilters[filter];
    // And query again
    vm.updateItems();
  }

  function prevPage() {
    if (vm.currentPage > 0) {
      vm.currentPage -= 1;
      const baseUrl = serverAddress.getBaseUrl().slice(0, -1);
      const url = `${baseUrl}${vm.previous}`;
      vm.updateItems(url, false);
    }
  }

  function nextPage() {
    if (vm.currentPage + 1 < vm.nPages) {
      vm.currentPage += 1;
      const baseUrl = serverAddress.getBaseUrl().slice(0, -1);
      const url = `${baseUrl}${vm.next}`;
      vm.updateItems(url, false);
    }
  }

  /**
   * Set scope based on URL => initiate page
   * @return {void}
   */
  function parseUrlParameters() {
    const params = $location.search();

    // nothing to do
    if (Object.keys(params).length === 0) return;

    Object.entries(params).forEach(filter => {
      let [field, value] = filter;
      if (value === 'undefined') return;
      if (value === 'null') value = null;
      if (vm.filtersDict[field]){
        const filterValue = vm.filtersDict[field].find(filterObject => filterObject.id == value);
        vm.newFilters[field] = filterValue;
      }
    });
  }

  $scope.isProceduralControlRoute = () => {
    return $location.path() === '/legalfiles/procedural-control/';
  };

  $scope.$on('assignmentSentSuccessfully', () => {
    vm.updateItems();
  });
}
