import './styles.css';

AnswersCtrl.$inject = ['$scope', '$http', 'serverAddress'];
export default function AnswersCtrl($scope, $http, serverAddress) {
  const vm = this;
  vm.getAnswerValue = getAnswerValue;

  vm.$onInit = function() {};

  $scope.$watch('vm.claim', claim => {
    if (!claim || !claim.id || !vm.segmentid) return;
    const req = {
      method: 'GET',
      url: serverAddress.getBaseUrl() + 'api2/v1/form_questions/',
      params: {
        segment_id: vm.segmentid,
        claim_id: claim.id
      }
    };

    $http(req).then(response => {
      vm._answers = response.data.objects;
      // response.data.objects.map(item => {
      //   return {
      //     question: item.question,
      //     answer: vm.getAnswerValue(item.value)
      //   };
      // });
    });
  });

  function getAnswerValue(value) {
    if (value === null) return '';
    const parseValue = Number(value);
    if(!isNaN(parseValue)) {
      if (value <= 1) return parseValue * 100;
    } else {
      return value;
    }
  }
}
