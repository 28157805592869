MassiveDocDownloadCtrl.$inject = ['$scope', 'Segment', 'DocumentType', 'serverAddress', '$http', '$route'];
export default function MassiveDocDownloadCtrl($scope, Segment, DocumentType, serverAddress, $http, $route) {
  var vm = this;
  vm.layoutSettings = $route.current.$$route.layoutSetting;
  vm.currentFile = null;

  vm.uploadFileInputs = {
    verticals: '',
    documentTypes: ''
  };

  vm.isClaimFile = false;
  vm.fileLoaded = false;
  vm.verticalSelected = false;
  vm.sendButtonEnabled = false;
  vm.doc_type = [];
  vm.showSendButton = false;
  vm.documentTypeSelected = false;
  vm.operationDownloadTypeSelected = false;
  vm.operationUploadTypeSelected = false;
  vm.operationTypeSelected = false;
  vm.isDownload = true;
  vm.isUpload = false;
  vm.path = 'common/bulk/download/';
  vm.zipfile = false;

  vm.optionSelected = function(arg) {
    return arg;
  };

  // ng-to-csv config
  vm.csv = {
    content: null,
    header: true,
    headerVisible: true,
    separator: ',',
    separatorVisible: true,
    result: null,
    encoding: 'UTF-8',
    encodingVisible: true,
    accept: '.csv'
  };

  Segment.get({ query: 'id__gt=0' }).$promise.then(function(response) {
    vm.verticalsList = response.objects;
  });

  /* Events */
  $scope.$watch('vm.csv.content', function(newValue) {
    if (newValue) {
      var idsArray = newValue.split('\n');
      var res = [];

      // Parse claims ids to number
      for (var i = 0; i < idsArray.length; i++) {
        res.push(idsArray[i]);
      }
      vm.res = res.filter(item => !!item);

      vm.fileLoaded = true;
      vm.isClaimFile = false;
      vm.verticalSelected = false;
      vm.showSendButton = false;
      vm.documentTypeSelected = false;
    }
  });

  $scope.$watch('vm.zipfile', function(newValue) {
    if (newValue && vm.currentFile != newValue) {
      vm.currentFile = newValue;
      vm.fileLoaded = true;
      vm.isClaimFile = false;
      vm.verticalSelected = false;
      vm.showSendButton = false;
      vm.documentTypeSelected = false;
    }
  });

  $scope.$watch(
    'vm.uploadFileInputs.verticals',
    function(newValue) {
      if (newValue) {
        vm.verticalSelected = true;
        vm.showSendButton = false;
        if (vm.isClaimFile) {
          switch (newValue.prefix) {
            case 'AE':
              DocumentType.get({ type: 'airclaim' }, function(data) {
                vm.documentTypes = data;
              });
              break;
            case 'BA':
              DocumentType.get({ type: 'banking' }, function(data) {
                vm.documentTypes = data;
              });
              break;
            case 'AC':
              DocumentType.get({ type: 'accident' }, function(data) {
                vm.documentTypes = data;
              });
              break;
            case 'LA':
              DocumentType.get({ type: 'laboral' }, function(data) {
                vm.documentTypes = data;
              });
              break;
            case 'SO':
              DocumentType.get({ type: 'so' }, function(data) {
                vm.documentTypes = data;
              });
              break;
            case 'TE':
              DocumentType.get({ type: 'phone' }, function(data) {
                vm.documentTypes = data;
              });
              break;
            case 'TA':
              DocumentType.get({ type: 'taxes' }, function(data) {
                vm.documentTypes = data;
              });
              break;
            case 'NE':
              DocumentType.get({ type: 'negligences' }, function(data) {
                vm.documentTypes = data;
              });
              break;
            default:
              break;
          }
        } else {
          DocumentType.get({ type: 'aircase' }, function(data) {
            vm.documentTypes = data;
          });
        }
      }
    },
    true
  );

  $scope.$watch(
    'vm.doc_type',
    function(newValue) {
      if (newValue) {
        vm.showSendButton = true;
        if (newValue.id) {
          vm.sendButtonEnabled = true;
        } else {
          vm.sendButtonEnabled = false;
        }
      }
    },
    true
  );

  $scope.$watch(
    'vm.selectOption',
    function(optionSelected) {
      if (optionSelected) {
        vm.showSendButton = false;
        vm.verticalSelected = false;
        vm.documentTypeSelected = true;
        if (optionSelected === 'Documentos de expedientes') {
          vm.isClaimFile = false;
        } else if (optionSelected === 'Documentos de reclamaciones') {
          vm.isClaimFile = true;
        }
      }
    },
    true
  );

  $scope.$watch(
    'vm.operation',
    function(operationSelected) {
      if (operationSelected) {
        vm.isDownload = true;
        vm.isUpload = false;
        vm.verticalSelected = false;
        vm.fileLoaded = false;
        vm.operationTypeSelected = true;
        vm.operationDownloadTypeSelected = true;
        vm.operationUploadTypeSelected = false;
        vm.path = 'common/bulk/download/';
      } else {
        vm.isDownload = false;
        vm.isUpload = true;
        vm.verticalSelected = false;
        vm.fileLoaded = false;
        vm.operationTypeSelected = true;
        vm.operationUploadTypeSelected = true;
        vm.operationDownloadTypeSelected = false;
        vm.path = 'common/bulk/download/';
      }
    },
    true
  );

  // Function to send data via ajax-post to the backend (maybe some stuff could be refactored as a service...)
  function postData(data) {
    /* Sample object of what will be sent:
            var dataAjax = {
            "claims":["1","2","7","27","52","77","5566","5567","5898"],
            "vertical":"BA",
            "documenttype": documentypeId
           }; */

    vm.successOcurred = false;
    vm.errorOcurred = false;
    vm.finishedMessage = '';

    // We had to put Content-Type headers, as described here:
    // http://stackoverflow.com/questions/19254029/angularjs-http-post-does-not-send-data
    $http({
      method: 'POST',
      url: serverAddress.getDocumentUrl() + vm.path,
      data: data
    })
      .then(function() {
        vm.successOcurred = true;
        if (vm.isDownload) {
          vm.finishedMessage =
            'Los documentos se estan preparando.\nRecibirás un correo electrónico con un enlace para acceder a ellos.';
        } else {
          vm.finishedMessage = 'Los documentos se van a subir.\nRecibirás un correo electrónico con la confirmación.';
        }
        vm.currentFile = null;
        $scope.$broadcast('loading-bar:hide');
      })
      .catch(function(response) {
        vm.errorOcurred = true;
        vm.finishedMessage = 'Ha ocurrido el siguiente error: ';
        vm.finishedMessage += response.data;
        $scope.$broadcast('loading-bar:hide');
      });
  }

  // Button to send all data to the backend
  vm.sendData = sendData;
  function sendData() {
    var dataToSend = {};
    dataToSend.claims = vm.res ? vm.res : false;
    dataToSend.vertical = vm.uploadFileInputs.verticals ? vm.uploadFileInputs.verticals.prefix : null;
    dataToSend.documentid = vm.doc_type ? vm.doc_type.id : null;
    dataToSend.isclaimfile = vm.isClaimFile;
    dataToSend.isdownload = vm.isDownload;
    dataToSend.operationTypeSelected = vm.operationTypeSelected;
    dataToSend.zipfile = vm.zipfile ? vm.zipfile : false;

    vm.dataToSend = dataToSend;
    postData(vm.dataToSend);
  }
}
