import './styles.css';

DocumentsInboxCtrl.$inject = [
  '$scope',
  '$filter',
  '$localStorage',

  'serverAddress',
  'fileUploaderGenerator',
  'DocumentsInboxService',
  'DocTools',
  'notifications',
  'DocumentType',
  'BoughtPortfolio',
  'ClaimType',
  '$http'
];
export default function DocumentsInboxCtrl(
  $scope,
  $filter,
  $localStorage,

  serverAddress,
  fileUploaderGenerator,
  DocumentsInboxService,
  DocTools,
  notifications,
  DocumentType,
  BoughtPortfolio,
  ClaimType,
  $http
) {
  var vm = this;

  const METHODS = {
    // ux actions
    truncateFilenameFilter: $filter('truncatefilename'),

    DocumentsInboxService,

    // helpers
    fetchDocuments,
    DocTools,

    // 4 the testes
    prepareDoc
  };

  Object.assign(vm, {
    documents: [],
    documentTypes: DocumentType.get(),
    selectedDocs: [],
    assignableLawyers: DocumentsInboxService.getUsers(),

    documentCount: 0,

    // ui
    assignInput: null,
    resultsPerPage: parseInt($localStorage.get('di_user-pagination-size')) || 10,
    currentPage: 0,
    totalDocuments: 0,
    sortOrder: false,
    initialLoad: true // prevent hiding loader beforea already loaded
  }, METHODS);
  vm.uploadFileInputs = {
    verticals: '',
    claimtypes:'',
    boughtPortfolio:''
  };

  // Initialization
  vm.sendButtonEnabled = false;

  $scope.$watch('vm.documents', documents => {
    if(!documents) return;

    vm.selectedDocs = documents.filter(e => e.selected);
  }, true);

  $scope.$watch('vm.uploadFileInputs.boughtPortfolio', function(newValue) {
    if (newValue) {
      vm.verticalsList = newValue.allowed_segments_for_bought_portfolio;
    }
  });

  $scope.$watch('vm.uploadFileInputs.verticals', function(newValue) {
    if (newValue) {
      vm.showoptions = true;
      vm.lawyersList = [{ first_name: 'NINGUNO', last_name: '', id: '0' }];
      vm.salesmanList = [{ first_name: 'NINGUNO', last_name: '', id: '0' }];
      vm.externalCourtLawyerList = [{ name: 'NINGUNO', id: '0' }];
      vm.statesList = [{ name: 'NINGUNO', id: '0' }];
      vm.costsstatesList = [{ name: 'NINGUNO', id: '0' }];
      vm.claimtypesList = [{ name: 'NINGUNO', id: '0' }];
      switch (newValue.prefix) {
        case 'AE':
          ClaimType.get({ type: 'airline'}, function(response) {
            vm.claimtypesList.push(...response.objects);
          });
          break;
        case 'BA':
          ClaimType.get({ type: 'bank'}, function(response) {
            vm.claimtypesList.push(...response.objects);
          });
          break;
        case 'AC':
          ClaimType.get({ type: 'accident'}, function(response) {
            vm.claimtypesList.push(...response.objects);
          });
          break;
        case 'LA':
          ClaimType.get({ type: 'laboral'}, function(response) {
            vm.claimtypesList.push(...response.objects);
          });
          break;
        case 'SO':
          ClaimType.get({ type: 'so'}, function(response) {
            vm.claimtypesList.push(...response.objects);
          });
          break;
        case 'TE':
          ClaimType.get({ type: 'phone'}, function(response) {
            vm.claimtypesList.push(...response.objects);
          });
          break;
        case 'TA':
          ClaimType.get({ type: 'taxes'}, function(response) {
            vm.claimtypesList.push(...response.objects);
          });
          break;
        case 'NE':
          ClaimType.get({ type: 'negligences'}, function(response) {
            vm.claimtypesList.push(...response.objects);
          });
          break;
        case 'GE':
          ClaimType.get({ type: 'managements'}, function(response) {
            vm.claimtypesList.push(...response.objects);
          });
          break;
        case 'SG':
          ClaimType.get({ type: 'legalservices'}, function(response) {
            vm.claimtypesList.push(...response.objects);
          });
          break;
        default:
      }
    }
  });

  BoughtPortfolio.get().$promise.then(function(response) {
    vm.boughtPortfoliosList = response.objects;
  });

  vm.doc_s3_url = '';

  vm.fileUploader = fileUploaderGenerator({
    vm: vm,
    url: serverAddress.getDocumentUrl() + 'documentsinbox/upload/',
    sucessCallback: (item, response) => {
      // TODO: process upload errors
      if(response.status !== 'ok') return;

      const FIXED_DOCS = response.uploaded_docs.map(e => prepareDoc(e));
      const PLURAL = FIXED_DOCS.length > 1 ? 's' : '';
      const FILENAMES = FIXED_DOCS.map(e => vm.truncateFilenameFilter(e.filename)).join(', ');

      vm.documents = FIXED_DOCS.concat(vm.documents);

      notifications.addCurrentView(
        'info',
        `Nuevo${PLURAL} documento${PLURAL} añadido${PLURAL}: ${FILENAMES}`
      );
    },
    errorCallback: function(item, response, status) {
      if(status == 403) {
        notifications.addCurrentView('error', 'No tienes permiso para subir el archivo.');
      } else {
        notifications.addCurrentView('error', response.message);
      }
    },
    ignoreDisplayUploader: true,
    removeAfterUpload: true,
    autoUpload: true,
    onCompleteAll: () => {
      vm.currentPage = 0;
      vm.sortColumn = 'date_added';
      vm.sortOrder = false;
      vm.fetchDocuments();
      vm.doc_s3_url = vm.documents[0].file_s3;
      vm.documents = [];
      vm.sendButtonEnabled = true;
    }
  });

  // METHODS
  // Button to send all data to the backend
  vm.sendData = sendData;
  function sendData() {
    $scope.$broadcast('loading-bar:show');
    var dataToSend = {};
    dataToSend.bought_portfolio_date = vm.bought_portfolio_date;
    dataToSend.bought_portfolio_id = vm.uploadFileInputs.boughtPortfolio.id
      ? vm.uploadFileInputs.boughtPortfolio.id
      : null; 
    dataToSend.claimtypes = vm.uploadFileInputs.claimtypes.id
      ? vm.uploadFileInputs.claimtypes.id
      : null;   
    dataToSend.doc_s3_url = vm.doc_s3_url;
    vm.dataToSend = dataToSend;
    postData(vm.dataToSend);
  }

  function postData(data) {
    let isError = false;
    let finishedMessage = '';
    var url = null;
    url = serverAddress.getDocumentUrl() + 'common/bulk/bought-portfolio/';
    $http({
      method: 'POST',
      url: url,
      data: data
    })
      .then(function() {
        finishedMessage =
          'Asignación de casos de cartera en marcha';
        $scope.$broadcast('loading-bar:hide');
      })
      .catch(function(response) {
        finishedMessage = 'Ha ocurrido el siguiente error: ';
        finishedMessage += response.data;
        $scope.$broadcast('loading-bar:hide');
        isError = true;
      })
      .finally(() => {
        notifications.addCurrentView(isError ? 'error' : 'info', finishedMessage);
        vm.bought_portfolio_date = '';
        vm.uploadFileInputs.boughtPortfolio = '';
        vm.uploadFileInputs.claimtypes = '';
        vm.doc_s3_url = '';
        vm.uploadFileInputs.verticals = '';

      });
  }  

  // ENDPOINT
  function fetchDocuments() {
    vm.busy = true;
    DocumentsInboxService.get({
      order_by: (vm.sortOrder ? '' : '-') + vm.sortColumn,
      limit: vm.resultsPerPage,
      offset: vm.currentPage * vm.resultsPerPage
    }).$promise.then(response => {
      if(vm.initialLoad) {
        vm.initialLoad = false;
      };
      vm.busy = false;
      vm.documents = response.objects.map(prepareDoc);
      vm.documents.forEach(item => (item.busy = false));
      vm.totalDocuments = response.meta.total_count;
      vm.totalPages = parseInt((vm.totalDocuments - 1) / vm.resultsPerPage, 10) + 1;
    });
  }

  function prepareDoc(doc) {
    if (DocTools.isDoc(doc.filename)) {
      doc.fixedUrl = DocTools.getViewerSafeUrl(doc.file_s3);
      doc.type = 'doc';
    } else if (DocTools.isPdf(doc.filename)) {
      doc.type = 'pdf';
      doc.fixedUrl = DocTools.getSafeUrl(doc.file_s3);
    } else if (DocTools.isImage(doc.filename)) {
      doc.type = 'image';
    } else {
      doc.type = 'unknown';
    }
    return doc;
  }
}