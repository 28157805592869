ProductService.$inject = ['$resource', 'serverAddress'];

export default function ProductService($resource, serverAddress) {
  let Product = null;
  const service = {
    set,
    get,
    save
  };
  function set(entity) {
    Product = $resource(
      `${serverAddress.getBaseUrl()}api2/v1/${entity}/:id/`,
      {},
      {
        update: { method: 'PATCH' }
      }
    );
  }
  function get(claim__id) {
    return Product.get({ claim__id }).$promise;
  }
  function save(id, data) {
    return Product.update({id}, data).$promise;
  }

  return service;
}
