ClientRoutes.$inject = ['$routeProvider'];
export default function ClientRoutes($routeProvider) {
  const permissions = {
    only: 'client-global-crm_view_client_list',
    redirectTo: 'no-permissions'
  };

  const clientListConfig = {
    title: 'Buscador de clientes',
    templateUrl: 'partials/common/baseList/claim-list.html',
    data: { permissions },
    layoutSetting: {
      contentTitle: 'Clientes',
      iconClass: 'fas fa-user',
      primaryPath: '/#/clients'
    },
    baseListSettings: {
      typeList: 'clients',
      typeTitle: 'clientes',
      templateListTable: 'js/modules/client/partials/claim-list-table.html',
      templateListFilter: 'js/modules/client/partials/claim-list-filters.html',
      title: 'Clientes',
      listResultsText: 'clientes'
    },
    controller: 'ClientListCtrl',
    controllerAs: 'vm'
  };

  $routeProvider.when('/clients', clientListConfig);
  $routeProvider.when('/clients/list/filter/:filter', clientListConfig);

  $routeProvider.when('/clients/:id', {
    title: 'Detalle de cliente',
    templateUrl: 'js/modules/client/partials/detail.html',
    data: {
      permissions: {
        only: 'client-global-crm_view_client_detail',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Clientes',      
      iconClass: 'fas fa-user',
      primaryPath: '/#/clients',
      secondaryPath: '/dasdas'
    },
    controller: 'ClientDetailCtrl',
    controllerAs: 'vm',
    reloadOnSearch: false
  });
}
