import './styles.css';
import claimKeysDict from '../../../../data/claimKeysDict.json';

DocumentsInboxCtrl.$inject = [
  '$scope',
  '$dialog',
  '$filter',
  '$localStorage',

  'serverAddress',
  'fileUploaderGenerator',
  'DocumentsInboxService',
  'ProcessedDocumentsInboxService',
  'DocTools',
  'notifications',
  'DocumentType'
];
export default function DocumentsInboxCtrl(
  $scope,
  $dialog,
  $filter,
  $localStorage,

  serverAddress,
  fileUploaderGenerator,
  DocumentsInboxService,
  ProcessedDocumentsInboxService,
  DocTools,
  notifications,
  DocumentType
) {
  var vm = this;

  const METHODS = {
    // ux actions
    removeDoc,
    removeMultipleDocs,
    renameDoc,
    assignDoc,
    assignMultiple,
    assignMultipleModal,
    saveComment,
    processDoc,
    processMultipleDocs,
    truncateFilenameFilter: $filter('truncatefilename'),

    toggleSelectAll,
    selectLastUploads,
    clearSelected,
    DocumentsInboxService,
    setSortColumn,
    goToPage,
    getPagerDocs,
    setProcessedSortColumn,
    goToProcessedPage,
    getProcessedPagerDocs,

    // helpers
    getMultipleAvailableDocTypes,
    getFileExtension,
    getDoctypeName,
    getFilteredDocuments,
    fetchDocuments,
    fetchProcessedDocuments,
    getDocsFilenames,
    getSelectedDocsFilenamesArray,
    getDisabledSelectTypeButton,
    getDisabledProcessButton,
    DocTools,

    // 4 the testes
    requestActionsEndpoint,
    initSearchModal,
    initExtraInfoModal,
    initMergeModal,
    initSliceModal,
    initAssignToLawyerModal,
    refreshLists,
    prepareDoc,
    processActionsEndpointResponse
  };

  Object.assign(vm, {
    documents: [],
    documentTypes: DocumentType.get(),
    selectedDocs: [],
    lastUploads: [],
    assignableLawyers: DocumentsInboxService.getUsers(),

    documentCount: 0,

    // ui
    busy: true,
    displayDocumentsUploader: null,
    assignInput: null,
    resultsPerPage: parseInt($localStorage.get('di_user-pagination-size')) || 10,
    processedResultsPerPage: parseInt($localStorage.get('di_user-processed-pagination-size')) || 10,
    resultsPerPageOptions: [10, 20, 50, 200, 500],
    currentPage: 0,
    totalDocuments: 0,
    sortColumn: 'date_added',
    lastTarget: null,
    sortOrder: false,
    processedCurrentPage: 0,
    processedTotalDocuments: 0,
    processedSortColumn: 'date_processed',
    processedSortOrder: false,
    initialLoad: true, // prevent hiding loader beforea already loaded
    FIELDS_DICT: claimKeysDict, // translations for fields names for notifications

    // modals
    displayConfirDeleteMultipleDocModal: false, // boolean
    displayConfirDeleteDocModal: false, // document id to delete
    extensionChangeModal: null // extension check data object
  }, METHODS);

  // Initialization
  vm.refreshLists();

  $scope.$watch('vm.documents', documents => {
    if(!documents) return;

    vm.selectedDocs = documents.filter(e => e.selected);
  }, true);

  // keep user pagination settings
  $scope.$watch('vm.resultsPerPage', results => {
    $localStorage.set('di_user-pagination-size', results);
  }, true);

  $scope.$watch('vm.processedResultsPerPage', results => {
    $localStorage.set('di_user-processed-pagination-size', results);
  }, true);

  vm.fileUploader = fileUploaderGenerator({
    vm: vm,
    url: serverAddress.getDocumentUrl() + 'documentsinbox/upload/',
    sucessCallback: (item, response) => {
      // TODO: process upload errors
      if(response.status !== 'ok') return;

      const FIXED_DOCS = response.uploaded_docs.map(e => prepareDoc(e));
      const PLURAL = FIXED_DOCS.length > 1 ? 's' : '';
      const FILENAMES = FIXED_DOCS.map(e => vm.truncateFilenameFilter(e.filename)).join(', ');

      vm.lastUploads = [].concat(FIXED_DOCS);
      vm.documents = FIXED_DOCS.concat(vm.documents);

      notifications.addCurrentView(
        'info',
        `Nuevo${PLURAL} documento${PLURAL} añadido${PLURAL}: ${FILENAMES}`
      );
    },
    errorCallback: function(item, response, status) {
      if(status == 403) {
        notifications.addCurrentView('error', 'No tienes permiso para subir el archivo.');
      } else {
        notifications.addCurrentView('error', response.message);
      }
    },
    ignoreDisplayUploader: true,
    removeAfterUpload: true,
    autoUpload: true,
    onAfterAddingAll: () => {
      vm.lastUploads = [];
    },
    onCompleteAll: () => {
      vm.currentPage = 0;
      vm.sortColumn = 'date_added';
      vm.sortOrder = false;
      vm.fetchDocuments();
    }
  });

  // METHODS
  function assignDoc(doc_id) {
    const DOC = vm.documents.find(e => e.id == doc_id);

    vm.initSearchModal(DOC).then(target => {
      if(!target) return;
      vm.lastTarget = target;

      if(DOC.target == target) {
        notifications.addCurrentView(
          'info',
          'No cambió la asignación. Acción cancelada.'
        );
        return;
      }

      DOC.busy = true;
      vm.requestActionsEndpoint({
        docs: [doc_id],
        actions: [['assign', target]]
      });
    });
  }

  function assignMultipleModal() {
    vm.initSearchModal().then(target => {
      if(!target) return;
      vm.lastTarget = target;

      vm.assignMultiple(target);
    });
  }


  function assignMultiple(target) {
    if(!vm.selectedDocs.length || !target) return;

    // ignore documents with the same assignation and notify
    const ALREADY_ASSIGNED = [];

    vm.selectedDocs.forEach(e => {
      if(e.target == target) {
        ALREADY_ASSIGNED.push(e);
        return;
      }
      e.busy = true;
      return e;
    });

    if(ALREADY_ASSIGNED.length) {
      if(vm.selectedDocs.length == ALREADY_ASSIGNED.length) {
        notifications.addCurrentView(
          'info',
          `Todos los documentos ya estaban asignados a ${target}`
        );

        return;
      };

      const PLURAL = ALREADY_ASSIGNED.length > 1;
      const FILENAMES = ALREADY_ASSIGNED.map(e => vm.truncateFilenameFilter(e.filename)).join(', ');

      notifications.addCurrentView(
        'info',
        `Documento${PLURAL ? 's' : ''} ${FILENAMES} ` +
        `estaba${PLURAL ? 'n' : ''} ya asignado${PLURAL ? 's' : ''} a ${target}`
      );
    }

    vm.requestActionsEndpoint({
      docs: vm.selectedDocs.filter(e => e.target != target).map(e => e.id),
      actions: [['assign', target]]
    });
  }

  function processDoc(doc_id) {
    const DOC = vm.documents.find(e => e.id == doc_id);
    if (DOC.busy) {
      return;
    }

    DOC.busy = true;

    return vm.requestActionsEndpoint({
      docs: [doc_id],
      actions: [['process']]
    });
  }

  function processMultipleDocs() {
    if(!vm.selectedDocs.length) return;
    if (vm.selectedDocs.some(e => e.busy === true)) {
      return;
    }

    vm.selectedDocs.forEach(e => {
      e.busy = true;
      return e;
    });

    vm.requestActionsEndpoint({
      docs: vm.selectedDocs.map(e => e.id),
      actions: [['process']]
    });
  }

  function removeDoc(doc_id) {
    const DOC = vm.documents.find(e => e.id == doc_id);
    DOC.busy = true;

    vm.requestActionsEndpoint({
      docs: [doc_id],
      actions: [['delete']]
    });
  }

  function removeMultipleDocs() {
    if(!vm.selectedDocs.length) return;
    vm.selectedDocs.forEach(e => {
      e.busy = true;
      return e;
    });

    vm.requestActionsEndpoint({
      docs: vm.selectedDocs.map(e => e.id),
      actions: [['delete']]
    });
  }

  function renameDoc(doc_id, newName, ignoreExtensionCheck) {
    const DOC = vm.documents.find(e => e.id == doc_id);

    if(DOC.filename == newName) {
      DOC.rename = null;
      notifications.addCurrentView(
        'error',
        'No se cambió el nombre del archivo. Acción cancelada.'
      );
      return;
    }

    // check extension change and launch confirmation email
    vm.extensionChangeModal = null;
    if(!ignoreExtensionCheck) {
      const OLD_EXTENSION = vm.getFileExtension(DOC.filename);
      const NEW_EXTENSION = vm.getFileExtension(newName);

      if(OLD_EXTENSION != NEW_EXTENSION) {
        vm.extensionChangeModal = {
          oldExtension: OLD_EXTENSION,
          newExtension: NEW_EXTENSION,
          doc: doc_id,
          newName: newName
        };
        return;
      }
    }

    DOC.busy = true;

    vm.requestActionsEndpoint({
      docs: [doc_id],
      actions: [['metadata', 'filename', newName]]
    });
  }

  function saveComment(doc_id, comment) {
    const DOC = vm.documents.find(e => e.id == doc_id);

    DOC.busy = true;

    vm.requestActionsEndpoint({
      docs: [doc_id],
      actions: [['metadata', 'comment', comment]]
    });
  }

  function requestActionsEndpoint(parameters) {
    // Fix responses: if the service return 200 the response data is in
    // response.data, if it fails, it's in response.data.data
    const p$ = DocumentsInboxService.actions(parameters).$promise
      .then(response => {
        vm.processActionsEndpointResponse(response, response.data);
      }, response => {
        vm.processActionsEndpointResponse(response, response.data.data);
      });
    return p$;
  }

  function processActionsEndpointResponse(response, data) {
    let msjs = [];
    let errors = [];
    let refresh = false; // force a refresh of the document when all the actions are processed
    const DOCS_KEYS = Object.keys(data);

    // Missing documents errors
    DOCS_KEYS.forEach(e => {
      if(data[e].status && data[e].status == 'error') errors.push(data[e].error);
    });

    const DOCS_BY_ACTION = getActionsObject(DOCS_KEYS, data);

    // process each action
    Object.keys(DOCS_BY_ACTION).forEach(action => {
      const ACTION_DOCS = DOCS_BY_ACTION[action];
      const SUCESSS_DOCS = ACTION_DOCS.filter(e => e.status == 'ok');
      const ERROR_DOCS = ACTION_DOCS.filter(e => e.status == 'error');

      switch(action) {
        case 'assign':
          if(SUCESSS_DOCS.length) {
            // applies changes to the real documents (the same as vm.documents)
            SUCESSS_DOCS.forEach(docAction => {
              docAction.doc.target = docAction.target;
              docAction.doc.segment = docAction.segment;
              docAction.doc.allowed_document_types = docAction.allowed_document_types;
              docAction.doc.crm_url = docAction.crm_url;
              docAction.doc.busy = false;
            });

            const PLURAL = SUCESSS_DOCS.length > 1 ? 's' : '';

            msjs.push(`Documento${PLURAL} asignado${PLURAL} correctamente ` +
              `a ${SUCESSS_DOCS[0].target}`);
          }

          if(ERROR_DOCS.length) {
            ERROR_DOCS.forEach(docAction => {
              docAction.doc.busy = false;
              const FILENAME = vm.truncateFilenameFilter(docAction.doc.filename);

              errors.push(`No se pudo asignar el documento ${FILENAME}: ${docAction.message}`);
            });
          }

          if(!ERROR_DOCS.length) {
            vm.assignInput = null;
          }
          break;

        case 'delete':
          if(SUCESSS_DOCS.length) {
            const SUCESSS_IDS = SUCESSS_DOCS.map(e => e.doc.id);
            const FILENAMES = getDocsFilenames(SUCESSS_DOCS);
            const PLURAL = SUCESSS_DOCS.length > 1 ? 's' : '';

            msjs.push(`Documento${PLURAL} ${FILENAMES} eliminado${PLURAL} correctamente`);

            vm.documents = vm.documents.filter(e => SUCESSS_IDS.indexOf(e.id) == -1);

            refresh = true;
          }

          if(ERROR_DOCS.length) {
            ERROR_DOCS.forEach(docAction => {
              const FILENAME = vm.truncateFilenameFilter(docAction.doc.filename);
              docAction.doc.busy = false;

              errors.push(`No se pudo eliminar el documento ${FILENAME}: ${docAction.message}`);
            });
          }

          break;

        case 'process':
          if(SUCESSS_DOCS.length) {
            const SUCESSS_IDS = SUCESSS_DOCS.map(e => e.doc.id);
            const FILENAMES = getDocsFilenames(SUCESSS_DOCS);
            const PLURAL = SUCESSS_DOCS.length > 1 ? 's' : '';

            const NOW = new Date();
            const PROCESSED_DOCS = vm.documents.filter(e => SUCESSS_IDS.indexOf(e.id) !== -1);

            PROCESSED_DOCS.forEach(e => {
              e.date_processed = NOW.toISOString();
            });

            // TODO: this don't care about sorting or pagin => WRONG
            vm.processedDocuments = PROCESSED_DOCS.concat(vm.processedDocuments);
            vm.documents = vm.documents.filter(e => SUCESSS_IDS.indexOf(e.id) == -1);
            vm.totalDocuments = vm.documents.length;

            msjs.push(`Documento${PLURAL} ${FILENAMES} procesado${PLURAL} correctamente`);

            refresh = true;
          }

          if(ERROR_DOCS.length) {
            ERROR_DOCS.forEach(docAction => {
              docAction.doc.busy = false;
              const FILENAME = vm.truncateFilenameFilter(docAction.doc.filename);

              errors.push(`No se pudo procesar el documento ${FILENAME}: ${docAction.message}`);
            });
          }

          break;

        case 'metadata':
          if(SUCESSS_DOCS.length) {
            SUCESSS_DOCS.forEach(docAction => {
              docAction.doc[docAction.field] = docAction.newValue;
              docAction.doc.busy = false;

              if(docAction.field == 'filename') {
                docAction.doc.rename = false;
                docAction.doc = prepareDoc(docAction.doc);
              }

              if(docAction.field == 'user') {
                const FILENAME = vm.truncateFilenameFilter(docAction.doc.filename);
                const TARGET_USER = vm.assignableLawyers.users.filter(e => e.id == docAction.newValue)
                  .map(e => e.username)[0];

                msjs.push(`Documento ${FILENAME} asignado a ${TARGET_USER}`);
                refresh = true;
              } else {
                const FILENAME = vm.truncateFilenameFilter(docAction.doc.filename);
                const FIELD = vm.FIELDS_DICT[docAction.field]
                  ? vm.FIELDS_DICT[docAction.field]
                  : docAction.field;

                msjs.push(`Campo <em>${FIELD}</em> actualizado en documento ${FILENAME}`);
              }
            });
          }

          if(ERROR_DOCS.length) {
            ERROR_DOCS.forEach(docAction => {
              const FIELD = vm.FIELDS_DICT[docAction.field]
                ? vm.FIELDS_DICT[docAction.field]
                : docAction.field;
              docAction.doc.busy = false;

              errors.push(`No se pudo cambiar el campo <em>${FIELD}</em> ` +
                `del documento ${vm.truncateFilenameFilter(docAction.doc.filename)}: ${docAction.message}`);
            });
          }
          break;
      }

    });

    if (refresh) vm.refreshLists();

    notifications.addCurrentView(
      (errors.length || (response.status != 'ok')) ? 'error' : 'info',
      generateFinalMessage(msjs, errors)
    );
  }

  // generate html generated for notifications
  function generateFinalMessage(msjs, errors) {
    if(!msjs.length && !errors.length) return 'No se procesó ninguna acción';

    let output = msjs.length ? ((msjs.length > 1 ? '- ' : '') + msjs.join('<br/>- ')) : '';

    if(errors.length) {
      output += msjs.length ? '<br/><br/>' : '';
      output += 'Se produjeron los siguientes errores:<br/>- ';
      output += errors.join('<br/>- ');
    }

    return output;
  }

  // generates an array of actions with document like this:
  // {
  //   'delete': [
  //     {
  //       'doc': FULL_DOC_OBJECT,
  //       'action': FULL_ACTION_RESPONSE
  //     }, {
  //       'doc': ANOTHER_DOC_OBJECT,
  //       'action': ANOTHER_ACTION_RESPONSE
  //     }
  //   ],
  //   'process': [
  //     MORE_DOCS_AND_ACTION_DATA
  //   ],
  //   ….
  // }

  function getActionsObject(DOCS_KEYS, DATA) {
    return DOCS_KEYS.filter(e => !(DATA[e].status && DATA[e].status == 'error'))
      .reduce((actions, docId) => {
        DATA[docId].actions.forEach(action => {
          if(!actions[action.action]) actions[action.action] = [];

          action.doc = vm.getFilteredDocuments(parseInt(docId)); // keep doc reference for later use
          actions[action.action].push(action);
        });
        return actions;
      }, {});
  }

  // return names combined for the doc objects in a actions array
  function getDocsFilenames(doc_list) {
    return doc_list.map(e => {
      return e.doc && e.doc.filename ? vm.truncateFilenameFilter(e.doc.filename) : '';
    }).join(', ');
  }

  // ENDPOINT AND PAGINATION STUFF
  function fetchDocuments() {
    vm.busy = true;

    DocumentsInboxService.get({
      order_by: (vm.sortOrder ? '' : '-') + vm.sortColumn,
      limit: vm.resultsPerPage,
      offset: vm.currentPage * vm.resultsPerPage
    }).$promise.then(response => {
      if(vm.initialLoad) {
        vm.initialLoad = false;
      };
      vm.busy = false;
      vm.documents = response.objects.map(prepareDoc);
      vm.documents.forEach(item => (item.busy = false));
      vm.totalDocuments = response.meta.total_count;
      vm.totalPages = parseInt((vm.totalDocuments - 1) / vm.resultsPerPage, 10) + 1;
    });
  }

  function fetchProcessedDocuments() {
    vm.busy = true;

    ProcessedDocumentsInboxService.get({
      order_by: (vm.processedSortOrder ? '' : '-') + vm.processedSortColumn,
      limit: vm.processedResultsPerPage,
      offset: vm.processedCurrentPage * vm.processedResultsPerPage
    }).$promise.then(response => {
      if(!vm.initialLoad) {
        vm.busy = false;
      };
      vm.processedDocuments = response.objects;
      vm.processedTotalDocuments = response.meta.total_count;
      vm.processedTotalPages = parseInt((vm.processedTotalDocuments - 1) / vm.processedResultsPerPage, 10) + 1;
    });
  }

  function prepareDoc(doc) {
    if (DocTools.isDoc(doc.filename)) {
      doc.fixedUrl = DocTools.getViewerSafeUrl(doc.file_s3);
      doc.type = 'doc';
    } else if (DocTools.isPdf(doc.filename)) {
      doc.type = 'pdf';
      doc.fixedUrl = DocTools.getSafeUrl(doc.file_s3);
    } else if (DocTools.isImage(doc.filename)) {
      doc.type = 'image';
    } else {
      doc.type = 'unknown';
    }
    return doc;
  }

  function setSortColumn(column) {
    vm.sortColumn = column;
    vm.fetchDocuments();
  }

  function goToPage(page) {
    vm.currentPage = page;
    vm.fetchDocuments();
  }

  function getPagerDocs() {
    return new Array(vm.totalPages);
  }

  function setProcessedSortColumn(column) {
    vm.processedSortColumn = column;
    vm.fetchProcessedDocuments();
  }

  function goToProcessedPage(page) {
    vm.processedCurrentPage = page;
    vm.fetchProcessedDocuments();
  }

  function getProcessedPagerDocs() {
    return new Array(vm.processedTotalPages);
  }

  function refreshLists() {
    vm.initialLoad = true;
    vm.fetchDocuments();
    vm.fetchProcessedDocuments();
  }

  // HELPERS
  function getMultipleAvailableDocTypes(docs) {
    // filter available docType checking every selected doc can use it
    return vm.documentTypes.objects.filter(type => {
      return docs.every(doc => {
        return doc.segment == 'LegalFile'
          ? type.type == 'aircase'
          : doc.allowed_document_types.indexOf(type.id) != -1;
      });
    });
  }

  function getDisabledSelectTypeButton() {
    return vm.selectedDocs.some(e => !e.target);
  }

  function getDisabledProcessButton() {
    return vm.selectedDocs.some(e => !e.target || !e.doc_type || e.busy);
  }

  function getDoctypeName(id) {
    if(vm.documentTypes.objects) {
      return vm.documentTypes.objects.filter(e => e.id == id).map(e => e.name)[0];
    }
    return null;
  }

  function clearSelected() {
    vm.documents.forEach(e => { e.selected = false; });
  }

  function toggleSelectAll() {
    const ALL_SELECTED = vm.selectedDocs.length == vm.documents.length;

    vm.documents.forEach(e => {
      e.selected = !ALL_SELECTED;
    });
  }

  function selectLastUploads() {
    const LAST_UPLOADS_IDS = vm.lastUploads.map(e => e.id);

    vm.documents.forEach(e => {
      e.selected = LAST_UPLOADS_IDS.indexOf(e.id) != -1;
      return e;
    });
  }

  function getFileExtension(filename) {
    const GET_EXTENSION = filename.lastIndexOf('.') > 0 && filename.lastIndexOf('.') < filename.length - 1;
    return GET_EXTENSION ? filename.split('.').pop() : null;
  }

  function getFilteredDocuments(ids) {
    // if it's an array of ids return an array
    if(Array.isArray(ids)) return vm.documents.filter(e => ids.indexOf(e.id) != -1);
    // Otherwise, expect a simple id return a object or None
    return vm.documents.find(e => e.id == ids);
  }

  function getSelectedDocsFilenamesArray() {
    return vm.selectedDocs.map(e => vm.truncateFilenameFilter(e.filename));
  }

  function initSearchModal(DOC) {
    var options = {
      backdrop: true,
      keyboard: true,
      backdropClick: true,
      templateUrl: 'js/modules/massive/documents-inbox/templates/modal-search.html',
      controller: 'SearchModalCtrl',
      dialogClass: 'modal modal-full modal-claimsandlegalfilesearch',
      resolve: {
        ActiveTarget: function() {
          return DOC && DOC.target ? DOC.target : null;
        },
        LastTarget: function() {
          return vm.lastTarget;
        }
      }
    };

    return $dialog.dialog(options).open();
  }

  function initExtraInfoModal(target) {
    var options = {
      backdrop: true,
      keyboard: true,
      backdropClick: true,
      templateUrl: 'js/modules/massive/documents-inbox/templates/modal-extra-info.html',
      controller: 'ExtraInfoModalCtrl',
      dialogClass: 'modal modal-extra-info',
      dialogSize: 'large',
      resolve: {
        DocTypes: function() {
          return vm.documentTypes.objects;
        },
        Targets: function() {
          return target ? [target] : vm.selectedDocs;
        }
      }
    };

    return $dialog.dialog(options).open()
      .then(response => {
        if(!response) return;
        // only 1 request
        if(!response.length) {
          vm.requestActionsEndpoint(response);
        } else {
          // multiple request // TODO: Fix
          response.map(e => vm.requestActionsEndpoint(e));
        }
      }, () => {
        // handle rejections
      });
  }

  function initAssignToLawyerModal(target) {
    var options = {
      backdrop: true,
      keyboard: true,
      backdropClick: true,
      templateUrl: 'js/modules/massive/documents-inbox/templates/modal-assign-lawyer.html',
      controller: 'AssignToLawyerModalCtrl',
      dialogClass: 'modal modal-assign-lawyer',
      dialogSize: 'medium',
      resolve: {
        Lawyers: function() {
          return vm.assignableLawyers.users;
        },
        Targets: function() {
          return target ? [target] : vm.selectedDocs;
        }
      }
    };

    return $dialog.dialog(options).open()
      .then(response => {
        if(!response) return;
        vm.requestActionsEndpoint({
          docs: target ? [target.id] : vm.selectedDocs.map(e => e.id),
          actions: [['metadata', 'user', response]]
        });
      });
  }

  function initSliceModal(target) {
    var options = {
      backdrop: true,
      keyboard: true,
      backdropClick: true,
      templateUrl: 'js/modules/massive/documents-inbox/templates/modal-slice.html',
      controller: 'SliceModalCtrl',
      dialogClass: 'modal modal-slice',
      dialogSize: 'medium',
      resolve: {
        Target: function() {
          return target;
        }
      }
    };

    return $dialog.dialog(options).open()
      .then(dialogResponse => {
        if(!dialogResponse) return;

        target.busy = true;

        DocumentsInboxService.slice(dialogResponse).$promise
          .then(response => {
            const FILENAME = vm.truncateFilenameFilter(dialogResponse.filename);

            notifications.addCurrentView('info', `Documento ${FILENAME} creado satisfactoriamente`);

            vm.documents.unshift(prepareDoc(response.model_data));

            target.busy = false;
          }, response => {
            notifications.addCurrentView('error', response.data.data);

            target.busy = false;
          });
      });
  }

  function initMergeModal() {
    if(vm.selectedDocs.length < 2) return;

    var options = {
      backdrop: true,
      keyboard: true,
      backdropClick: true,
      templateUrl: 'js/modules/massive/documents-inbox/templates/modal-merge.html',
      controller: 'MergeModalCtrl',
      dialogClass: 'modal modal-merge',
      dialogSize: 'medium',
      resolve: {
        Targets: function() {
          return vm.selectedDocs;
        }
      }
    };

    return $dialog.dialog(options).open()
      .then(dialogResponse => {
        if(!dialogResponse) return;

        vm.documents.filter(e => dialogResponse.docs.indexOf(e.id) != -1)
          .forEach(e => {
            e.busy = true;
            return e;
          });

        DocumentsInboxService.merge(dialogResponse).$promise
          .then(response => {
            const FILENAME = vm.truncateFilenameFilter(dialogResponse.filename);

            notifications.addCurrentView('info', `Documento ${FILENAME} creado satisfactoriamente`);

            vm.documents.unshift(prepareDoc(response.model_data));

            vm.documents.filter(e => dialogResponse.docs.indexOf(e.id) != -1)
              .forEach(e => {
                e.busy = false;
                return e;
              });
          }, response => {
            notifications.addCurrentView('error', response);

            vm.documents.filter(e => dialogResponse.docs.indexOf(e.id) != -1)
              .forEach(e => {
                e.busy = false;
                return e;
              });
          });
      }, () => {
        // handle rejections
      });
  }
}
