import { extend } from 'angular';

AirlineClaimDetailCtrl.$inject = [
  '$scope',
  '$controller',
  '$http',
  '$dialog',
  '$window',

  'DESISTED_STATES',
  'notifications',
  'serverAddress',
  'DialogFactory',
  'DocumentDownloadFactory',
  'sameFlightCSV',
  'FlightFactory',
  'fileUploaderGenerator',
  'AirlineConstants'
];

// Airline Claim Detail
export default function AirlineClaimDetailCtrl(
  $scope,
  $controller,
  $http,
  $dialog,
  $window,

  DESISTED_STATES,
  notifications,
  serverAddress,
  DialogFactory,
  DocumentDownloadFactory,
  sameFlightCSV,
  FlightFactory,
  fileUploaderGenerator,
  AirlineConstants
) {
  const vm = this;

  const kidEntity = {
      name: '',
      surname: '',
      age: 0,
      idDoc: ''
    },

    INITIAL_STATE = {
      DESISTED_STATES,
      claim: {
        client: {
          user: {
            is_active: true
          }
        }
      },
      viewCancelledClientPermission: true,
      editMode: false,
      incAction: 'Crear',

      ob: {
        incId: 0,
        strikeOption: 1,
        airportDeparture: false,
        airportArrival: false,
        cityDeparture: false,
        cityArrival: false,
        countryDeparture: false,
        countryArrival: false,
        incidentComments: ''
      },

      incidentToDelete: null,

      search: null,
      saleStateSelected: '-',
      filterClaimFinished: '1',
      extLawyerProv: '',

      displayAmountChange: false,
      flight_documents: [],

      showKidForm: false,

      kidsPendingValidation: false,
      newKid: Object.assign({}, kidEntity),

      adult_flying: true,

      disableRegl: false,
      disableConv: false,

      null_boolean_options: [{ value: null, name: '-' }, { value: true, name: 'Sí' }, { value: false, name: 'No' }],

      // Amount related info
      amountInfo: { others: [], total: 0 },
      amountTotal: 0,
      amountInfoValues: [0, 250, 300, 400, 600],

      stop_flight_code: null,

      flight_stops: [],
      flightStatsPath: null,
      flightData: null,
      actions: {
        showNewCaseModal
      }
    },

    METHODS = {
      postRefreshClaimCB,
      preSaveClaimCB,
      saveIncident,
      editIncident,
      deleteIncident,
      newIncident,
      hideIncidentForm,
      updateIncidents,
      filterClaimSameFlightByFinished,
      filterClaimSameFlightByState,
      setFile,
      addAnotherAmount,
      deleteAmount,
      saveAmount,
      sendInvoice,
      inlineAssignLegalFile,
      assignLegalFileFlight,
      sendFax,
      sendDataCancellation,
      changeScopeProcurador,
      displayGeneratePower,
      adultFlying,
      validateKid,
      deleteKid,
      addNewKid,
      validateFlight,
      updateLegalFile,
      changeStateSimple,
      changeClaimAmount,
      changeFlightClaimAmount,
      changeAmount,
      groupInSameFlight,
      changeFlightDetails,
      changeFlightSentencesInfo,
      editFlightStatus,
      updateFlightStatus,
      updateFlightSalesman,
      downloadAllAirlineDocuments,
      exportSameFlightCSV,
      resetIncidentVars,
      sendSignaturitLawsuitToClient,
      showDocumentMergingModal,
      showDocumentSlicingModal,
      showDocumentCopyModal,
      getFlightStops,
      updateFlightStop,
      addFlightStop,
      updateFlightStops,
      deleteFlightStop,
      deleteAllFlightStops,
      prepareFlightTab,
      getDocumentTypeById,

      displayAssignFileToFlightModal: false,
      displayDeleteLegalfileModal: false,

      hasPreview: DocumentDownloadFactory.hasPreviewDocument
    };

  /* Controller startup */
  $controller('BaseDetailCtrl', { vm: vm, $scope: $scope });

  Object.assign(vm, INITIAL_STATE, METHODS, AirlineConstants);

  vm.claimDetail.init();

  fileUploaderGenerator({
    vm: vm,
    url: serverAddress.getDocumentUrl() + 'airline-claim/upload/',
    fillItemData: function(item) {
      vm.busy = true;

      item.formData = [
        {
          type_id: item.documenType.id || 0,
          comment: item.comment || '',
          all_claims: item.allClaims ? 'flight' : '',
          claim_id: vm.claim.id,
          claim_agreement: item.claim_agreement ? 'claim' : ''
        }
      ];
    },
    sucessCallback: () => {
      vm.claimDetail.update('Archivo subido con éxito, actualizando reclamación');
    }
  });

  // FROM HERE JUST FUNCTIONS => => => => => => => => => =>

  function postRefreshClaimCB() {
    initFlightData();

    // fill url variable
    vm.flightStatsPath = getFlightStatsLinkURL();

    // overwrite flight information for flight panels only if was already loader (tabs)
    if(vm.claim.flight && vm.flightData) vm.flightData = vm.claim.flight;

    vm.getDocumentTypes();
    getKids();
    getAmount();
    getFlightStops();

    if (vm.claim.flight) {
      if (vm.claim.flight.salesman) vm.flight_salesman_id = vm.claim.flight.salesman.id;
      else vm.flight_salesman_id = null;

      if (vm.claim.flight.salesman2) vm.flight_salesman2_id = vm.claim.flight.salesman2.id;
      else vm.flight_salesman2_id = null;
    } else {
      vm.flight_salesman_id = null;
      vm.flight_salesman2_id = null;
    }

    if (vm.claim.force_majeure_reason === 6) {
      $http
        .get(`${serverAddress.getBaseUrl()}common/claim/customcomment/get/${vm.SEGMENT_PREFIX}/${vm.claim.id}`, {})
        .then(function(response) {
          vm.comments.changeState = response.data.custom_comment;
        });
    }

    if (!vm.claim.user_finished) {
      vm.claim.user_finished = 'Cliente';
    }

    updateIncidents();
  }

  function preSaveClaimCB() {
    if (vm.flight_stops.length > 0) updateFlightStops();
  }

  function resetIncidentVars() {
    vm.editMode = false;
    vm.incAction = 'Crear';
    vm.ob.incId = 0;
    vm.ob.strikeOption = 1;
    vm.ob.airportDeparture = false;
    vm.ob.airportArrival = false;
    vm.ob.cityDeparture = false;
    vm.ob.cityArrival = false;
    vm.ob.countryDeparture = false;
    vm.ob.countryArrival = false;
    vm.ob.incidentComments = '';
  }

  function saveIncident() {
    // Data to POST
    var data = {
      strikeOption: 0,
      airportDeparture: vm.ob.airportDeparture,
      airportArrival: vm.ob.airportArrival,
      cityDeparture: vm.ob.cityDeparture,
      cityArrival: vm.ob.cityArrival,
      countryDeparture: vm.ob.countryDeparture,
      countryArrival: vm.ob.countryArrival,
      incidentComments: vm.ob.incidentComments,
      claim_id: vm.claim.id,
      id: vm.ob.incId
    };

    // Checkbox logic
    if (vm.ob.strikeOption === 'la huelga') {
      data['strikeOption'] = 1;
    } else if (vm.ob.strikeOption === 'el incidente') {
      data['strikeOption'] = 2;
    }

    $http({
      url: serverAddress.getBaseUrl() + 'incident/',
      method: 'POST',
      data: data
    })
      .then(function() {
        notifications.addCurrentView('info', 'Huelga/Incidente salvado con éxito');
        vm.hideIncidentForm();
        vm.updateIncidents();
      })
      .catch(function() {
        notifications.addCurrentView('error', 'Error salvando huelga/incidente');
        vm.hideIncidentForm();
        vm.updateIncidents();
      });
  }

  function editIncident(incident) {
    vm.editMode = true;
    vm.incAction = 'Actualizar';

    vm.ob.strikeOption = incident.strikeOption;
    vm.ob.airportDeparture = incident.airportDeparture;
    vm.ob.airportArrival = incident.airportArrival;
    vm.ob.cityDeparture = incident.cityDeparture;
    vm.ob.cityArrival = incident.cityArrival;
    vm.ob.countryDeparture = incident.countryDeparture;
    vm.ob.countryArrival = incident.countryArrival;
    vm.ob.incidentComments = incident.incidentComments;
    vm.ob.incId = incident.id;
  }

  function deleteIncident() {
    if(!vm.incidentToDelete) {
      throw new Error('deleteIncident called without an active incident.');
    };

    const DELETE_URL = serverAddress.getBaseUrl() + 'delete-incident/' + vm.incidentToDelete.id + '/';

    vm.busy = true;
    vm.incidentToDelete = null;

    $http.get(DELETE_URL)
      .then(() => {
        notifications.addCurrentView('info', 'Huelga/incidente eliminada con éxito');
      })
      .catch(() => {
        notifications.addCurrentView('error', 'Error borrando huelga/incidente');
      })
      .finally(() => {
        vm.busy = false;
        vm.hideIncidentForm();
        vm.updateIncidents();
      });
  }

  function newIncident() {
    vm.editMode = true;
    vm.incAction = 'Crear';

    vm.ob.strikeOption = 'la huelga';
    vm.ob.airportDeparture = false;
    vm.ob.airportArrival = false;
    vm.ob.cityDeparture = false;
    vm.ob.cityArrival = false;
    vm.ob.countryDeparture = false;
    vm.ob.countryArrival = false;
    vm.ob.incidentComments = '';
    vm.ob.incId = 0;
  }

  function hideIncidentForm() {
    vm.editMode = false;
    vm.incAction = 'Crear';
  }

  function updateIncidents() {
    $http({
      url: serverAddress.getBaseUrl() + 'incidents-which-affect/' + vm.claim.id + '/',
      method: 'GET'
    })
      .then(function(response) {
        vm.incidents_list = response.data;
        resetIncidentVars();
      })
      .catch(function() {
        notifications.addCurrentView('error', 'Error obteniendo huelgas/incidentes actualizados');
        resetIncidentVars();
      });
  }

  function filterClaimSameFlightByFinished(finishedText) {
    return function(c) {
      if (finishedText === '1') {
        return c.finished;
      } else if (finishedText === '0') {
        return !c.finished;
      }
      // All items valid
      return true;
    };
  }

  function filterClaimSameFlightByState(state) {
    return function(c) {
      // No filter, all claims
      if (state === '*') {
        return true;
      } else if (state === '-') {
        // Just claims with no sales state
        return !c.sales_state__id;
      }
      // Just claims with specific sales state
      return c.sales_state__id === state;
    };
  }

  function setFile(element) {
    var reader = new FileReader();
    reader.onload = (function() {
      return function(e) {
        $scope.$apply(function() {
          vm.amountInfo.others[0].file.contents = e.target.result;
        });
      };
    })();
    reader.readAsText(element.files[0]);
  }

  function addAnotherAmount() {
    vm.amountInfo.others.push({ description: '', amount: '' });
  }

  function deleteAmount(amount) {
    var index = vm.amountInfo.others.indexOf(amount);
    vm.amountInfo.others.splice(index, 1);
  }

  function saveAmount() {
    $http.post(`${serverAddress.getBaseUrl()}claim/${vm.claim.id}/amount/`, vm.amountInfo)
      .then(function() {
        getAmount(true);
        notifications.addCurrentView('info', 'Cantidad modificada con exito');
      })
      .catch(function() {
        notifications.addCurrentView('error', 'Error guardando datos. Revista el formato de los números');
      });
  }

  function getAmount(refresh) {
    $http.get(`${serverAddress.getBaseUrl()}claim/${vm.claim.id}/amount/`, {})
      .then(function(res) {
        if (res.data.regulation === 'None') {
          res.data.regulation = '';
        }
        vm.amountInfo = res.data;
        kidsAmount();
        if (refresh) {
          vm.claimDetail.update();
        }
      });
  }

  function kidsAmount() {
    vm.kidsPendingValidation = false;
    let kids = 0;
    for (var i = 0; i < vm.claim.kids.length; i += 1) {
      // Skip deleted kids
      if (vm.claim.kids[i].deleted) {
        continue;
      }

      if (vm.claim.kids[i].is_validated === null) {
        if (vm.amountInfo.total === 0) vm.kidsPendingValidation = true;
        break;
      } else if (vm.claim.kids[i].is_validated) {
        kids += 1;
      }
    }
    return kids;
  }

  function lawsuitProcedure() {
    if (vm.claim.flight && vm.claim.flight.legal_type) {
      if (vm.claim.flight.legal_type === 'CO' || vm.claim.type.id === 4) {
        vm.amountInfo.type = 'convenio';
      }
      if (vm.claim.flight.legal_type === 'RE' && vm.claim.type.id !== 4) {
        vm.amountInfo.type = 'reglamento';
      }
    }
  }

  function sendInvoice() {
    $http({
      url: serverAddress.getBaseUrl() + 'claim/sendinvoice/',
      data: vm.claim.id,
      method: 'POST'
    })
      .then(function() {
        vm.claimDetail.update('Factura enviada al cliente');
      })
      .catch(function(response) {
        notifications.addCurrentView('error', response.data);
      });
  }

  function inlineAssignLegalFile(claimId) {
    $http({
      url: serverAddress.getBaseUrl() + 'claim/legalfile/flight/',
      data: { claim_id: claimId, legalfile_id: vm.claim.legalfile.id },
      method: 'POST'
    })
      .then(function(response) {
        vm.claimDetail.update('Numero de expediente asignado a reclamacion: #' + response.data);
      })
      .catch(function(response) {
        notifications.addCurrentView('error', response.data);
      });
  }

  function sendFax() {
    $http({
      url: serverAddress.getBaseUrl() + 'claim/sendfax/',
      data: vm.claim.id,
      method: 'POST'
    }).then(function() {
      vm.claimDetail.update('Fax enviado a la compañia');
    });
  }

  function sendDataCancellation() {
    $http({
      url: serverAddress.getBaseUrl() + 'claim/senddatacancellation/',
      data: vm.claim.id,
      method: 'POST'
    })
      .then(function() {
        vm.claimDetail.update('Cancelación de datos enviada a la compañia');
      })
      .catch(function(response) {
        notifications.addCurrentView('error', response.data);
      });
  }

  function updateFlightStops() {
    for (var i = 0; i < vm.flight_stops.length; i++) {
      var airport = vm.flight_stops[i].airport ? vm.flight_stops[i].airport : null;
      var airline = vm.flight_stops[i].airline ? vm.flight_stops[i].airline : null;
      updateFlightStop(
        airport,
        vm.flight_stops[i].stop_nr,
        vm.flight_stops[i].flight_code,
        vm.flight_stops[i].scheduled_departure,
        airline
      );
    }
  }

  function getKids() {
    vm.kids = vm.claim.kids.filter(d => !d.deleted);
  }

  function changeScopeProcurador(procurador) {
    vm.legalfileinfo.procurador_id = procurador;
  }

  function displayGeneratePower() {
    return vm.claim.step >= 4;
  }

  function adultFlying(claimId, adult) {
    $http({
      url: serverAddress.getBaseUrl() + 'claim/kid/adultflying/',
      method: 'POST',
      data: {
        claim_id: claimId,
        adult: adult
      }
    })
      .then(function() {
        vm.claimDetail.update('Información del adulto modificada con éxito');
      })
      .catch(function() {
        notifications.addCurrentView('error', 'No ha sido posible modificar la información del adulto');
      });
  }

  function validateKid(airlineComplaintKidId, valid) {
    $http({
      url: serverAddress.getBaseUrl() + 'claim/kid/validate/',
      method: 'POST',
      data: {
        airline_complaint_kid_id: airlineComplaintKidId,
        valid: valid
      }
    }).then(function() {
      vm.claimDetail.update('Información del menor modificada con éxito, recuerda revisar la cantidad reclamada');
    });
  }

  function deleteKid(airlineComplaintKidId) {
    $http({
      url: serverAddress.getBaseUrl() + 'claim/kid/delete/',
      method: 'POST',
      data: {
        airline_complaint_kid_id: airlineComplaintKidId
      }
    })
      .then(function() {
        vm.claimDetail.update('Información del Menor eliminado con éxito');
      })
      .catch(function() {
        notifications.addCurrentView('error', 'No ha sido posible eliminar al menor');
      });
  }

  function addNewKid() {
    $http
      .post(serverAddress.getBaseUrl() + 'claim/kid/addnewkid/', {
        kid_name: vm.newKid.name,
        kid_surname: vm.newKid.surname,
        kid_age: vm.newKid.age,
        kid_iddocument: vm.newKid.idDoc,
        claim_id: vm.claim.id
      })
      .then(() => vm.claimDetail.update('Menor añadido con éxito'))
      .catch(() => notifications.addCurrentView('error', 'No ha sido posible añadir al menor'))
      .finally(() => {
        vm.showKidForm = false;
        Object.assign(vm.newKid, kidEntity);
      });
  }

  function findForceMajeureReport() {
    var forceMajeureReport = false;
    for (var i = 0; i < vm.flight_documents.length; i++) {
      if (vm.flight_documents[i]['type']['id'] === 71) {
        forceMajeureReport = true;
      }
    }
    if (forceMajeureReport === false) {
      notifications.addCurrentView(
        'error',
        'No es posible invalidar el vuelo si el informe de fuerza mayor no ha sido incluido.'
      );
      return false;
    }
    return true;
  }

  function validateFlight(flightId, valid, status) {
    var cause = $window.$('#invalidate').val();

    if (cause === 1) {
      if (!findForceMajeureReport()) {
        return;
      }
    }

    if (valid) {
      cause = 0;
    } else {
      switch (status) {
        case 'delayed':
          if (cause === 0) {
            notifications.addCurrentView('error', 'Por favor, selecciona un motivo de invalidez.');
          }
          break;
        case 'cancelled':
          cause = 1; //Major
          break;
        default:
          cause = 3;
          break;
      }
    }

    $http({
      url: serverAddress.getBaseUrl() + 'claim/flight/validate/',
      method: 'POST',
      data: {
        flight_id: flightId,
        valid: valid,
        cause: cause,
        force_majeure_reason: vm.claim.force_majeure_reason
      }
    }).then(function() {
      vm.claimDetail.update('Información del vuelo modificada con éxito');
    });
  }

  function updateLegalFile(claimId, legalfile) {
    $http({
      url: serverAddress.getBaseUrl() + 'claim/' + claimId + '/update/legalfile/',
      method: 'POST',
      data: {
        legalfile: legalfile
      }
    }).then(function() {
      vm.claimDetail.update();
    });
  }

  function downloadAllAirlineDocuments() {
    DocumentDownloadFactory.downloadAllAirlineDocuments(vm.claim);
  }

  // Unfinished claims, desists change states
  function changeStateSimple(newStateId, comment) {
    $http
      .post(serverAddress.getBaseUrl() + 'claim/state/changesimple/', {
        claim_id: vm.claim.id,
        new_state_id: newStateId,
        comment: comment
      })
      .then(function() {
        vm.claimDetail.update('Cambio de estado completado');
      })
      .catch(function(response) {
        vm.claimDetail.update('');
        notifications.addCurrentView('error', response.data);
      });
  }

  function changeClaimAmount() {
    notifications.clear();

    var amountData = {
      amountType: vm.amountInfo.type,
      amountRegulation: vm.amountInfo.regulation,
      amountMoral: vm.amountInfo.moral,
      others: vm.amountInfo.others
    };

    var d = $dialog.dialog(
      extend(vm.changeAmountDialogOpts, {
        resolve: {
          claimId: function() {
            return vm.claim.id;
          }, // We will overwrite this one
          legalfileId: function() {
            return vm.claim.legalfile.id;
          },
          amountData: function() {
            return amountData;
          },
          parentScope: function() {
            return vm;
          }
        }
      })
    );
    d.open();
  }

  function changeFlightClaimAmount() {
    notifications.clear();

    var d = $dialog.dialog(
      extend(vm.changeAmountDialogOpts, {
        template: require('../../../../partials/common/modals/flight-change-amount.html'),
        resolve: {
          claim_id: function() {
            return vm.claim.id;
          }, // We will overwrite this one
          legalfile_id: function() {
            return null;
          },
          amountType: function() {
            return vm.amountInfo.type;
          },
          amountRegulation: function() {
            return vm.amountInfo.regulation;
          },
          amountMoral: function() {
            return vm.amountInfo.moral;
          },
          others: function() {
            return vm.amountInfo.others;
          },
          parentScope: function() {
            return vm;
          },
          serverMethod: function() {
            return 'flight/amount/change/';
          }
        }
      })
    );
    d.open();
  }

  function changeAmount() {
    vm.displayAmountChange = true;
  }

  function groupInSameFlight() {
    var claimsSameFlight = vm.claim.claims_same_flight.filter(claim => claim.source__name === 'Web');

    if (claimsSameFlight.length > 0) {
      var claimIds = claimsSameFlight.map(claim => claim.id);
      var flightCode = vm.claim.flight_code;
      claimIds.push(vm.claim.id);

      var d = $dialog.dialog(
        extend(vm.ClaimsChangeSourceDialogOpts, {
          resolve: {
            flight_code: function() {
              return flightCode;
            },
            claim_ids: function() {
              return claimIds;
            },
            parentScope: function() {
              return vm;
            }
          }
        })
      );
      d.open();
    } else {
      notifications.addCurrentView(
        'info',
        'No hay reclamaciones a las que se le pueda cambiar el origen a "Aeropuerto"'
      );
    }
  }

  function exportSameFlightCSV() {
    var sameFlightClaimIds = vm.claim.claims_same_flight.map(el => el.id);
    sameFlightClaimIds.push(vm.claim.id);

    sameFlightCSV(sameFlightClaimIds).then(function(response) {
      if (response.data === 'BIG_EXCEL_EMAIL') {
        var msg =
          'El excel que has solicitaste es demasiado grande. Por está razón, te lo hemos enviado a tu' +
          ' correo. En breve lo recibirás en tu bandeja de entrada. ¡Saludos! (^_^)/';
        notifications.addCurrentView('info', msg);
      } else {
        $window.location = '/data/' + response.data;
      }
    });
  }

  function getFlightStops() {
    FlightFactory.getFlightStops(vm.claim.id).then(function(response) {
      vm.flight_stops = response.data;
    });
  }

  function getFlightStatsLinkURL() {
    const BASEPATH = 'https://www.flightstats.com/v2/historical-flight/';
    if(!vm.claim || !vm.claim.flight) return BASEPATH;

    const AIRCODE = vm.claim.flight.airline_code;
    const FLIGHTNUMBER = vm.claim.flight.flight_number;
    const DEPARTURE_YEAR = vm.claim.flight.scheduled_departure.substring(0, 4);
    const DEPARTURE_MONTH = vm.claim.flight.scheduled_departure.substring(5, 7);
    const DEPARTURE_DAY = vm.claim.flight.scheduled_departure.substring(8, 10);

    return `${BASEPATH}${AIRCODE}/${FLIGHTNUMBER}/${DEPARTURE_YEAR}/${DEPARTURE_MONTH}/${DEPARTURE_DAY}`;
  }

  // Assign current legalfile to all claims in the flight
  function assignLegalFileFlight() {
    vm.busy = true;
    vm.displayAssignFileToFlightModal = false;

    $http
      .post(serverAddress.getBaseUrl() + 'claim/legalfile/flight/all/', {
        claim_id: vm.claim.id,
        legalfile_id: vm.claim.legalfile.id
      })
      .then(function(response) {
        vm.claimDetail.update('Numero de expediente asignado a: ' + response.data + ' nuevas reclamaciones');
      })
      .catch(function(response) {
        notifications.addCurrentView(
          'error',
          'Expediente asignado excepto para las reclamaciones ' + response.data + ' que tienen estado comercial'
        );
      })
      .finally(() => {
        vm.busy = false;
      });
  }

  function showDocumentMergingModal() {
    var currentDocuments = vm.documents;
    var documentTypes = vm.documentTypes;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: vm.SEGMENT_PREFIX
    };

    var multipleSourceSelectionEnabled = false;
    DialogFactory.mergeDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimData,
      multipleSourceSelectionEnabled,
      function() {
        var successMsg = 'Unión de documentos solicitada. Cuando termine recibirás un email.';
        vm.claimDetail.update(successMsg);
      }
    );
  }

  function showDocumentSlicingModal() {
    var currentDocuments = vm.documents;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: vm.SEGMENT_PREFIX
    };
    DialogFactory.sliceDocument(currentDocuments, claimData, function() {
      var successMsg = 'Separación de documento solicitada. Cuando termine recibirás un email.';
      vm.claimDetail.update(successMsg);
    });
  }

  function showDocumentCopyModal(prefix, type) {
    var currentDocuments = null;
    var documentTypes = vm.documentTypes;
    var claimTypes = vm.claimTypes;
    var multipleSourceSelectionEnabled = false;
    var copy_type = type;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: prefix
    };

    // If the claim has no documents, an error is shown
    if (vm.documents.length === 0) {
      notifications.addCurrentView(
        'error',
        'No se ha podido realizar la acción. La reclamación no tiene ' + 'ningún documento adjunto.'
      );
      return;
    }

    if (type === 'claim') {
      try {
        currentDocuments = DialogFactory.copyDocsToClaimValidation(vm.documents);
      } catch (e) {
        notifications.addCurrentView('error', e);
        return;
      }
    } else {
      try {
        currentDocuments = DialogFactory.copyDocsToLegalfileValidation(vm.documents, vm.claim);
      } catch (e) {
        notifications.addCurrentView('error', e);
        return;
      }
    }

    DialogFactory.copyDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimTypes,
      claimData,
      multipleSourceSelectionEnabled,
      copy_type,
      function() {
        vm.claimDetail.update();
      }
    );
  }

  function changeFlightDetails() {
    $http({
      url: serverAddress.getBaseUrl() + 'airdata/flight/details/change/',
      method: 'POST',
      data: {
        flight_details: vm.flight_details,
        legal_type: vm.claim.flight.legal_type
      }
    }).then(function(response) {
      if (response.data.error) {
        notifications.addCurrentView('error', response.data.msg);
      } else {
        vm.claimDetail.update();
      }
    });
  }

  function changeFlightSentencesInfo() {
    var d = $dialog.dialog(
      extend(vm.confirmChangeFlightSentencesOpts, {
        resolve: {
          parentScope: function() {
            return vm;
          }
        }
      })
    );
    d.open();
  }

  function editFlightStatus() {
    vm.updateFlightStatusChecked = !vm.updateFlightStatusChecked;
  }

  function updateFlightStatus() {
    if (vm.claim.flight.status === 'in_time') {
      vm.claim.flight.status = null;
    }

    $http({
      url: serverAddress.getBaseUrl() + 'airdata/flight-status/update/',
      method: 'POST',
      data: {
        flight_id: vm.claim.flight.id,
        new_flight_status: vm.claim.flight.status,
        new_delayed_time: vm.claim.flight.delay
      }
    }).then(function(response) {
      if (response.data.error) {
        notifications.addCurrentView('error', response.data.msg);
      } else {
        vm.claimDetail.update(response.data.msg);
      }
    });
  }

  function updateFlightSalesman(flightSalesmanId1, flightSalesmanId2) {
    $http({
      url: serverAddress.getBaseUrl() + 'airdata/flight-salesman/update/',
      method: 'POST',
      data: {
        flight_id: vm.claim.flight.id,
        salesman1: flightSalesmanId1,
        salesman2: flightSalesmanId2
      }
    }).then(function(response) {
      if (response.data.error) {
        notifications.addCurrentView('error', response.data.msg);
      } else {
        notifications.addCurrentView('success', response.data.msg);
      }
      vm.claimDetail.update();
    });
  }

  function sendSignaturitLawsuitToClient() {
    // Send email to claim client with the lawsuit, to be signed via Signaturit
    $http
      .post(serverAddress.getBaseUrl() + 'airlines/' + vm.claim_id + '/send/lawsuit-with-signaturit/', {})
      .then(function(response) {
        notifications.addCurrentView('info', response.data);
      })
      .catch(function(response) {
        notifications.addCurrentView('error', response.data);
      });
  }

  // Watches for changes in amount info to calculate the total
  $scope.$watch(
    'vm.amountInfo',
    function() {
      if (!vm.claim) return;

      var moral = vm.amountInfo.moral || 0;
      var regulation = vm.amountInfo.regulation || 0;


      if (vm.claim && vm.claim.kids) {
        regulation += regulation * vm.amountInfo.kids;
      }

      if (!vm.claim.adult_flying) {
        regulation -= vm.amountInfo.regulation;
      }

      if (vm.amountInfo.type === 'convenio') {
        vm.amountTotal = moral;
      } else {
        vm.amountTotal = moral + regulation;
      }

      for (var i = 0; i < vm.amountInfo.others.length; i++) {
        vm.amountTotal += vm.amountInfo.others[i].amount || 0;
      }

      lawsuitProcedure();
    },
    true
  );

  // TODO: Burn this code
  /* eslint-disable no-unused-vars */
  function deleteFlightStop(airport, stop_nr, delete_stop) {
    notifications.clear();

    var d = $dialog.dialog(
      extend(vm.confirmFlightStopDeleteCtrl, {
        resolve: {
          claim_id: function() {
            return vm.claim.id;
          },
          stop_nr: function() {
            return stop_nr;
          },
          parentScope: function() {
            return vm;
          },
          flight_code: function() {
            return -1;
          },
          stop_airport: function() {
            return null;
          },
          airline_id: function() {
            return null;
          }
        }
      })
    );
    d.open();
  }
  /* eslint-enable */

  function deleteAllFlightStops() {
    notifications.clear();

    var d = $dialog.dialog(
      extend(vm.confirmFlightStopDeleteAllCtrl, {
        resolve: {
          claim_id: function() {
            return vm.claim.id;
          },
          stop_nr: function() {
            return -1;
          },
          parentScope: function() {
            return vm;
          },
          flight_code: function() {
            return -1;
          },
          stop_airport: function() {
            return null;
          },
          airline_id: function() {
            return null;
          }
        }
      })
    );
    d.open();
  }

  vm.confirmFlightStopDeleteCtrl = {
    controller: 'ConfirmFlightStopDeleteCtrl',
    template: require('../../../../partials/common/modals/flight-delete-stop.html'),
    backdrop: true,
    keyboard: true,
    backdropClick: true
  };

  vm.confirmFlightStopDeleteAllCtrl = {
    controller: 'ConfirmFlightStopDeleteCtrl',
    template: require('../../../../partials/common/modals/flight-delete-all-stops.html'),
    backdrop: true,
    keyboard: true,
    backdropClick: true
  };

  function updateFlightStop(stop_airport, stopnr, stop_flight_code, scheduled_dep, stop_airline) {
    $http({
      url: serverAddress.getBaseUrl() + 'airdata/update-flight-stop/',
      method: 'POST',
      data: {
        claim_id: vm.claim.id,
        stop_airport: stop_airport !== null ? stop_airport.id : null,
        stop_nr: stopnr,
        flight_code: stop_flight_code,
        scheduled_departure: scheduled_dep,
        airline_id: stop_airline !== null ? stop_airline.id : null
      }
    }).then(response => {
      if (response.data.status == 'ok') {
        notifications.addCurrentView('success', 'Escala modificada');

      } else {
        notifications.addCurrentView('error', 'No se ha podido modificar la escala');
      }
    })
      .catch(() => {
        notifications.addCurrentView('error', 'Ocurrió un error al actualizar la escala. Vuelva a intentarlo.');
      })
      .finally(function(response) {
        if (response.data.claim_updated == true) {
          vm.claimDetail.update();
        } else {
          getFlightStops();
        }
      });
  }

  function addFlightStop(stop_nr) {
    $http({
      url: serverAddress.getBaseUrl() + 'airdata/add-flight-stop/',
      method: 'POST',
      data: {
        claim_id: vm.claim.id,
        stop_nr: stop_nr !== null ? stop_nr : null
      }
    }).then(response => {
      if (response.data.status == 'ok') {
        if (stop_nr == 0) {
          vm.claimDetail.update('El vuelo ha pasado a tener escalas');
        } else if (stop_nr == -1 && response.data.stop_deleted) {
          vm.claimDetail.update('El vuelo ha dejado de ser con escalas');
        }
      }
    })
      .catch(() => {
        notifications.addCurrentView('error', 'Ocurrió un error al generar la nueva escala. Vuelva a intentarlo.');
      })
      .finally(() => {
        getFlightStops();
        notifications.addCurrentView('success', 'Rellena los datos de la nueva escala');
      });
  }

  function initFlightData() {
    if (vm.claim.flight) {
      vm.flight_documents = vm.claim.flight.flightdocuments.filter(d => !d.is_missing && !d.deleted);

      FlightFactory.getFlightDetails(vm.claim.flight.id)
        .then(function(response) {
          vm.flight_details = response.data.flight_details;
          vm.flight_details.aesa_complaint = response.data.flight_details.aesa_complaint ? 'true' : 'false';
          vm.flight_details.negotiated = response.data.flight_details.negotiated ? 'true' : 'false';
          vm.flight_details.negotiation_type = response.data.flight_details.negotiation_type
            ? response.data.flight_details.negotiation_type
            : null;
        });

    } else {
      vm.flight_documents = [];
    }
  }

  function prepareFlightTab() {
    if(vm.claim.flight && !vm.flightData) vm.flightData = vm.claim.flight;
  }

  function getDocumentTypeById(id) {
    return vm.documentTypes.find(e => e.id == id);
  }

  /**
  * @param {*} showNewCaseModal 
  */
  function showNewCaseModal() {
    const data = {
      currentDocuments: vm.documents,
      ...[vm.documentType],
      claimData: {
        id: vm.claim.id,
        prefix: vm.SEGMENT_PREFIX,
        type: vm.claim.type.id
      }
    };
    if (!vm.loadedDocuments) {
      vm.fetchDocuments(true)
        .then(() => {
          data.currentDocuments = vm.documentsResponse;
          DialogFactory.generateNewCase(data);
        })
        .catch(() => {
          notifications.addCurrentView('error', `Se produjo un error actualizando los documentos de la ${vm.VERBOSE_NAME.toLowerCase()}`);
        });
    } else {
      DialogFactory.generateNewCase(data);
    }
  }
}
