import angular from 'angular';

import BankClaimDetailCtrl from './claimDetailCtrl';
import BankClaimListCtrl from './claimListCtrl';

// Lists
import BankLawyerPanelCtrl from './lawyerPanelCtrl';
import BankSalesPeoplePanelCtrl from './salesPeoplePanelCtrl';

// Common Services
import BankClaim from './services/claimService';
import BankDictionaryService from './services/bankDictionaryService';
import BankEntityService from './services/bankEntityService';
import CreditCardService from './services/creditCardService';
import IssuingBankService from './services/issuingBankService';

import BankSalesman from './services/salesmanService';
import BankLawyer from './services/lawyerService';

// Products
import ReportService from './services/reportService';

import BankConstants from './constants';

import BankRoutes from './routes';

angular
  .module('reclamador.segments.bank', [])

  .controller('BankClaimDetailCtrl', BankClaimDetailCtrl)
  .controller('BankClaimListCtrl', BankClaimListCtrl)
  .controller('BankLawyerPanelCtrl', BankLawyerPanelCtrl)
  .controller('BankSalesPeoplePanelCtrl', BankSalesPeoplePanelCtrl)

  .factory('BankClaim', BankClaim)
  .factory('ReportService', ReportService)
  .factory('BankSalesman', BankSalesman)
  .factory('BankLawyer', BankLawyer)
  .factory('BankDictionaryService', BankDictionaryService)
  .factory('BankEntityService', BankEntityService)
  .factory('CreditCardService', CreditCardService)
  .factory('IssuingBankService', IssuingBankService)

  .constant('BankConstants', BankConstants)
  .config(BankRoutes);
