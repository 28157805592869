import moment from 'moment';

UserPanelCtrl.$inject = [
  '$rootScope',
  '$route',
  '$window',
  '$timeout',
  'ClaimsLawyerPanelData',
  'ClaimType'
];

export default function UserPanelCtrl(
  $rootScope,
  $route,
  $window,
  $timeout,
  ClaimsLawyerPanelData,
  ClaimType
) {
  const vm = this;

  let filterTimer = null;
  let lawyerUsername = '';
  let segmentWithSLA = {};

  // Variables
  const INITIAL_STATE = {
    SLA: false,
    busy: true,
    expiredSlasCount: 0,
    aboutToExpireSlasCount: 0,
    urlToExpiredSLAClaimsList: '',
    pathToAboutToExpireSLAClaimsList: '',
    claimTypes: [],
    claimTypesGroupedByActive: {
      true: [],
      false: []
    },

    narrowFilterSearch: '',
    dropdownFiltersTitle: '',
    hasClaimStats: false,
    appliedClaimTypes: [],
    slaExpirationStartDatetime: null,

    completedWorkMsg: 'Todo en orden. ¡Buen trabajo!',
    expiredSlasPanelTitle: 'SLAs vencidos',
    aboutToExpireSlasPanelTitle: 'SLAs a punto de vencer',

    // required by tpl
    layoutSettings: $route.current.$$route.layoutSetting,

    claimStats: [],
    claimStatColumns: [
      { id: 0, sortable: true, headerName: 'ESTADO', fieldName: 'state_name', sorting: '' },
      { id: 1, sortable: true, headerName: 'NÚMERO', fieldName: 'count', sorting: '' },
      { id: 2, sortable: true, headerName: 'AVISOS', fieldName: 'expired_slas', sorting: '' }
    ]
  };

  const METHODS = {
    // Public functions
    sortByColumn,
    getSegmentWithSLA,
    getSegmentType,

    openClaimListWithAppliedFilter,
    toggleFilters,
    narrowClaimTypes,
    removeFilterViaTag,
    formatClaimStat,
    formatAppliedClaimTypes,
    clearFilters
  };

  Object.assign(vm, INITIAL_STATE, METHODS);

  // Initialization logic
  $rootScope.$on('event:auth-session', (ev, user) => {
    startup(user);
  });

  if ($rootScope.user) {
    startup($rootScope.user);
  }

  function startup(user) {
    try {
      lawyerUsername = user.username;
      vm.layoutSettings.contentTitle = 'Hola, ' + lawyerUsername;

      segmentWithSLA = getSegmentWithSLA(user.segments);
      vm.SLA = Boolean(segmentWithSLA);

      if (vm.SLA) {
        segmentWithSLA.type = getSegmentType(segmentWithSLA);
        segmentWithSLA.prefix = segmentWithSLA && segmentWithSLA.prefix;
        _updateClaimStats(segmentWithSLA.prefix, segmentWithSLA.type);
        _updateClaimTypes(segmentWithSLA.type);
      } else {
        vm.busy = false;
      }
    } catch (e) {
      console.error(e);
    }
  }

  function getSegmentWithSLA(segments) {
    if (!segments || segments.length === 0) return null;
    return segments.find(s => s.sla_panel_data);
  }
  function getSegmentType(segmentWithSLA) {
    if (
      !segmentWithSLA ||
      !segmentWithSLA.hasOwnProperty('claim_types') ||
      segmentWithSLA.claim_types.length === 0
    )
      return null;
    return segmentWithSLA.claim_types[0].type; //Move as topic field
  }

  // Public functions implementation

  /*
   * Sorts the contents of the claim stats table, based on the selected column and its sorting order.
   */
  function sortByColumn(column) {
    column.sorting = column.sorting === 'asc' ? 'desc' : 'asc';
    vm.claimStats = vm.claimStats.sortBy(column.fieldName);

    vm.claimStatColumns.forEach(statColumn => {
      statColumn.sorting = statColumn.fieldName === column.fieldName ? column.sorting : '';
    });

    if (column.sorting === 'desc') {
      vm.claimStats = vm.claimStats.reverse();
    }
  }

  /*
   * Opens a new tab/window whose URL is the one associated to the claims list panel, with username and state
   * filters applied.
   */
  function openClaimListWithAppliedFilter(claimStat) {
    const type = segmentWithSLA.type;
    let targetPath = `#/${type}-claims/list/filter/lawyer=${lawyerUsername}`;

    const claim_state_id = claimStat.state_id;
    if (claimStat.is_sales_state) {
      targetPath += '&sales_state_id=' + claim_state_id;
    } else {
      targetPath += '&state_id=' + claim_state_id + '&sales_state_id=null';
    }

    targetPath = _applyClaimTypesFiltersToPath(targetPath);

    $window.open(targetPath);
  }

  /*
   * Given a claim type id, toggles the status of the claim type filters accordingly. This function is used from a
   * checkbox input, therefore the corresponding claim type filter will already be toggled when calling this
   * function.
   *
   * Considerations:
   * - When activating the "Todos los productos" claim type filter, deactivate the remaining filters.
   * - When deactivating any other claim type filter, deactivate the "Todos los productos" claim type filter.
   */
  function toggleFilters() {
    $timeout.cancel(filterTimer);

    _updateDropdownTitle();
    _updateAppliedClaimTypes();

    // Perform request for filtered claim stats when user has stopped toggling filters
    filterTimer = $timeout(_updateClaimListPanelWithFilters, 2000);
  }

  function narrowClaimTypes() {
    /*
     * Called each time the user enters text in the search input of the claim types filter.
     *
     * Each call filters the contents of the claim types filter dropdown by hiding the claim type filters whose name
     * does NOT match the entered text in the search input.
     */
    if (vm.narrowFilterSearch === '' || !vm.narrowFilterSearch) {
      _resetClaimTypes();
      return;
    }

    vm.claimTypes.forEach(claimType => {
      var claimTypeName = claimType.name.toLowerCase();
      var targetText = vm.narrowFilterSearch.toLowerCase();
      if (claimTypeName.startsWith(targetText)) {
        claimType.show = true;
      } else {
        claimType.show = false;
      }
    });
  }

  /*
   * Removes a claim type filter when clicking on a claim type filter TAG. Relies on the toggleFilters function.
   *
   * ONLY used when removing a claim type filter tag from the SLAs claim user panel.*/
  function removeFilterViaTag(claimTypeId) {
    var claimType = vm.claimTypes.find(cT => cT.id === claimTypeId);
    if (claimType) {
      var claimTypeIdx = vm.claimTypes.indexOf(claimType);
      vm.claimTypes[claimTypeIdx].active = !vm.claimTypes[claimTypeIdx].active;
    }
    toggleFilters(claimTypeId);
  }

  /*
   * Generates a string based on a claim stat, depending on the number of:
   * - Expired SLAs count for a given state.
   * - About to expire SLAs count for a given state.
   *
   * If no results exist for both counts, a default string is returned (namely "--")
   */
  function formatClaimStat(claimStat) {
    var statStrings = [];
    if (claimStat.expired_slas > 0) {
      var expiredSlasString = claimStat.expired_slas + ' con SLA vencido';
      statStrings.push(expiredSlasString);
    }

    if (claimStat.about_to_expire_slas > 0) {
      var aboutToExpireSlasString =
        claimStat.about_to_expire_slas + ' están a punto de vencer su SLA';
      statStrings.push(aboutToExpireSlasString);
    }

    return statStrings.join('|') || '--';
  }

  // Private functions implementation
  /*
   * Resets the search input text of the claim types filter dropdown, and toggles all claim type filters to be shown
   * again in such dropdown.
   */
  function _resetClaimTypes() {
    vm.claimTypes.forEach(claimType => {
      claimType.show = true;
    });
    vm.narrowFilterSearch = '';
  }

  /*
   * Updates a variable containing the names of ALL the applied claim type filters, to be shown on the SLAs claim
   * user panel as an informative message.
   */
  function _updateAppliedClaimTypes() {
    var filterNames = [];
    var activeFilters = vm.claimTypes.filter(cT => cT.active === true);
    activeFilters.forEach(activeFilter => {
      filterNames.push(activeFilter.name);
    });

    vm.appliedClaimTypes = filterNames;
  }

  function formatAppliedClaimTypes() {
    return vm.appliedClaimTypes.join(', ');
  }

  function clearFilters() {
    vm.appliedClaimTypes = [];
    vm.claimTypes.forEach(claimType => {
      claimType.active = false;
    });

    _updateDropdownTitle();
    _updateClaimListPanelWithFilters();
  }

  /*
   * Updates the contents of the claims list table, based on the currently applied claim type filters.
   */
  function _updateClaimListPanelWithFilters() {
    var activeClaimTypes = vm.claimTypes.filter(item => item.active);
    if (activeClaimTypes) {
      var activeClaimTypeIds = activeClaimTypes.map(item => item.id);
      _updateClaimStats(segmentWithSLA.prefix, segmentWithSLA.type, {
        claim_type_ids: activeClaimTypeIds
      });
    }
    _sortClaimTypes();
    _resetClaimTypes();
  }

  /*
   * Helper to generate the list of claim type filters so that:
   * - First claim type filter is a custom item: "Todos los productos".
   * - The remaining claim type filters are items fetched from the server, and SORTED BY NAME ALPHABETICALLY.
   */
  function _sortClaimTypes() {
    // Group by active and non-active items. Then sort each group alphabetically by name
    vm.claimTypesGroupedByActive = Object.assign(
      vm.claimTypesGroupedByActive,
      vm.claimTypes.groupBy('active')
    );
    vm.claimTypes = vm.claimTypesGroupedByActive.true
      .sortBy('name')
      .concat(vm.claimTypesGroupedByActive.false.sortBy('name'));
  }

  /*
   * Updates the controller variable that stores the number of expired SLAs.
   */
  function _updateExpiredSLAPanel(expiredSLAsCount) {
    vm.expiredSlasCount = expiredSLAsCount;
  }

  /*
   * Updates the controller variable that stores the number of about-to-expire SLAs.
   */
  function _updateAboutToExpireSLAPanel(aboutToExpireSLAsCount) {
    vm.aboutToExpireSlasCount = aboutToExpireSLAsCount;
  }

  /*
   * Updates the controller variable that stores the claim stats of the claim stats table.
   */
  function _updateClaimListPanel(claimStatsPerState) {
    let statesArray = Object.keys(claimStatsPerState).map(k => claimStatsPerState[k]);
    vm.hasClaimStats = statesArray.length > 0;
    vm.claimStats = statesArray;
  }

  /*
   * Performs a call to the server to fetch the valid claim types, then updates the claim type filters variables.
   */
  function _updateClaimTypes(type) {
    ClaimType.get({ type })
      .$promise.then(data => {
        vm.claimTypes = data.objects;
        vm.claimTypes.forEach(claimType => {
          claimType.active = false;
          claimType.show = true;
        });
        _sortClaimTypes();
        _updateDropdownTitle();
        // originalClaimTypes = angular.copy(vm.claimTypes);
      })
      .catch(er => console.error(er));
  }

  /*
   * Builds a claims list URL path to fetch the claims that have an already-expired SLA.
   */
  function generateExpiredSLAClaimListUrl(type) {
    let targetPath = `#/${type}-claims/list/filter/`;

    targetPath += 'lawyer=' + lawyerUsername;
    targetPath += '&applied_lawyer_type_sla=True';
    targetPath = _applyClaimTypesFiltersToPath(targetPath);

    vm.pathToExpiredSLAClaimsList = targetPath;
  }

  /*
   * Builds a claim list URL path to fetch the claims that have will have their current state's SLA expired soon.
   */
  function generateAboutToExpireSLAClaimListUrl(type) {
    let basePath = `#/${type}-claims/list/filter/`;
    let targetPath = 'lawyer=' + lawyerUsername;
    const currentDatetime = moment(vm.slaExpirationStartDatetime, 'DD/MM/YYYY').unix();

    targetPath += encodeURIComponent(
      '&applied_lawyer_type_sla=False&expiration_lawyer_type_sla_datetime__lte=' + currentDatetime
    );
    targetPath = _applyClaimTypesFiltersToPath(targetPath);

    vm.pathToAboutToExpireSLAClaimsList = encodeURI(basePath + targetPath);
  }

  /*
   * Helper to change the claim type fitlers dropdown title depending on the currently active filters. Valid titles
   * are:
   * - "Todos los productos": when the "Todos los productos" filter is selected.
   * - The name of the currently selected filter WHEN that filter is the only selected filter.
   * - "Selección múltiple": if more than one claim type filter, different than "Todos los productos" are active.
   * - "Ninguno": when no claim type filters are selected.
   */
  function _updateDropdownTitle() {
    var activeFilters = vm.claimTypes.filter(t => t.active === true);
    if (activeFilters.length === 0) {
      vm.dropdownFiltersTitle = 'Todos los productos';
    } else if (activeFilters.length === 1) {
      vm.dropdownFiltersTitle = activeFilters[0].name;
    } else {
      vm.dropdownFiltersTitle = 'Selección múltiple';
    }
  }

  /*
   * Helper to append the currently applied claim type filters to a claims list URL path.
   */
  function _applyClaimTypesFiltersToPath(path) {
    // Filtering based on claim type
    let newPath = path;
    var claimTypesFilter = '&type_id=';
    var claimTypeIds = [];
    vm.claimTypes.forEach(claimType => {
      if (claimType.active) {
        claimTypeIds.push(claimType.id);
      }
    });

    if (claimTypeIds.length > 0) {
      claimTypesFilter += claimTypeIds.join('-'); // Send claim type ids as list of hyphen separated ids.
      newPath = path + claimTypesFilter;
    }

    return newPath;
  }

  /*
   * Perform a request to the server to fetch the claim stats grouped by claim state for the current lawyer.
   */
  function _updateClaimStats(segmentPrefix, type, queryParams) {
    vm.busy = true;
    ClaimsLawyerPanelData.getClaimList(segmentPrefix, queryParams)
      .then(function(response) {
        vm.slaExpirationStartDatetime = response.data.sla_expiration_start_datetime;
        const data = response.data.claim_stats;

        _updateExpiredSLAPanel(data.expired_slas_count);
        _updateClaimListPanel(data.states);
        generateExpiredSLAClaimListUrl(type);

        _updateAboutToExpireSLAPanel(data.about_to_expire_slas_count);
        generateAboutToExpireSLAClaimListUrl(type);
      })
      .catch(e => console.warn(e))
      .finally(() => {
        vm.busy = false;
      });
  }
}
