LaboralClaimDetailCtrl.$inject = [
  '$scope',
  '$controller',
  '$http',
  'notifications',
  'serverAddress',
  'LaboralConstants',
  'DocumentDownloadFactory',
  'DocxTemplates',
  '$interval',
  'DialogFactory',
  'fileUploaderGenerator'
];

export default function LaboralClaimDetailCtrl(
  $scope,
  $controller,
  $http,
  notifications,
  serverAddress,
  LaboralConstants,
  DocumentDownloadFactory,
  DocxTemplates,
  $interval,
  DialogFactory,
  fileUploaderGenerator
) {
  const vm = this;

  const SMAC_TARGET_DOCTYPE_ID = 176; // Solicitud de conciliacion SMAC

  const INITIAL_STATE = {
      claim: {
        client: {
          user: {
            is_active: true
          }
        }
      },
      viewCancelledClientPermission: true,
      changeButtonDisabled: false,
      displaySMACGenerationButton: false,
      disabledSMACGenerationButton: false,
      selectedSMACTemplate: null,

      CAUSES: LaboralConstants.LABORAL_CAUSES,
      actions: {
        showNewCaseModal
      }
    },
    METHODS = {
      initClaimCB,
      postRefreshClaimCB,

      downloadAllLaboralDocuments,
      generateSMACDraftDocument,
      showDocumentMergingModal,
      showDocumentSlicingModal,
      showDocumentCopyModal
    };

  $controller('BaseDetailCtrl', { vm: vm, $scope: $scope });

  Object.assign(vm, INITIAL_STATE, METHODS, LaboralConstants);

  vm.claimDetail.init();

  fileUploaderGenerator({
    vm: vm,
    url: serverAddress.getDocumentUrl() + 'common/upload/',
    fillItemData: function(item) {
      vm.busy = true;

      item.formData = [
        {
          type_id: item.documenType.id || 0,
          comment: item.comment || '',
          claim_id: vm.claim_id,
          segment: vm.MODEL_PROVIDER
        }
      ];
    },
    sucessCallback: () => {
      vm.claimDetail.update('Archivo subido con éxito, actualizando reclamación');
    }
  });

  // FROM HERE JUST FUNCTIONS => => => => => => => => => =>
  function initClaimCB() {
    let options = {
      active: true,
      segments__id: vm.segmentID,
      target_holder: 'claim',
      target_doc_type__id: SMAC_TARGET_DOCTYPE_ID
    };

    DocxTemplates.get(options).$promise.then(function(response) {
      vm.SMACTemplates = response.objects;
      vm.selectedSMACTemplate = response.objects[0].id;
    });
  }

  function postRefreshClaimCB() {
    vm.getDocumentTypes();
  }

  function downloadAllLaboralDocuments() {
    DocumentDownloadFactory.downloadAllLaboralDocuments(vm.claim);
  }

  /**
   * Starts the generation of a SMAC draft document, based on the selected SMAC Template.
   * It checks every 5 seconds and up to 3 times if generation is finished (based on
   * the SMAC documents count difference before and after).
   */
  function generateSMACDraftDocument() {
    vm.displaySMACGenerationButton = false;
    vm.disabledSMACGenerationButton = true;

    $http
      .post(serverAddress.getBaseUrl() + 'laboral/api/generate-smac-draft-document/', {
        segment: 'laboral',
        smac_template_id: vm.selectedSMACTemplate,
        claim_id: vm.claim_id,
        new_draft: true
      })
      .then(function(postResponse) {
        notifications.addCurrentView(
          'info',
          'Lanzada la tarea de generación del borrador de la papeleta del ' +
            'SMAC. Tardara unos 30 segundos en finalizar. Si para entonces no ha terminado, por favor, recarga ' +
            'la pagina. En caso de que la papeleta no haya sido generada, por favor, abre un ticket de soporte.'
        );

        // Check if a new SMAC document has been generated.
        var previousSMACDocumentsCount = postResponse.data.previousSMACDocumentsCount;
        var checkSMACDocumentsCounter = 0;
        var myInterval = $interval(function() {
          $http
            .get(serverAddress.getBaseUrl() + 'laboral/api/check-smac-draft-generation/' + vm.claim.id + '/')
            .then(function(getResponse) {
              notifications.addCurrentView(
                'info',
                'La tarea de generación del borrador aun está activa. Por favor, ' + 'espera'
              );
              if (getResponse.data.currentSMACDocumentsCount > previousSMACDocumentsCount) {
                notifications.addCurrentView('success', 'Finalizada generación del borrador de la papeleta del SMAC.');
                $interval.cancel(myInterval);
                vm.disabledSMACGenerationButton = false;
                vm.claimDetail.update();
              }
            });

          // Limit checks to 3.
          checkSMACDocumentsCounter++;
          if (checkSMACDocumentsCounter == 3) {
            notifications.addCurrentView(
              'error',
              'La generación del borrador está tardando más de lo esperado. Espera unos minutos.'
            );
            $interval.cancel(myInterval);
            vm.disabledSMACGenerationButton = false;
          }
        }, 20000);
      })
      .catch(function() {
        notifications.addCurrentView('error', 'Error inesperado al generar la papeleta del SMAC.');
        vm.disabledSMACGenerationButton = false;
      });
  }

  function showDocumentMergingModal() {
    var currentDocuments = vm.documents;
    var documentTypes = vm.documentTypes;
    var claimData = {
      claim_id: vm.claim_id,
      claim_prefix: 'LA'
    };

    var multipleSourceSelectionEnabled = false;
    DialogFactory.mergeDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimData,
      multipleSourceSelectionEnabled,
      function() {
        var successMsg = 'Unión de documentos solicitada. Cuando termine recibirás un email.';
        vm.claimDetail.update(successMsg);
      }
    );
  }

  function showDocumentSlicingModal() {
    var currentDocuments = vm.documents;
    var claimData = {
      claim_id: vm.claim_id,
      claim_prefix: 'LA'
    };
    DialogFactory.sliceDocument(currentDocuments, claimData, function() {
      var successMsg = 'Separación de documento solicitada. Cuando termine recibirás un email.';
      vm.claimDetail.update(successMsg);
    });
  }

  function showDocumentCopyModal(prefix, type) {
    var currentDocuments = null;
    var documentTypes = vm.documentTypes;
    var multipleSourceSelectionEnabled = false;

    var claimData = {
      claim_id: vm.claim_id,
      claim_prefix: prefix
    };
    var copy_type = type;
    var claimTypes = vm.claimTypes;

    // If the claim has no documents, an error is shown
    if (vm.documents.length === 0) {
      notifications.addCurrentView(
        'error',
        'No se ha podido realizar la acción. La reclamación no tiene ' + 'ningún documento adjunto.'
      );
      return;
    }

    if (type === 'claim') {
      try {
        currentDocuments = DialogFactory.copyDocsToClaimValidation(vm.documents);
      } catch (e) {
        notifications.addCurrentView('error', e);
        return;
      }
    } else {
      try {
        currentDocuments = DialogFactory.copyDocsToLegalfileValidation(vm.documents, vm.claim);
      } catch (e) {
        notifications.addCurrentView('error', e);
        return;
      }
    }

    DialogFactory.copyDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimTypes,
      claimData,
      multipleSourceSelectionEnabled,
      copy_type,
      function() {
        vm.claimDetail.update();
      }
    );
  }
  
  /**
  * 
  * @param {*} showNewCaseModal 
  */
  function showNewCaseModal() {
    const data = {
      currentDocuments: vm.documents,
      ...[vm.documentType],
      claimData: {
        id: vm.claim.id,
        prefix: vm.SEGMENT_PREFIX,
        type: vm.claim.type.id
      }
    };
    if (!vm.loadedDocuments) {
      vm.fetchDocuments(true)
        .then(() => {
          data.currentDocuments = vm.documentsResponse;
          DialogFactory.generateNewCase(data);
        })
        .catch(() => {
          notifications.addCurrentView('error', `Se produjo un error actualizando los documentos de la ${vm.VERBOSE_NAME.toLowerCase()}`);
        });
    } else {
      DialogFactory.generateNewCase(data);
    }
  }
}
