export default function busyDirective() {
  return {
    restrict: 'A',
    transclude: true,
    scope: {
      busy: '<',
      busyMsj: '<'
    },
    template: `
      <div class="busy-wrapper" ng-class="{busy: busy}">
        <div ng-transclude class="transclude"></div>
        <div class="busy-main" ng-if="busy">
          <div class="d-flex flex-column justify-content-center p-2 text-center inner">
            <div class="progress"><div class="progress-bar progress-bar-striped progress-bar-animated"></div></div>
            <p>{{ busyMsj || 'Procesando la solicitud. Espere unos segundos' }}</p>
          </div>
        </div>
      </div>`
  };
}
