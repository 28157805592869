ExtraInfoModalCtrl.$inject = ['$scope', 'dialog', 'DocTypes', 'Targets']; // , 'notifications', 'Verticals'];

export default function ExtraInfoModalCtrl ($scope, dialog, DocTypes, Targets) { //, notifications, Verticals) {
  try { // modal factory for some reason don't throw errors, so...
    $scope.vm = Object.assign({}, {
      // methods
      ok,
      cancel,
      isFormValid,
      initiateTargets,
      filterDocTypes,
      resetForm,
      getActionsForIndividualForms,
      getActionsForCommonForm,
      getDocTypeById,

      // state
      targets: initiateTargets(), // shallow copy of original Targets to handle differences
      doc_types: DocTypes,
      allDocTypes: [],
      commonForm: null, // form to validate extrafields
      multiDocTypeValue: null,
      multiDocComment: null,
      commonFormActive: false // form for all Targets
    });
    $scope.vm.allDocTypes = getAllDocTypes();

    function ok () {
      dialog.close();

      const RESPONSE = $scope.vm.commonFormActive
        ? $scope.vm.getActionsForCommonForm()
        : $scope.vm.getActionsForIndividualForms();

      return dialog.deferred.resolve(RESPONSE);
    }

    // close modal
    function cancel () {
      dialog.close();
      return dialog.deferred.reject(null);
    }

    function isFormValid() {
      // common multiple docs form is only valid if some value changed
      if($scope.vm.commonFormActive) {
        return $scope.vm.multiDocTypeValue || $scope.vm.multiDocComment;
      }

      // Separated docs form check any valid action is made
      return $scope.vm.commonForm && $scope.vm.commonForm.$valid && $scope.vm.getActionsForIndividualForms().length;
    }

    // Clone the targets sent to the modal to operate on
    function initiateTargets() {
      return Targets.map(e => Object.assign({}, e));
    }

    // recover initial state
    function resetForm() {
      $scope.vm.multiDocTypeValue = null;
      $scope.vm.multiDocComment = null;
      $scope.vm.targets = initiateTargets();
    }

    function getActionsForIndividualForms() {
      // fields to check for changes from initial state and generate
      // actions object to perform changes on the back
      const FIELDS = [
        'doc_type',
        'comment',
        'ask_for_view',
        'no_view',
        'view_date',
        'legal_temerity',
        'has_costs',
        'costs_type',
        'compensation_type',
        'not_airline_agreement',
        'flight_document'
      ];

      return $scope.vm.targets.reduce((state, e, idx) => {
        const ORIGINAL = Targets[idx];
        let actions = [];

        FIELDS.forEach(field => {
          if(ORIGINAL[field] !== e[field]) {
            actions.push(['metadata', field, e[field]]);
          };
        },[]);

        if(actions.length) {
          state.push({
            actions: actions,
            docs: [e.id]
          });
        }

        return state;
      }, []);
    }

    function getActionsForCommonForm() {
      let actions = [];
      if($scope.vm.multiDocComment) {
        actions.push(['metadata', 'comment', $scope.vm.multiDocComment]);
      }

      if($scope.vm.multiDocTypeValue) {
        actions.push(['metadata', 'doc_type', $scope.vm.multiDocTypeValue]);
      }

      return {
        actions: actions,
        docs: $scope.vm.targets.map(e => e.id)
      };
    }

    // Filter docTypes available in every targets
    function getAllDocTypes() {
      return DocTypes.filter(docType => {
        return $scope.vm.targets.every(doc => {
          if(!doc.segment) return false;
          if(doc.segment == 'LegalFile') return docType.type == 'aircase';
          return doc.allowed_document_types.indexOf(docType.id) != -1;
        });
      });
    }

    // same as before, but opposite :_D
    function filterDocTypes(doc) {
      return function(docType) {
        if(!doc.segment) return false;
        if(doc.segment == 'LegalFile') return docType.type == 'aircase';
        return doc.allowed_document_types.indexOf(docType.id) != -1;
      };
    }

    // same as before, but opposite :_D
    function getDocTypeById(id) {
      return $scope.vm.doc_types.find(e => e.id == id);
    }
  } catch(e){
    console.log(e);
  }
}