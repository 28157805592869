
import angular from 'angular';

import PhoneClaimDetailCtrl from './claimDetailCtrl';
import PhoneClaimListCtrl from './claimListCtrl';
import PhoneLawyerPanelCtrl from './lawyerPanelCtrl';

import PhoneClaim from './claimService';
import PhoneLawyer from './lawyerService';

import PhoneRoutes from './routes';
import PhoneConstants from './constants';

angular
  .module('reclamador.segments.phone', [])

  .controller('PhoneClaimDetailCtrl', PhoneClaimDetailCtrl)
  .controller('PhoneClaimListCtrl', PhoneClaimListCtrl)
  .controller('PhoneLawyerPanelCtrl', PhoneLawyerPanelCtrl)

  .factory('PhoneClaim', PhoneClaim)
  .factory('PhoneLawyer', PhoneLawyer)

  .constant('PhoneConstants', PhoneConstants)

  .config(PhoneRoutes);
