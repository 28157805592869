/**
* Document Manager Controller
* The parent component for the whole document-manager.
* Manages the different claim documents available, provides events so the subcomponents can
* perform actions with the documents, and manage permissions for the actions.
*
* TODO:
* - Keep all viewer management in his component (activeDocument, activeGroupDocs...)
* - Prepare an intermediate documentGroup component to paint each document table
*/

DocumentManagerCtrl.$inject = [
  '$scope',
  'DocTools',
  'notifications'
];

export default function DocumentManagerCtrl (
  $scope,
  DocTools,
  notifications
) {
  let vm = this;

  Object.assign(vm, {
    // bindings
    claim: null,
    documents: null,
    flightDocuments: null,
    legalfileDocuments: null,
    legalfileDocumentTypes: null,
    busy: false,

    documentTypes: [],
    entityData: null,
    claimClass: null,
    entityUpdateCb: null,
    documentationAnalysis: [],

    // available document groups: set configuration for viewer and lists
    documentGroups: [
      {
        id: 1,
        key: 'validDocs',
        name: 'Documentos validados',
        objsKey: 'processedDocumentValid', // vm variable for this kind of documents
        docTypesKey: 'documentTypes', // vm variable for documenttypes for this kind of documents
        actions: [ // avilable actions you can perfom on the documents
          'validate',
          'changeType',
          'download',
          'delete',
          'rename']},
      {
        id: 2,
        key: 'invalidDocs',
        name: 'Documentos invalidados',
        objsKey: 'processedDocumentInValid',
        docTypesKey: 'documentTypes',
        actions: [
          'validate',
          'changeType',
          'download',
          'delete',
          'rename']},
      {
        id: 3,
        key: 'pendingDocs',
        name: 'Documentos pendiente de validar',
        objsKey: 'processedDocumentPending',
        docTypesKey: 'documentTypes',
        actions: [
          'validate',
          'changeType',
          'download',
          'delete',
          'rename']},
      {
        id: 4,
        key: 'fileDocs',
        name: 'Documentos del expediente',
        objsKey: 'processedLegalfileDocuments',
        docTypesKey: 'legalfileDocumentTypes',
        actions: [
          'download',
          'changeType',
          'rename']},
      {
        id: 5,
        key: 'flightDocs',
        name: 'Documentos del vuelo',
        objsKey: 'processedFlightDocuments',
        docTypesKey: 'documentTypes',
        actions: [
          'download',
          'delete',
          'changeType']}
    ],

    // viewer data
    availableDocumentGroups: [], // documentGroups with available documents
    activeDocumentGroup: {}, // the active documentGroup
    activeGroupDocs: [], // the documents from the active document group
    activeDocument: null, // the document in the viewers

    // Filtered document lists
    processedDocumentValid: null,
    processedDocumentInValid: null,
    processedDocumentPending: null,
    processedLegalfileDocuments: null,
    processedFlightDocuments: null,

    // fold document lists status
    hideValid: false,
    hideInValid: false,
    hidePending: false,
    hideLegalDocs: false,
    hideFlightDocs: false,

    // viewer methods
    initViewer,
    _resetViewer,
    setDocumentGroup,
    getDocumentGroup,
    documenthasViewPermission,

    // internal
    loadDocument,
    _refreshData,
    _updateCb,
    _getInitialDoc
  });

  _init();

  // launch viewer and set initial varialbes
  function initViewer(initialDoc) {
    if (!initialDoc) initialDoc = _getInitialDoc();
    $scope.$broadcast('viewer:init');
    loadDocument(initialDoc);
  }

  // clear viewer
  function _resetViewer() {
    $scope.$broadcast('viewer:remove');
    vm.activeDocument = null;
  }

  // load new document for the viewer
  function loadDocument(doc) {
    if (doc) {
      vm.activeDocument = doc;
      vm._refreshData();
    } else {
      notifications.addCurrentView('error', 'No se pudo cargar el documento, inténtalo de nuevo.');
    }
  }

  // find documentGroup by key
  function getDocumentGroup(key) {
    return vm.documentGroups.find(g => g.key == key);
  }

  /* Try to call updateCb function or just notify */
  function _updateCb(msj) {
    if(vm.entityUpdateCb) {
      vm.entityUpdateCb(msj);
    } else {
      if(msj) notifications.addCurrentView('info', msj);
      vm.busy = false;
    }
  }

  /* Get the first document group who contains the passed element */
  function _getDocGroup(doc) {
    for (var i = vm.availableDocumentGroups.length - 1; i >= 0; i--) {
      if(vm[vm.availableDocumentGroups[i].objsKey].find(d => d.id == doc.id)) return vm.availableDocumentGroups[i];
    }
  }

  // update enviroment data
  function _refreshData() {
    vm.availableDocumentGroups = vm.documentGroups.filter(g => g && vm[g.objsKey] && vm[g.objsKey].length);

    // from here only if the viewer is active
    if(!vm.activeDocument) return;

    const newGroup = _getDocGroup(vm.activeDocument);
    if (newGroup) {
      setDocumentGroup(newGroup);
      vm.activeGroupDocs = vm[newGroup.objsKey].filter(d => !d.is_drive && DocTools.documenthasViewPermission(d, vm.entityData.prefix));
    }
  }

  /* Returns the first document from the first active document group */
  function _getInitialDoc() {
    return vm[vm.availableDocumentGroups[0].objsKey][0];
  }

  /* Change active document group */
  function setDocumentGroup(documentGroup) {
    if(vm.activeDocumentGroup == documentGroup) return;
    vm.activeDocumentGroup = documentGroup;
  }

  // bootstrap controller
  function _init() {
    _setBindingsWatchers();
    _bindDocumentEvents();
    _bindViewerEvents();
  }

  function _setBindingsWatchers() {
    // parse document changes
    $scope.$watch('vm.documents', docs => {
      if(!docs) return;

      let docsOK = docs.filter(d => !d.deleted && !d.is_missing);
      vm.processedDocumentValid = docsOK.filter(e => e.id_validation_state == '1');
      vm.processedDocumentInValid = docsOK.filter(e => e.id_validation_state == '2');
      vm.processedDocumentPending = docsOK.filter(e => e.id_validation_state == '3');

      vm._refreshData();
    }, true);

    // parse legalfiles changes
    $scope.$watch('vm.legalfileDocuments', docs => {
      if(!docs) return;
      vm.processedLegalfileDocuments = vm.legalfileDocuments.filter(d => !d.deleted && !d.is_missing)
        .sort((a, b) => b.id - a.id);
      vm._refreshData();
    });

    // parse new flight documents
    $scope.$watch('vm.flightDocuments', docs => {
      if(!docs) return;
      vm.processedFlightDocuments = vm.flightDocuments.filter(d => !d.is_missing && !d.deleted);
      vm._refreshData();
    });

    // detect changes in activeDocumentGroup
    $scope.$watch('vm.activeDocumentGroup', activeGroup => {
      if(activeGroup && activeGroup.objsKey) {
        vm.activeGroupDocs = vm[activeGroup.objsKey].filter(d => !d.is_drive && DocTools.documenthasViewPermission(d, vm.entityData.prefix));
      } else {
        vm.activeGroupDocs = [];
      }
    });
  }

  function _bindDocumentEvents() {
    $scope.$on('document:delete', (e, doc) => {
      vm.busy = true;
      DocTools.deleteDocument(doc);
    });

    $scope.$on('document:deleted', (e, doc) => {
      vm.busy = false;
      vm._updateCb(`Documento ${doc.filename} borrado`);
    });

    $scope.$on('document:validate', (e, doc, validate) => {
      if (doc && !doc.id) {
        notifications.addCurrentView('error', 'No se pudo validar el documento, inténtalo de nuevo.');
        return;
      }

      vm.busy = true;

      DocTools.validateDocument(doc.id, vm.claimClass, validate)
        .then(function() {
          vm._updateCb(`Documento "${doc.filename}" ${validate ? 'validado' : 'invalidado'}`);
          $scope.$broadcast('document:validated');
        })
        .catch(response => {
          notifications.addCurrentView('error', 'No se pudo validar el documento: ' + response.data);
          vm.busy = false;
          return false;
        });
    });

    $scope.$on('document:rename', (e, docData) => {
      vm.busy = true;
      DocTools.renameDocument(docData, vm.entityData)
        .then(() => {
          vm._updateCb(`Actualizado nombre de documento "${docData.newName}"`);
          $scope.$broadcast('document:renamed');
        })
        .catch(response => {
          notifications.addCurrentView('error', response.data);
          vm.busy = false;
          return false;
        });
    });

    $scope.$on('document:changeType', (e, docData, typeId, docGroup) => {
      // TODO: this only works for main documentTypes (not legalfileDocumentTypes)
      let documentType = null;
      if(docGroup == 'fileDocs') {
        documentType = vm.legalfileDocumentTypes.find(t => t.id == typeId);
      } else {
        documentType = vm.documentTypes.find(t => t.id == typeId);
      }

      if(docData.type && docData.type.id == typeId) {
        return notifications.addCurrentView('warning', 'El documento ya tiene el tipo ' + documentType.name_crm ? documentType.name_crm : documentType.name);
      }
      vm.busy = true;

      DocTools.changeDocumentType(docData, typeId, vm.claimClass, docGroup, vm.claim)
        .then(() => {
          vm._updateCb(`Cambiado tipo de documento a "${documentType.name}"`);
        })
        .catch(() => {
          vm.busy = false;
          notifications.addCurrentView('error', 'Se ha producido un error cambiando el tipo de documento');
        });
    });

    $scope.$on('document:download', (e, doc) => {
      DocTools.downloadDocument(doc);
    });

    $scope.$on('document:openDrive', (e, doc) => {
      DocTools.openInDrive(doc.drive_key);
    });
  }

  function _bindViewerEvents() {
    // clear viewer when destroying the component
    vm.$onDestroy = _resetViewer;

    // click on document to load viewer
    $scope.$on('viewer:loadDoc', (e, doc) => {
      initViewer(doc);
    });

    // document-viewer request new document
    $scope.$on('viewer:changeDoc', (e, doc) => {
      loadDocument(doc);
    });

    // document-viewer request group change
    $scope.$on('viewer:changeGroup', (e, group) => {
      setDocumentGroup(group);
      loadDocument(vm[group.objsKey][0]);
    });

    // document-viewer request closing
    $scope.$on('viewer:close', () => {
      _resetViewer();
    });
  }

  function documenthasViewPermission(doc) {
    return DocTools.documenthasViewPermission(doc, vm.entityData.prefix);
  }
}
