/* eslint-disable angular/di */
import angular from 'angular';

angular
  .module('http-auth-interceptor', [])
  .factory('httpBuffer', httpBuffer)
  .config([
    '$httpProvider',
    function($httpProvider) {
      /**
       * $http interceptor.
       * On 401 response (without 'ignoreAuthModule' option) stores the request
       * and broadcasts 'event:auth-loginRequired'.
       * On 403 response (without 'ignoreAuthModule' option) discards the request
       * and broadcasts 'event:auth-forbidden'.
       */
      $httpProvider.interceptors.push([
        '$rootScope',
        '$q',
        '$localStorage',
        'version',
        function($rootScope, $q, $localStorage, version) {
          return {
            request: function(httpConfig) {
              const token = $localStorage.get('token');
              const username = $localStorage.get('username');
              if (token) {
                httpConfig.headers['Authorization'] = `ApiKey ${username}:${token}`;
              }
              return httpConfig;
            },
            response: function(httpConfig) {
              const headerVersion = httpConfig.headers('version');
              if (headerVersion) {
                const headerSemVerMajor = getSemver(headerVersion).major;
                const appSemVerMajor = getSemver(version).major;
                if (headerSemVerMajor !== appSemVerMajor) {
                  $rootScope.$broadcast('event:auth-invalidVersion');
                  // return $q.reject(new Error('Invalid version'));
                }
              }
              return httpConfig;
            },
            responseError: function(rejection) {
              switch (rejection.status) {
                case 401:
                  // var deferred = $q.defer();
                  // var bufferLength = httpBuffer.append(config, deferred);
                  // if (bufferLength === 1) $rootScope.$broadcast('event:auth-loginRequired', rejection);
                  // deferred.reject('No permissions');
                  $rootScope.$broadcast('event:auth-loginRequired', rejection);
                  break;
                case 403:
                  $rootScope.$broadcast('event:auth-forbidden', rejection);
                  break;
                default:
              }
              // otherwise, default behaviour
              return $q.reject(rejection);
            }
          };
        }
      ]);
    }
  ]);

/**
 * Private module, a utility, required internally by 'http-auth-interceptor'.
 */
httpBuffer.$inject = ['$injector'];
function httpBuffer($injector) {
  /** Holds all the requests, so they can be re-requested in future. */
  var buffer = [];

  /** Service initialized later because of circular dependency problem. */
  var $http;

  function retryHttpRequest(config, deferred) {
    function successCallback(response) {
      deferred.resolve(response);
    }
    function errorCallback(response) {
      deferred.reject(response);
    }
    $http = $http || $injector.get('$http');
    $http(config).then(successCallback, errorCallback);
  }

  return {
    /**
     * Appends HTTP request configuration object with deferred response attached to buffer.
     * @return {Number} The new length of the buffer.
     */
    append: function(config, deferred) {
      return buffer.push({
        config: config,
        deferred: deferred
      });
    },

    /**
     * Abandon or reject (if reason provided) all the buffered requests.
     */
    rejectAll: function(reason) {
      if (reason) {
        for (var i = 0; i < buffer.length; ++i) {
          buffer[i].deferred.reject(reason);
        }
      }
      buffer = [];
    },

    /**
     * Retries all the buffered requests clears the buffer.
     */
    retryAll: function(updater) {
      for (var i = 0; i < buffer.length; ++i) {
        var _cfg = updater(buffer[i].config);
        if (_cfg !== false) retryHttpRequest(_cfg, buffer[i].deferred);
      }
      buffer = [];
    }
  };
}

function getSemver(version) {
  const regexp = version.match(/^(?:(\d+)\.)?(?:(\d+)\.)?(\*|\d+)$/);
  if (regexp.length < 4) return null;
  const semVer = {
    major: regexp[1],
    minor: regexp[2],
    patch: regexp[3]
  };
  return semVer;
}
