ClaimsandlegalfilesService.$inject = ['$resource', 'serverAddress'];

const CLAIMS_AND_LEGALFILES_PATH = 'common/claimsandlegalfiles/list/:limit/:offset/:format/';

export default function ClaimsandlegalfilesService($resource, serverAddress) {
  return $resource(serverAddress.getBaseUrl() + CLAIMS_AND_LEGALFILES_PATH, {}, {
    get: {
      params: {
        format: 'json',
        limit: 4,
        offset: 0,
        query: ''
      }
    }
  });
};
