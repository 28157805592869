import angular from 'angular';

import './styles.css';

angular
  .module('reclamador.components.previousStates', [])
  .component('previousStatesComponent', {
    bindings:{
      previousStates: '<'
    },
    controllerAs: 'vm',
    template: `
      <div ng-show="vm.previousStates && vm.previousStates.length" class="mb-4 position-relative">
        <h4 class="d-inline position-relative">Estados anteriores:</h4>
        <div class="previous-states-component-inn text-right">
          <span ng-repeat="state in vm.previousStates | reverse track by $index">
            &nbsp;<&nbsp;{{ state }}
          </span>
        </div>
      </div>`
  });