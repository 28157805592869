NavbarCtrl.$inject = ['$rootScope', 'version'];

export default function NavbarCtrl($rootScope, version) {
  const vm = this;
  vm.user = {};
  vm.avatar = '//ssl.gstatic.com/accounts/ui/avatar_1x.png';

  vm.$onInit = () => {
    $rootScope.$on('event:worker-info', (ev, data) => {
      vm.version = version;
      vm.username = data.username;
      vm.avatar = data.avatar;
    });
  };

  vm.logout = () => {
    $rootScope.$broadcast('event:auth-logout', {});
  };
}
