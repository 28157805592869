
import angular from 'angular';

import TaxesClaimDetailCtrl from './claimDetailCtrl';
import TaxesClaimListCtrl from './claimListCtrl';
import TaxesLawyerPanelCtrl from './lawyerPanelCtrl';

import MunicipalTaxClaim from './claimService';
import TaxesSalesman from './salesmanService';
import TaxesLawyer from './lawyerService';

import TaxesRoutes from './routes';
import TaxesConstants from './constants';

angular
  .module('reclamador.segments.taxes', [])

  .controller('TaxesClaimDetailCtrl', TaxesClaimDetailCtrl)
  .controller('TaxesClaimListCtrl', TaxesClaimListCtrl)
  .controller('TaxesLawyerPanelCtrl', TaxesLawyerPanelCtrl)

  .factory('MunicipalTaxClaim', MunicipalTaxClaim)
  .factory('TaxesSalesman', TaxesSalesman)
  .factory('TaxesLawyer', TaxesLawyer)
  .constant('TaxesConstants', TaxesConstants)

  .config(TaxesRoutes);
