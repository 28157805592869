import angular from 'angular';

import AirlineClaimDetailCtrl from './claimDetailCtrl';
import AirlineClaimListCtrl from './claimListCtrl';
import AirlinesLawyersPanelCtrl from './lawyersPanelCtrl';
import AirlinesLawyersGoalsPanelCtrl from './lawyersGoalsPanelCtrl';
import AirlinesSalespeopleGoalsPanelCtrl from './salespeopleGoalsPanelCtrl';
import AirlinePendingValidationPanelCtrl from './pendingValidationPanelCtrl';

import AirlineClaim from './claimService';
import AirlineSalesman from './salesmanService';
import AirlineOpposingLawyer from './opposingLawyerService';

import AirlineConstants from './constants';

import AirlineRoutes from './routes';

import FlightsListCtrl from './flightsListCtrl';

angular
  .module('reclamador.segments.airline', [])

  .controller('AirlineClaimDetailCtrl', AirlineClaimDetailCtrl)
  .controller('AirlineClaimListCtrl', AirlineClaimListCtrl)
  .controller('AirlinesLawyersPanelCtrl', AirlinesLawyersPanelCtrl)
  .controller('AirlinesLawyersGoalsPanelCtrl', AirlinesLawyersGoalsPanelCtrl)
  .controller('AirlinesSalespeopleGoalsPanelCtrl', AirlinesSalespeopleGoalsPanelCtrl)
  .controller('AirlinePendingValidationPanelCtrl', AirlinePendingValidationPanelCtrl)

  .controller('FlightsListCtrl', FlightsListCtrl)

  .factory('AirlineClaim', AirlineClaim)
  .factory('AirlineSalesman', AirlineSalesman)
  .factory('AirlineOpposingLawyer', AirlineOpposingLawyer)
  .constant('DESISTED_STATES', function desistedStates() {
    return [3, 36, 37, 38, 39, 40, 41, 45, 46, 97, 98, 99, 60, 71, 72, 73, 74, 84, 85, 86, 107, 113, 151, 119];
  })
  .constant('AirlineConstants', AirlineConstants)
  .config(AirlineRoutes);
