import $ from 'jquery';
import 'viewerjs';
import 'jquery-viewer/dist/jquery-viewer.esm';

/**
 * Document Viewer Controller:
 * Controler for a document viewer component. Manages jquery plugin and provides an interface to navigate
 * between files document groups and launch allowed actions
 *
 * TODO:
 * - When a document completely fail to load, the viewer won't work again
 */

DocumentViewerCtrl.$inject = [
  '$scope',
  '$timeout',
  '$interval',
  '$window',
  'DocTools',
  'notifications'
];

export default function DocumentViewerCtrl(
  $scope,
  $timeout,
  $interval,
  $window,
  DocTools,
  notifications
) {
  let vm = this;

  Object.assign(vm, {
    // bindings
    activeDocument: null,
    activeCategory: null,
    activeCategoryDocs: [],
    categories: [],
    documentTypes: [],
    busy: false,

    // viewer form
    viewerSelectActiveDocument: null,
    viewerSelectActiveGroup: null,
    viewerSelectActiveDocType: null,

    // internal properties
    modalId: '#viewerModal',
    docViewerId: '#docViewer',
    imgViewerId: '#imgViewer',
    $modal: null,
    $docViewer: null,
    $imgViewer: null,
    viewerInstance: null,
    retryViewerInterval: null,
    intervalRetry: 5000,

    // public methods
    changeActiveDocument,
    changeActiveGroup,
    changeActiveDocType,
    hasPrevious,
    hasNext,
    goPrevious,
    goNext,
    closeViewer,

    // actions
    download,
    invalidate,
    validate
  });

  $scope.$on('viewer:init', () => {
    if (!vm.$modal) _initDOM();

    vm.$modal.modal('show');
  });

  $scope.$on('viewer:remove', () => {
    _resetDOM();

    vm.loading = false;
    vm.activeFile = null;
  });

  $scope.$on('document:validated', () => {
    goNext();
  });

  $scope.$watch('vm.activeCategory', cat => {
    vm.viewerSelectActiveGroup = cat;
    $timeout(() => $scope.$apply());
  });

  $scope.$watch('vm.activeDocument', _processNewDoc);

  function _processNewDoc(newDoc) {
    if (!newDoc) return;

    if (!newDoc.url) {
      closeViewer();
      return notifications.addCurrentView(
        'error',
        'El documento que se está intentando abrir es inválido'
      );
    }

    _resetInterval();

    vm.viewerSelectActiveDocument = newDoc;
    vm.viewerSelectActiveDocType = newDoc.type;
    vm.viewerSelectActiveGroup = vm.activeCategory;

    vm.activeFile = DocTools.checkFileExtension(newDoc);

    if (!vm.activeFile) {
      return;
    }

    vm.$docViewer.attr('src', '');

    if (vm.activeFile.isImage) {
      vm.loading = true;
      _initImgViewer();
      vm.$imgViewer.viewer('update');
    } else {
      vm.$imgViewer.viewer('destroy');
    }

    if (vm.$docViewer.attr('src') == vm.activeFile.url) return;

    if (vm.activeFile.isPdf) {
      vm.$docViewer.attr('src', vm.activeFile.url);
    } else if (vm.activeFile.isDoc) {
      vm.$docViewer.attr('src', vm.activeFile.url);

      vm.retryViewerInterval = $interval(() => {
        if (vm.activeFile && vm.activeFile.url) {
          vm.$docViewer.attr('src', vm.activeFile.url);
        }
      }, vm.intervalRetry);
    }
  }

  // actions
  function download() {
    $scope.$emit('document:download', vm.activeDocument);
  }

  function validate() {
    if (vm.activeDocument) {
      $scope.$emit('document:validate', vm.activeDocument, true);
    }
  }

  function invalidate() {
    if (vm.activeDocument) {
      $scope.$emit('document:validate', vm.activeDocument, false);
    }
  }

  function changeActiveDocument() {
    let doc = vm.viewerSelectActiveDocument;
    if (doc != vm.activeDocument) $scope.$emit('viewer:changeDoc', doc);
  }

  function changeActiveGroup() {
    let group = vm.viewerSelectActiveGroup;

    if (!group) return;

    if (group && group != vm.activeDocumentGroup) {
      $scope.$emit('viewer:changeGroup', group);
    }
  }

  function changeActiveDocType() {
    if (!vm.viewerSelectActiveDocType) return;
    $scope.$emit(
      'document:changeType',
      vm.activeDocument,
      vm.viewerSelectActiveDocType.id,
      vm.viewerSelectActiveGroup.key
    );
  }

  function hasPrevious() {
    return !!_getPrevDoc();
  }

  function hasNext() {
    return !!_getNextDoc();
  }

  function closeViewer() {
    $scope.$emit('viewer:close');
    _resetInterval();
  }

  function goPrevious() {
    const target = _getPrevDoc();
    if (target) return $scope.$emit('viewer:changeDoc', target);
    closeViewer();
  }

  function goNext() {
    const target = _getNextDoc();
    if (target) return $scope.$emit('viewer:changeDoc', target);
    closeViewer();
  }

  // internal methods
  function _resetDOM() {
    if (vm.$modal) vm.$modal.modal('hide').removeData();

    if (vm.$imgViewer) vm.$imgViewer.viewer('destroy');
    if (vm.$docViewer) vm.$docViewer.attr('src', '');

    $('body')
      .removeClass('modal-open')
      .find('.modal-backdrop')
      .remove();
  }

  function _getNextDoc() {
    if (!vm.activeDocument) return null;
    const idx = vm.activeCategoryDocs.findIndex(e => e.id == vm.activeDocument.id);
    return idx >= vm.activeCategoryDocs.length - 1 ? null : vm.activeCategoryDocs[idx + 1];
  }

  function _getPrevDoc() {
    if (!vm.activeDocument) return null;
    const idx = vm.activeCategoryDocs.findIndex(e => e.id == vm.activeDocument.id);
    return idx <= 0 ? null : vm.activeCategoryDocs[idx - 1];
  }

  function _initImgViewer() {
    // impruveable
    if (vm.viewerInstance) {
      vm.$imgViewer.viewer('destroy');
    }

    vm.viewerInstance = vm.$imgViewer.viewer({
      inline: false,
      toolbar: {
        zoomIn: 1,
        zoomOut: 1,
        oneToOne: 1,
        reset: 1,
        prev: 0,
        next: 0,
        play: 0,
        rotateLeft: 1,
        rotateRight: 1,
        flipHorizontal: 1,
        flipVertical: 1
      },
      title: false,
      fullscreen: false,
      backdrop: false,
      button: false,
      navbar: false,
      keyboard: false,

      ready: function() {
        vm.$imgViewer.viewer('show');
      },

      view: function() {
        $timeout(() => {
          vm.loading = false;
        });
      },

      viewed: function() {
        vm.$imgViewer.viewer('move', -100, -40);
      }
    });
  }

  function _initDOM() {
    vm.$modal = $window.$(vm.modalId);
    vm.$docViewer = $window.$(vm.docViewerId);
    vm.$imgViewer = $window.$(vm.imgViewerId);
    vm.$modal.on('hidden.bs.modal', () => {
      closeViewer();
    });

    vm.$docViewer.on('load', () => {
      _resetInterval();
      vm.loading = false;
    });
  }

  function _resetInterval() {
    if (vm.retryViewerInterval) $interval.cancel(vm.retryViewerInterval);
  }
}
