import angular from 'angular';

import './styles.css';

angular.module('reclamador.components.listActionsDropdown', []).component('listActionsDropdown', {
  template: `
      <span uib-dropdown>
        <a
          href
          uib-dropdown-toggle
        >
          <i class="fas fa-ellipsis-v"></i>
        </a>
        <ul class="dropdown-menu" uib-dropdown-menu>
          <li
            ng-repeat="(action, settings) in vm.actionList track by $index"
            class="dropdown-item"
          >
            <button ng-click="settings.onClick(vm.item)"><i class={{settings.icon}}></i> {{settings.name}}</button>
          </li>
        </ul>
      </span>
    `,
  replace: false,
  bindings: {
    item: '<',
    actionList: '<'
  },
  controllerAs: 'vm'
});
