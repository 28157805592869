ClaimsLawyerPanelData.$inject = ['$http', 'serverAddress'];

export default function ClaimsLawyerPanelData($http, serverAddress) {
  return {
    getClaimList: getClaimList
  };

  function getClaimList(segmentCode, queryParams) {
    return $http.get(`${serverAddress.getBaseUrl()}common/${segmentCode.toLowerCase()}/lawyer-claims-panel/`, { params: queryParams });
  }
}
