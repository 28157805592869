import angular from 'angular';

import './styles.css';

angular.module('reclamador.components.calendarWithDueDate', []).component('calendarWithDueDate', {
  template: `
    <div class="input-group">
      <input
        class="form-control w-50"
        type="text"
        ui-date="vm.dateOptions"
        ng-model="vm.dueDate"
        ui-date-format="yy-mm-dd"
        ng-disabled="vm.withoutDueDate"
        placeholder="{{ vm.getPlaceholderText() }}"
      />
      <div class="input-group-append">
        <button
          type="button"
          class="btn green __btn_due_date"
          ng-click="vm.toggleDueDate()"
          uib-tooltip="{{ vm.getButtonTooltip() }}"
          tooltip-placement="left"
        >
          <i class=" {{ (vm.withoutDueDate || vm.dueDate) ? 'fas fa-times' : 'fas fa-calendar-times' }}"></i>
        </button>
      </div>    
    </div>
  `,
  bindings: {
    dueDate: '=',
    withoutDueDate: '='
  },
  controller: CalendarWithDueDateController,
  controllerAs: 'vm'
});

function CalendarWithDueDateController() {
  var vm = this;

  vm.dateOptions = {
    dateFormat: 'dd/mm/yy',
    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
    dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
    monthNames: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ],
    monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    today: 'Hoy',
    firstDay: 1,
    suffix: [],
    meridiem: []
  };

  vm.getPlaceholderText = () => {
    return (vm.withoutDueDate && !vm.dueDate) ? 'Sin plazo' : 'Elija fecha';
  };

  vm.getButtonTooltip = () => {
    if (vm.withoutDueDate || !!vm.dueDate){
      return 'Eliminar fecha';
    }
    return 'Sin plazo';
  };

  vm.toggleDueDate = function () {
    if (vm.dueDate){
      vm.dueDate = null;
      vm.withoutDueDate = false;
    }else{
      vm.withoutDueDate = !vm.withoutDueDate;
    }
  };
}
