import './styles.css';
import $ from 'jquery';

GotoCtrl.$inject = ['serverAddress', '$http', '$location', 'notifications', 'ClaimFactory'];

export default function GotoCtrl(serverAddress, $http, $location, notifications, ClaimFactory) {
  const vm = this;
  vm.url = '';

  vm.$onChanges = function (changes) {
    if (changes.claimId && changes.claimId.currentValue) {
      vm.getUrl(changes.claimId.currentValue).then(url => {
        vm.url = url;
      });
    }
  };

  vm.getUrl = async id => {
    const ID = !!id ? id : ClaimFactory.isValidId(vm.goToInput);
    if (!ID) {
      return notifications.addCurrentView(
        'error',
        `${vm.goToInput} no es un id de reclamación o expediente válido`
      );
    }
    try {
      const response = await $http.post(serverAddress.getBaseUrl() + 'common/claim/geturl/', {
        id: ID
      });
      const composingUrl = $location.$$protocol + '://' + $location.$$host + ':' + $location.$$port + '/#' + response.data.crm_url;
      return composingUrl;
    } catch (e) {
      if (e && e.data && e.data.message && e.data.message.indexOf('Object not found') != -1) {
        return notifications.addCurrentView('error', `No se encontró el id ${ID}`);
      }
      return notifications.addCurrentView('error', `Se produjo un error buscando ${ID}`);
    }
  };

  vm.goTo = function() {
    vm.getUrl().then(url => {
      vm.gotoFormActive = false;
      vm.goToInput = '';
      window.location.href = url;
    });
  };

  vm.copyURL = function() {
    navigator.clipboard.writeText(vm.url);
  };

  vm.select = function() {
    $('#gotoInput').focus();
    $('#gotoInput')[0].focus();
    $('#gotoInput')[0].select();
  };
}
