LegalfilesRoutes.$inject = ['$routeProvider', 'LegalfilesConstants'];

export default function LegalfilesRoutes($routeProvider, LegalfilesConstants) {
  const permissions = {
    only: 'segment-global-crm_view_legalfile_list',
    redirectTo: 'no-permissions'
  };

  // Management panel
  $routeProvider.when('/legalfiles/management-notifications/', {
    title: 'Notificaciones',
    templateUrl: 'partials/common/baseList/tastypie-list.html',
    data: {
      permissions: {
        only: 'segment-global-crm_notification_assignable_lawyer',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Legal',
      iconClass: 'fas fa-gavel'
    },
    baseListSettings: {
      typeList: 'notifications',
      typeTitle: 'notificaciones',
      templateListTable: 'js/modules/segments/legalfiles/partials/notifications/notification-list-table.html',
      templateListFilter: 'js/modules/segments/legalfiles/partials/notifications/notification-list-filters.html',
      templateListFooter: 'partials/common/baseList/tastypie-list-footer.html',
      title: 'Notificaciones',
      listResultsText: 'Notificaciones'
    },
    controller: 'NotificationsListCtrl',
    controllerAs: 'vm',
    segmentID: LegalfilesConstants.SEGMENT_ID
  });

  // Procedural control
  $routeProvider.when('/legalfiles/procedural-control/', {
    title: 'Control procesal',
    templateUrl: 'partials/common/baseList/tastypie-list.html',
    data: {
      permissions: {
        only: 'segment-global-crm_view_procedural_control_panel',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Legal',
      iconClass: 'fas fa-gavel'
    },
    baseListSettings: {
      typeList: 'notifications',
      typeTitle: 'notificaciones',
      templateListTable: 'js/modules/segments/legalfiles/partials/notifications/notification-procedural-control-table.html',
      templateListFilter: 'js/modules/segments/legalfiles/partials/notifications/notification-procedural-control-filters.html',
      templateListFooter: 'partials/common/baseList/tastypie-list-footer.html',
      title: 'Control Procesal',
      listResultsText: 'Control Procesal'
    },
    controller: 'NotificationsProceduralControlListCtrl',
    controllerAs: 'vm',
    segmentID: LegalfilesConstants.SEGMENT_ID
  });

  // Procedural files
  const listConfigProceduralFiles = {
    title: 'Expedientes',
    templateUrl: 'partials/common/baseList/claim-list.html',
    data: { permissions },
    layoutSetting: {
      contentTitle: 'Legal',
      iconClass: 'fas fa-gavel'
    },
    baseListSettings: {
      typeList: 'legalfiles',
      typeTitle: 'expedientes',
      templateListTable: 'js/modules/segments/legalfiles/partials/claim-list-table.html',
      templateListFilter: 'js/modules/segments/legalfiles/partials/claim-list-filters.html',
      title: 'Expedientes',
      listResultsText: 'expedientes'
    },
    controller: 'LegalFilesListCtrl',
    controllerAs: 'vm'
  };

  $routeProvider.when('/legalfiles', listConfigProceduralFiles);
  $routeProvider.when('/legalfiles/list/filter/:filter', listConfigProceduralFiles);

  $routeProvider.when('/legalfiles/:id', {
    title: 'Detalle de expedientes',
    templateUrl: 'js/modules/segments/legalfiles/partials/detail.html',
    // data: { permissions },
    layoutSetting: {
      contentTitle: 'Legal',
      iconClass: 'fas fa-gavel'
    },
    controller: 'LegalFilesDetailCtrl',
    controllerAs: 'vm',
    reloadOnSearch: false,
    segmentID: LegalfilesConstants.SEGMENT_ID
  }); 
}
