PhoneRoutes.$inject = ['$routeProvider', 'PhoneConstants'];

export default function PhoneRoutes($routeProvider, PhoneConstants) {
  $routeProvider.when('/phone/lawyers/counts', {
    title: 'Panel Abogados Telefonía',
    templateUrl: 'partials/common/panels/lawyers.html',
    data: {
      permissions: {
        only: 'segment-perobject-TE-crm_view_lawyers_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Telefonía',
      iconClass: 'fas fa-phone-volume',
      cardHead: 'Panel Abogados Telefonía'
    },
    controller: 'PhoneLawyerPanelCtrl',
    controllerAs: 'vm',
    segmentID: PhoneConstants.SEGMENT_ID
  });

  const permissions = {
    only: 'segment-perobject-TE-crm_view_claims_list',
    redirectTo: 'no-permissions'
  };

  const listConfig = {
    title: 'Reclamaciones Telefonía',
    templateUrl: 'partials/common/baseList/claim-list.html',
    data: { permissions },
    layoutSetting: {
      contentTitle: 'Telefonía',
      iconClass: 'fas fa-phone-volume'
    },
    baseListSettings: {
      typeList: 'phone',
      typeTitle: 'telefonía',
      templateListTable: 'js/modules/segments/phone/partials/claim-list-table.html',
      templateListFilter: 'partials/common/baseList/common-claim-list-filter.html',
      title: 'Reclamaciones Telefonía',
      listResultsText: 'reclamaciones de telefonia'
    },
    controller: 'PhoneClaimListCtrl',
    controllerAs: 'vm',
    segmentID: PhoneConstants.SEGMENT_ID
  };

  $routeProvider.when('/phone-claims', listConfig);
  $routeProvider.when('/phone-claims/list/filter/:filter', listConfig);

  $routeProvider.when('/phone-claims/:id', {
    title: 'Detalle reclamación telefonía',
    templateUrl: 'js/modules/segments/phone/partials/detail.html',
    data: {
      permissions: {
        only: 'segment-perobject-TE-crm_view_claim_detail',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Telefonía',
      iconClass: 'fas fa-phone-volume'
    },
    controller: 'PhoneClaimDetailCtrl',
    controllerAs: 'vm',
    segmentID: PhoneConstants.SEGMENT_ID,
    reloadOnSearch: false
  });
}
