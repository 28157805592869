HistoryListCtrl.$inject = ['$scope'];

/*
Common history list component with all the functionality related to history.
We have a new-comment component for sending new ones, but once the history object
it pases to this component.

Basically displays a table of comments, filtered and themed based on format parameter
passed to the controller. The available filters are:
- Full => displays all the comments and display the user filters block.
- documents => just display comments marked as show_in_docs_tab
- state => display state changes (management tab)
- firstImportant => just display the last comment (new summary tab)
*/
export default function HistoryListCtrl ($scope) {
  const vm = this;

  Object.assign(vm, {
    // bindings
    format: null,
    history: [],
    entityType: null,

    // Internal data
    title: null,
    filteredHistory: [],
    filters: {},
    parsedFilters: [],

    // methods
    updateHistory,
    goToHistoryTab
  });

  vm.$onInit = () => {
    setTitle();
    $scope.$watch('vm.history', vm.updateHistory);

    /*
    Detect changes in filter forms (only in format='full') and
    transform form filters (vm.filters) into real (and usable) parsedFilters object
    */
    $scope.$watch('vm.filters', filters => {
      if(!filters) return;

      var selectedFilters = [];

      if (filters.comment) selectedFilters.push('comment');
      if (filters.statechange) selectedFilters.push('statechange');
      if (filters.communication) selectedFilters.push('communication', 'sistema');
      if (filters.documents) selectedFilters.push('documents');
      if (filters.editchanges) selectedFilters.push('change');
      if (filters.zendesk) selectedFilters.push('zendesk');

      vm.parsedFilters = selectedFilters;

      updateHistory(vm.history);
    }, true);
  };

  function goToHistoryTab() {
    $scope.$emit('claim:setActiveTab', 'historial');
  }

  // just pass title for each block format
  function setTitle() {
    const blockTitles = {
      'documents': 'Comentarios importantes',
      'full': 'Historial de la reclamación',
      'firstImportant': 'Último comentario destacado',
      'state': 'Historial de cambios de estado'
    };

    vm.title = blockTitles[vm.format] ? blockTitles[vm.format] : null;
  }

  /*
  Parse the scope changes and filter them for the current format
  and for the active set of filters,
  */
  function updateHistory(history) {
    if(!history) return;

    let docs = [];

    switch(vm.format) {
      case 'documents':
        docs = history.filter(e => e.show_in_docs_tab);
        break;
      case 'full':
        docs = history.slice(0); // just clone
        break;
      case 'firstImportant':
        docs = history.filter(e => e.is_important).slice(0, 1);
        break;
      case 'state':
        docs = history.filter(e => e.type == 'statechange');
        break;
      default:
        throw new Error('Unknow historyList format ' + vm.format);
        break;
    }

    vm.filteredHistory = docs.filter(e => _filterChange(e));
  };

  /*
  Filter a change checking the active set of filters if any.
  */
  function _filterChange(e) {
    if (!vm.parsedFilters || vm.parsedFilters.length == 0 || !e) return true;
    if (vm.parsedFilters.indexOf(e.type) !== -1) return true;

    return vm.parsedFilters.indexOf('documents') !== -1 && e.type.indexOf('document') !== -1;
  };
}
