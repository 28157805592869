// Controller for modal to change claim state from legalfile
ConfirmStateChangeCtrl.$inject = [
  '$http',
  '$scope',
  'serverAddress',
  'newStateId',
  'comment',
  'legalfileId',
  'legalfilePrefix',
  'sendingEmail',
  'parentScope',
  'dialog',
  'notifications',
  'client_attendance'
];

export default function ConfirmStateChangeCtrl(
  $http,
  $scope,
  serverAddress,
  newStateId,
  comment,
  legalfileId,
  legalfilePrefix,
  sendingEmail,
  parentScope,
  dialog,
  notifications,
  client_attendance
) {
  /* Variables */
  $scope.changeButtonDisabled = false;

  /* Functions */
  $scope.change = change;
  $scope.dismiss = dismiss;

  /* Function implementations */
  function change() {
    var data = {
      legalfile_id: legalfileId,
      comment: comment,
      new_state_id: newStateId,
      legalfile_prefix: legalfilePrefix,
      same: 'legalfile',
      sending_email: sendingEmail,
      client_attendance: client_attendance
    };

    // Case for airline opposing lawyer
    if (legalfilePrefix === 'AE' && parentScope.legalfile.airlineopposinglawyer_id) {
      data['airlineopposinglawyer_id'] = parentScope.legalfile.airlineopposinglawyer_id;
    }

    $scope.changeButtonDisabled = true;

    $http
      .post(serverAddress.getBaseUrl() + 'legalfile/state/change/', data)
      .then(function() {
        parentScope.displayStatusComment = false;
        notifications.addCurrentView('info', 'Cambio de estado exitoso');
        parentScope.updateFile();
        dialog.close();
        $scope.changeButtonDisabled = false;
      })
      .catch(function(response) {
        for (var i = 0; i < response.data.length; i++) {
          notifications.addCurrentView('error', response.data[i]);
        }
        parentScope.updateFile();
        parentScope.displayStatusComment = false;
        dialog.close();
        $scope.changeButtonDisabled = false;
      });
  }

  function dismiss() {
    dialog.close();
  }
}
