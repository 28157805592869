const SEGMENT_ID = 4,
  MODEL_PROVIDER = 'PhoneClaim';

export default {
  SEGMENT_ID,
  MODEL_PROVIDER,
  DJANGO_MODEL: MODEL_PROVIDER,
  SEGMENT_PREFIX: 'TE',
  ALIAS: 'phone',
  permissions: {
    downloadCSVPermission: 'segment-perobject-TE-crm_download_csv'
  },
  VERBOSE_NAME: 'Reclamación por telefonía',
  CLAIM_DICTIONARIES: {
    users: 'phonelawyers',
    claimStates: {
      entity: 'claimstates',
      data: { segments__id: SEGMENT_ID, costs: false }
    },
    sources: 'claimsources',
    courts: 'courts',
    courtlawyers: 'courtlawyers',
    procuradores: {
      entity: 'procuradores',
      data: { is_phone: true }
    },
    claimTypes: {
      entity: 'claimtypes',
      data: { type: 'phone' }
    },
    saleStates: {
      entity: 'salesclaimstates',
      data: { segments__id: SEGMENT_ID }
    },
    trialMunicipalities: 'trialMunicipalities'
  },
  tabConfig: [
    {
      heading: 'Resumen',
      indexKey: 'resumen',
      template: 'js/modules/segments/phone/partials/detail/summary.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Detalles',
      indexKey: 'detalles',
      template: 'js/modules/segments/phone/partials/detail/details.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Facturas',
      indexKey: 'facturas',
      template: 'js/modules/segments/phone/partials/detail/bills.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Documentación',
      indexKey: 'documentacion',
      template: 'js/modules/segments/phone/partials/detail/documentation.html'
    },
    {
      heading: 'Historial',
      indexKey: 'historial',
      template: '/partials/common/detail/historic.html',
      headingBadge: null
    },
    {
      heading: 'Mismo cliente',
      indexKey: 'mismo-cliente',
      template: '/partials/common/detail/same_client/base.html'
    },
    {
      heading: 'Administración',
      indexKey: 'administracion',
      template: '/partials/common/detail/administration.html',
      select: null,
      headingBadge: null,
      permission: 'segment-global-crm_view_administration_tab'
    }
  ],
  defaultColumnDef: [
    { headerName: 'ID', fieldName: 'id', sortable: true, alwaysVisible: true },
    { headerName: 'Tipo', fieldName: 'type_name', sortable: true },
    { headerName: 'Cliente', fieldName: 'client_surnames', sortable: true },
    { headerName: 'Correo cliente', fieldName: 'client_email', sortable: true, hidden: true },
    { headerName: 'Provincia cliente', fieldName: 'province', sortable: true, hidden: true },
    { headerName: 'Tlfno', fieldName: 'client_phone', sortable: true, hidden: true },
    { headerName: 'Finalizada', fieldName: 'finished', sortable: true },
    { headerName: 'Fecha finalizada', fieldName: 'date_finished', sortable: true },
    { headerName: 'Abogado', fieldName: 'lawyer', sortable: true },
    { headerName: 'Comercial', fieldName: 'salesman', sortable: true },
    { headerName: 'Validado por', fieldName: 'validator_lawyer', sortable: true },
    { headerName: 'Estado', fieldName: 'state_name', sortable: true },
    { headerName: 'Estado C.', fieldName: 'sales_state_name', sortable: true },
    { headerName: 'Fase', fieldName: 'phase_name', sortable: true },
    { headerName: 'Fuente', fieldName: 'source_name', sortable: true },
    { headerName: 'Cómo nos conoció', fieldName: 'how_met_us', sortable: true },
    { headerName: 'Fuente invitador', fieldName: 'invited', sortable: true, hidden: true },
    { headerName: 'Compañía', fieldName: 'company', sortable: true },
    { headerName: 'Reclamación operadora', fieldName: 'previous_claim', sortable: true },
    {
      headerName: 'Núm. Reclamación operadora',
      fieldName: 'previous_claim_number',
      sortable: true
    },
    { headerName: 'Campaña', fieldName: 'campaign_name', sortable: true, hidden: true },
    { headerName: 'SEM', fieldName: 'has_sem_gclid', sortable: true, hidden: true },
    { headerName: 'SEM (Valor)', fieldName: 'sem_gclid', sortable: true, hidden: true },
    { headerName: 'Network', fieldName: 'sem_network', sortable: true, hidden: true },
    { headerName: 'Placement', fieldName: 'sem_placement', sortable: true, hidden: true },
    { headerName: 'Campaign', fieldName: 'sem_campaign', sortable: true, hidden: true },
    {
      headerName: 'Ultimo cambio estado',
      fieldName: 'laststatechange',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Usuario cambio estado',
      fieldName: 'laststatechangeuser',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Comentario cambio estado',
      fieldName: 'laststatecomment',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Ultimo cambio estado comercial',
      fieldName: 'lastsalesstatechange',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Usuario cambio estado comercial',
      fieldName: 'lastsalesstatechangeuser',
      sortable: true,
      hidden: true
    },
    { headerName: 'Último comentario', fieldName: 'last_comment', sortable: true, hidden: true },
    {
      headerName: 'Usuario últ. comentario ',
      fieldName: 'last_comment_user',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'fecha últ. comentario',
      fieldName: 'last_comment_date',
      sortable: true,
      hidden: true
    },
    { headerName: 'Creado', fieldName: 'date_added', sortable: true },
    { headerName: 'Modificado', fieldName: 'date_updated', sortable: true },
    { headerName: 'Numero documentos', fieldName: 'num_docs', sortable: true, hidden: true },
    { headerName: '', fieldName: null, sortable: true, alwaysVisible: true },
    {
      headerName: 'Cedida',
      fieldName: 'transfered_to_investor',
      sortable: true
    },
    {
      headerName: 'Fecha cesión',
      fieldName: 'transfered_to_investor_date',
      sortable: true
    },
    {
      headerName: 'Cartera',
      fieldName: 'investor_business_name',
      sortable: true
    },
    {
      headerName: 'Comprada',
      fieldName: 'bought_from_portfolio',
      sortable: true
    },
    {
      headerName: 'Comprado a',
      fieldName: 'bought_portfolio_business_name',
      sortable: true
    },
    {
      headerName: 'Fecha compra',
      fieldName: 'bought_date',
      sortable: true
    },
    {
      headerName: 'Email cartera cliente',
      fieldName: 'email_bought_client',
      sortable: true
    },
    {
      headerName: 'Teléfono cartera cliente',
      fieldName: 'phone_bought_client',
      sortable: true
    }
  ],
  filterFieldInfo: {
    date_updated__gte: {
      label: 'Fecha modificado desde',
      type: 'date',
      column: 'phoneclaim.date_updated',
      order: '>='
    },
    date_updated__lte: {
      label: 'Fecha modificado hasta',
      type: 'date',
      column: 'phoneclaim.date_updated',
      order: '<='
    },
    date_added__gte: {
      label: 'Fecha creado desde',
      type: 'date',
      column: 'phoneclaim.date_added',
      order: '>='
    },
    date_added__lte: {
      label: 'Fecha creado hasta',
      type: 'date',
      column: 'phoneclaim.date_added',
      order: '<='
    },
    date_finished__gte: {
      label: 'Fecha finalizado desde',
      type: 'date',
      column: 'phoneclaim.date_finished',
      order: '>='
    },
    date_finished__lte: {
      label: 'Fecha finalizado hasta',
      type: 'date',
      column: 'phoneclaim.date_finished',
      order: '<='
    },
    client_name: { label: 'Nombre cliente', type: 'text' },
    client_surnames: { label: 'Apellidos cliente', type: 'text' },
    lawyer: { label: 'Abogado' },
    sales_state_id: { label: 'Estado Comercial' },
    finished: { label: 'Terminada' },
    source_id: { label: 'Fuente', select_id: 'claim-source-select' },
    company: { label: 'Compañía', json_value: 'true', use_field: 'phone' },
    campaign_name: { label: 'Campaña', json_value: 'true', use_field: 'name' },
    type_id: { label: 'Tipo', select_id: 'claim-type-select' },
    state_id: { label: 'Estado (Legal)', select_id: 'claim-state-select' },
    transfered_to_investor: {
      label: 'Cedida',
      column: 'transfered_to_investor',
      type: 'boolean'
    },
    investor_id: {
      label: 'Cartera de inversiones',
      select_id: 'claim-investor-select',
      column: 'investor.id'
    },
    transfered_to_investor_date__gte: {
      label: 'Fecha de cesión desde',
      type: 'date',
      column: 'claim.transfered_to_investor_date',
      order: '>='
    },
    transfered_to_investor_date__lte: {
      label: 'Fecha de cesión hasta',
      type: 'date',
      column: 'claim.transfered_to_investor_date',
      order: '<='
    }
  }
};
