/* eslint-disable angular/di */
import angular from 'angular';

import ManagementDetailCtrl from './ManagementDetailCtrl';
import ManagementListCtrl from './ManagementListCtrl';

import {
  Management,
  ManagementLawyer,
  ManagementSalesman,
  InheritancePhoneQuestions,
  VCPhoneQuestions,
  Inheritor,
  VC,
  VCType,
  VCInternalType,
  VCServiceTiming
} from './services';

import ManagementConstants from './constants';

import ManagementRoutes from './routes';

angular
  .module('reclamador.segments.managements', [])
  .controller('ManagementDetailCtrl', ManagementDetailCtrl)
  .controller('ManagementListCtrl', ManagementListCtrl)

  .component('managementsDetail', {
    templateUrl: 'partials/managements/claim.html',
    controller: ManagementDetailCtrl
  })
  .component('managementsList', {
    templateUrl: 'partials/common/baseList/claim-list.html',
    controller: ManagementListCtrl
  })

  .factory('ManagementsClaim', Management)
  .factory('ManagementLawyer', ManagementLawyer)
  .factory('ManagementSalesman', ManagementSalesman)
  .factory('InheritancePhoneQuestions', InheritancePhoneQuestions)
  .factory('VCPhoneQuestions', VCPhoneQuestions)
  .factory('Inheritor', Inheritor)

  .factory('VC', VC)
  .factory('VCType', VCType)
  .factory('VCInternalType', VCInternalType)
  .factory('VCServiceTiming', VCServiceTiming)


  .constant('ManagementConstants', ManagementConstants)

  .config(ManagementRoutes);
