CostsPanelCtrl.$inject = [
  '$http',
  'serverAddress',
  'dataLoader',
  'CostTypes',
  'constantsFactory',
  '$location',
  '$scope',
  'DownloadCSVService',
  '$timeout'
];
export default function CostsPanelCtrl(
  $http,
  serverAddress,
  dataLoader,
  CostTypes,
  constantsFactory,
  $location,
  $scope,
  DownloadCSVService,
  $timeout
) {
  const vm = this;

  const INITIAL_STATE = {
    baseUrl: '',
    busy: true,
    claimTypes: [],
    productTypes: [],
    costsTypes: [],
    segments: [],

    isExportingCSV: false,

    segmentPrefix: $location.search().segment || 'ALL',
    selectedSegment: null,

    claimstatesCosts: [],
    selectedcostsFilters: [],
    data: [],
    settings: {
      pageSize: 20,
      currentPage: 0
    },
    filters: {
      cost_type_id: [],
      claim_type_id: null
    },
    intervals: [
      { id: 'r0', name: 'TOTAL' },
      { id: 'r4', name: 'Más de 60 días' },
      { id: 'r3', name: '30-60 días' },
      { id: 'r2', name: '15-30 días' },
      { id: 'r1', name: '0-15 días' }
    ]
  };

  const METHODS = {
    filterByClaimType,
    filterByCostType,
    applyFilters,
    getRowTitle,
    getRowValues,
    navigateToProcedureList,
    downloadCSV,
    initClaimStatesCosts,
    loadSegmentData
  };

  Object.assign(vm, INITIAL_STATE, METHODS);

  vm.baseUrl = `${serverAddress.getBaseUrl()}costsprocedures/list/`;
  // Hardcode costtypes
  CostTypes.get().then(data => {
    vm.costsTypes = data.map(item => ({ id: item.id, active: false, name: item.name }));
  });

  dataLoader(['claimtypes', 'costsclaimstates', 'segments'], {
    claimtypes: {
      refresh: true,
      requestData: {
        is_active: true
      }
    }
  }).$promise.then(res => {
    vm.busy = false;
    vm.segments = res['segments'].objects
      .map(item => ({
        id: item.id,
        label: item.name,
        prefix: item.prefix,
        active: false
      }))
      .sortBy('id');
    vm.segments.unshift({ id: 0, label: 'TODOS', prefix: 'ALL', active: true });

    vm.productTypes = res['claimtypes'].objects
      .map(item => ({
        id: item.id,
        label: item.name,
        type: item.type,
        active: false
      }))
      .sortBy('label');

    vm.initClaimStatesCosts(res['costsclaimstates'].objects);

    vm.selectedSegment = getSelectedSegmentByPrefix(vm.segmentPrefix);
  });

  vm.$onInit = () => {
    $scope.$watch('vm.selectedSegment', function(segment) {
      if (segment) {
        $location.search('segment', segment.prefix);
        vm.loadSegmentData(segment.prefix);
      }
    });
    $scope.$watch('vm.selectedProductType', function(productType) {
      if (productType) {
        vm.filterByClaimType(productType);
      }
    });
    $scope.$watch('vm.selectedCost', function(costFilter) {
      if (costFilter) {
        vm.filterByCostType(costFilter);
      }
    });
  };

  function loadSegmentData(prefix) {
    vm.segments.forEach(i => {
      i.active = false;
    });
    vm.selectedSegment = getSelectedSegmentByPrefix(prefix);

    vm.segmentPrefix = vm.selectedSegment.prefix;

    if (vm.segmentPrefix === 'ALL') {
      vm.productTypesForSegment = vm.productTypes;
    } else {
      vm.productTypesForSegment = vm.productTypes.filter(function(i) {
        return i.type == constantsFactory.getSegmentAlias(vm.selectedSegment.prefix).alias;
      });
    }
    vm.productTypesForSegment.unshift({ id: 0, label: 'TODOS', active: true });

    // ALL product filter by default
    $timeout(() => {
      vm.selectedProductType = vm.productTypesForSegment[0];
      vm.selectedSegment.active = true;
    }, 50);
  }

  function initClaimStatesCosts(data) {
    vm.claimstatesCosts = data.map(item => ({
      id: item.id,
      name: item.name
    }));
    vm.claimstatesCosts.unshift({ id: 'TOTAL', name: 'TOTAL' });
  }

  function getSelectedSegmentByPrefix(prefix) {
    const el = vm.segments.find(i => i.prefix === prefix);
    return el;
  }

  function getRowTitle(row) {
    return Object.keys(row)[0];
  }

  function getRowValues(row) {
    return Object.values(row)[0];
  }

  function filterByCostType(costTypes) {
    vm.filters.cost_type_id = costTypes.map(i => i.id);
    vm.applyFilters();
  }

  function filterByClaimType(claimType) {
    vm.productTypes.forEach(i => {
      i.active = false;
    });
    claimType.active = true;
    if (claimType.id === 0) {
      vm.filters.claim_type_id = null;
    } else {
      vm.filters.claim_type_id = claimType.id;
    }
    vm.applyFilters();
  }

  function requestData(params) {
    return $http.get(`${serverAddress.getBaseUrl()}common/${vm.segmentPrefix}/costs/counts/`, {
      params
    });
  }

  function applyFilters() {
    vm.busy = true;
    requestData(vm.filters)
      .then(res => {
        // TODO: The structure returned by back-end is inefficient [[id]: {}, ...], we need sort and map on this side
        vm.data = sortData(res.data.result);
      })
      .catch(err => console.error(err))
      .finally(() => {
        vm.busy = false;
      });
  }

  function navigateToProcedureList({ key, range }) {
    const costState = vm.claimstatesCosts.find(item => item.name == key);
    const procedureFilters = Object.assign({}, vm.filters, { cost_state_id: costState.id, range });
    $location.path(`/${vm.segmentPrefix}/procedures`);
    $location.search(procedureFilters);
  }

  function downloadCSV() {
    const claimType = vm.productTypes.find(pT => pT.id == vm.filters.claim_type_id);
    const costType = vm.costsTypes.find(cT => vm.filters.cost_type_id.includes(cT.id)); // TODO: Make cost_type to be an array
    
    const params = {
      segment: vm.segmentPrefix,
      filter: {
        claim_type_id: claimType ? Object.assign(claimType, { value: claimType.id }) : undefined, // 'value' property is neccessary to filter
        cost_type_id: costType ? Object.assign(costType, { value: costType.id }) : undefined, // 'value' property is neccessary to filter
        range: { label: 'Rango de tiempo', type: 'string', id: 'r0', name: '', value: 'r0' }
      },
      sort: {}
    };
    
    const downloadURL = `${vm.baseUrl}${vm.settings.pageSize}/${vm.settings.currentPage}`;

    vm.isExportingCSV = true;

    DownloadCSVService.fetch(downloadURL, params);

    alert('La exportación del archivo CSV está en progreso. Por favor, espera un momento...');
    $timeout(function() {
      vm.isExportingCSV = false;
    }, 5000);
  }

  /* Aux Functions */
  function sortData(data) {
    const dataSorted = {};
    //TODO: Move this action to back-end. Replace id by name for claimtypes with flag costs set 'true' and return as list instead of object
    vm.claimstatesCosts.forEach(item => {
      if (data.hasOwnProperty(item.id)) {
        // r0: ALL, [r1, r2, ..., rn] other periods
        const { r0, r4, r3, r2, r1 } = data[item.id];
        dataSorted[item.name] = { r0, r4, r3, r2, r1 };
      }
    });
    return dataSorted;
  }
}
