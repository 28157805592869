import { element } from 'angular';
UserProfileCtrl.$inject = [
  '$rootScope',
  'Worker',
  'toastr',
  'fileUploader',
  '$timeout'
];

export default function UserProfileCtrl(
  $rootScope,
  Worker,
  toastr,
  fileUploader,
  $timeout
) {
  const vm = this;

  const INITIAL_STATE = {
    worker: {
      email: '',
      surnames: '',
      id: null,
      name: '',
      avatar: '//ssl.gstatic.com/accounts/ui/avatar_2x.png'
    },
    user: {},
    uploader: null,
    avatarLoading: false
  };
  const METHODS = {
    updateProfile,
    updateAvatar,
    triggerUpload,
    initializeFileUploader,
    passwordReset
  };

  Object.assign(vm, INITIAL_STATE, METHODS);

  vm.$onInit = () => {
    vm.initializeFileUploader();
    if (!$rootScope.worker) {
      $rootScope.$on('event:worker-info', (ev, data) => {
        vm.worker = data;
        vm.uploader.url = `${Worker.getBaseUrl()}${vm.worker.id}/`;
      });
    } else {
      vm.worker = $rootScope.worker;
      vm.uploader.url = `${Worker.getBaseUrl()}${vm.worker.id}/`;
    }
  };

  function triggerUpload() {
    vm.uploader.queue.forEach(el => {
      el.upload();
    });
  }

  function updateAvatar(ev) {
    $timeout(() => element('#worker_avatar-input').click());
    ev.stopPropagation();
    return false;
  }

  function updateProfile() {
    Worker.update(vm.worker.id, {
      name: vm.worker.name,
      surnames: vm.worker.surnames
    })
      .then(() => toastr.success('Perfil actualizado correctamente'))
      .catch(error => {
        toastr.error('Problema al actualizar tu perfil', error);
      });
  }

  function initializeFileUploader() {
    vm.uploader = fileUploader({
      url: `${Worker.getBaseUrl()}`,
      alias: 'avatar',
      method: 'PUT',
      fillItemData: function(item) {
        item.formData = [
          {
            name: vm.worker.name,
            surnames: vm.worker.surnames
          }
        ];
      },
      onAfterAddingFile: () => {
        vm.triggerUpload();
      },
      onProgressItem: () => {
        vm.avatarLoading = true;
      },
      sucessCallback: () => {
        Worker.get({ id: vm.worker.id }).then(response => {
          vm.worker = Object.assign(vm.worker, response.data);
          vm.avatarLoading = false;
        });
      },
      errorCallback: error => {
        toastr.error('Se ha producido un error', error);
      }
    });
    vm.uploader.filters.push(
      {
        name: 'imageFilterExt',
        fn: item => {
          const type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
          const condition = '|jpg|png|jpeg|'.indexOf(type) !== -1;
          if (!condition) {
            toastr.error('La extensión de imagen que intentas subir no está permitida.');
          }
          return condition;
        }
      },
      {
        name: 'imageFilterSize',
        fn: item => {
          const condition = item.size < 2000000; // 2MB
          if (!condition) {
            toastr.error('El tamaño de imagen que intentas subir es demasiado grande.');
          }
          return condition;
        }
      }
    );
  }

  function passwordReset() {
    $rootScope.$emit('event:password-reset', vm.worker.email);
    toastr.info('Te hemos envíado un email para que puedas resetear tu contraseña.');
  }
}
