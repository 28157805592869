NotificationsListCtrl.$inject = [
  '$scope',
  '$controller',
  '$http',
  '$window',
  'LegalfilesConstants',
  'dataLoader',
  'NotificationService',
  'notifications',
  'DocumentDownloadFactory',
  '$location',
  'serverAddress',
  'UtilsFactory'
];

export default function NotificationsListCtrl(
  $scope, $controller, $http, $window, LegalfilesConstants, dataLoader, NotificationService, notifications,
  DocumentDownloadFactory, $location, serverAddress, UtilsFactory
) {
  const vm = this;

  function handleLegalfileClick(notification) {
    $window.open(`#/legalfiles/${notification.legalfile.id}`, '_blank');
  }
  function handleOpenDocumentClick(notification) {
    vm.initViewer(notification);
  }
  function handleManageNotificationClick(notification) {
    NotificationService.manageNotification(vm.settings.resourceName, notification.id).then(() => {
      notifications.addCurrentView('success', `Notificación "${notification.type.name}" gestionada con éxito.`);
      vm.updateItems();
    })
      .catch(response => {
        notifications.addCurrentView('error', response.data);
      });
  }

  vm.actionsNotificationsList = {
    legalfile: {icon:'fas fa-bullhorn', name: 'Expediente Procesal', onClick: handleLegalfileClick },
    openDocument: {icon:'fas fa-file-alt', name: 'Documentación', onClick: handleOpenDocumentClick },
    manageNotification: {icon:'fas fa-check', name: 'Notificación gestionada', onClick: handleManageNotificationClick }
  };

  vm.getExpirationStyleText = function(dueDate) {
    if (!dueDate) return { style: 'badge-secondary', text: 'Sin plazo' };

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    const oneDay = 24 * 60 * 60 * 1000;
    const yesterday = new Date(today.getTime() - oneDay);
    const tomorrow = new Date(today.getTime() + oneDay);
    const twoDaysFuture = new Date(today.getTime() + (2 * oneDay));

    let parsedDueDate = new Date(Date.parse(dueDate));
    parsedDueDate.setHours(0, 0, 0, 0);

    if (parsedDueDate.getTime() <= yesterday.getTime()) return { style: 'badge-legalfiles-notifications', text: 'GRACIA' };
    if (parsedDueDate.getTime() === today.getTime()) return { style: 'badge-danger', text: 'Hoy' };
    if (parsedDueDate.getTime() === tomorrow.getTime()) return { style: 'badge-danger', text: 'Mañana' };
    if (parsedDueDate.getTime() === twoDaysFuture.getTime()) return { style: 'text-danger', text: parsedDueDate.toLocaleDateString() };
    return { style: 'badge-secondary', text: parsedDueDate.toLocaleDateString() };
  };

  const INITIAL_STATE = {

    data: {
      totalCount: 0,
      items: [],
      totalCountBySegment: {
        'AE': 0,
        'BA': 0,
        'AC': 0,
        'TE': 0,
        'LA': 0,
        'NE': 0,
        'SO': 0,
        'TA': 0
      }
    },
    loadingFilters: true,
    filtersDict: {
      proceduralLawyer: [],
      proceduralPhase: [],
      documentType: [],
      priority: LegalfilesConstants.dueDateOptions_Notifications,
      dueDate: LegalfilesConstants.dueDateOptions_Notifications
    },
    settings: {
      title: 'Filter list unnamed',
      enableFiltering: true,
      displaycolumnsFilters: false,
      resourceName: 'airline_legalfile_document',
      resourceNameBySegment: {
        'AE': 'airline_legalfile_document',
        'BA': 'bank_legalfile_document',
        'AC': 'accident_legalfile_document',
        'TE': 'phone_legalfile_document',
        'LA': 'laboral_legalfile_document',
        'NE': 'negligences_legalfile_document',
        'SO': 'so_legalfile_document',
        'TA': 'municipaltax_legalfile_document'
      },
      pageSize: '20',
      columnDef: LegalfilesConstants.defaultColumnDef_Notifications,
      filterFieldInfo: LegalfilesConstants.filterFieldInfo_Notifications
    },
    documentGroups: [
      {
        id: 6,
        key: 'validDocs',
        name: 'Notificaciones',
        objsKey: 'documentsProcessed',
        docTypesKey: null,
        actions: ['download']
      }
    ],
    segmentToFilter: 'AE',
    hideAllOptionSegmentToFilter: true,
    hideIMOptionSegmentToFilter: true,

    // viewer data
    availableDocumentGroups: [], // documentGroups with available documents
    activeDocumentGroup: {}, // the active documentGroup
    activeGroupDocs: [], // the documents from the active document group
    activeDocument: null, // the document in the viewers
    documentsProcessed: [],

    // viewer methods
    initViewer,
    _resetViewer,
    setDocumentGroup,
    getDocumentGroup
  };

  const METHODS = {
    segmentToFilterHandler,
    getTotalCountBySegment
  };

  /**
  * Override default filter change behaviour.
  * In this case we need to replace the resource name based on the segment.
  */
  function segmentToFilterHandler(segment) {
    if(vm.settings.resourceNameBySegment[segment]){
      vm.settings.resourceName = vm.settings.resourceNameBySegment[segment];
    }
    vm.updateItems();
  }

  $controller('TastypieBaseListCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE, METHODS);

  function getTotalCountBySegment(){
    const url = `${serverAddress.getBaseUrl()}api2/v1/legalfile_documents_count/`;

    const params = {
      'group_by': 'segment',
      ...vm.getFilterParams()
    };

    $http
      .get(url, { params })
      .then(res => {
        vm.data.totalCountBySegment = UtilsFactory.reduceArrayToObject(res.data.objects, 'segment', 'total_count');
      })
      .catch(error => console.error(error));
  }

  $scope.$watch('vm.data.items', docs => {
    if(!docs) return;

    vm.documentsProcessed = docs.filter(d => !d.deleted && !d.is_missing);
    _updateDoc();
  });

  // document-viewer request closing
  $scope.$on('viewer:close', () => {
    _resetViewer();
  });

  $scope.$on('document:download', (e, doc) => {
    DocumentDownloadFactory.downloadDocumentDirectly(doc);
  });

  // click on document to load viewer
  $scope.$on('viewer:loadDoc', (e, doc) => {
    initViewer(doc);
  });

  // document-viewer request new document
  $scope.$on('viewer:changeDoc', (e, doc) => {
    loadDocument(doc);
  });

  // document-viewer request group change
  $scope.$on('viewer:changeGroup', (e, group) => {
    setDocumentGroup(group);
    loadDocument(vm[group.objsKey][0]);
  });

  /* Change active document group */
  function setDocumentGroup(documentGroup) {
    if(vm.activeDocumentGroup == documentGroup) return;
    vm.activeDocumentGroup = documentGroup;
    vm.activeGroupDocs = vm[vm.activeDocumentGroup.objsKey].filter(d => !d.is_drive);
  }

  /* Returns the first document from the first active document group */
  function _getInitialDoc() {
    return vm[vm.availableDocumentGroups[0].objsKey][0];
  }

  /* Get the first document group who contains the passed element */
  function _getDocGroup(doc) {
    for (var i = vm.availableDocumentGroups.length - 1; i >= 0; i--) {
      if(vm[vm.availableDocumentGroups[i].objsKey].find(d => d.id == doc.id)) return vm.availableDocumentGroups[i];
    }
  }

  function _updateDoc() {
    _updateDocumentGroups();

    if(!vm.activeDocument) return;
    let newGroup = _getDocGroup(vm.activeDocument);
    if(newGroup != vm.activeDocumentGroup) setDocumentGroup(newGroup);
  }

  /* Filter available document groups, with the ones with documents */
  function _updateDocumentGroups() {
    vm.availableDocumentGroups = vm.documentGroups.filter(g => vm[g.objsKey] && vm[g.objsKey].length);
  }


  function initViewer(initialDoc) {
    if (!initialDoc) initialDoc = _getInitialDoc();
    $scope.$broadcast('viewer:init');
    setDocumentGroup(_getDocGroup(initialDoc));
    loadDocument(initialDoc);
  }

  function _resetViewer() {
    $scope.$broadcast('viewer:remove');
    vm.activeDocument = null;
  }

  // load new document for the viewer
  function loadDocument(doc) {
    vm.activeDocument = doc;
  }


  function getDocumentGroup(key) {
    return vm.documentGroups.find(g => g.key == key);
  }

  async function populateFilters() {
    try {
      const filters = await dataLoader(['proceduralPhases', 'proceduralLawyers', 'legalfileDocumentTypes']).$promise;
      if(vm.filtersDict.proceduralPhase.length === 0){
        // Prevent adding null filter multiple times
        const proceduralPhaseNullFilter = {
          id: null,
          name: 'Sin fase',
          resource_uri: null
        };
        vm.filtersDict.proceduralPhase.push(proceduralPhaseNullFilter);
      }
      vm.filtersDict.proceduralPhase.push(...filters['proceduralPhases'].objects);

      if(vm.filtersDict.proceduralLawyer.length === 0){
        const proceduralLawyerNullFilter = {
          id: null,
          username: 'Sin abogado',
          resource_uri: null
        };
        vm.filtersDict.proceduralLawyer.push(proceduralLawyerNullFilter);
      }
      vm.filtersDict.proceduralLawyer.push(...filters['proceduralLawyers'].objects);

      vm.filtersDict.documentType = filters['legalfileDocumentTypes'].objects;
      vm.loadingFilters = false;
      $scope.$apply();
    } catch (error) {
      console.error('Error loading filters:', error);
    }
  }

  /** Initiate List! */
  if ($location.search()){
    const segment = $location.search()['segment'];
    if (segment && vm.settings.resourceNameBySegment[segment]){
      vm.segmentToFilter = segment;
      vm.settings.resourceName = vm.settings.resourceNameBySegment[segment];
    }
    // We need to populate before initializing the resource to be able to display filter labels provided in URL
    populateFilters().then(() => vm.initResource());
  }else{
    vm.initResource();
  }

}
