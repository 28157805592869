LegalServiceDetailCtrl.$inject = [
  '$scope',
  '$controller',
  'fileUploaderGenerator',
  'serverAddress',
  'DialogFactory',
  'LegalServicesConstants',
  'MicroserviceType',
  'MicroserviceHour',
  'LegalServiceNotes',
  'BudgetCalculator',
  'BudgetReport',
  'toastr',
  '$rootScope',
  'notifications',
  '$timeout'
];
export default function LegalServiceDetailCtrl(
  $scope,
  $controller,
  fileUploaderGenerator,
  serverAddress,
  DialogFactory,
  LegalServicesConstants,
  MicroserviceType,
  MicroserviceHour,
  LegalServiceNotes,
  BudgetCalculator,
  BudgetReport,
  toastr,
  $rootScope,
  notifications,
  $timeout
) {
  const vm = this;

  const INITIAL_STATE = {
    displayDocumentsUploader: false,
    microservices: [],
    legalServiceNotes: [],
    lawyers: [],
    budget: [],
    ccggDate: '',
    lastNote: null,
    showNoteModal: true,

    actions: {
      showNewCaseModal
    }
  };

  const METHODS = {
    initClaimCB,
    postRefreshClaimCB,

    updateMicroServiceHour,
    addLegalServiceNotes,
    editLegalServiceNotes,
    deleteLegalServiceNotes,
    getMicroserviceHour,

    calculateBudget,
    generateBudget,

    showDocumentCopyModal,
    showDocumentMergingModal,
    showDocumentSlicingModal
  };

  $controller('BaseDetailCtrl', { vm: vm, $scope: $scope });

  Object.assign(vm, INITIAL_STATE, METHODS, LegalServicesConstants);

  vm.claimDetail.init();

  /* Function declaration */
  /* BaseDetail Callbacks */
  function initClaimCB() {
    vm.getDocumentTypes();

    // Scope data according product
    vm.claimType = {
      id: vm.claim.type.id | 0,
      key: vm.CLAIM_TYPE,
      path: 'legalservices'
    };
    vm.productDetails = vm.CLAIM_TYPES[vm.claimType.id];
    /* Microservices */
    MicroserviceType.get().$promise.then(data => {
      if (data.objects) {
        vm.microservices = data.objects.map(el => ({ id: el.id, name: el.name }));
      }
    });
    /* Notes */
    LegalServiceNotes.get({ claim__id: vm.claim.id }).$promise.then(data => {
      vm.legalServiceNotes = data.objects;
      vm.lastNote = vm.legalServiceNotes[vm.legalServiceNotes.length - 1];
    });
  }

  function postRefreshClaimCB() {
    vm.getMicroserviceHour();
  }

  fileUploaderGenerator({
    vm: vm,
    url: serverAddress.getDocumentUrl() + 'common/upload/',
    fillItemData: function (item) {
      vm.busy = true;
      item.formData = [
        {
          type_id: (item.documenType && item.documenType.id) || 0,
          comment: item.comment || '',
          claim_id: vm.claim.id,
          segment: vm.DJANGO_MODEL
        }
      ];
    },
    sucessCallback: () => {
      vm.claimDetail.update('Archivo subido con éxito, actualizando reclamación');
    }
  });

  /* Microservices management */
  function getMicroserviceHour() {
    MicroserviceHour.get({ claim__id: vm.claim.id }).$promise.then(data => {
      if (data.objects) {
        vm.microserviceHours = data.objects;
      }
    });
  }
  function updateMicroServiceHour(item, data) {
    const id = !!item.id ? item.id : null;
    MicroserviceHour.update({ id }, {})
      .$promise.then(() => {
        toastr.info('Consulta borrada con éxito');
        const budget = vm.budgets.find(item => item.id === id);
        if (budget) budget = data;
      })
      .catch(err => {
        console.error(err);
        toastr.error('No ha sido posible borrar la consulta');
      });
  }

  /* Notes management */
  function addLegalServiceNotes() {
    const newNote = {
      note: ''
    };
    vm.legalServiceNotes.push(newNote);
  }

  function editLegalServiceNotes(item) {
    const id = !!item.id ? item.id : null;
    const method = !!item.id ? 'update' : 'save';
    const newItem = { claim_id: vm.claim.id, created_by_id: $rootScope.user.id, ...item };

    const tmp = Array.from(vm.legalServiceNotes);
    LegalServiceNotes[method]({ id }, newItem)
      .$promise.then(newQ => {
        Object.assign(item, newQ);
        vm.lastNote = item;
        vm.legalServiceNotes = [];
        $timeout(() => {
          vm.legalServiceNotes = [...tmp];
          toastr.info('Nota añadida con éxito');
        }, 0);
      })
      .catch(err => {
        console.error(err);
        toastr.error('No ha sido posible añadir la nota');
      });
  }

  function deleteLegalServiceNotes(note) {
    const result = window.confirm('¿Estás seguro?');
    if (result)
      LegalServiceNotes.delete({ id: note.id })
        .$promise.then(() => {
          toastr.info('Nota borrada con éxito');
          vm.legalServiceNotes = vm.legalServiceNotes.filter(item => item.id !== note.id);
          vm.lastNote = vm.legalServiceNotes[vm.legalServiceNotes.length - 1];
        })
        .catch(err => {
          console.error(err);
          toastr.error('No ha sido posible borrar la nota');
        });
  }

  /* Budget */
  function calculateBudget() {
    const updateAll = () => {
      return Promise.all(
        vm.microserviceHours.map(async item => {
          await _updateMicroserviceHour(item);
        })
      );
    };

    updateAll()
      .then(() => {
        // toastr.info('Horas actualizadas con éxito');
        // Call to calculate budget endpoint
        const budget = {
          discount: vm.claim.discount,
          discount_concept: vm.claim.discount_concept,
          price_per_hour: vm.claim.price_per_hour
        };
        BudgetCalculator.save({ id: vm.claim.id }, budget)
          .$promise.then(data => {
            toastr.success('Presupuesto calculado con éxito');
            vm.claim.calculated_price = data.calculated_price;
            vm.claim.compensation = vm.claim.calculated_price;
          })
          .catch(err => {
            if (err.data && err.data.status) {
              return toastr.error(err.data.status);
            }
            toastr.error('No ha sido posible calcular presupuesto');
          });
      })
      .catch(err => {
        if (err.data && err.data.status) {
          return toastr.error(err.data.status);
        }
        toastr.error('No ha sido posible actualizar horas');
      });
  }

  function _updateMicroserviceHour(element) {
    return MicroserviceHour.update({ id: element.id }, { claim__id: vm.claim.id, ...element })
      .$promise;
  }

  function generateBudget() {
    vm.claimDetail.save().then(() => {
      BudgetReport.get({ id: vm.claim.id })
        .$promise.then(() => {
          const url = '/#' + window.location.href.split('#')[1] + '#documentacion';
          toastr.success(
            `<p class="body"><a href="${url}" class="btn btn-success h4">Ir a documentación</a></p>`,
            'Presupuesto generado con éxito',
            {
              allowHtml: true,
              timeOut: 0
            }
          );
        })
        .catch(err => {
          if (err.data && err.data.status) {
            return toastr.error(err.data.status);
          }
          toastr.error('No ha sido posible generar presupuesto');
        });
    });
  }

  /* Doc helpers */
  function showDocumentMergingModal() {
    var currentDocuments = vm.documents;
    var documentTypes = vm.documentTypes;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: vm.SEGMENT_PREFIX
    };

    var multipleSourceSelectionEnabled = false;
    DialogFactory.mergeDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimData,
      multipleSourceSelectionEnabled,
      function () {
        vm.claimDetail.update('Unión de documentos solicitada. Cuando termine recibirás un email.');
      }
    );
  }

  function showDocumentSlicingModal() {
    var currentDocuments = vm.documents;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: vm.SEGMENT_PREFIX
    };
    DialogFactory.sliceDocument(currentDocuments, claimData, function () {
      vm.claimDetail.update(
        'Separación de documento solicitada. Cuando termine recibirás un email.'
      );
    });
  }

  function showDocumentCopyModal(prefix, type) {
    var currentDocuments = null;
    var documentTypes = vm.documentTypes;
    var multipleSourceSelectionEnabled = false;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: prefix
    };
    var copy_type = type;
    var claimTypes = vm.claimTypes;

    // If the claim has no documents, an error is shown
    if (vm.documents.length === 0) {
      toastr.error(
        'No se ha podido realizar la acción. La reclamación no tiene ningún documento adjunto.'
      );
      return;
    }
    let method = '';

    switch (type) {
      case 'claim':
        method = 'copyDocsToClaimValidation';
        break;
      case 'legalfile':
        method = 'copyDocsToLegalfileValidation';
        break;
      default:
        break;
    }

    try {
      currentDocuments = DialogFactory[method](vm.documents, vm.claim);
    } catch (e) {
      toastr.error(e.message);
      return;
    }

    DialogFactory.copyDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimTypes,
      claimData,
      multipleSourceSelectionEnabled,
      copy_type,
      function () {
        vm.claimDetail.update();
      }
    );
  }

  /**
   *
   * @param {*} showNewCaseModal
   */
  function showNewCaseModal() {
    const data = {
      currentDocuments: vm.documents,
      ...[vm.documentType],
      claimData: {
        id: vm.claim.id,
        prefix: vm.SEGMENT_PREFIX,
        type: vm.claim.type.id
      }
    };
    if (!vm.loadedDocuments) {
      vm.fetchDocuments(true)
        .then(() => {
          data.currentDocuments = vm.documentsResponse;
          DialogFactory.generateNewCase(data);
        })
        .catch(() => {
          notifications.addCurrentView(
            'error',
            `Se produjo un error actualizando los documentos de la ${vm.VERBOSE_NAME.toLowerCase()}`
          );
        });
    } else {
      DialogFactory.generateNewCase(data);
    }
  }
}
