SOClaimDetailCtrl.$inject = [
  '$controller',
  '$scope',
  '$http',
  'notifications',
  'serverAddress',
  'SOConstants',
  'DialogFactory',
  'fileUploaderGenerator'
];
export default function SOClaimDetailCtrl(
  $controller,
  $scope,
  $http,
  notifications,
  serverAddress,
  SOConstants,
  DialogFactory,
  fileUploaderGenerator
) {
  const vm = this;

  const INITIAL_STATE = {
      // TODO: this could be converted to an array instead of an object
      CAUSES: { 0: 'Particular', 1: 'Empresa' },

      total_debts: 0,
      total_liquidities: 0,

      salary_to_add: 0,
      total_to_mediate: 0,
      remission: 0,
      to_refinance: 0,
      years_to_refinance: 5,
      payment_plan: 0,

      total_expenses: 0,

      more_actives_than_detbs: false,

      obj: {},

      debts: [],
      liquidities: [],

      // Switch to disable the report generation if something goes wrong
      generateReportAllowed: false,
      actions: {
        showNewCaseModal
      },
      claim: {
        client: {
          user: {
            is_active: true
          }
        }
      },
      viewCancelledClientPermission: true
    },
    METHODS = {
      postRefreshClaimCB,
      generateReport,
      showDocumentCopyModal,
      setDebtsAmounts,
      setLiqsAmounts,
      updateTotalDebts,
      updateFinalDebts,
      updateTotalLiquidities,
      updateReportGeneration,
      clearCalculations
    };

  $controller('BaseDetailCtrl', { vm: vm, $scope: $scope });

  Object.assign(vm, INITIAL_STATE, METHODS, SOConstants);

  vm.claimDetail.init();

  fileUploaderGenerator({
    vm: vm,
    url: serverAddress.getDocumentUrl() + 'common/upload/',
    fillItemData: function(item) {
      vm.busy = true;

      item.formData = [
        {
          type_id: item.documenType.id || 0,
          comment: item.comment || '',
          claim_id: vm.claim.id,
          segment: vm.DJANGO_MODEL
        }
      ];
    },
    sucessCallback: () => {
      vm.claimDetail.update('Archivo subido con éxito, actualizando reclamación');
    }
  });

  /* Events */
  $scope.$watch('obj.notary_expenses', function() {
    updateFinalDebts();
  });

  // FROM HERE JUST FUNCTIONS => => => => => => => => => =>
  function postRefreshClaimCB() {
    vm.getDocumentTypes();

    vm.clearCalculations();

    // Update amounts
    vm.setDebtsAmounts();
    vm.setLiqsAmounts();

    // Update totals
    vm.updateTotalDebts();
    vm.updateTotalLiquidities();

    vm.updateFinalDebts();

    vm.updateReportGeneration();
  }

  function setDebtsAmounts() {
    if (vm.debttypes) {
      vm.debts = [];
      var name;

      function assignation(value, index) {
        if ('name' in value && value['name'] === name) {
          vm.debts[index] = value['amount'];
        }
      }
      for (let index = 0; index < vm.debttypes.length; index++) {
        name = vm.debttypes[index]['name'];
        vm.claim.debts.forEach(value => assignation(value, index));
      }
    }
  }

  function setLiqsAmounts() {
    if (vm.liqtypes) {
      vm.liquidities = [];
      var name;
      function assignation(value, index) {
        if ('name' in value && value['name'] === name) {
          vm.liquidities[index] = value['amount'];
        }
      }
      for (let index = 0; index < vm.liqtypes.length; index++) {
        name = vm.liqtypes[index]['name'];
        vm.claim.liquidities.forEach(value => assignation(value, index));
      }
    }
  }

  function clearCalculations() {
    vm.total_debts = 0;
    vm.total_liquidities = 0;
    vm.salary_to_add = 0;

    vm.total_to_mediate = 0;
    vm.remission = 0;
    vm.to_refinance = 0;
    vm.years_to_refinance = 5;
    vm.payment_plan = 0;

    vm.total_expenses = 0;
  }

  function updateReportGeneration() {
    if (vm.claim && vm.debts) {
      vm.generateReportAllowed =
        vm.total_debts > vm.total_liquidities &&
        vm.total_debts <= 5000000 &&
        (typeof vm.debts[0] === 'undefined' || vm.debts[0] === 0) &&
        (vm.claim.state.id === 1 || vm.claim.state.id === 82 || vm.claim.state.id === 217);
    }
  }

  function updateTotalDebts() {
    vm.total_debts = 0;
    vm.debts.forEach(value => {
      vm.total_debts += parseFloat(value);
    });
  }

  function updateTotalLiquidities(justCash) {
    vm.total_liquidities = 0;

    var validIds = [1, 2, 3, 4, 5, 6];
    if (justCash) {
      validIds = [1, 3, 4];
    }

    // Avoid adding all the salary to actives. So, we avoid the first element of the array
    var slicedArray = vm.liquidities.slice(1, vm.liquidities.length);

    slicedArray.forEach((value, index) => {
      if (validIds.includes(index + 1)) {
        // +1 because slice operation moved all the elements from their original positions (so, the element in position n in $scope.liquidities is at position n-1 in sliced_array)
        vm.total_liquidities += parseFloat(value);
      }
    });

    // If salary is an active, we add the salaries minus the inembargable part to actives
    if (vm.liquidities[0]) {
      var inembargableSalary = 624 * 12;
      vm.salary_to_add = vm.liquidities[0] - inembargableSalary;

      if (vm.salary_to_add > 0) {
        vm.total_liquidities += vm.salary_to_add;
      } else {
        vm.salary_to_add = 0;
      }
    }
  }

  function updateFinalDebts() {
    // Round the amounts to 2 decimal points by doing:
    // number_2dp = Math.round(100*number) / 100;
    // This technique is called scaling. Check http://stackoverflow.com/a/11832950/593722
    vm.total_to_mediate = Math.round(100 * (vm.total_debts - vm.total_liquidities)) / 100;

    // More actives than debts: use just cash actives
    if (vm.total_to_mediate <= 0) {
      updateTotalLiquidities(true);

      vm.more_actives_than_detbs = true;
    }

    vm.years_to_refinance = 5;

    // TODO: Get rid of this if we finally use this field
    vm.obj.mediator_expenses = 0;

    if (vm.total_to_mediate > 0) {
      vm.remission = Math.round(25 * vm.total_to_mediate) / 100;
      vm.to_refinance = Math.round(100 * (vm.total_to_mediate - vm.remission)) / 100;

      vm.payment_plan = Math.round(100 * (vm.to_refinance / vm.years_to_refinance)) / 100;

      vm.fee = Math.round(10 * vm.remission) / 100;

      // Default values
      if (!vm.obj.notary_expenses) {
        vm.obj.notary_expenses = 0;
      }

      vm.total_expenses =
        Math.round(
          100 * (parseFloat(vm.fee) + parseFloat(vm.obj.notary_expenses) + parseFloat(vm.obj.mediator_expenses))
        ) / 100;

      vm.more_actives_than_detbs = true; // Typo in debts?
    } else {
      // Still more actives than debts... Not possible to generate
      vm.remission = 0;
      vm.to_refinance = 0;
      vm.payment_plan = 0;
      vm.fee = 0;
      vm.total_expenses = 0;

      vm.more_actives_than_detbs = true;
    }
  }

  // TODO: check if this is used somewhere and if not, delete it
  function sendReport() {
    var data = {
      creditors: vm.obj.creditors,
      fee: vm.fee,
      total_debts: vm.total_debts,
      total_actives: vm.total_liquidities,
      total_expenses: vm.total_expenses,
      total_to_mediate: vm.total_to_mediate,
      remission: vm.remission,
      mediator_expenses: vm.obj.mediator_expenses,
      notary_expenses: vm.obj.notary_expenses,
      to_refinance: vm.to_refinance,
      years_to_refinance: vm.years_to_refinance,
      payment_plan: vm.payment_plan
    };

    // Now generate report
    $http({
      url: serverAddress.getBaseUrl() + 'ley-segunda-oportunidad/' + vm.claim_id + '/generate-client-report/',
      method: 'POST',
      data: data
    })
      .then(function(response) {
        if (vm.more_actives_than_detbs) {
          vm.claimDetail.update(
            'Informe generado. ATENCIÓN: al ser la suma de los activos mayor que la deuda, en el cálculo del informe sólo se han tenido en cuenta los activos en efectivo'
          );
        } else {
          vm.claimDetail.update(response.data);
        }
      })
      .catch(function(response) {
        notifications.addCurrentView('error', response.data);
      });
  }

  function generateReport() {
    if (vm.claim) {
      notifications.addCurrentView('info', 'Generando informe. Por favor, espera un momento. Ya casi lo tenemos...');
      // First, save claim values and then call send report
      // TODO: running both functions may produce inconsistent results due to their asynchronous nature.
      vm.claimDetail.save();
      sendReport();
    } else {
      notifications.addCurrentView('error', 'No se ha podido cargar la reclamacion correctamente');
    }
  }

  function showDocumentCopyModal(prefix, type) {
    var currentDocuments = null;
    var documentTypes = vm.documentTypes;
    var claimTypes = vm.claimTypes;
    var multipleSourceSelectionEnabled = false;
    var copy_type = type;
    var claimData = {
      claim_id: vm.claim_id,
      claim_prefix: prefix
    };

    // If the claim has no documents, an error is shown
    if (vm.documents.length === 0) {
      notifications.addCurrentView(
        'error',
        'No se ha podido realizar la acción. La reclamación no tiene ' + 'ningún documento adjunto.'
      );
      return;
    }

    if (type === 'claim') {
      try {
        currentDocuments = DialogFactory.copyDocsToClaimValidation(vm.documents);
      } catch (e) {
        notifications.addCurrentView('error', e);
        return;
      }
    } else {
      try {
        currentDocuments = DialogFactory.copyDocsToLegalfileValidation(vm.documents, vm.claim);
      } catch (e) {
        notifications.addCurrentView('error', e);
        return;
      }
    }

    DialogFactory.copyDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimTypes,
      claimData,
      multipleSourceSelectionEnabled,
      copy_type,
      function() {
        vm.claimDetail.update();
      }
    );
  }

  /**
 * 
 * @param {*} showNewCaseModal 
 */
  function showNewCaseModal() {
    const data = {
      currentDocuments: vm.documents,
      ...[vm.documentType],
      claimData: {
        id: vm.claim.id,
        prefix: vm.SEGMENT_PREFIX,
        type: vm.claim.type.id
      }
    };
    if (!vm.loadedDocuments) {
      vm.fetchDocuments(true)
        .then(() => {
          data.currentDocuments = vm.documentsResponse;
          DialogFactory.generateNewCase(data);
        })
        .catch(() => {
          notifications.addCurrentView('error', `Se produjo un error actualizando los documentos de la ${vm.VERBOSE_NAME.toLowerCase()}`);
        });
    } else {
      DialogFactory.generateNewCase(data);
    }
  }
}
