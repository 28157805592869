LegalServiceNotes.$inject = ['$resource', 'serverAddress'];

export default function LegalServiceNotes($resource, serverAddress) {
  return $resource(
    `${serverAddress.getBaseUrl()}api2/v1/legalservice_notes/:id/`,
    {},
    {
      update: {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json;' }
      }
    }
  );
}
