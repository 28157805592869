import angular from 'angular';

import './claim-tabs';
import './claim-info-blocks';

angular
  .module('reclamador.components.claim', [
    'reclamador.components.claim.info',
    'reclamador.components.claim.tabs'
  ]);
