BankSalesPeoplePanelCtrl.$inject = [
  '$scope',
  '$http',
  'serverAddress',
  'SalesClaimState',
  'BankSalesman',
  'ClaimState',
  '$route',
  '$q',
  '$timeout',
  '$window',
  'notifications'
];

export default function BankSalesPeoplePanelCtrl(
  $scope,
  $http,
  serverAddress,
  SalesClaimState,
  BankSalesman,
  ClaimState,
  $route,
  $q,
  $timeout,
  $window,
  notifications
) {
  var vm = this;

  vm.busy = true;
  $scope.$on('ngRepeatFinished', $window.rclmng_globals.ux.pepinColFixer.resizeTables);

  vm.layoutSettings = $route.current.$$route.layoutSetting;
  vm.is_extern = false;

  $q
    .all([
      $http.get(serverAddress.getBaseUrl() + 'bank-claim/salespeople/counts/'),
      BankSalesman.get().$promise,
      ClaimState.get({ segments__id: 2 }).$promise,
      SalesClaimState.get().$promise
    ])
    .then(
      function(data) {
        vm.salesman = data[1];
        vm.counts = data[0].data;

        // parse Claimstate
        vm.states = data[2].objects;
        vm.bank_sales_states = [];

        var validStates = [26, 130, 61, 6, 36, 72, 74, 63, 200];
        for (var i = 0; i < vm.states.length; i++) {
          if (validStates.indexOf(vm.states[i].id) > -1) {
            vm.bank_sales_states.push(vm.states[i]);
          }
        }

        // parse salestate
        vm.sale_states = data[3].objects;
        vm.bank_real_sales_states = [];
        var validStates = [264, 262, 263, 27, 231, 117, 228, 273];
        for (var j = 0; j < vm.sale_states.length; j++) {
          if (validStates.indexOf(vm.sale_states[j].id) > -1) {
            vm.bank_real_sales_states.push(vm.sale_states[j]);
          }
        }

        // ux update
        vm.busy = false;
        $timeout($window.rclmng_globals.ux.pepinColFixer.init);
      },
      function() {
        notifications.addCurrentView('error', 'Lo sentimos, se produjo un error solicitando la informe.');
        vm.busy = false;
      }
    );
}