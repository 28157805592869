LawyersList.$inject = ['Lawyer', 'BankLawyer', 'AccidentLawyer', 'SOLawyer', 'PhoneLawyer', 'LaboralLawyer', 'TaxesLawyer', 'NegligencesLawyer'];

export default function LawyersList(Lawyer, BankLawyer, AccidentLawyer, SOLawyer, PhoneLawyer, LaboralLawyer, TaxesLawyer, NegligencesLawyer) {
  return {
    airlineGroup: Lawyer,
    bankGroup: BankLawyer,
    accidentGroup: AccidentLawyer,
    soGroup: SOLawyer,
    phoneGroup: PhoneLawyer,
    laboralGroup: LaboralLawyer,
    taxesGroup: TaxesLawyer,
    negligencesGroup: NegligencesLawyer
  };
}
