
import angular from 'angular';

import AccidentClaimDetailCtrl from './claimDetailCtrl';
import AccidentClaimListCtrl from './claimListCtrl';
import AccidentLawyerPanelCtrl from './lawyerPanelCtrl';

import AccidentClaim from './claimService';
import AccidentSalesman from './salesmanService';
import AccidentLawyer from './lawyerService';

import AccidentRoutes from './routes';
import AccidentConstants from './constants';

angular
  .module('reclamador.segments.accident', [])

  .controller('AccidentClaimDetailCtrl', AccidentClaimDetailCtrl)
  .controller('AccidentClaimListCtrl', AccidentClaimListCtrl)
  .controller('AccidentLawyerPanelCtrl', AccidentLawyerPanelCtrl)

  .factory('AccidentClaim', AccidentClaim)
  .factory('AccidentSalesman', AccidentSalesman)
  .factory('AccidentLawyer', AccidentLawyer)
  .constant('AccidentConstants', AccidentConstants)

  .config(AccidentRoutes);
