documentViewerKeyControls.$inject = ['$document'];

export default function documentViewerKeyControls($document) {
  return {
    scope: {
      goPrev: '&',
      goNext: '&',
      close: '&'
    },
    restrict: 'A',
    link: function(scope) {
      const myHandler = function(e) {
        if (e.key == 'ArrowLeft') {
          scope.$apply(() => scope.goPrev());
        } else if (e.key == 'ArrowRight') {
          scope.$apply(() => scope.goNext());
        } else if (e.key == 'Escape') {
          scope.$apply(() => scope.close());
        }
      };

      scope.$on('viewer:init', () => {
        $document.on('keyup', myHandler);
      });

      scope.$on('viewer:remove', () => {
        $document.off('keyup', myHandler);
      });

      scope.$on('$destroy', function () {
        $document.off('keyup', myHandler);
      });
    }
  };
}
