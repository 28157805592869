DocTools.$inject = [
  '$sce',
  '$http',
  '$window',
  'serverAddress',
  'DocumentChangeFactory',
  'DocumentDownloadFactory',
  'DialogFactory',
  'PermissionsService'
];

export default function DocTools(
  $sce,
  $http,
  $window,
  serverAddress,
  DocumentChangeFactory,
  DocumentDownloadFactory,
  DialogFactory,
  PermissionsService
) {
  return {
    isPdf,
    isDoc,
    isImage,
    checkFileExtension,
    renameDocument,
    changeDocumentType,
    downloadDocument,
    deleteDocument,
    documenthasViewPermission,
    openInDrive,
    validateDocument,
    getExtension,
    isDoc,
    isPdf,
    isImage,
    getViewerSafeUrl,
    getSafeUrl
  };

  function isPdf(filename) {
    if (!filename) return false;
    return getExtension(filename) == 'pdf';
  }

  function isDoc(filename) {
    if (!filename) return false;
    return ['doc', 'docx', 'rtf', 'txt', 'xsl', 'xlsx'].includes(getExtension(filename));
  }

  function isImage(filename) {
    if (!filename) return false;
    return ['jpg', 'jpeg', 'png', 'bmp'].includes(getExtension(filename));
  }

  function checkFileExtension(doc) {
    if (!doc || doc.url === null) return {}; // Error

    const returnObj = {
      url: '',
      isPdf: false,
      isImage: false,
      isDoc: false,
      isOther: false
    };

    if (isDoc(doc.filename)) {
      returnObj.url = getViewerSafeUrl(doc.url);
      returnObj.isDoc = true;
    } else if (isPdf(doc.filename)) {
      returnObj.url = getSafeUrl(doc.url);
      returnObj.isPdf = true;
    } else if (isImage(doc.filename)) {
      returnObj.url = getSafeUrl(doc.url);
      returnObj.isImage = true;
    } else {
      returnObj.url = getSafeUrl(doc.url);
      returnObj.isOther = true;
    }

    return returnObj;
  }

  function getViewerSafeUrl(url) {
    return $sce.trustAsResourceUrl(
      'https://docs.google.com/gview?url=' + encodeURIComponent(url) + '&embedded=true'
    );
  }

  function getSafeUrl(url) {
    return $sce.trustAsResourceUrl(url);
  }

  function getExtension(filename) {
    if(!filename) return;
    return filename
      .split('.')
      .pop()
      .toLowerCase();
  }

  function documenthasViewPermission(doc, segmentPrefix) {
    // This is far from perfect, but it works for a binary security model (can / cannot see doc)
    if (doc.type && parseInt(doc.type.minimum_security_level, 10) > 1) {
      return PermissionsService.hasPermission(`segment-perobject-${segmentPrefix}-crm_view_secure_documents`);
    }

    return true;
  }

  function renameDocument(docData, entityData) {
    // TODO: Flight documents throw exception
    docData.itemData = entityData;

    return $http.post(serverAddress.getBaseUrl() + 'common/rename-document/', docData);
  }

  function changeDocumentType(docData, typeId, claimClass, docGroup, claim) {
    if(docGroup == 'flightDocs') {
      return DocumentChangeFactory.changeFlightDocumentType(docData.id, typeId);
    } else if(docGroup == 'fileDocs') {
      return DocumentChangeFactory.changeLegalFileDocumentType(docData.id, typeId, claim.legalfile.id);
    }
    return DocumentChangeFactory.changeClaimDocumentType(docData.id, typeId, claimClass, claim.id);
  }

  function downloadDocument(doc) {
    DocumentDownloadFactory.downloadDocumentDirectly(doc);
  }

  function deleteDocument(doc) {
    DialogFactory.deleteDocument(doc, 'ConfirmClaimDocumentDeleteCtrl');
  }

  function openInDrive(drive_key) {
    $window.open('https://docs.google.com/a/reclamador.es/document/d/' + drive_key);
  }

  function validateDocument(documentId, djangoModel, validate) {
    return $http({
      url: serverAddress.getDocumentUrl() + 'common/claim/documents/validate/',
      method: 'POST',
      data: {
        django_class: djangoModel,
        document_id: documentId,
        validate: validate
      }
    });
  }
}
