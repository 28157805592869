LegalFile.$inject = ['$resource', 'serverAddress'];

export default function LegalFile($resource, serverAddress) {
  const LEGAL_FILE_PATH = 'api2/v1/legalfile/:id/?q=:filter&:query';
  return $resource(
    serverAddress.getBaseUrl() + LEGAL_FILE_PATH,
    {
      filter: '',
      query: ''
    },
    {
      update: {
        method: 'PATCH',
        params: {},
        isArray: false
      }
    }
  );
}
