VCPhoneQuestions.$inject = ['$resource', 'serverAddress'];

export default function VCPhoneQuestions($resource, serverAddress) {
  return $resource(
    serverAddress.getBaseUrl() + 'api2/v1/videocall-phone-questions/:id/',
    {    
      offset: 0,
      limit: 0
    },
    {
      update: {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json;' }
      }
    }
  );
}
