import angular from 'angular';
import './styles.css';
import AssigmentModalStep1Ctrl from './assigmentModalStep1Ctrl';
import AssigmentModalStep2Ctrl from './assigmentModalStep2Ctrl';
import AssigmentModalStep1Service from './assigmentModalStep1Service';
import AssigmentModalStep2Service from './assigmentModalStep2Service';
import AssigmentModalShared from './assigmentModalShared.factory';

angular
  .module('reclamador.components.assigmentModal', [])
  .component('assigmentModalStep1', {
    templateUrl: 'js/components/assignment-modal/assigment-modal-step-1.html',
    controller: AssigmentModalStep1Ctrl,
    controllerAs: 'vm'
  })
  .component('assigmentModalStep2', {
    templateUrl: 'js/components/assignment-modal/assigment-modal-step-2.html',
    controller: AssigmentModalStep2Ctrl,
    controllerAs: 'vm'
  })
  .service('AssigmentModalStep1Service', AssigmentModalStep1Service)
  .service('AssigmentModalStep2Service', AssigmentModalStep2Service)
  .factory('AssigmentModalShared', AssigmentModalShared);
