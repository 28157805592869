/* eslint-disable angular/di */
import angular from 'angular';

import SOClaimDetailCtrl from './claimDetailCtrl';
import SOClaimListCtrl from './claimListCtrl';
import SOLawyerPanelCtrl from './lawyerPanelCtrl';

import SOClaim from './claimService';
import SOLawyer from './lawyerService';
import SOSalesman from './salesmanService';
import SODebtType from './debtTypeService';
import SOLiqType from './liqTypeService';

import SORoutes from './routes';
import SOConstants from './constants';

angular
  .module('reclamador.segments.so', [])

  .controller('SOClaimDetailCtrl', SOClaimDetailCtrl)
  .controller('SOClaimListCtrl', SOClaimListCtrl)
  .controller('SOLawyerPanelCtrl', SOLawyerPanelCtrl)

  .factory('SOClaim', SOClaim)
  .factory('SOLawyer', SOLawyer)
  .factory('SOSalesman', SOSalesman)

  .factory('SODebtType', SODebtType)
  .factory('SOLiqType', SOLiqType)
  .constant('SOConstants', SOConstants)

  .config(SORoutes);
