fileUploaderGenerator.$inject = ['FileUploader', 'AuthService'];

export default function fileUploaderGenerator(FileUploader, AuthService) {
  return function(config) {
    if (!config || !config.url) {
      throw new Error('fileUploaderGenerator initiated without proper configuration');
    }

    let defaults = {
      headers: {
        Authorization: AuthService.getAPIKey()
      },
      removeAfterUpload: true,
      onBeforeUploadItem: function(item) {
        // allow to modify the item before sending
        if (config.fillItemData) config.fillItemData(item);
      },
      onErrorItem: function(item, response, status, headers) {
        if (config.errorCallback) config.errorCallback(item, response, status, headers);
      },
      onSuccessItem: function(item, response, status, headers) {
        if (config.sucessCallback) config.sucessCallback(item, response, status, headers);
      },
      onCancelItem: function(item, response, status, headers) {
        if (config.cancelCallback) config.cancelCallback(item, response, status, headers);
      },
      onAfterAddingFile: function() {
        if (config.onAfterAddingFile) config.onAfterAddingFile();
      },
      onCompleteAll: function() {
        if (config.onCompleteAll) config.onCompleteAll();
      },
      onAfterAddingAll: function() {
        if (config.onAfterAddingAll) config.onAfterAddingAll();
      }
    };

    return new FileUploader(Object.assign(defaults, config));
  };
}
