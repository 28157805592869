AuthService.$inject = [
  '$http',
  'serverAddress',
  '$q',
  '$location',
  '$rootScope',
  '$localStorage',
  'User',
  'Profiles',
  'Worker',
  'notifications',
  'toastr',
  '$timeout',
  'PermissionsService'
];
// service to log, unlog, and provide user data
export default function AuthService(
  $http,
  serverAddress,
  $q,
  $location,
  $rootScope,
  $localStorage,
  User,
  Profiles,
  Worker,
  notifications,
  toastr,
  $timeout,
  PermissionsService
) {
  let recoveringSession$ = null;
  // interface
  return {
    init,
    login,
    logout,
    getUser,
    getWorker,
    getWorkerData,
    getgroupsFromUser,
    resetUser,
    loginConfirmed,
    loginCancelled,
    getAPIKey,
    setAPIKey,
    restoreSesionUrl
  };

  // Initialize User data
  function init() {
    $rootScope.$on('$routeChangeSuccess', (scope, next) => {
      if (next.$$route.originalPath === '/user/claims-panel') {
        if (!$rootScope.user) {
          $timeout(() => $location.path('login'));
        }
      }
    });

    $rootScope.$on('event:auth-logout', () => {
      logout();
    });
    $rootScope.$on('event:password-reset', (ev, email) => {
      Profiles.reset(email);
    });

    $rootScope.$on('event:auth-forbidden', () => {
      notifications.clear();
      notifications.addCurrentView(
        'error',
        'No tienes permisos para acceder a este recurso. Por favor, contacta con el equipo de Tecnología.'
      );
    });

    $rootScope.$on('event:auth-loginRequired', () => {
      notifications.clear();
      notifications.addCurrentView(
        'error',
        'Combinación usuario/contraseña errónea. Por favor, verifica tus credenciales.'
      );
      $location.path('login');
    });

    const offInvalidVersionNotice = $rootScope.$on('event:auth-invalidVersion', () => {
      const body =
        'Se ha detectado una nueva versión de esta aplicación. Por favor, refresca el navegador (Ctrl+F5 o tecla Fn+F5) para poder disfrutar de las nuevas funcionalidades.';
      toastr.warning(body, 'Aviso nueva versión', {
        timeOut: 0,
        preventDuplicates: true
      });
      offInvalidVersionNotice();
    });

    $rootScope.$on('event:unauthorized', () => {
      $location.path('/no-permissions');
    });
    if (!recoveringSession$) {
      recoveringSession$ = _recoverUserSession();
    }
    return recoveringSession$;
  }

  function getUser() {
    if ($rootScope.user) {
      return $q.resolve($rootScope.user);
    }
    if (!recoveringSession$) {
      recoveringSession$ = _recoverUserSession();
    }
    return recoveringSession$;
  }

  async function _recoverUserSession() {
    let user = {};
    const deferred = $q.defer();
    try {
      const apiKey = getAPIKey();
      $rootScope.prevPath = $location.path();
      $location.path('/loading');
      if (apiKey) {
        user.username = $localStorage.get('username');
        if (!user.username) throw new Error('_checkUser::invalid user object');

        const userDataResponse = await User.get({ query: `username=${user.username}` }).$promise;
        const userDataComplete = userDataResponse.objects[0];
        $rootScope.user = userDataComplete;

        // Get user permissions and make them available globally.
        const userPermissionsResponse = await Profiles.getPermissions(userDataComplete.id);
        const userPermissionsComplete = userPermissionsResponse.data;
        userPermissionsComplete.ready = true;
        $rootScope.userPermissions = userPermissionsComplete;

        const worker = await getWorker(userDataComplete);
        $rootScope.worker = worker;

        deferred.resolve({ user: userDataComplete, permissions: userPermissionsComplete });
        $rootScope.$broadcast('event:auth-session', userDataComplete);
        restoreSesionUrl();
      } else {
        throw new Error('catch::User no logged');
      }
    } catch (error) {
      console.error('_recoverUserSession::', error);
      if (
        !$rootScope.prevPath.includes('/password-reset-confirm/') &&
        !$rootScope.prevPath.includes('/password-reset/') &&
        !$rootScope.prevPath.includes('/activate-account/')
      ) {
        $location.path('login');
      } else {
        $location.path($rootScope.prevPath);
      }
    }
    return deferred.promise;
  }

  function restoreSesionUrl() {
    const prevPath = $rootScope.prevPath;
    const excludedRoutes = ['/login', '/loading'];
    if (prevPath && !excludedRoutes.includes(prevPath)) {
      $rootScope.prevPath = null;
      $timeout(() => $location.path(prevPath));
    } else {
      $location.path('/');
    }
  }

  /**
   * Call this function to indicate that authentication was successful and trigger a
   * retry of all deferred requests.
   * @param data an optional argument to pass on to $broadcast which may be useful for
   * example if you need to pass through details of the user that was logged in
   */
  function loginConfirmed(data) {
    notifications.clear();
    $rootScope.$broadcast('event:auth-loginConfirmed', data);
  }
  /**
   * Call this function to indicate that authentication should not proceed.
   * All deferred requests will be abandoned or rejected (if reason is provided).
   * @param data an optional argument to pass on to $broadcast.
   * @param reason if provided, the requests are rejected; abandoned otherwise.
   */
  function loginCancelled() {
    resetUser();
    $rootScope.$broadcast('event:auth-loginCancelled');
  }

  function login(credentials) {
    recoveringSession$ = null;
    const promise = $http.post(`${serverAddress.getBaseUrl()}api2/v1/apikey/`, {
      username: credentials.username,
      password: credentials.password
    });

    promise
      .then(responseLogin => {
        setAPIKey(responseLogin.data);
        return init();
      })
      .then(userResponse => {
        PermissionsService.setAll(userResponse.permissions);
        restoreSesionUrl();
        loginConfirmed(userResponse);
      })
      .catch(err => {
        console.error(err);
        loginCancelled();
      });
    return promise;
  }

  function logout() {
    $rootScope.prevPath = $location.path();
    loginCancelled();
    $location.path('login');
  }

  function resetUser() {
    $rootScope.user = null;
    $localStorage.remove('username');
    $localStorage.remove('token');
  }

  function getWorker(data) {
    const deferred = $q.defer();

    if ($rootScope.worker) {
      deferred.resolve($rootScope.worker);
    } else {
      Worker.get({ id: data.worker })
        .then(response => {
          const worker = Object.assign(
            {
              username: $rootScope.user.username,
              date_joined: $rootScope.user.date_joined,
              last_login: $rootScope.user.date_joined,
              is_staff: $rootScope.user.is_staff,
              is_active: $rootScope.user.is_active,
              email: $rootScope.user.email
            },
            response.data
          );
          $rootScope.$emit('event:worker-info', worker);
          deferred.resolve(worker);
        })
        .catch(() => {
          notifications.addCurrentView('error', 'Error al obtener información de tu perfil');
        });
    }
    return deferred.promise;
  }

  function getWorkerData() {
    return recoveringSession$;
  }

  function getgroupsFromUser(userData) {
    if (userData && userData.groups) {
      return userData.groups.reduce((v, e) => {
        v[e.name] = true;
        return v;
      }, {});
    }
    return {};
  }

  function getAPIKey() {
    const user = {};
    user.token = $localStorage.get('token');
    user.username = $localStorage.get('username');
    if (user.token && user.username) {
      return `ApiKey ${user.username}:${user.token}`;
    }
    return '';
  }

  function setAPIKey(user) {
    if (!user.hasOwnProperty('key') || !user.hasOwnProperty('username')) {
      return;
    }
    $localStorage.set('token', user.key);
    $localStorage.set('username', user.username);
  }


}
