Documents.$inject = ['$resource', 'serverAddress'];

var DOCUMENTS_PATH = 'api2/v1/document/:documentId/?format=json';

// TODO: add test case to check that the $update function is defined
export default function Documents($resource, serverAddress) {
  return $resource(
    serverAddress.getBaseUrl() + DOCUMENTS_PATH,
    {},
    {
      update: {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json;' }
      }
    }
  );
}
