notifications.$inject = ['$rootScope', '$timeout'];

export default function notifications($rootScope, $timeout) {
  const notifications = [];

  $rootScope.$on('autoremove', (event, not) => remove(not));

  // interface
  return {
    get: function() {
      return notifications;
    },

    addCurrentView: function(type, msg, time) {
      add(new Notification(type, msg, time, false));
    },
    addNextView: function(type, msg, time) {
      add(new Notification(type, msg, time, true));
    },

    clear: function() {
      for (var i = notifications.length - 1; i >= 0; i--) {
        remove(notifications[i]);
      }
      $rootScope.$broadcast('clearNotifications');
    }
  };

  function add(noti) {
    if (!checkRepeated(noti)) {
      notifications.push(noti);
      $rootScope.$broadcast('updateNotifications');
      noti.start();
      if (noti.timer$) {
        noti.timer$.then(() => {
          remove(noti);
          $rootScope.$broadcast('updateNotifications');
        });
      }
    }
  }
  function remove(noti) {
    noti.stop();
    return notifications.splice(notifications.indexOf(noti), 1);
  }

  function checkRepeated(notification) {
    const same = notifications.filter(noti => noti.type === notification.type && noti.msg === notification.msg);
    return same.length !== 0;
  }

  // notification object
  function Notification(
    type = 'warning',
    msg = 'Lo sentimos, se produjo un error indeterminado',
    time = null,
    nextview
  ) {
    const settings = {
      defaultTimeout: 5000,
      alwaysTimeout: false // allow perpetual notifications
    };

    this.type = type;
    this.msg = msg;
    this.nextView = nextview;
    this.timer$ = null;
    this.time = time;

    // if (('success', 'info').includes(this.type)) { // Not working in some cases. :O Babel transpilation?
    if (this.type === 'success' || this.type === 'info') {
      this.time = time || settings.defaultTimeout;
    }

    this.stop = function() {
      if (this.timer$) {
        $timeout.cancel(this.timer$);
      }
    };

    this.start = function() {
      if (this.time) {
        this.timer$ = $timeout(() => console.log(this.msg), this.time);
      }
    };

    this.autoremove = function() {
      $rootScope.$emit('autoremove', this);
    };

    return this;
  }
}
