DocumentTransformationFactory.$inject = ['$http', 'serverAddress'];

export default function DocumentTransformationFactory($http, serverAddress) {
  var MERGE_DOCUMENTS_PATH = 'common/claim/documents/merge/';
  var SLICE_DOCUMENT_PATH = 'common/claim/documents/slice/';

  var service = {
    mergeDocuments: mergeDocuments,
    sliceDocument: sliceDocument
  };

  return service;

  function mergeDocuments(claimData, newDocumentData) {
    var targetUrl = serverAddress.getBaseUrl() + MERGE_DOCUMENTS_PATH;

    return $http.post(targetUrl, {
      claim_id: claimData['claim_id'],
      claim_prefix: claimData['claim_prefix'],
      new_doc_items: newDocumentData['doc_items'],
      new_doc_type_id: newDocumentData['doc_type_id'],
      new_doc_name: newDocumentData['doc_name'],
      new_doc_comment: newDocumentData['doc_comment']
    });
  }

  function sliceDocument(claimData, slicingData) {
    var targetUrl = serverAddress.getBaseUrl() + SLICE_DOCUMENT_PATH;

    return $http.post(targetUrl, {
      claim_id: claimData['claim_id'],
      claim_prefix: claimData['claim_prefix'],
      doc_to_split_id: slicingData['doc_to_split_id'],
      doc_to_split_type_id: slicingData['doc_to_split_type_id'],
      pages: slicingData['pages'],
      rotations: slicingData['rotations']
    });
  }
}
