import angular from 'angular';

import './styles.css';

FlightCertificatesCtrl.$inject = ['$scope', 'FlightFactory', 'notifications'];

angular
  .module('reclamador.components.flightCertificates', [])
  .controller('FlightCertificatesCtrl', FlightCertificatesCtrl)
  .component('flightCertificates', {
    bindings: {
      flight: '<',
      busy: '=',
      updateClaimCb: '<'
    },
    controller: 'FlightCertificatesCtrl',
    controllerAs: 'vm',
    template: require('./template.html')
  });

function FlightCertificatesCtrl ($scope, FlightFactory, notifications) {
  const vm = this;

  Object.assign(vm, {
    // bindings
    flight: null,
    updateClaimCb: null,

    // Internal state
    loadingFlightstatsData: false,
    attachingScreenshot: false,

    // data from to endpoints
    flightstatsData: null,

    // methods
    initFlightstatsData,
    updateFlightDocument
  });

  // set initial watch to update data if flight binding changes
  vm.$onInit = () => {
    $scope.$watch('vm.flight', function(flight){
      if(!flight) return;
      vm.initFlightstatsData(flight);
    });
  };

  // request Flightstats scraped data
  function initFlightstatsData(flight) {
    vm.loadingFlightstatsData = true;
    FlightFactory.getFlightStatsData(flight.id)
      .then(function(response) {
        vm.flightstatsData = !response.data.error ? response.data.data : false;
        vm.loadingFlightstatsData = false;
      })
      .catch(function() {
        console.error('Se produjo un error cargando la información del Flightstats.');
        vm.loadingFlightstatsData = false;
      });

  }

  // request update flight document
  function updateFlightDocument(flight, url) {
    vm.attachingScreenshot = true;
    FlightFactory.updateFlightDocument(flight.id, url)
      .then(function(response) {
        if (response.data.status == 'ok') {
          vm.updateClaimCb('Captura adjuntada correctamente');
        } else {
          notifications.addCurrentView('error', 'Se produjo un error adjuntando la captura.');
        }
        vm.attachingScreenshot = false;
      })
      .catch(function() {
        console.error('Se produjo un error adjuntando la captura.');
        vm.attachingScreenshot = false;
      });

  }
}