BaseCtrl.$inject = ['$route', '$routeParams', 'constantsFactory'];
export default function BaseCtrl($route, $routeParams, constantsFactory) {
  const vm = this;
  const segmentTitle = constantsFactory.getSegmentAlias($routeParams.segmentPrefix).title;
  if ($route.current.$$route.layoutSetting) {
    $route.current.$$route.layoutSetting.contentTitle = segmentTitle;
  }
  const INITIAL_STATE = {
    layoutSettings: $route.current.$$route.layoutSetting
  };
  Object.assign(vm, INITIAL_STATE);
}
