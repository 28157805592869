import angular from 'angular';
import AnswersCtrl from './AnswersCtrl';
import Answers2Ctrl from './Answers2Ctrl';

angular
  .module('reclamador.components.claim.info.detail', [])
  .controller('AnswerCtrl', AnswersCtrl)
  .controller('Answer2Ctrl', Answers2Ctrl)
  .component('clientAnswers', {
    bindings: {
      claim: '<',
      segmentid: '<',
      productid: '<',
      url: '<'
    },
    controller: AnswersCtrl,
    controllerAs: 'vm',
    template: `
    <h3 class="form-section">Formulario del cliente</h3>
    <div class="row">
      <table class="table answers col-xl-8 col-md-10">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Pregunta</th>
            <th scope="col">Respuesta del cliente</th>
          </tr>
        </thead>
        <tbody>
          <tr ng-repeat="(key, value) in vm._answers track by key">
            <th scope="row">
              {{value.question}}
            </th>
            <td>
              {{value.answer}}
            </td>
          </tr>
        </tbody>
      </table>
    </div> 
  `
  })
  .component('clientAnswers2', {
    bindings: {
      claim: '<',
      segmentid: '<',
      productid: '<',
      url: '<'
    },
    controller: Answers2Ctrl,
    controllerAs: 'vm',
    template: `
    <h3 class="form-section">Formulario del cliente</h3>
    <div class="row">
      <table class="table answers col-xl-8 col-md-10">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Pregunta</th>
            <th scope="col">Respuesta del cliente</th>
          </tr>
        </thead>
        <tbody>
          <tr ng-repeat="(key, value) in vm._answers track by key">
            <th scope="row">
              {{value.question}}
            </th>
            <td class="answer">
              <div ng-repeat="(keyblock, block) in value.blocks track by $index" class="border m-1">
                <div ng-if="block.options && block.type === 'radio'" class="m-2 radio" ng-repeat="(k, v) in block.options | orderBy:'order'">
                  <input type="radio" data-ng-value="''+v.value" data-ng-model="block.value" disabled /> {{v.label}}
                </div>
                <div ng-if="block.options && block.type === 'checkbox'" class="m-2 radio" ng-repeat="(k, v) in block.options | orderBy:'order'">
                  <input type="checkbox" data-ng-value="''+v.value" data-ng-model="block.value" disabled /> {{v.label}}
                </div>
                <div ng-if="block.type=='number'" class="m-2">{{block.value}}</div>
                <div ng-if="block.type=='phone'" class="m-2">{{block.value}}</div>
                <div ng-if="block.type=='textarea'" class="m-2">{{block.value}}</div>
                <div ng-if="block.type=='selectized'" class="m-2">
                  {{vm.getAnswerValue(block.value, block.options, 'selectized')}}
                </div>
                <div ng-if="block.type=='select'" class="m-2">
                  {{vm.getAnswerValue(block.value, block.options, 'select')}}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div> 
  `
  });

