routeProviderConfig.$inject = ['$routeProvider'];
export default function routeProviderConfig($routeProvider) {
  /* LOGIN */
  $routeProvider.when('/login', {
    title: 'Inicio de sesión',
    templateUrl: 'js/modules/auth/partials/login.html',
    controller: 'LoginCtrl',
    controllerAs: 'vm'
  });
  $routeProvider.when('/no-permissions', {
    title: 'Panel de reclamaciones de usuario',
    template: '<div><h1>No tienes permisos para acceder a esta parte del CRM.</h1></div>',
    controller: 'BaseCtrl',
    controllerAs: 'vm'
  });

  /* PASSWORD RESET */
  $routeProvider.when('/password-reset', {
    title: 'Recuperar contraseña',
    templateUrl: 'js/modules/auth/partials/password-reset.html',
    controller: 'PasswordResetCtrl',
    controllerAs: 'vm'
  });

  $routeProvider.when('/password-reset-confirm/:uidb36/:token', {
    title: 'Establecer nueva contraseña',
    templateUrl: 'js/modules/auth/partials/password-reset-confirm.html',
    controller: 'PasswordResetConfirmCtrl',
    controllerAs: 'vm'
  });

  /* Activate account */
  $routeProvider.when('/activate-account/:uidb36/:token', {
    title: 'Activar cuenta de usuario',
    templateUrl: 'js/modules/auth/partials/activate-account.html',
    controller: 'ActivateAccountCtrl',
    controllerAs: 'vm'
  });
}
