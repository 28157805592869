AirlinesLawyersPanelCtrl.$inject = [
  '$scope',
  '$window',
  '$route',
  'Lawyer',
  'ClaimState',
  'notifications',
  'PanelTools',
  '$q',
  '$timeout'
];

export default function AirlinesLawyersPanelCtrl(
  $scope,
  $window,
  $route,
  Lawyer,
  ClaimState,
  notifications,
  PanelTools,
  $q,
  $timeout
) {
  var vm = this;

  /* Variables */
  vm.layoutSettings = $route.current.$$route.layoutSetting;
  vm.digital_sign_via_signaturit = false;
  vm.domain = '';
  vm.busy = true;
  vm.refreshDomain = refreshDomain;
  vm.toggleDigitalSignature = toggleDigitalSignature;

  /* Startup */
  $scope.$on('ngRepeatFinished', $window.rclmng_globals.ux.pepinColFixer.resizeTables);

  $q.all([
    ClaimState.get({ segments__id: 1, costs: false }).$promise,
    Lawyer.get().$promise,
    PanelTools.getUserClaimCounts(vm)
  ]).then(
    function(responses) {
      vm.states = responses[0].objects;
      vm.lawyers = responses[1];
      vm.busy = false;
      $timeout($window.rclmng_globals.ux.pepinColFixer.init);
    },
    function() {
      notifications.addCurrentView('error', 'Lo sentimos, se produjo un error solicitando la informe.');
      vm.busy = false;
    }
  );


  /* Functions */
  function refreshDomain(domain) {
    if (domain !== vm.domain) {
      if (domain === '' || domain === 'es' || domain === 'pt') {
        vm.busy = true;
        $window.rclmng_globals.ux.pepinColFixer.clearTables();

        vm.domain = domain;

        PanelTools.getUserClaimCounts(vm).then(
          function() {
            // ux update
            vm.busy = false;
            $timeout($window.rclmng_globals.ux.pepinColFixer.init);
          },
          function() {
            notifications.addCurrentView('error', 'Lo sentimos, se produjo un error solicitando la informe.');
            vm.busy = false;
          }
        );
      }
    }
  }


  function toggleDigitalSignature() {
    vm.digital_sign_via_signaturit = !vm.digital_sign_via_signaturit;
    vm.busy = true;
    $window.rclmng_globals.ux.pepinColFixer.clearTables();

    PanelTools.getUserClaimCounts(vm).then(
      function() {
        // ux update
        vm.busy = false;
        $timeout($window.rclmng_globals.ux.pepinColFixer.init);
      },
      function() {
        notifications.addCurrentView('error', 'Lo sentimos, se produjo un error solicitando la informe.');
        vm.busy = false;
      }
    );
  }
}
