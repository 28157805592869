import angular from 'angular';
import GotoCtrl from './GotoCtrl';

angular
  .module('reclamador.components.goto', [])
  .controller('GotoCtrl', GotoCtrl)
  .component('gotoComponent', {
    template: `
      <form ng-submit="vm.goTo()" id="gotoForm" ng-class="{active: vm.gotoFormActive}">
        <div class="gotoWrap">
          <input type="text" class="form-control" ng-model="vm.goToInput" placeholder="Introduce id" id="gotoInput">
        </div>
        <a 
          href="" 
          ng-class="{disabled: !vm.ClaimFactory.isValidId(vm.goToInput)}" 
          class="pr-2 pl-2"
          ng-click="vm.gotoFormActive = !vm.gotoFormActive; vm.select();"
        >
          <i class="fas fa-rocket"></i>
        </a>
      </form>`,
    controller: GotoCtrl,
    controllerAs: 'vm'
  })
  .component('gotoLink', {
    template: `
      <a ng-href="{{vm.url}}" target="_blank" class="pr-4"> {{vm.claimId}} </a>
      <button class="btn btn-info" ng-click="vm.copyURL()">Copiar enlace</button>
      `,
    controller: GotoCtrl,
    bindings: {
      claimId: '<'
    },
    controllerAs: 'vm'
  });
