GenericClaimDetailCtrl.$inject = [
  '$scope',
  '$controller',
  '$routeParams',
  'serverAddress',
  'notifications',
  'fileUploaderGenerator'
];
export default function GenericClaimDetailCtrl(
  $scope,
  $controller,
  $routeParams,
  serverAddress,
  notifications,
  fileUploaderGenerator
) {
  const vm = this;

  const BASE_DETAIL_CONFIG = {
    DJANGO_MODEL: 'GenericClaim',
    MODEL_PROVIDER: 'GenericClaim',
    PROVIDER_CLAIM_ID_NAME: 'id',
    SEGMENT_PREFIX: 'GE',
    VERBOSE_NAME: 'Gestión genérica', // optional
    claim_id: $routeParams.id,
    CLAIM_DICTIONARIES: {
      lawyers: 'genericlawyers'
    },
    tabConfig: [
      {
        heading: 'Detalles',
        indexKey: 'gestion',
        template: 'js/modules/segments/generic/partials/section/details.html',
        select: null,
        headingBadge: null
      },
      {
        heading: 'Documentación',
        indexKey: 'documentacion',
        template: 'js/modules/segments/generic/partials/section/documentation.html'
      },
      {
        heading: 'Historial',
        indexKey: 'historial',
        template: '/partials/common/detail/historic.html',
        headingBadge: null
      }
    ],

    /* Callbacks */
    postRefreshClaimCB
  };

  const INITIAL_STATE = {
    claim: {
      id: null,
      documents: [],
      legalfile: {},
      clientProvince: '',
      claim_type: {
        id: '',
        name: ''
      },
      state: {
        name: ''
      },
      client: {
        user: {
          is_active: true
        }
      }
    },
    viewCancelledClientPermission: true
  };

  const METHODS = {
    addCommentSucessCb
  };

  $controller('BaseDetailCtrl', { vm: vm, $scope: $scope });

  Object.assign(vm, BASE_DETAIL_CONFIG, INITIAL_STATE, METHODS);

  fileUploaderGenerator({
    vm: vm,
    url: serverAddress.getDocumentUrl() + 'generic-claim/document/upload',
    fillItemData: function(item) {
      vm.busy = true;

      item.formData = [
        {
          type_id: item.documenType.id || 0,
          comment: item.comment || '',
          generic_claim_id: vm.claim.id
        }
      ];
    },
    sucessCallback: () => {
      vm.claimDetail.update('Archivo subido con éxito, actualizando reclamación');
    }
  });

  vm.claimDetail.init();

  /* Function declaration */
  function postRefreshClaimCB() {
    vm.getDocumentTypes();
  }

  function addCommentSucessCb() {
    notifications.addCurrentView('info', 'Comentario añadido con éxito');
    vm.claim.update().then(function() {
      // TODO updateDate do not return promise but is not stricly necessary to execute fetchHistoryChanges
      // wait for refactor and claimDetail.update
      vm.fetchHistoryChanges(true);
    });
  }
}
