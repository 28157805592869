import angular from 'angular';
import './styles.css';
import EditNotificationController from './editNotificationCtrl';
import NotificationService from '../../modules/segments/legalfiles/services/notificationService.js';

angular
  .module('reclamador.components.editNotification', [])
  .factory('NotificationService', NotificationService)
  .component('editNotification', {
    template: `
    <button class="btn green btn-sm" ng-click="vm.openEditModal(vm.index)" title="Editar">
      <i class="fas fa-edit"></i>
    </button>

    <div class="modal fade" id="editModal-{{vm.index}}" tabindex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true" ng-include="'js/components/edit-notification/edit-notification-modal.html'">
    </div>
  `,
    bindings: {
      onDocumentUpdated: '<',
      index: '<',
      document: '<',
      typeList: '<',
      comment: '<',
      phaseList: '<',
      lawyerList: '<'
    },
    controller: EditNotificationController,
    controllerAs: 'vm'
  });
