LegalServicesRoutes.$inject = ['$routeProvider', 'LegalServicesConstants'];

export default function LegalServicesRoutes($routeProvider, LegalServicesConstants) {

  const permissions = {
    only: 'segment-perobject-SG-crm_view_claims_list',
    redirectTo: 'no-permissions'
  };

  const listConfig = {
    title: 'Servicio genérico',
    templateUrl: 'partials/common/baseList/claim-list.html',
    data: { permissions },
    layoutSetting: {
      contentTitle: 'Servicio genérico',
      iconClass: 'fas fa-id-card',
      primaryPath: '/#/generic'
    },
    baseListSettings: {
      typeList: 'claims',
      title: 'Servicio genérico',
      listResultsText: 'Servicio genérico',
      templateListTable: 'js/modules/segments/legalservices/partials/claim-list-table.html',
      templateListFilter: 'js/modules/segments/legalservices/partials/claim-list-filter.html'
    },
    controller: 'LegalServicesListCtrl',
    controllerAs: 'vm',
    segmentID: LegalServicesConstants.SEGMENT_ID
  };

  $routeProvider.when('/legalservices-claims/', listConfig);
  $routeProvider.when('/legalservices-claims/list/filter/:filter', listConfig);

  $routeProvider.when('/legalservices/:id', {
    title: 'Servicio genérico',
    templateUrl: 'js/modules/segments/legalservices/partials/claim.html',
    data: {
      permissions: {
        only: 'segment-perobject-SG-crm_view_claim_detail',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Servicio genérico',
      iconClass: 'fas fa-id-card',
      primaryPath: '/#/legalservices-claims',
      secondaryPath: '/#/legalservices-claims'
    },
    controller: 'LegalServicesDetailCtrl',
    controllerAs: 'vm',
    segmentID: LegalServicesConstants.SEGMENT_ID,
    reloadOnSearch: false
  });
}
