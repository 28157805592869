NegligencesClaimDetailCtrl.$inject = [
  '$scope',
  '$controller',
  'notifications',
  'serverAddress',
  'DocumentDownloadFactory',
  'DialogFactory',
  'HealthCompany',
  'fileUploaderGenerator',
  'NegligencesConstants'
];
export default function NegligencesClaimDetailCtrl(
  $scope,
  $controller,
  notifications,
  serverAddress,
  DocumentDownloadFactory,
  DialogFactory,
  HealthCompany,
  fileUploaderGenerator,
  NegligencesConstants
) {
  const vm = this;

  const INITIAL_STATE = {
    claim: {
      client: {
        user: {
          is_active: true
        }
      }
    },
    viewCancelledClientPermission: true,
    clinicType: [
      { label: 'Público', value: false },
      { label: 'Privado', value: true }
    ]
  };
  const METHODS = {
    postRefreshClaimCB,

    downloadAllNegligencesDocuments,
    showDocumentMergingModal,
    showDocumentSlicingModal,
    showDocumentCopyModal,
    getHealthCompany
  };

  $controller('BaseDetailCtrl', { vm: vm, $scope: $scope });

  Object.assign(vm, INITIAL_STATE, METHODS, NegligencesConstants);

  vm.claimDetail.init();

  /* BaseDetail Callbacks */
  function postRefreshClaimCB() {
    vm.setEntityData(
      {
        claim_id: vm.claim.id,
        legalfile_id: vm.claim.legalfile === null ? undefined : vm.claim.legalfile.id
      },
      { prefix: 'NE' }
    );

    vm.getDocumentTypes();
    if (vm.claim.health_company) {
      vm.getHealthCompany();
    }
  }

  // FROM HERE JUST FUNCTIONS => => => => => => => => => =>
  fileUploaderGenerator({
    vm: vm,
    url: serverAddress.getDocumentUrl() + 'common/upload/',
    fillItemData: function(item) {
      vm.busy = true;

      item.formData = [
        {
          type_id: item.documenType.id || 0,
          comment: item.comment || '',
          claim_id: vm.claim.id,
          segment: vm.MODEL_PROVIDER
        }
      ];
    },
    sucessCallback: () => {
      vm.claimDetail.update('Archivo subido con éxito, actualizando reclamación');
    }
  });

  function getHealthCompany() {
    HealthCompany.get({ id: vm.claim.health_company.id }).$promise
      .then(response => {
        vm.claim.health_company = response;
      })
      .catch(e => console.error(e));
  }

  function downloadAllNegligencesDocuments() {
    DocumentDownloadFactory.downloadAllNegligencesDocuments(vm.claim);
  }

  function showDocumentMergingModal() {
    var currentDocuments = vm.documents;
    var documentTypes = vm.documentTypes;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: 'NE'
    };

    var multipleSourceSelectionEnabled = false;
    DialogFactory.mergeDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimData,
      multipleSourceSelectionEnabled,
      function() {
        vm.claimDetail.update('Unión de documentos solicitada. Cuando termine recibirás un email.');
      }
    );
  }

  function showDocumentSlicingModal() {
    var currentDocuments = vm.documents;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: 'NE'
    };
    DialogFactory.sliceDocument(currentDocuments, claimData, function() {
      vm.claimDetail.update(
        'Separación de documento solicitada. Cuando termine recibirás un email.'
      );
    });
  }

  function showDocumentCopyModal(prefix, type) {
    var currentDocuments = null;
    var documentTypes = vm.documentTypes;
    var multipleSourceSelectionEnabled = false;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: prefix
    };
    var copy_type = type;
    var claimTypes = vm.claimTypes;

    // If the claim has no documents, an error is shown
    if (vm.documents.length === 0) {
      notifications.addCurrentView(
        'error',
        'No se ha podido realizar la acción. La reclamación no tiene ningún documento adjunto.'
      );
      return;
    }

    if (type === 'claim') {
      try {
        currentDocuments = DialogFactory.copyDocsToClaimValidation(vm.documents);
      } catch (e) {
        notifications.addCurrentView('error', e);
        return;
      }
    } else {
      try {
        currentDocuments = DialogFactory.copyDocsToLegalfileValidation(vm.documents, vm.claim);
      } catch (e) {
        notifications.addCurrentView('error', e);
        return;
      }
    }

    DialogFactory.copyDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimTypes,
      claimData,
      multipleSourceSelectionEnabled,
      copy_type,
      function() {
        vm.claimDetail.update();
      }
    );
  }
}
