SliceModalCtrl.$inject = [
  '$scope',
  '$filter',
  'dialog',
  'Target'
];

export default function SliceModalCtrl (
  $scope,
  $filter,
  dialog,
  Target
) {
  try { // modal factory for some reason don't throw errors, so...
    $scope.vm = Object.assign({}, {
      Target,
      actions: [{rotation: 0, pages: ''}],
      outputFileName: getTargetFilename(Target.filename),

      // methods
      truncateFilenameFilter: $filter('truncatefilename'),
      ok,
      cancel,
      prepareSlice,
      rotateItem,
      processAction,
      checkFormInvalid,
      getTargetFilename
    });

    // generate Slice request Object
    function prepareSlice (actions, output) {
      const PARSED_ACTIONS = actions.map(processAction);

      const PAGES = PARSED_ACTIONS.map(e => { return e ? e[0] : null; }).flat();
      const ROTATIONS = PARSED_ACTIONS.map(e => { return e ? e[1] : null; }).flat();

      return {
        pages: PAGES,
        rotations: ROTATIONS,
        filename: output,
        doc: Target.id
      };
    }

    // check all actions are valid
    function checkFormInvalid() {
      // TODO: Better form validator
      return $scope.vm.actions.some(e => !$scope.vm.processAction(e));
    }

    // Analize action object and return valid object for API
    function processAction(action) {
      const SEGMENT_REGEX = /^(\d+)-(\d+)$/gm;
      const SINGLE_PAGE_REGEX = /^(\d+)$/gm;

      let pages_output, rotation_output;

      const PAGES = action.pages;

      // Just number occurencies
      if(PAGES.match(SINGLE_PAGE_REGEX)) {
        pages_output = [parseInt(PAGES, 10)];
        rotation_output = [action.rotation];

      // RANGE occurencies (12-41)
      } else if(PAGES.match(SEGMENT_REGEX)) {
        const REGEX_OUT = SEGMENT_REGEX.exec(PAGES);
        let RANGE_INIT = parseInt(REGEX_OUT[1], 10);
        let RANGE_END = parseInt(REGEX_OUT[2], 10);

        if(RANGE_INIT > RANGE_END) [RANGE_INIT, RANGE_END] = [RANGE_END, RANGE_INIT];

        pages_output = Array(RANGE_END - RANGE_INIT + 1).fill()
          .map((e, idx) => RANGE_INIT + idx);
        rotation_output = Array(RANGE_END - RANGE_INIT + 1).fill(action.rotation);
      } else {
        return null; // TODO: fix this
      }

      return [pages_output, rotation_output];
    }

    function rotateItem (item) {
      item.rotation = item.rotation == 270 ? 0 : item.rotation + 90;
    }

    function ok () {
      dialog.close();
      return dialog.deferred.resolve($scope.vm.prepareSlice($scope.vm.actions, $scope.vm.outputFileName));
    }

    function cancel () {
      dialog.close();
      return dialog.deferred.reject(null);
    }

    // Generate a initial filename for the file
    function getTargetFilename (filename) {
      const SPLIT_FILENAME = filename.split('.');

      if(SPLIT_FILENAME.length == 1) return filename + '-split';
      return SPLIT_FILENAME.slice(0, -1).join('.') + '-split.' + SPLIT_FILENAME.pop();
    }
  } catch(e){
    console.log(e);
  }
}