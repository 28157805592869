LaboralClaimListCtrl.$inject = ['$scope', '$controller', 'LaboralConstants'];

export default function LaboralClaimListCtrl($scope, $controller, LaboralConstants) {
  const vm = this;
  const claimDetailTabs = {};
  LaboralConstants.tabConfig.forEach(element => {
    claimDetailTabs[element.indexKey] = element.heading;
  });
  const INITIAL_STATE = {
    claimDetailTabs,
    ALIAS: LaboralConstants.ALIAS + '-claims',

    itemsPath: 'laboral-claim/list/',
    listPath: 'laboral-claims/list/filter/',
    listPathNoFIlter: 'laboral-claims',
    defaultColumnDef: LaboralConstants.defaultColumnDef,
    filterFieldInfo: LaboralConstants.filterFieldInfo
  };
  $controller('BaseListCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE);

  /** Initiate List! */
  vm.initBaseList(
    LaboralConstants.SEGMENT_ID,
    LaboralConstants.ALIAS,
    LaboralConstants.permissions
  );
}
