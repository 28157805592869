DocumentChangeFactory.$inject = ['$http', 'serverAddress'];

export default function DocumentChangeFactory($http, serverAddress) {

  return {
    changeClaimDocumentType,
    changeLegalFileDocumentType,
    changeFlightDocumentType
  };

  function changeClaimDocumentType(documentId, typeId, djangoModel, modelId) {
    return changeDocumentType('common/claim/documents/changetype/', documentId, typeId, djangoModel, modelId);
  }

  function changeLegalFileDocumentType(documentId, typeId, modelId) {
    return changeDocumentType('common/claim/documents/changetype/', documentId, typeId, 'LegalFile', modelId);
  }

  function changeFlightDocumentType(documentId, typeId) {
    return $http({
      url: serverAddress.getDocumentUrl() + 'claim/flight-documents/changetype/',
      method: 'POST',
      data: {
        flight_document_id: documentId,
        type_id: typeId
      }
    });
  }

  /* Private functions */
  function changeDocumentType(path, documentId, typeId, djangoModel, modelId) {
    return $http({
      url: serverAddress.getDocumentUrl() + path,
      method: 'POST',
      data: {
        django_class: djangoModel,
        document_id: documentId,
        type_id: typeId,
        model_id: modelId
      }
    });
  }
}
