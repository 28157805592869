export default function formatCurrency() {
  return (input = 0) => {
    // Notice that output is a different encoding of UTF-8 chars
    return Number(input).toLocaleString('es-ES', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'EUR'
    });
  };
}
