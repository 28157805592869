/* eslint-disable angular/di */
import angular from 'angular';

import NegligencesClaimDetailCtrl from './claimDetailCtrl';
import NegligencesClaimListCtrl from './claimListCtrl';
import NegligencesLawyerPanelCtrl from './lawyerPanelCtrl';

import NegligencesClaim from './claimService';
import NegligencesSalesman from './salesmanService';
import NegligencesLawyer from './lawyerService';

import HealthCompany from './healthCompanyService';

import NegligencesConstants from './constants';

import NegligencesRoutes from './routes';

angular
  .module('reclamador.segments.negligences', [])

  .controller('NegligencesClaimDetailCtrl', NegligencesClaimDetailCtrl)
  .controller('NegligencesClaimListCtrl', NegligencesClaimListCtrl)
  .controller('NegligencesLawyerPanelCtrl', NegligencesLawyerPanelCtrl)

  .factory('NegligencesClaim', NegligencesClaim)
  .factory('NegligencesSalesman', NegligencesSalesman)
  .factory('NegligencesLawyer', NegligencesLawyer)

  .constant('NegligencesConstants', NegligencesConstants)

  .factory('HealthCompany', HealthCompany)

  .config(NegligencesRoutes);
