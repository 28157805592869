import moment from 'moment';
import { NULL_BOOLEAN_TYPE, NUMERAL_BOOLEAN_TYPE } from '../field-types';

ClaimDetailProductCtrlclaim.$inject = [
  '$scope',
  '$timeout',
  'dataLoader',
  '$controller',
  'VCServiceTiming',
  'ManagementsClaim'
];

export default function ClaimDetailProductCtrlclaim(
  $scope,
  $timeout,
  dataLoader,
  $controller,
  VCServiceTiming,
  ManagementsClaim
) {
  const vm = this;

  $controller('BaseDetailCtrl', { vm: vm, $scope: $scope });

  Object.assign(vm);

  vm._details = [];
  vm.parseDate = function (date) {
    return moment(date, 'YYYY-MM-DD', 'es');
  };

  $scope.$watch('vm.data', data => {
    fillData(vm.details, data);
  });

  $scope.$watch('vm.details', details => {
    fillData(details, vm.data);
  });

  async function getVCServiceTimingData(date) {
    try {
      const response = await VCServiceTiming.get({
        effective_date_start__lte: date,
        effective_date_end__gt: date
      }).$promise;
      return response.objects || null;
    } catch (error) {
      console.error('Error fetching VCServiceTiming data: ', error);
      return null;
    }
  }

  async function calculateFilteredDuration() {
    try {
      const data = await ManagementsClaim.get({ id: vm.claim_id }).$promise;
      return data ? await getVCServiceTimingData(data.date_added) : null;
    } catch (error) {
      console.error('Error processing request: ', error);
      return null;
    }
  }

  async function fillFilteredDuration(i) {
    try {
      i.dictionary = await calculateFilteredDuration();
    } catch (error) {
      console.error('Error processing request: ', error);
    }
  }

  function fillData(d = [], data = {}) {
    if (d.length && data && data.id) {
      d.forEach(i => {
        if (i.type === 'date') {
          $timeout(() => {
            i.formatValue = vm.parseDate(data[i.id]);
          });
        } else if (i.type === 'nullboolean') {
          if (i.dictionary) return;
          i.dictionary = NULL_BOOLEAN_TYPE;
        } else if (i.type === 'numeralboolean') {
          if (i.dictionary) return;
          i.dictionary = NUMERAL_BOOLEAN_TYPE;
        } else if (['select', 'localselect'].includes(i.type)) {
          if (i.id === 'service_timing') {
            fillFilteredDuration(i);
            return;
          }
          if (i.dictionary) {
            i.dictionary = Array.from(i.dictionary).map(i => ({ ...i, name: i.name || i.type }));
            return;
          }
          const requestData = { entity: i.entity, key: i.key };
          if (i.keyId && data[i.keyId]) {
            requestData.keyId = data[i.keyId].id;
          }
          dataLoader(i.service, {
            requestData
          })
            .$promise.then(response => {
              if (response.hasOwnProperty('objects')) {
                i.dictionary = Array.from(response.objects).map(i => ({
                  ...i,
                  name: i.label || i.name || i.type
                }));
              } else {
                i.dictionary = Array.from(response).map(i => ({ ...i, name: i.name || i.type }));
              }
            })
            .catch(e => {
              console.error('Error processing request: ' + e);
            });
        } else if (i.type === 'picklist') {
          if (i.service) {
            const requestData = { entity: i.entity, key: i.key };
            if (i.keyId && data[i.keyId]) {
              requestData.keyId = data[i.keyId].id;
            }
            dataLoader(i.service, {
              requestData
            })
              .$promise.then(response => {
                if (response.hasOwnProperty('objects')) {
                  i.dictionary = response.objects;
                } else {
                  i.dictionary = Array.from(response).map(i => ({ ...i, name: i.name || i.type }));
                }
              })
              .catch(e => {
                console.error('Error processing request: ' + e);
              });
          }
        }
      });

      vm._details = d;
    }
  }
}
