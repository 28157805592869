import 'core-js/stable';
import 'regenerator-runtime/runtime';

import moment from 'moment';
window['moment'] = moment;
moment.locale('es');

// vendor: [
import 'jquery';
import 'popper.js';
import 'bootstrap';
import 'angular';
import 'angular-i18n/es-es';
import 'angular-route';
import 'angular-sanitize';
import 'angular-resource';
import 'angular-cookies';
import 'angular-animate';
import 'angular-csv-import';
import 'angular-drag-and-drop-lists';
import 'angular-file-upload';
import 'ng-currency';

import 'select2';
import 'angular-ui-select2/src/select2';
import 'ui-select';

import 'angular-google-analytics';
import 'angular-toastr';

import '../lib/ui-bootstrap-tpls-0.3.0_fixes';//TODO: Candidate to be removed

import '../lib/jquery-ui/jquery-ui';
import '../lib/angular-ui-date';

import '../lib/datetimepicker/bootstrap-datetimepicker.min';
import '../lib/datetimepicker/bootstrap-datetimepicker.es';

import 'angular-moment-picker';

import '../lib/Utils';
