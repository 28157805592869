export default function sentnotsent() {
  return function(input) {
    if (
      input === true ||
      (typeof input === 'string' && (input.toUpperCase() === 'Y' || input.toUpperCase() === 'YES')) ||
      input == 1
    ) {
      return 'Enviada';
    }
    return '';
  };
}
