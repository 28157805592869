Inheritor.$inject = ['$resource', 'serverAddress'];

export default function Inheritor($resource, serverAddress) {
  return $resource(
    serverAddress.getBaseUrl() + 'api2/v1/inheritor/:id/',
    {},
    {
      update: {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json;' }
      }
    }
  );
}
