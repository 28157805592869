AccidentClaimListCtrl.$inject = ['$scope', '$controller', 'AccidentConstants'];

export default function AccidentClaimListCtrl($scope, $controller, AccidentConstants) {
  const vm = this;
  const claimDetailTabs = {};
  AccidentConstants.tabConfig.forEach(element => {
    claimDetailTabs[element.indexKey] = element.heading;
  });
  const INITIAL_STATE = {
    claimDetailTabs,
    ALIAS: AccidentConstants.ALIAS + '-claims',
    VEHICLE_TYPES: AccidentConstants.VEHICLE_TYPES,
    CAUSES: AccidentConstants.CAUSES,

    itemsPath: 'accident-claim/list/',
    listPath: 'accident-claims/list/filter/',
    listPathNoFIlter: 'accident-claims',
    defaultColumnDef: AccidentConstants.defaultColumnDef,
    filterFieldInfo: AccidentConstants.filterFieldInfo
  };
  $controller('BaseListCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE);

  /** Initiate List! */
  vm.initBaseList(
    AccidentConstants.SEGMENT_ID,
    AccidentConstants.ALIAS,
    AccidentConstants.permissions
  );
}
