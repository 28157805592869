DocumentCopyFactory.$inject = ['$http', 'serverAddress'];

export default function DocumentCopyFactory($http, serverAddress) {
  var COPY_DOCUMENT_TO_ANOTHER_CLAIM_PATH = 'common/claim/documents/copy/';
  var COPY_DOCUMENT_TO_LEGALFILE_PATH = 'common/copy/claim-to-legalfile/';
  var COPY_DOCUMENT_TO_NEW_CLAIM = 'common/claim/documents/copy-new-claim/';

  return {
    copyDocumentsToAnotherClaim,
    copyDocumentsToLegalfile,
    copyDocumentsToNewClaim
  };

  function copyDocumentsToAnotherClaim(claimData, document_ids) {
    var targetUrl = serverAddress.getBaseUrl() + COPY_DOCUMENT_TO_ANOTHER_CLAIM_PATH;

    return $http.post(targetUrl, {
      claim_id: claimData['claim_id'],
      claim_prefix: claimData['claim_prefix'],
      document_ids: document_ids,
      new_claim_id: claimData['new_claim_id']
    });
  }

  function copyDocumentsToNewClaim(claimData, document_ids) {
    var targetUrl = serverAddress.getBaseUrl() + COPY_DOCUMENT_TO_NEW_CLAIM;

    return $http.post(targetUrl, {
      claim_id: claimData['claim_id'],
      claim_prefix: claimData['claim_prefix'],
      document_ids: document_ids,
      new_claim_type_id: claimData['new_claim_type_id'],
      lawyer_comment: claimData['lawyer_comment']
    });
  }

  function copyDocumentsToLegalfile(claimData, document_ids) {
    var targetUrl = serverAddress.getBaseUrl() + COPY_DOCUMENT_TO_LEGALFILE_PATH;
    return $http.post(targetUrl, {
      claim_id: claimData['claim_id'],
      claim_prefix: claimData['claim_prefix'],
      document_ids: document_ids
    });
  }
}
