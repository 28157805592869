import angular from 'angular';

// horizontal controllers
import BaseCtrl from './BaseCtrl';
import BaseListCtrl from './BaseListCtrl';
import TastypieBaseListCtrl from './TastypieBaseListCtrl';
import BaseDetailCtrl from './BaseDetailCtrl';
import BaseLawyerPanelCtrl from './BaseLawyerPanelCtrl';

import CommonSalesDashboardCtrl from './CommonSalesDashboardCtrl';
import IndexCaseStatesCtrl from './IndexCaseStatesCtrl';

angular
  .module('reclamador.controllers.base', [])
  .controller('BaseListCtrl', BaseListCtrl)
  .controller('TastypieBaseListCtrl', TastypieBaseListCtrl)
  .controller('BaseDetailCtrl', BaseDetailCtrl)
  .controller('BaseLawyerPanelCtrl', BaseLawyerPanelCtrl)
  .controller('BaseCtrl', BaseCtrl);

angular
  .module('reclamador.controllers', ['ngCookies'])
  .controller('IndexCaseStatesCtrl', IndexCaseStatesCtrl)
  .controller('CommonSalesDashboardCtrl', CommonSalesDashboardCtrl);
