/* eslint-disable angular/di */
import angular from 'angular';

import ForeignerServicesClaimDetailCtrl from './claimDetailCtrl';
import ForeignerServicesClaimListCtrl from './claimListCtrl';
import ForeignerServicesLawyerPanelCtrl from './lawyerPanelCtrl';

import ForeignerServicesClaim from './claimService';
import ForeignerServicesLawyer from './lawyerService';
import ForeignerServicesSalesman from './salesmanService';

import ForeignerServicesConstants from './constants';

import ForeignerServicesRoutes from './routes';


angular
  .module('reclamador.segments.foreignerServices', [])
  .controller('ForeignerServicesClaimDetailCtrl', ForeignerServicesClaimDetailCtrl)
  .controller('ForeignerServicesClaimListCtrl', ForeignerServicesClaimListCtrl)
  .controller('ForeignerServicesLawyerPanelCtrl', ForeignerServicesLawyerPanelCtrl)

  .component('foreignerServicesClaimDetail', {
    templateUrl: 'partials/foreignerservices/claim.html',
    controller: ForeignerServicesClaimDetailCtrl
  })
  .component('foreignerServicesClaimList', {
    templateUrl: 'partials/common/baseList/claim-list.html',
    controller: ForeignerServicesClaimListCtrl
  })

  .factory('ForeignerServicesClaim', ForeignerServicesClaim)
  .factory('ForeignerServicesLawyer', ForeignerServicesLawyer)
  .factory('ForeignerServicesSalesman', ForeignerServicesSalesman)

  .constant('ForeignerServicesConstants', ForeignerServicesConstants)

  .config(ForeignerServicesRoutes);
