ClaimsChangeSourceCtrl.$inject = [
  '$http',
  '$scope',
  'serverAddress',
  'claimIds',
  'flightCode',
  'parentScope',
  'dialog',
  'notifications'
];

ClaimsChangeSourceCtrl.$inject = [
  '$http',
  '$scope',
  'serverAddress',
  'claimIds',
  'flightCode',
  'parentScope',
  'dialog',
  'notifications'
];

export default function ClaimsChangeSourceCtrl(
  $http,
  $scope,
  serverAddress,
  claimIds,
  flightCode,
  parentScope,
  dialog,
  notifications
) {
  /* Variables */
  $scope.flight_code = flightCode;

  /* Functions */
  $scope.change = change;
  $scope.dismiss = dismiss;

  /* Function implementation */
  function change() {
    $http
      .post(serverAddress.getBaseUrl() + 'claim/source/change/', {
        claim_ids: claimIds
      })
      .then(function() {
        parentScope.claimDetail.update();
        var msg = 'Cambiado el origen de las todas reclamaciones a "Aeropuerto" del vuelo (' + flightCode + ')   :)';
        notifications.addCurrentView('success', msg);
        dialog.close();
      })
      .catch(function() {
        notifications.addCurrentView('error', 'No se ha podido cambiar el origen en las reclamaciones.');
        dialog.close();
      });
  }

  function dismiss() {
    dialog.close();
  }
}
