const SEGMENT_ID = 6,
  MODEL_PROVIDER = 'AccidentClaim';

export default {
  SEGMENT_ID,
  MODEL_PROVIDER,
  SEGMENT_PREFIX: 'AC',
  ALIAS: 'accident',
  DJANGO_MODEL: MODEL_PROVIDER,
  VERBOSE_NAME: 'Reclamación por accidente', // optional
  permissions: {
    downloadCSVPermission: 'segment-perobject-AC-crm_download_csv'
  },
  CLAIM_DICTIONARIES: {
    salesman: 'accidentsalesmen',
    users: 'accidentlawyers',
    claimStates: {
      entity: 'claimstates',
      data: { segments__id: SEGMENT_ID, costs: false }
    },
    claimTypes: {
      entity: 'claimtypes',
      data: { type: 'accident' }
    },
    sources: 'claimsources',
    courtlawyers: 'courtlawyers',
    procuradores: {
      entity: 'procuradores',
      data: { is_accident: true }
    },
    courts: 'courts',
    saleStates: {
      entity: 'salesclaimstates',
      data: { segments__id: SEGMENT_ID }
    },
    statesWithEmail: {
      entity: 'statesWithPreviewEmail',
      data: { django_class: MODEL_PROVIDER }
    },
    trialMunicipalities: 'trialMunicipalities',
    provinces: 'provinces',
    docxtemplates: {
      entity: 'docxtemplates',
      data: {active: true, segments__id: SEGMENT_ID, target_holder: 'claim'}
    }
  },

  VEHICLE_TYPE_CAR: 0,
  VEHICLE_TYPE_MOTORBIKE: 1,
  VEHICLE_TYPE_BICYCLE: 2,
  VEHICLE_TYPE_PEDESTRIAN: 3,
  VEHICLE_TYPES: {
    0: 'Coche',
    1: 'Moto',
    2: 'Bici',
    3: 'Peatón'
  },
  TRAVELLING_AS_DRIVER: 0,
  TRAVELLING_AS_PASSENGER: 1,
  TRAVELLING_AS: {
    0: 'Conductor',
    1: 'Acompañante'
  },
  CAUSES_ROAD_POOR_CONDITION: 0,
  CAUSES_SOMETHING_IN_THE_ROAD: 1,
  CAUSES_GOT_A_HIT: 2,
  CAUSES_HANDRAILS_GUARDRAILS: 3,
  CAUSES_HIT_ANOTHER_VEHICLE: 4,
  CAUSES_PILE_UP: 5,
  CAUSES_POOR_MARKED_ROAD: 6,
  CAUSES_POOR_MARKED_ROAD_JOB: 7,
  CAUSES_WAS_HIT: 8,
  CAUSES_FELL_IN_STREET: 9,
  CAUSES_OTHER: 10,
  CAUSES: {
    0: 'Pavimento en mal estado',
    1: 'Había algo sobre el asfalto',
    2: 'Me dieron un golpe',
    3: 'Me di contra el quitamiedos / guardarrailes',
    4: 'Di un golpe a otro vehículo',
    5: 'Hubo un choque en cadena',
    6: 'Vía mal señalizada o sin señalizar',
    7: 'Obras mal señalizadas o sin señalizar',
    8: 'Fui atropellado',
    9: 'Me caí en la vía pública',
    10: 'Otros'
  },
  LOCATION_PAVIMENT: 0,
  LOCATION_ZEBRA_CROSSING: 1,
  LOCATION_ROAD: 2,
  LOCATION_SHOULDER: 3,
  LOCATION: {
    0: 'En la acera',
    1: 'En el paso de peatones',
    2: 'En la carretera',
    3: 'En el arcén'
  },
  DAMAGES_YES_WITH_SICK_LEAVE: 0,
  DAMAGES_YES_WITHOUT_SICK_LEAVET: 1,
  DAMAGES: {
    0: 'Sí, tuve que coger una baja laboral',
    1: 'Sí, pero no cogí baja',
    2: 'No, estoy bien'
  },
  NO_FRIENDLY_AGREEMENT: 0,
  YES_BUT_NOT_REPORT_COPY: 1,
  FRIENDLY_REPORT: {
    0: 'No, no lo hicimos',
    1: 'Sí, pero no tengo la copia',
    2: 'Si, tengo un parte amistoso'
  },
  NATIONAL_POLICE: 0,
  LOCAL_POLICE: 1,
  GUARDIA_CIVIL: 2,
  POLICE_TYPE: {
    0: 'Policía Nacional',
    1: 'Policía Local',
    2: 'Guardia Civil'
  },
  POLICE_REPORT_YES: 0,
  POLICE_REPORT_ONLY_NUMBER: 1,
  POLICE_REPORT_NO: 2,
  POLICE_REPORT: {
    0: 'Sí, tengo una copia del atestado',
    1: 'No, pero tengo el número del atestado',
    2: 'No, no tengo nada'
  },
  ALCOHOL_TEST_NO: 0,
  ALCOHOL_TEST_UNKNOWN: 1,
  ALCOHOL_TEST_YES: 2,
  ALCOHOL_TEST: {
    0: 'No, nadie dio positivo',
    1: 'No lo sé',
    2: 'Sí, alguien dio positivo en alcoholemia'
  },
  WITNESS_YES: 0,
  WITNESS_NO: 1,
  WITNESS_BUT_NO_CONTACT: 2,
  WITNESS: {
    0: 'Sí, puedo contactar con testigos',
    1: 'No, no había nadie más',
    2: 'Había testigos pero sabría como contactarles'
  },
  LEAVE: {
    0: 'No, ya me dieron el alta',
    1: 'Sí, sigo baja laboral'
  },
  tabConfig: [
    {
      heading: 'Resumen',
      indexKey: 'resumen',
      template: 'js/modules/segments/accident/partials/detail/summary.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Detalles',
      indexKey: 'detalles',
      template: 'js/modules/segments/accident/partials/detail/details.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Indemnización',
      indexKey: 'indemnizacion',
      template: 'js/modules/segments/accident/partials/detail/amount.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Documentación',
      indexKey: 'documentacion',
      template: 'js/modules/segments/accident/partials/detail/documentation.html'
    },
    {
      heading: 'Historial',
      indexKey: 'historial',
      template: '/partials/common/detail/historic.html',
      headingBadge: null
    },
    {
      heading: 'Mismo cliente',
      indexKey: 'mismo-cliente',
      template: '/partials/common/detail/same_client/base.html'
    },
    {
      heading: 'Invitaciones',
      indexKey: 'invitaciones',
      template: 'js/modules/segments/accident/partials/detail/invitations.html',
      select: null
    },
    {
      heading: 'Administración',
      indexKey: 'administracion',
      template: '/partials/common/detail/administration.html',
      select: null,
      headingBadge: null,
      permission: 'segment-global-crm_view_administration_tab'
    }
  ],
  defaultColumnDef: [
    {
      headerName: 'ID',
      fieldName: 'id',
      sortable: true,
      alwaysVisible: true
    },
    {
      headerName: 'Tipo',
      fieldName: 'type_name',
      sortable: true
    },
    {
      headerName: 'Cliente',
      fieldName: 'client_surnames',
      sortable: true
    },
    {
      headerName: 'Correo cliente',
      fieldName: 'client_email',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Provincia cliente',
      fieldName: 'client_province',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Tlfno',
      fieldName: 'client_phone',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fuente invitador',
      fieldName: 'invited',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Finalizada',
      fieldName: 'finished',
      sortable: true
    },
    {
      headerName: 'Fecha Finalizada',
      fieldName: 'date_finished',
      sortable: true
    },
    {
      headerName: 'Abogado',
      fieldName: 'lawyer',
      sortable: true
    },
    {
      headerName: 'Validado por',
      fieldName: 'validator_lawyer',
      sortable: true
    },
    {
      headerName: 'Estado',
      fieldName: 'state_name',
      sortable: true
    },
    {
      headerName: 'Estado C.',
      fieldName: 'sales_state_name',
      sortable: true
    },
    {
      headerName: 'Fase',
      fieldName: 'phase_name',
      sortable: true
    },
    {
      headerName: 'Fuente',
      fieldName: 'source_name',
      sortable: true
    },
    {
      headerName: 'Cómo nos conoció',
      fieldName: 'how_met_us',
      sortable: true
    },
    {
      headerName: 'Campaña',
      fieldName: 'campaign_name',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'SEM',
      fieldName: 'has_sem_gclid',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'SEM (Valor)',
      fieldName: 'sem_gclid',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Network',
      fieldName: 'sem_network',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Placement',
      fieldName: 'sem_placement',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Campaign',
      fieldName: 'sem_campaign',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Vehículo',
      fieldName: 'vehicle_type',
      sortable: true
    },
    {
      headerName: 'Causa',
      fieldName: 'cause',
      sortable: true
    },
    {
      headerName: 'Daños',
      fieldName: 'damages',
      sortable: true
    },
    {
      headerName: 'Polícia',
      fieldName: 'police',
      sortable: true
    },
    {
      headerName: 'Fecha del siniestro',
      fieldName: 'accident_date',
      sortable: true
    },
    {
      headerName: 'Ultimo cambio estado',
      fieldName: 'laststatechange',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Usuario cambio estado',
      fieldName: 'laststatechangeuser',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Comentario cambio estado',
      fieldName: 'laststatecomment',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Ultimo cambio estado comercial',
      fieldName: 'lastsalesstatechange',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Usuario cambio estado comercial',
      fieldName: 'lastsalesstatechangeuser',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Invitaciones enviadas',
      fieldName: 'num_sent_invitations',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Invitaciones aceptadas',
      fieldName: 'num_accepted_invitations',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Último comentario',
      fieldName: 'last_comment',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Usuario últ. comentario ',
      fieldName: 'last_comment_user',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'fecha últ. comentario',
      fieldName: 'last_comment_date',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Creado',
      fieldName: 'date_added',
      sortable: true
    },
    {
      headerName: 'Modificado',
      fieldName: 'date_updated',
      sortable: true
    },
    {
      headerName: 'Numero documentos',
      fieldName: 'num_docs',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Cantidad reclamada',
      fieldName: 'compensation',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Primer vertical',
      fieldName: 'first_claim_segment',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Primer producto',
      fieldName: 'first_claim_type',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha alta cliente',
      fieldName: 'client_date_added',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha primera reclamación válida',
      fieldName: 'first_claim_date_added',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Tipo de facturación',
      fieldName: 'is_immediate',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha a facturar',
      fieldName: 'billing_date',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Cantidad acuerdo prejudicial',
      fieldName: 'agreement_amount',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Cedida',
      fieldName: 'transfered_to_investor',
      sortable: true
    },
    {
      headerName: 'Fecha cesión',
      fieldName: 'transfered_to_investor_date',
      sortable: true
    },
    {
      headerName: 'Cartera',
      fieldName: 'investor_business_name',
      sortable: true
    },
    {
      headerName: 'Comprada',
      fieldName: 'bought_from_portfolio',
      sortable: true
    },
    {
      headerName: 'Comprado a',
      fieldName: 'bought_portfolio_business_name',
      sortable: true
    },
    {
      headerName: 'Fecha compra',
      fieldName: 'bought_date',
      sortable: true
    },
    {
      headerName: 'Email cartera cliente',
      fieldName: 'email_bought_client',
      sortable: true
    },
    {
      headerName: 'Teléfono cartera cliente',
      fieldName: 'phone_bought_client',
      sortable: true
    }
  ],
  filterFieldInfo: {}
};
