PhoneClaimListCtrl.$inject = ['$scope', '$controller', 'PhoneConstants'];

export default function PhoneClaimListCtrl($scope, $controller, PhoneConstants) {
  const vm = this;
  const claimDetailTabs = {};
  PhoneConstants.tabConfig.forEach(element => {
    claimDetailTabs[element.indexKey] = element.heading;
  });
  const INITIAL_STATE = {
    claimDetailTabs,
    ALIAS: PhoneConstants.ALIAS + '-claims',

    itemsPath: 'phone-claim/list/',
    listPath: 'phone-claims/list/filter/',
    listPathNoFIlter: 'phone-claims',
    defaultColumnDef: PhoneConstants.defaultColumnDef,
    filterFieldInfo: PhoneConstants.filterFieldInfo
  };
  $controller('BaseListCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE);

  /** Initiate List! */
  vm.initBaseList(
    PhoneConstants.SEGMENT_ID,
    PhoneConstants.ALIAS,
    PhoneConstants.permissions
  );
}
