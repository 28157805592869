AirlinePendingValidationPanelCtrl.$inject = [
  '$scope',
  'Lawyer',
  '$route',
  '$timeout',
  'notifications',
  '$window',
  '$localStorage',
  'PanelTools'
];

export default function AirlinePendingValidationPanelCtrl(
  $scope,
  Lawyer,
  $route,
  $timeout,
  notifications,
  $window,
  $localStorage,
  PanelTools
) {
  var vm = this;
  vm.busy = true;

  $scope.$on('ngRepeatFinished', $window.rclmng_globals.ux.pepinColFixer.resizeTables);

  vm.layoutSettings = $route.current.$$route.layoutSetting;

  vm.showLawyers = false;
  vm.assignedLawyer = '';
  vm.alreadyFiltered = false;
  vm.domain = '';
  vm.lawyers = {};

  vm.intervals = [
    { name: '+15 días', interval: [15, 0], filter: '&date_finished=15-0' },
    { name: '8-15 días', interval: [7, 15], filter: '&date_finished=7-15' },
    { name: '3-7 días', interval: [2, 7], filter: '&date_finished=2-7' },
    { name: '0-2 días', interval: [0, 2], filter: '&date_finished=0-2' }
  ];

  vm.type_rows = [
    {
      name: 'Retraso y Cancelación',
      claim_types: [1, 2],
      css_class: 'level_1',
      calculate: true,
      negative: false,
      filter: '&claim_type=1-2'
    },
    {
      name: 'Vuelo ya validado',
      claim_types: [1, 2],
      css_class: 'level_2',
      calculate: true,
      negative: false,
      filter: '&claim_type=1-2&validated_flight=t'
    },
    {
      name: 'Posible fuerza mayor',
      claim_types: [1, 2],
      css_class: 'level_2',
      calculate: true,
      negative: false,
      filter: '&claim_type=1-2&possible_force_majeure=t&validated_flight=not_t'
    },
    {
      name: 'Vuelo con S. desfav. o parcialmente fav.',
      claim_types: [1, 2],
      css_class: 'level_2',
      calculate: true,
      negative: false,
      filter: '&claim_type=1-2&flight_has_unfavorable_sentence=True'
    },
    {
      name: 'Resto',
      claim_types: [1, 2],
      css_class: 'level_2',
      calculate: true,
      negative: false,
      filter: '&claim_type=1-2&validated_flight=not_t&possible_force_majeure=f'
    },
    {
      name: 'Discrepancias',
      claim_types: [1, 2],
      css_class: 'level_2',
      calculate: true,
      negative: true,
      filter: '&claim_type=1-2&validated_flight=t&possible_force_majeure=t'
    },
    {
      name: 'Overbooking',
      claim_types: [3],
      css_class: 'level_1',
      calculate: true,
      negative: false,
      filter: '&claim_type=3'
    },
    {
      name: 'Equipaje',
      claim_types: [4],
      css_class: 'level_1',
      calculate: true,
      negative: false,
      filter: '&claim_type=4'
    }
  ];

  vm.urgent_rows = [
    {
      name: 'Clientes de paso',
      claim_types: [1, 2, 3, 4],
      css_class: 'level_1',
      calculate: true,
      negative: false,
      filter: '&claim_type=1-2-3-4&time_spending_in_spain=time_in_spain'
    }
  ];

  vm.refreshPartValDomain = refreshPartValDomain;
  vm.toggleSelectBoxLawyers = toggleSelectBoxLawyers;
  vm.filterByLawyers = filterByLawyers;
  vm.lawyerSelected = lawyerSelected;

  // Get list of lawyers and check if the  current user is one of them
  Lawyer.get().$promise.then(function(response) {
    vm.lawyers = response;
    var result = response.objects.filter(e => e.username === $localStorage.get('username')) || [];

    if (result.length > 0) {
      vm.showLawyers = true;
      vm.alreadyFiltered = true;
      vm.assignedLawyer = result[0].username;
    } else {
      vm.showLayers = false;
      vm.alreadyFiltered = false;
      vm.assignedLawyer = '';
    }

    PanelTools.getIntervals(vm).then(
      function() {
        // ux update
        vm.busy = false;
        $timeout($window.rclmng_globals.ux.pepinColFixer.init);
      },
      function() {
        notifications.addCurrentView('error', 'Lo sentimos, se produjo un error solicitando la informe.');
        vm.busy = false;
      }
    );
  }, function() {
    notifications.addCurrentView('error', 'Lo sentimos, se produjo un error solicitando la informe.');
    vm.busy = false;
  });

  function refreshPartValDomain(domain) {
    if (domain !== vm.domain) {
      if (domain === '' || domain === 'es' || domain === 'pt') {
        vm.domain = domain;

        vm.busy = true;
        $window.rclmng_globals.ux.pepinColFixer.clearTables();

        PanelTools.getIntervals(vm).then(
          function() {
            // ux update
            vm.busy = false;
            $timeout($window.rclmng_globals.ux.pepinColFixer.init);
          },
          function() {
            notifications.addCurrentView('error', 'Lo sentimos, se produjo un error solicitando la informe.');
            vm.busy = false;
          }
        );
      }
    }
  }

  function toggleSelectBoxLawyers(val) {
    vm.showLawyers = val;

    // Reset
    if (!val) {
      vm.busy = true;
      $window.rclmng_globals.ux.pepinColFixer.clearTables();

      vm.assignedLawyer = '';
      vm.alreadyFiltered = false;
      PanelTools.getIntervals(vm).then(
        function() {
          // ux update
          vm.busy = false;
          $timeout($window.rclmng_globals.ux.pepinColFixer.init);
        },
        function() {
          notifications.addCurrentView('error', 'Lo sentimos, se produjo un error solicitando la informe.');
          vm.busy = false;
        }
      );
    }
  }

  function filterByLawyers() {
    vm.busy = true;
    $window.rclmng_globals.ux.pepinColFixer.clearTables();

    PanelTools.getIntervals(vm).then(
      function() {
        // ux update
        vm.busy = false;
        $timeout($window.rclmng_globals.ux.pepinColFixer.init);
      },
      function() {
        notifications.addCurrentView('error', 'Lo sentimos, se produjo un error solicitando la informe.');
        vm.busy = false;
      }
    );
  }

  function lawyerSelected() {
    // Allows the "Filter" button to be shown when we just change a lawyer, selecting a valid option
    if (vm.assignedLawyer !== null) {
      vm.alreadyFiltered = false;
      // Just to make the "Filter" button dissapear in this situation
    } else {
      vm.alreadyFiltered = true;
    }
  }
}
