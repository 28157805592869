MicroserviceHour.$inject = ['$resource', 'serverAddress'];

export default function MicroserviceHour($resource, serverAddress) {
  const MICROSERVICE_PATH = 'api2/v1/microservicehour/:id/?:query';
  return $resource(
    `${serverAddress.getBaseUrl() + MICROSERVICE_PATH}`,
    {},
    {
      update: {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json;' }
      }
    }
  );
}
