SearchModalCtrl.$inject = [
  '$scope',
  'dialog',
  'ClaimsandlegalfilesService',
  'notifications',
  'Verticals',

  // modal resolves
  'ActiveTarget',
  'LastTarget'
];

export default function SearchModalCtrl(
  $scope,
  dialog,
  ClaimsandlegalfilesService,
  notifications,
  Verticals,

  ActiveTarget,
  LastTarget
) {
  try { // modal factory for some reason don't throw errors, so...
    Object.assign($scope, {
      // state
      busy: false,
      results: null,
      search: null,
      searchField: ActiveTarget,
      LastTarget,
      Verticals,

      // pagination
      resultsPerPageOptions: [10, 20, 50],
      resultsPerPage: 10,
      currentPage: 0,
      totalDocuments: 0,
      totalPages: 0,
      activeRow: {value: null}, // radio model, angular creates value in scope if it's not like this

      // methods
      doSearch,
      ok,
      assignRow,
      cancel,
      clearSearch,
      goToPage,
      getPagerElements,
      getResults,
      setResultsPerPage,
      getResultFullId,
      getResultUrl,
      ClaimsandlegalfilesService,
      assignLastTarget
    });

    function goToPage(page) {
      $scope.currentPage = page;
      $scope.getResults();
    }

    function getPagerElements() {
      return new Array($scope.totalPages);
    }

    function doSearch (search) {
      $scope.currentPage = 0;
      $scope.search = search;
      $scope.getResults();
    }

    function getResults () {
      $scope.busy = true;

      $scope.ClaimsandlegalfilesService.get({
        query: $scope.search,
        limit: $scope.resultsPerPage,
        offset: $scope.currentPage * $scope.resultsPerPage
      }).$promise
        .then(response => {
          $scope.activeRow.value = null; // reset radio
          $scope.busy = false;
          $scope.results = response.objects;
          $scope.totalDocuments = response.meta.total_count;
          $scope.totalPages = parseInt(($scope.totalDocuments - 1) / $scope.resultsPerPage, 10) + 1;
        }, e => {
          notifications.addCurrentView(
            'error',
            `se produjo un error obteniendo los resultados: ${e.statusText}`
          );
          $scope.busy = false;
          $scope.totalPages = 0;
          $scope.results = [];
          $scope.totalDocuments = 0;
        });
    }

    function assignLastTarget () {
      dialog.close();
      return dialog.deferred.resolve(LastTarget);
    }

    function assignRow(item, evt) {
      if(evt.target && (evt.target.href || evt.target.type == 'radio')) return;

      $scope.activeRow.value = item;
    }

    function ok() {
      if(!$scope.activeRow.value) return;

      dialog.close();
      return dialog.deferred.resolve(getResultFullId($scope.activeRow.value));
    }

    function getResultFullId(result) {
      if(result.is_legalfile) return result.num_file;
      return Verticals.getPrefixFromName(result.segment) + result.id;
    }

    function getResultUrl(result) {
      if(result.is_legalfile) return `#/legalfiles/${result.id}`;
      return `#/${Verticals.getUrlFromSegmentName(result.segment)}/${result.id}`;
    }

    function cancel () {
      dialog.close();
      return dialog.deferred.reject(null);
    }

    function clearSearch () {
      $scope.searchField = null;
      $scope.results = null;
    }

    function setResultsPerPage(results) {
      $scope.resultsPerPage = results;
      $scope.currentPage = 0;
      $scope.getResults();
    }

  } catch(e){
    console.log(e);
  }
}