import moment from 'moment';
import { removeSpacesPhone } from '../../../../lib/Utils';

BankClaimDetailCtrl.$inject = [
  '$scope',
  '$controller',
  '$http',
  'notifications',
  'serverAddress',
  'DialogFactory',
  'fileUploaderGenerator',
  'BankConstants',
  'DocumentDownloadFactory',
  'DocumentType',
  'ProductService',
  'ReportService',
  'dataLoader',
  '$timeout'
];

export default function BankClaimDetailCtrl(
  $scope,
  $controller,
  $http,
  notifications,
  serverAddress,
  DialogFactory,
  fileUploaderGenerator,
  BankConstants,
  DocumentDownloadFactory,
  DocumentType,
  ProductService,
  ReportService,
  dataLoader,
  $timeout
) {
  const vm = this;

  const METHODS = {
      getDocumentTypes,
      prepareClaimData,
      postRefreshClaimCB,
      preSaveClaimCB,

      showButton, // fuck generic name
      generateBankLawsuit,
      updateFloorClauseCalculation,
      updateSharesCalculation,
      generateSwapReport,
      generateIRPHReport,
      updateFormalizationExpensesCalculation,
      addAmortization,
      removeAmortization,
      addIncrement,
      removeIncrement,
      calculateCompensation,

      sendMediationMail,
      sendFormalizationExpensesMail,

      checkFloorClauseNotApplying,
      showDocumentBuilder,
      showDocumentSlicingModal,
      showDocumentCopyModal,
      addCommentSucessCb,

      remove_deed,
      add_form_expenses,

      parseCurrenciesAsNumber,
      getMediations,
      downloadAllBankDocuments,
      getPaymentTypeLiteral,
      generateReport,
      removeSpacesPhone
    },
    INITIAL_STATE = {
      claim: {
        applying_floor_clause: '',
        client: {
          user: {
            is_active: true
          }
        }
      },
      viewCancelledClientPermission: true,
      additional_fields: {},
      ob: {
        amortizations: [],
        increments: [],
        bank_data: '',
        contract_type: []
      },
      showUpdatedCalc: false,
      showRecalcButton: false,
      makingCalculations: false,
      states_sending_custom_msg: [60, 71],

      show_warning_after_calc: false,

      // TODO: This variable was used in management.html but never defined. We use now to avoid changing the claim state
      // if we dind't choose client_attendance in the "Juicio señalado" case.
      changeButtonDisabled: false,
      claimType: {},
      productDetails: [],

      // Revolving
      r_financial_entity_types: { url: '', values: [] },
      actions: {
        showNewCaseModal
      }
    };

  $controller('BaseDetailCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE, METHODS, BankConstants);

  vm.claimDetail.init();

  fileUploaderGenerator({
    vm: vm,
    url: serverAddress.getDocumentUrl() + 'banca/upload/',
    fillItemData: function (item) {
      vm.busy = true;

      item.formData = [
        {
          type_id: item.documenType.id || 0,
          comment: item.comment || '',
          claim_id: vm.claim_id
        }
      ];
    },
    sucessCallback: () => {
      vm.claimDetail.update('Archivo subido con éxito, actualizando reclamación');
    }
  });

  // claim update callback (old updateBankClaim)
  function postRefreshClaimCB() {
    $scope.claim = vm.claim; // Required for partials of same_airlines, same... since it seems complicated to pass the the claim to each partial
    getMediations();
    vm.getDocumentTypes();
    vm.setEntityData(
      {
        claim_id: vm.claim_id,
        legalfile_id: vm.claim.legalfile === null ? undefined : vm.claim.legalfile.id
      },
      { prefix: 'BA' }
    );

    // Scope data according product
    vm.claimType = {
      id: vm.claim.type.id | 0,
      key: '',
      path: '',
      reportPath: 'calculate-shares'
    };
    switch (vm.claimType.id) {
      case 30:
        vm.claimType.path = 'irphmortgageclaim';
        vm.claimType.reportPath = 'generate-irph-report';
        vm.claimType.key = 'irph';
        break;
      case 56:
        vm.claimType.path = 'revolvingclaim';
        vm.claimType.reportPath = 'generate-revolving-report';
        vm.claimType.key = 'revolving';

        // First dict to cascading dropdown
        const requestData = {
          entity: 'financialentity'
        };
        dataLoader('bankEntity', { requestData })
          .$promise.then(response => {
            vm.r_financial_entity_types.values = response.objects;
          })
          .catch(e => {
            console.error('Error processing request: ' + e);
          });
        dataLoader('creditcardEntity').$promise.then(function(response) {
          vm.r_credit_card_types = response.objects;
        });
        dataLoader('issuingbankEntity').$promise.then(function(response) {
          vm.r_issuing_bank_types = response.objects;
        });

        break;
      default:
        break;
    }

    ReportService.set(vm.claim.id, vm.claimType.reportPath);
    ProductService.set(vm.claimType.path);
    // TODO: Migate rest of products to isolated variables endpoint
    if (vm.claimType.key) {
      ProductService.get(vm.claim.id).then(response => {
        vm.productDetails = vm.CLAIM_PRODUCT_FIELDS[vm.claimType.id];
        vm[vm.claimType.key] = null;
        $timeout(() => {
          vm[vm.claimType.key] = response.objects[0];
        });
      });
    }
  }

  function preSaveClaimCB() {
    if (vm.claimType.key) {
      const data = Object.assign({ claim: vm.claim }, vm[vm.claimType.key]);
      // Plain child objects references
      Object.keys(data).forEach(currentItem => {
        data[currentItem] = data[currentItem] === undefined ? null : data[currentItem];
        if (data[currentItem] instanceof moment) {
          data[currentItem] = data[currentItem].toISOString(true);
        }
      });
      ProductService.save(vm.claimType.id, data)
        .then(() => {
          notifications.addCurrentView('success', 'Datos guardados correctamente.');
        })
        .catch(error => {
          notifications.addNextView('error', error.data);
        });
    } else {
      /*
      Edge case: If user removes automatic values generated from server on compensation expenses calculator,
      we ensure that it's sending null on this fields: vm.claim.interests, vm.claim.compensation, vm.claim.interests_amortization
      */
      vm.claim.compensation = Number(vm.claim.compensation) || null;
      vm.claim.interests = Number(vm.claim.interests) || null;
      vm.claim.interests_amortization = Number(vm.claim.interests_amortization) || null;
    }
  }

  // process changes in claim before initialized
  function prepareClaimData(claimData) {
    // TODO: Remove when back returns currencies as number
    vm.parseCurrenciesAsNumber(claimData, [
      'compensation',
      'invoiced_amount',
      'compensation_total',
      'estimated_debt_reduction',
      'compensation_with_sustitutive_reference_type',
      'estimated_debt_reduction_with_sustitutive_reference_type',
      'interests',
      'shares_invested_amount',
      'shares_exchange_value',
      'shares_current_value',
      'loan_amount',
      'irph_mortgage_fixed_sustitutive_reference_type',
      'irph_mortgage_fixed_sustitutive_reference_type',
      'irph_mortgage_mortgage_constitution_expenses',
      'ceil_clause_percentage',
      'floor_clause_fixed_interest_rate',
      'floor_clause_differential',
      'floor_clause_percentage'
      // claim.claim.amortizations[].amount
      // claim.increments[].amount
    ]);

    return claimData;
  }

  // Methods overwritten from BaseDetailCtrl
  function getDocumentTypes(cb) {
    vm.documentTypes = vm.claim.type.allowed_document_types;
    vm.clientDocumentTypes = vm.claim.type.allowed_document_types.filter(e => e.client_doc);

    // bank uses the extra 'aircase' type documents
    DocumentType.get({ type: 'aircase' }, response => {
      vm.fileDocumentTypes = response.objects;
    });

    // post-process loaded document types
    if (cb) return cb(vm.documentTypes, vm.clientDocumentTypes);
  }

  /* ImplementationS */
  /* Because the client's custom response to the question ¿A qué uso estaba destinado el inmueble? can be an
   * arbitrary string, we watch the bank claim field and update the set of values used to populate the select dropdown
   * on the bank claim details pane. The updated set of values includes the client's custom response. */
  $scope.$watch('vm.claim.floor_clause_property_use', function (newValue) {
    if (newValue) {
      vm.FLOOR_CLAUSE_USE.push(vm.claim.floor_clause_property_use);
    }
  });

  function parseCurrenciesAsNumber(claimData, properties = []) {
    properties.forEach(p => (claimData[p] = Number(claimData[p]) || null));
  }

  function checkFloorClauseNotApplying() {
    return vm.claim.applying_floor_clause === 'N';
  }

  function showDocumentSlicingModal() {
    const claimData = {
      claim_id: vm.claim_id,
      claim_prefix: 'BA'
    };
    DialogFactory.sliceDocument(vm.documents, claimData, function () {
      var successMsg = 'Separación de documento solicitada. Cuando termine recibirás un email.';
      vm.claimDetail.update(successMsg);
    });
  }

  function showDocumentBuilder() {
    var currentDocuments = vm.documents;
    var documentTypes = vm.documentTypes;
    var claimTypeId = vm.claim.claim_type.id;
    var claimData = {
      claim_id: vm.claim_id,
      claim_prefix: 'BA',
      claim_type: claimTypeId,
      claim_segment: 'banca'
    };

    var multipleSourceSelectionEnabled = [39, 56, 6, 30].includes(vm.claim.claim_type.id);

    DialogFactory.mergeDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimData,
      multipleSourceSelectionEnabled,
      function () {
        var successMsg = 'Generación de documento solicitada. Cuando termine recibirás un email.';
        vm.claimDetail.update(successMsg);
      }
    );
  }

  function showDocumentCopyModal(prefix, type) {
    var currentDocuments = null;
    var documentTypes = vm.documentTypes;
    var claimTypes = vm.claimTypes;
    var multipleSourceSelectionEnabled = false;
    var claimData = {
      claim_id: vm.claim_id,
      claim_prefix: prefix
    };
    var copy_type = type;

    // If the claim has no documents, an error is shown
    if (vm.documents.length === 0) {
      notifications.addCurrentView(
        'error',
        'No se ha podido realizar la acción. La reclamación no tiene ' + 'ningún documento adjunto.'
      );
      return;
    }

    if (type === 'claim') {
      try {
        currentDocuments = DialogFactory.copyDocsToClaimValidation(vm.documents);
      } catch (e) {
        notifications.addCurrentView('error', e);
        return;
      }
    } else {
      try {
        currentDocuments = DialogFactory.copyDocsToLegalfileValidation(vm.documents, vm.claim);
      } catch (e) {
        notifications.addCurrentView('error', e);
        return;
      }
    }

    DialogFactory.copyDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimTypes,
      claimData,
      multipleSourceSelectionEnabled,
      copy_type,
      function () {
        vm.claimDetail.update();
      }
    );
  }

  function showButton() {
    vm.showRecalcButton = true;
    vm.showUpdatedCalc = false;
    // In expenses, we don't reset compensation each time we change
    // a value in a specific expense.
    if (vm.claim.type.id != 39) {
      vm.claim.compensation = 0;
    }
    vm.estimated_debt_reduction = 0;
  }

  function generateBankLawsuit() {
    notifications.addCurrentView(
      'info',
      'Generando modelo de demanda. Por favor, espera un momento. Ya casi lo tenemos...'
    );

    $http({
      url: serverAddress.getBaseUrl() + 'legalfile/BA/generate_banking_lawsuit/',
      method: 'POST',
      data: {
        claim_id: vm.claim_id,
        segment: 'banking',
        legalfile_doc: true,
        new_draft: 1
      }
    })
      .then(function () {
        vm.claimDetail.update(
          'Operación lanzada, recibirás un email cuando el ' + 'documento esté listo :-)'
        );
      })
      .catch(function (response) {
        notifications.addCurrentView('error', response.data);
      });
  }

  function updateSharesCalculation() {
    vm.makingCalculations = true;

    var data = {
      invested_amount: vm.claim.shares_invested_amount,
      exchange_value: vm.claim.shares_exchange_value,
      current_value: vm.claim.shares_current_value,
      legal_costs: vm.legalfileinfo.legal_costs
    };

    $http({
      url: serverAddress.getBaseUrl() + 'bank-claim/' + vm.claim_id + '/calculate-shares/',
      method: 'POST',
      data
    })
      .then(function (response) {
        notifications.clear();
        notifications.addCurrentView(
          'info',
          'Actualizando los datos de la reclamación. Por favor, espera. Ya casi lo tenemos...'
        );

        // TODO: remove when backend returns currencies as number
        vm.additional_fields.total_shares_converted = Number(response.data.total_shares_converted);
        vm.additional_fields.investment_value = Number(response.data.investment_value);
        vm.additional_fields.loss = Number(response.data.loss);
        vm.additional_fields.fixed_legal_costs = response.data.fixed_legal_costs;
        vm.additional_fields.reclamador_fee = Number(response.data.reclamador_fee);
        vm.claim.compensation = Number(response.data.compensation);

        vm.showUpdatedCalc = true;
        vm.showRecalcButton = false;

        vm.fetchDocuments(true);

        vm.claimDetail.update(
          'Datos de la indemnización actualizados. No hace falta que salves la reclamación'
        );
      })
      .catch(function (response) {
        vm.showUpdatedCalc = false;
        vm.showRecalcButton = false;

        vm.claimDetail.update();

        notifications.addCurrentView('error', response.data);
      })
      .finally(() => {
        vm.makingCalculations = false;
      });
  }

  /***
   * The next 3 funcionts are almost identical. The only things that change
   * are the JSON data we send and the url to call. So, it would make sense to
   * generalize them to one function. But we expect the criteria to change in
   * the future (it has happened...). So, we keep them apart.
   ** */

  function updateFloorClauseCalculation() {
    vm.makingCalculations = true;

    var data = {
      floor_clause_fixed_interest_rate: vm.claim.floor_clause_fixed_interest_rate,
      floor_clause_fixed_interest_rate_months: vm.claim.floor_clause_fixed_interest_rate_months,
      floor_clause_reference_type: vm.claim.floor_clause_reference_type,
      floor_clause_reference_month: vm.claim.floor_clause_reference_month,
      floor_clause_differential: vm.claim.floor_clause_differential,
      floor_clause_years: vm.claim.floor_clause_years,
      floor_clause_revision_period: vm.claim.floor_clause_revision_period,
      floor_clause_mortgage_date: vm.claim.floor_clause_mortgage_date,
      floor_clause_calc_date: vm.claim.floor_clause_calc_date,
      floor_clause_percentage: vm.claim.floor_clause_percentage,
      ceil_clause_percentage: vm.claim.ceil_clause_percentage,
      loan_amount: vm.claim.loan_amount,
      amortizations: vm.claim.amortizations,
      increments: vm.claim.increments,
      from_signature: vm.claim.compensation_from_signature,
      floor_clause_property_use: vm.claim.floor_clause_property_use,
      assume_legal_costs: vm.claim.assume_legal_costs,
      applying_floor_clause: vm.claim.applying_floor_clause,
      date_legal_interests_until: vm.claim.date_legal_interests_until
    };

    $http({
      url: serverAddress.getBaseUrl() + 'bank-claim/' + vm.claim_id + '/calculate-floor-clause/',
      method: 'POST',
      data: data
    })
      .then(function () {
        vm.showUpdatedCalc = true;
        vm.showRecalcButton = false;
        vm.show_warning_after_calc = true;
        vm.claimDetail.update(
          'La tarea de cálculo y generación del informe ha' +
            ' sido lanzada. Cuando termine, te llegará un correo. Si en unos' +
            ' 10 minutos no te ha llegado, por favor, abre un ticket de' +
            ' soporte.'
        );
      })
      .catch(function (response) {
        vm.showUpdatedCalc = false;
        vm.showRecalcButton = false;

        vm.claimDetail.update();

        notifications.addCurrentView('error', response.data);
      })
      .finally(() => {
        vm.makingCalculations = false;
      });
  }

  function updateFormalizationExpensesCalculation() {
    vm.busy = true;
    $http({
      url:
        serverAddress.getBaseUrl() +
        'bank-claim/' +
        vm.claim_id +
        '/generate-form-expenses-report/',
      method: 'GET'
    })
      .then(function (response) {
        notifications.addCurrentView('info', 'Tarea de generacion de informe de gastos lanzada.');
        vm.claim.compensation = response.data.compensation;
        vm.claim.interests = response.data.interests;
      })
      .catch(function (response) {
        notifications.addCurrentView('error', response.data);
      })
      .finally(function () {
        vm.busy = false;
      });
  }

  function generateSwapReport() {
    vm.makingCalculations = true;

    var data = {
      compensation: vm.claim.compensation
    };

    $http({
      url: serverAddress.getBaseUrl() + 'bank-claim/' + vm.claim_id + '/generate-swap-report/',
      method: 'POST',
      data: data
    })
      .then(function (response) {
        notifications.clear();
        notifications.addCurrentView('info', 'Generando informe. Ya casi lo tenemos...');

        vm.claim.compensation = response.data.compensation;

        vm.showUpdatedCalc = true;
        vm.showRecalcButton = false;

        vm.claimDetail.update(
          'Datos de la indemnización actualizados. No hace falta que salves la reclamación'
        );
      })
      .catch(function (response) {
        vm.showUpdatedCalc = false;
        vm.showRecalcButton = false;

        vm.claimDetail.update();

        notifications.addCurrentView('error', response.data);
      })
      .finally(() => {
        vm.makingCalculations = false;
      });
  }

  // TODO: Migrate old code to unique function
  function generateReport() {
    vm.makingCalculations = true;
    vm.claimDetail
      .save('Generando informe. Ya casi lo tenemos...')
      .then(() => ReportService.generate())
      .then(() =>
        vm.claimDetail.update('Datos actualizados. No hace falta que salves la reclamación')
      )
      .catch(e => {
        notifications.addCurrentView('error', e.data);
        console.error(e);
      })
      .finally(() => {
        vm.makingCalculations = false;
      });
  }

  function generateIRPHReport() {
    vm.makingCalculations = true;

    $http({
      url: serverAddress.getBaseUrl() + 'bank-claim/' + vm.claim_id + '/generate-irph-report/',
      method: 'GET'
    })
      .then(function (response) {
        notifications.addCurrentView('info', 'Generando informe. Ya casi lo tenemos...');

        vm.claim.compensation = response.data.compensation;
        vm.claim.interests = response.data.interests;

        vm.claimDetail.update('Datos actualizados. No hace falta que salves la reclamación');
      })
      .catch(function (response) {
        notifications.addCurrentView('error', response.data);
      })
      .finally(() => {
        vm.makingCalculations = false;
      });
  }

  function addAmortization() {
    vm.claim.amortizations.push({ amount: 0, date: '' });
    notifications.addCurrentView(
      'info',
      'Amortización de capital añadida. Recuerda que debes añadir cantidad y fecha y recalcular la indemnización para que se quede almacenado.'
    );
  }

  function removeAmortization(index) {
    vm.claim.amortizations.splice(index, 1);
    notifications.addCurrentView(
      'info',
      'Amortización de capital eliminada. Recuerda que debes recalcular la indemnización para que se quede almacenado.'
    );
  }

  function addIncrement() {
    vm.claim.increments.push({ amount: 0, date: '' });
    notifications.addCurrentView(
      'info',
      'Ampliación de capital añadida. Recuerda que debes añadir cantidad y fecha y recalcular la indemnización para que se quede almacenado.'
    );
  }

  function removeIncrement(index) {
    vm.claim.increments.splice(index, 1);
    notifications.addCurrentView(
      'info',
      'Ampliación de capital eliminada. Recuerda que debes recalcular la indemnización para que se quede almacenado.'
    );
  }

  function calculateCompensation() {
    if (
      vm.claim.claim_type.id === 16 ||
      vm.claim.claim_type.id === 17 ||
      vm.claim.claim_type.id === 27
    ) {
      vm.updateSharesCalculation();
    } else if (vm.claim.claim_type.id === 6) {
      vm.updateFloorClauseCalculation();
    } else if (vm.claim.claim_type.id === 10) {
      vm.generateSwapReport();
    } else if (vm.claim.claim_type.id === 39) {
      vm.updateFormalizationExpensesCalculation();
    } else if (vm.claim.claim_type.id === 30) {
      vm.generateIRPHReport();
    } else {
      vm.generateReport();
    }
  }

  function getMediations() {
    vm.mediations = vm.claim.mediations[vm.claim.mediations.length - 1] || {};
  }

  function sendMediationMail(lawyer, comment, amount, send) {
    var success = 'Se ha guardado la información correctamente :)';

    if (send === true) {
      success = 'Se ha enviado el email al cliente correctamente :)';
    }

    $http
      .post(serverAddress.getBaseUrl() + 'banco/claim/mediation/', {
        bank_claim_id: vm.claim_id,
        lawyer: lawyer,
        comment: comment,
        amount: amount,
        send: send
      })
      .then(function () {
        notifications.addCurrentView('success', success);
      })
      .catch(function (response) {
        notifications.addCurrentView('error', response.data);
      });
  }

  function sendFormalizationExpensesMail() {
    notifications.addCurrentView('info', 'Enviando email al cliente');
    $http
      .post(serverAddress.getBaseUrl() + 'banco/claim/formalizationexpenses-offer/', {
        claim_id: vm.claim_id
      })
      .then(function () {
        notifications.clear();
        notifications.addCurrentView(
          'success',
          'Se ha enviado el email al cliente correctamente: '
        );
      })
      .catch(function (response) {
        notifications.clear();
        notifications.addCurrentView('error', response.data);
      });
  }

  function add_form_expenses() {
    const newFormatlizationExpense = {
      id: null,
      claim_id: vm.claim_id,
      deed_type: 'ND',
      notary_expenses: 0,
      registry_expenses: 0,
      appraisal_expenses: 0,
      management_expenses: 0,
      legal_acts_expenses: 0,
      opening_expenses: 0,
      formalization_calc_date: null
    };

    vm.claim.formalization_expenses.push(Object.assign({}, newFormatlizationExpense));

    vm.claimDetail
      .save()
      .then(() => {})
      .finally(() => {
        vm.busy = false;
      });
  }

  function remove_deed(id) {
    if (confirm('¿Borrar contrato con id ' + id + ' ?')) {
      deleteBankClaimFormExpenses(id, 'Contrato borrado');
    }

    function deleteBankClaimFormExpenses(form_expenses_id, msg) {
      // This is hacky, buy it works. We only pass the argument in one situation (formalization expenses)
      const data = {
        form_expenses_id
      };

      vm.claimDetail
        .save(data, msg)
        .then(() => {})
        .finally(() => {
          vm.busy = false;
        });
    }
  }

  function addCommentSucessCb() {
    vm.claimDetail.update('Comentario añadido con éxito').then(function () {
      vm.fetchHistoryChanges(true);
    });
  }

  function downloadAllBankDocuments() {
    DocumentDownloadFactory.downloadAllBankDocuments(vm.claim);
  }

  function getPaymentTypeLiteral() {
    let v = '';
    switch (vm.claim.payment_type_id) {
      case 1:
        v = 'pago';
        break;
      case 2:
      case 3:
        v = 'cobro';
        break;
      case 4:
        v = 'cobro de costas';
        break;
      default:
        break;
    }
    return v;
  }

  /**
* 
* @param {*} showNewCaseModal 
*/
  function showNewCaseModal() {
    const data = {
      currentDocuments: vm.documents,
      ...[vm.documentType],
      claimData: {
        id: vm.claim.id,
        prefix: vm.SEGMENT_PREFIX,
        type: vm.claim.type.id
      }
    };
    if (!vm.loadedDocuments) {
      vm.fetchDocuments(true)
        .then(() => {
          data.currentDocuments = vm.documentsResponse;
          DialogFactory.generateNewCase(data);
        })
        .catch(() => {
          notifications.addCurrentView('error', `Se produjo un error actualizando los documentos de la ${vm.VERBOSE_NAME.toLowerCase()}`);
        });
    } else {
      DialogFactory.generateNewCase(data);
    }
  }
}
