export default function yesno() {
  return function(input) {
    if (
      input === true ||
      (typeof input === 'string' && (input.toUpperCase() === 'Y' || input.toUpperCase() === 'YES')) ||
      input == 1
    ) {
      return 'Sí';
    } else if (
      input === false ||
      (typeof input === 'string' && (input.toUpperCase() === 'N' || input.toUpperCase() === 'NO')) ||
      input == 0
    ) {
      return 'No';
    }
    return '';
  };
}
