const BOOLEAN_TYPE = [
    { value: true, label: 'Sí' },
    { value: false, label: 'No' }
  ],
  NULL_BOOLEAN_TYPE = [
    { value: null, label: 'No lo sabe' },
    { value: true, label: 'Sí' },
    { value: false, label: 'No' }
  ],
  NUMERAL_BOOLEAN_TYPE = [
    { value: 2, label: 'No lo sabe' },
    { value: 1, label: 'No' },
    { value: 0, label: 'Si' }
  ];

export {
  BOOLEAN_TYPE,
  NULL_BOOLEAN_TYPE,
  NUMERAL_BOOLEAN_TYPE
};
