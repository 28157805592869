import { removeSpacesPhone } from '../../../lib/Utils';

ClientDetailCtrl.$inject = [
  '$scope',
  '$controller',
  '$routeParams',
  '$location',
  '$http',
  'Client',
  'notifications',
  'Province',
  'Agency',
  'Affiliate',
  'serverAddress',
  '$route',
  '$q'
];

export default function ClientDetailCtrl(
  $scope,
  $controller,
  $routeParams,
  $location,
  $http,
  Client,
  notifications,
  Province,
  Agency,
  Affiliate,
  serverAddress,
  $route,
  $q
) {
  const vm = this;

  const INITIAL_STATE = {
    layoutSettings: $route.current.$$route.layoutSetting,
    clientID: $routeParams.id,
    client: {
      id: null,
      user: {
        is_active: true
      }
    },
    agency: null,
    provinces: {},
    identifyDocumentTypes: ['DNI', 'NIE','Pasaporte'],
    // Audit tab
    displayNewComment: false,
    clientComment: '',
    // Affiliate tab
    affiliateActionsDisabled: false,
    busy: false,
    countriesCache: null,
    viewCancelledClientPermission: true
  };
  const METHODS = {
    init,
    fetch,
    save,
    cancel,
    cancellationData,
    saveAffiliate,
    resetAffiliate,
    addCommentSucessCb,
    getImpersonateUrl,
    removeSpacesPhone
  };

  $controller('BaseDetailCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE, METHODS);

  vm.init();

  /* Methods */
  function init() {
    $q.all([Province.get().$promise, Client.countries().$promise, Client.languages().$promise])
      .then(([provinces, countries, languages]) => {
        vm.provinces = provinces.objects;
        vm.countries = countries;
        vm.languages = languages;
      })
      .catch(err => console.error(err));

    vm.fetch();
  }

  function fetch() {
    vm.busy = true;
    const promise$ = Client.get({ id: vm.clientID }).$promise;

    promise$
      .then(response => {
        vm.client = response;
        vm.claim = response; // Required for the templates of same claims

        vm.tabConfig = [
          {
            heading: 'Detalle',
            indexKey: 'detalle',
            template: 'js/modules/client/partials/detail/details.html',
            select: null,
            headingBadge: null
          },
          {
            heading: 'Casos',
            indexKey: 'mismo-cliente',
            template: '/partials/common/detail/same_client/base.html',
            select: vm.fetchSameUserClaims,
            headingBadge: Number(vm.client.number_of_claims_same_client)
          },
          {
            heading: 'Historial',
            indexKey: 'historial',
            template: 'js/modules/client/partials/detail/history.html',
            select: null,
            headingBadge: null
          }
        ];

        if (vm.client.affiliate) {
          vm.tabConfig.push({
            heading: 'Afiliado',
            indexKey: 'afiliado',
            template: 'js/modules/client/partials/detail/affiliate.html',
            select: null,
            headingBadge: null
          });
        }

        if (vm.client.is_agency) {
          Agency.get({ clientId: vm.clientID }).$promise.then(function (agencyResult) {
            vm.agency = agencyResult.objects[0];

            // Agency_type
            vm.agency.type = 'Agencia';
            if (vm.agency.is_company) vm.agency.type = 'Empresa';
            else if (vm.agency.is_consolidator) vm.agency.type = 'Consolidador';

            // Agency contract url
            if (vm.agency.contract) {
              vm.agency.contract = `https://www.reclamador.es/agencies/contract/${vm.agency.agency_urls[0].slug}/download`;
            }
            // Agency logo url
            if (vm.agency.logo) {
              vm.agency.logo = `https://www.reclamador.es${vm.agency.logo.replace(
                'media',
                'data'
              )}`;
            }

            // Add Tipo agencia to tabs
            if (vm.agency) {
              vm.tabConfig.push({
                heading: vm.agency.type,
                indexKey: 'tipo-agencia',
                template: 'js/modules/client/partials/detail/agency.html',
                select: null,
                headingBadge: null
              });
            }
          });
        }
      })
      .catch(err => {
        if (err['data'] && err['data'] == 'No tienes permisos para ver usuarios cancelados.'){
          notifications.addCurrentView('error', err['data']);
          vm.viewCancelledClientPermission = false;
          vm.client.user.is_active = false;
        }else{
          notifications.addCurrentView('error', 'Error al recuperar la información del cliente');
        }
        console.error(err);
      })
      .finally(() => {
        vm.busy = false;
      });

    return promise$;
  }

  function save() {
    Client.update({ id: vm.clientID }, vm.client)
      .$promise.then(function () {
        notifications.addNextView('info', 'Cliente actualizado con exito');
        vm.fetch();
      })
      .catch(function (data) {
        if (data.status == 500) {
          notifications.addCurrentView('error', 'Error inesperado. Abre ticket a IT');
        } else {
          for (var k in data.data) {
            if (Object.prototype.hasOwnProperty.call(data.data, k)) {
              notifications.addCurrentView('error', data.data[k]);
            }
          }
        }
      });
  }

  function cancel() {
    $location.path('clients');
  }

  function cancellationData() {
    $http
      .post(serverAddress.getBaseUrl() + 'client/cancellation/', {
        client_id: vm.clientID
      })
      .then(function () {
        notifications.addCurrentView('info', 'Cliente cancelado');
        vm.fetch();
      });
  }

  function saveAffiliate() {
    vm.affiliateActionsDisabled = true;

    Affiliate.update({ affiliateId: vm.client.affiliate.id }, vm.client.affiliate)
      .$promise.then(function () {
        return Affiliate.get({ affiliateId: vm.client.affiliate.id }).$promise;
      })
      .then(function (getResponse) {
        vm.client.affiliate = getResponse;
        notifications.addCurrentView('success', 'Información actualizada');
      })
      .catch(function (errorResponse) {
        Object.keys(errorResponse.data.affiliate).forEach(function (key) {
          notifications.addCurrentView('error', key + ': ' + errorResponse.data.affiliate[key][0]);
        });
      })
      .finally(function () {
        vm.affiliateActionsDisabled = false;
      });
  }

  function resetAffiliate() {
    vm.affiliateActionsDisabled = true;

    Affiliate.get({ affiliateId: vm.client.affiliate.id })
      .$promise.then(function (response) {
        vm.client.affiliate = response;
      })
      .finally(function () {
        vm.affiliateActionsDisabled = false;
      });
  }

  function addCommentSucessCb() {
    notifications.addCurrentView('info', 'Comentario añadido con exito');
    vm.fetch();
  }

  // Exclusive client method
  function getImpersonateUrl() {
    return serverAddress.getImpersonateUrl() + 'impersonate/' + vm.client.user.id;
  }
}
