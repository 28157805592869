FlightsListCtrl.$inject = ['$scope', '$controller', 'AirlineConstants'];

export default function FlightsListCtrl($scope, $controller, AirlineConstants) {
  const vm = this;
  const claimDetailTabs = {};
  AirlineConstants.tabConfig.forEach(element => {
    claimDetailTabs[element.indexKey] = element.heading;
  });
  const INITIAL_STATE = {
    claimDetailTabs,
    VEHICLE_TYPES: AirlineConstants.VEHICLE_TYPES,
    CAUSES: AirlineConstants.CAUSES,

    itemsPath: 'flight/list/',
    listPath: 'airlines/flights/',
    listPathNoFIlter: 'airlines/flights/',
    defaultColumnDef: AirlineConstants.defaultColumnDef_flights,
    filterFieldInfo: AirlineConstants.filterFieldInfo_flights
  };
  $controller('BaseListCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE);

  /** Initiate List! */
  vm.initBaseList(
    AirlineConstants.SEGMENT_ID,
    AirlineConstants.ALIAS,
    AirlineConstants.permissions_flights
  );
}
