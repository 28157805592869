import angular from 'angular';

import CommentEditCtrl from './CommentEditCtrl';
import CommentEditService from './commentEdit.service';

angular
  .module('reclamador.components.commentEdit', [])
  .factory('CommentEditService', CommentEditService)
  .controller('CommentEditCtrl', CommentEditCtrl)
  .component('commentEdit', {
    bindings: {
      idComment: '<',
      oldComment: '<',
      originalComment: '<',
      documentType: '<',
      documentUri: '<'
    },
    controller: 'CommentEditCtrl',
    controllerAs: 'vm',
    template: require('./comment-edit.html')
  });
