ClientSearchCtrl.$inject = ['$location', '$routeParams', 'notifications'];

export default function ClientSearchCtrl($location, $routeParams, notifications) {
  let phoneNumber = $location.search()['user_number'] || $routeParams.phonenumber;

  if (phoneNumber) {
    notifications.clear();
    notifications.addNextView('info', 'Buscando cliente con telefono: ' + phoneNumber);

    $location.search({}).path('/clients/list/filter/filterText=' + phoneNumber);
  } else {
    $location.path('/clients/');
  }
}
