AssignToLawyerModalCtrl.$inject = ['$rootScope', '$scope', 'dialog', 'Targets', 'Lawyers'];

export default function AssignToLawyerModalCtrl ($rootScope, $scope, dialog, Targets, Lawyers) {
  try { // modal factory for some reason don't throw errors, so...
    $scope.vm = Object.assign({}, {
      // methods
      ok,
      cancel,

      // state
      Targets,
      Lawyers: Lawyers.filter(e => e.username != $rootScope.worker.username),
      targetLawyer: null
    });

    function ok () {
      dialog.close();
      return dialog.deferred.resolve($scope.vm.targetLawyer);
    }

    function cancel () {
      dialog.close();
      return dialog.deferred.reject(null);
    }
  } catch(e){
    console.log(e);
  }
}