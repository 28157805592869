AssigmentModalShared.$inject = ['$rootScope'];

export default function AssigmentModalShared($rootScope) {
  const sharedData = {};

  function set(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        sharedData[key] = data[key];
      }
    }
    $rootScope.$broadcast('sharedDataSend');
  }

  function get(key) {
    return sharedData[key];
  }

  function getSharedData() {
    return sharedData;
  };

  return {
    set,
    get,
    getSharedData
  };
}
