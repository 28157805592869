import angular from 'angular';

import ClientDetailCtrl from './detailCtrl';
import ClientListCtrl from './listCtrl';
import ClientSearchCtrl from './clientSearchCtrl';

import Client from './clientService';

import ClientRoutes from './routes';
import ClientConstants from './constants';

angular
  .module('reclamador.client', [])

  .controller('ClientDetailCtrl', ClientDetailCtrl)
  .controller('ClientListCtrl', ClientListCtrl)
  .controller('ClientSearchCtrl', ClientSearchCtrl)

  .factory('Client', Client)
  .constant('ClientConstants', ClientConstants)

  .config(ClientRoutes);
