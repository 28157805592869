DocumentsInboxService.$inject = ['$resource', 'serverAddress'];
ProcessedDocumentsInboxService.$inject = ['$resource', 'serverAddress'];

var DOCUMENTS_INBOX_LIST_PATH = 'api2/v1/inboxdocument/';
var DOCUMENTS_INBOX_COMMON_PATH = 'documentsinbox/';
var PROCESSEDDOCUMENTS_INBOX_LIST_PATH = 'api2/v1/processedinboxdocument/';

export function DocumentsInboxService($resource, serverAddress) {
  return $resource(serverAddress.getBaseUrl() + DOCUMENTS_INBOX_LIST_PATH, {}, {
    get: {
      params: {
        format: 'json',
        limit: 4,
        offset: 0,
        order_by: '-date_added'
      }
    },
    actions: {
      method: 'POST',
      url: serverAddress.getBaseUrl() + DOCUMENTS_INBOX_COMMON_PATH + 'actions/'
    },
    merge: {
      method: 'POST',
      url: serverAddress.getBaseUrl() + DOCUMENTS_INBOX_COMMON_PATH + 'merge/'
    },
    slice: {
      method: 'POST',
      url: serverAddress.getBaseUrl() + DOCUMENTS_INBOX_COMMON_PATH + 'slice/'
    },
    getUsers: {
      method: 'GET',
      url: serverAddress.getBaseUrl() + DOCUMENTS_INBOX_COMMON_PATH + 'users/'
    }});
}


export function ProcessedDocumentsInboxService($resource, serverAddress) {
  return $resource(serverAddress.getBaseUrl() + PROCESSEDDOCUMENTS_INBOX_LIST_PATH, {}, {
    get: {
      params: {
        format: 'json',
        limit: 4,
        offset: 0,
        order_by: '-date_processed'
      }
    }
  });
}
