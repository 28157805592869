/**
* Document Row Controller:
* Controler for a document row component in a document table.
*/

DocumentRowCtrl.$inject = [
  '$scope'
];

export default function DocumentRowCtrl (
  $scope
) {
  const vm = this;

  Object.assign(vm, {
    // bindings
    document: null,
    documentTypes: [],
    documentGroup: null,
    documentationAnalysis: [],
    hasViewPermission: false,

    // actions
    validate,
    invalidate,
    deleteDoc,
    downloadDoc,
    changeDocType,
    openInDrive,

    initViewer,

    // internal state
    documentTypeSelect: null,
    showDocumentTypePopover: false
  });

  // click on document
  function initViewer() {
    $scope.$emit('viewer:loadDoc', vm.document);
  }

  // delegated methods
  function deleteDoc() {
    $scope.$emit('document:delete', vm.document);
  }

  function downloadDoc() {
    $scope.$emit('document:download', vm.document);
  }

  function changeDocType(type) {
    $scope.$emit('document:changeType', vm.document, type, vm.documentGroup.key);
  }

  function validate() {
    if (vm.document) {
      $scope.$emit('document:validate', vm.document, true);
    }
  }

  function invalidate() {
    if (vm.document) {
      $scope.$emit('document:validate', vm.document, false);
    }
  }

  function openInDrive() {
    $scope.$emit('document:openDrive', vm.document);
  }

  $scope.$watch('vm.document.type', type => {
    vm.documentTypeSelect = type;
  });
}
