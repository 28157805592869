const SEGMENT_ID = 3,
  MODEL_PROVIDER = 'GenericClaim';

export default {
  SEGMENT_ID,
  MODEL_PROVIDER,
  DJANGO_MODEL: MODEL_PROVIDER,
  SEGMENT_PREFIX: 'GE',
  VERBOSE_NAME: 'Reclamación genérica',
  ALIAS: 'generic',
  permissions: {
    downloadCSVPermission: 'segment-perobject-LA-crm_download_csv'
  },

  tabConfig: [
    {
      heading: 'Detalles',
      indexKey: 'detalles'
    },
    {
      heading: 'Documentación',
      indexKey: 'documentacion'
    },
    {
      heading: 'Historial',
      indexKey: 'historial'
    }
  ],
  defaultColumnDef: [
    { headerName: 'ID', fieldname: 'id' },
    { headerName: 'Tipo', fieldname: 'subtype' },
    { headerName: 'Cliente', fieldname: 'client_surnames' },
    { headerName: 'Correo cliente', fieldname: 'client_email' },
    { headerName: 'Abogado', fieldname: 'laywer' }
  ],
  filterFieldInfo: {}
};
