export default function slasPanelBlock() {
  return {
    restrict: 'E',
    template: `
    <div class="card">
      <div class="card-header">
        <h5>{{ slaPanelTitle }}</h5>
      </div>
      <div class="card-body text-center">
        <div class="card-text">
          <div class="{{ pendingWorkClass }}" ng-if="slaItemsCount > 0">
            <p>{{ slaItemsCount }}</p>
            <span class="sla-target-url"><a ng-href="{{ targetPath }}">Ir al listado</a></span>
          </div>
          <div class="{{ completedWorkClass }}" ng-if="slaItemsCount <= 0">
            <p>{{ slaItemsCount }}</p>
            <span class="black">{{ completedWorkMsg }}</span>
          </div>
        </div>
      </div>
    </div>
    `,
    scope: {
      slaPanelTitle: '=',
      pendingWorkClass: '=',
      completedWorkClass: '=',
      completedWorkMsg: '=',
      targetPath: '=',
      slaItemsCount: '='
    }
  };
}
