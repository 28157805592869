sameFlightCSV.$inject = ['$http', 'serverAddress'];

export default function sameFlightCSV($http, serverAddress) {
  return sameFlightCSV;

  function sameFlightCSV(sameFlightClaimIds) {
    return $http({
      url: serverAddress.getBaseUrl() + 'claim/list/20/0/excel/',
      method: 'GET',
      params: {
        query: null,
        sort: {},
        filter: {
          same_flights_claim_ids: {
            column: 'claim.id',
            json_value: 'true',
            name: 'same_flights_claim_ids',
            value: sameFlightClaimIds
          }
        }
      }
    });
  }
}
