import $ from 'jquery';

AssigmentModalStep2Ctrl.$inject = [
  'AssigmentModalShared',
  'AssigmentModalStep2Service',
  '$scope',
  '$timeout',
  'toastr'
];

export default function AssigmentModalStep2Ctrl(
  AssigmentModalShared,
  AssigmentModalStep2Service,
  $scope,
  $timeout,
  toastr
) {
  const vm = this;

  $scope.$on('sharedDataSend', () => {
    const getDataStep1 = AssigmentModalShared.getSharedData();

    vm.getAssignedLawyers = getDataStep1.selectedAssignedLawyers.filter(
      lawyerId => lawyerId !== null
    );
    vm.getCurrentLawyer = getDataStep1.selectedCurrentLawyer;
    vm.getPhase = getDataStep1.selectedPhase;
    vm.getType = getDataStep1.selectedType;
    vm.getVertical = getDataStep1.selectedVertical;
    vm.getTotalDocuments = getDataStep1.totalDocuments;

    vm.isLoadingData = true;

    const notificationAssignment = () => {
      const totalAssignedLawyers = vm.getAssignedLawyers.length;
      const documentsPerLawyer = Math.floor(vm.getTotalDocuments / totalAssignedLawyers);
      let remainingDocuments = vm.getTotalDocuments % totalAssignedLawyers;

      for (let i = 0; i < totalAssignedLawyers; i++) {
        vm.getAssignedLawyers[i].assignedDocuments = documentsPerLawyer;
      }

      let currentLawyerIndex = totalAssignedLawyers - 1;
      while (remainingDocuments > 0) {
        vm.getAssignedLawyers[currentLawyerIndex].assignedDocuments++;
        remainingDocuments--;
        currentLawyerIndex--;
      }

      $('[id^="lawyerStep2-"]').each(function (index) {
        const assignedDocuments = vm.getAssignedLawyers[index].assignedDocuments;
        $(this).val(assignedDocuments);
      });
    };

    vm.calculateTotalAssignedNotifications = () => {
      let totalAssignedNotifications = 0;
      for (let i = 0; i < vm.getAssignedLawyers.length; i++) {
        totalAssignedNotifications += vm.getAssignedLawyers[i].assignedDocuments;
      }
      return totalAssignedNotifications;
    };

    vm.calculateAssignedPercentage = assignedDocuments => {
      const totalDocuments = vm.getTotalDocuments;
      const percentage = (assignedDocuments / totalDocuments) * 100;
      return Math.min(Math.round(percentage), 100);
    };

    vm.updatePercentage = lawyer => {
      lawyer.percentage = vm.calculateAssignedPercentage(lawyer.assignedDocuments);
    };

    vm.calculateTotalAssignedPercentage = () => {
      const totalNotifications = vm.calculateTotalAssignedNotifications();
      const totalDocuments = vm.getTotalDocuments;

      if (totalNotifications === totalDocuments) {
        return 100;
      }

      let totalPercentage = 0;
      for (let i = 0; i < vm.getAssignedLawyers.length; i++) {
        totalPercentage += vm.calculateAssignedPercentage(
          vm.getAssignedLawyers[i].assignedDocuments
        );
      }
      return totalPercentage;
    };

    vm.sendData = () => {
      const assignationsData = vm.getAssignedLawyers.map(lawyer => {
        return {
          lawyer: lawyer.resource_uri,
          total_documents: lawyer.assignedDocuments
        };
      });

      const requestData = {
        current_lawyer: vm.getCurrentLawyer.resource_uri,
        assignations: assignationsData
      };

      if (vm.getPhase) {
        requestData.procedural_phase = vm.getPhase.resource_uri;
      }

      if (vm.getVertical) {
        requestData.segment = vm.getVertical;
      }

      if (vm.getType) {
        requestData.type = vm.getType.resource_uri;
      }

      AssigmentModalStep2Service.postData(requestData)
        .then(response => {
          console.info('Asignación realizada correctamente', response);
          toastr.info('Asignación realizada correctamente.');
          $scope.$emit('assignmentSentSuccessfully');
          $scope.$emit('clearModalFields');
        })
        .catch(error => {
          console.error('Error al enviar la solicitud:', error);
          toastr.error('Error al enviar la solicitud. Por favor, inténtelo de nuevo más tarde.');
        });
    };

    $timeout(() => {
      notificationAssignment();
      vm.isLoadingData = false;
    }, 1100);
  });
}
