InheritancePhoneQuestions.$inject = ['$resource', 'serverAddress'];

export default function InheritancePhoneQuestions($resource, serverAddress) {
  return $resource(
    serverAddress.getBaseUrl() + 'api2/v1/inheritance-phone-questions/:id/',
    {},
    {
      update: {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json;' }
      }
    }
  );
}
