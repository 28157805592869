Profiles.$inject = ['$http', 'serverAddress', '$window'];
export default function Profiles($http, serverAddress, $window) {
  const baseURL = `${serverAddress.getBaseUrl()}profiles/api/`;
  return {
    activateAccount,
    reset,
    resetConfirm,
    getPermissions
  };

  function activateAccount(uidb36, token, pass1, pass2) {
    return $http.post(`${serverAddress.getBaseUrl()}rrhh/api/worker/${uidb36}/${token}/activate/`, $window.$.param({ new_password1: pass1, new_password2: pass2 }), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });
  }

  function reset(email) {
    return $http.post(`${baseURL}crm-password-reset/`, $window.$.param({ email }), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });
  }

  function resetConfirm(uidb36, token, pass1, pass2) {
    return $http.post(`${baseURL}crm-password-reset-confirm/${uidb36}/${token}/`, $window.$.param({ new_password1: pass1, new_password2: pass2 }), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    });
  }

  function getPermissions(userId) {
    return $http.get(`${baseURL}users/${userId}/crm-permissions/`);
  }
}
