ForeignerServicesClaimDetailCtrl.$inject = [
  '$scope',
  '$controller',
  'fileUploaderGenerator',
  'serverAddress',
  'DialogFactory',
  'ForeignerServicesConstants'
];
export default function ForeignerServicesClaimDetailCtrl(
  $scope,
  $controller,
  fileUploaderGenerator,
  serverAddress,
  DialogFactory,
  ForeignerServicesConstants
) {
  const vm = this;

  const INITIAL_STATE = {
    claim: {
      client: {
        user: {
          is_active: true
        }
      }
    },
    viewCancelledClientPermission: true,
    displayDocumentsUploader: false
  };

  const METHODS = {
    initClaimCB,

    showDocumentMergingModal,
    showDocumentSlicingModal
  };

  $controller('BaseDetailCtrl', { vm: vm, $scope: $scope });

  Object.assign(vm, INITIAL_STATE, METHODS, ForeignerServicesConstants);

  vm.claimDetail.init();

  /* Function declaration */
  /* BaseDetail Callbacks */
  function initClaimCB() {
    vm.getDocumentTypes();
  }

  fileUploaderGenerator({
    vm: vm,
    url: serverAddress.getDocumentUrl() + 'common/upload/',
    fillItemData: function(item) {
      vm.busy = true;
      item.formData = [
        {
          type_id: (item.documenType && item.documenType.id) || 0,
          comment: item.comment || '',
          claim_id: vm.claim.id,
          segment: vm.DJANGO_MODEL
        }
      ];
    },
    sucessCallback: () => {
      vm.claimDetail.update('Archivo subido con éxito, actualizando reclamación');
    }
  });

  // FROM HERE JUST FUNCTIONS => => => => => => => => => =>

  function showDocumentMergingModal() {
    var currentDocuments = vm.documents;
    var documentTypes = vm.documentTypes;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: vm.SEGMENT_PREFIX
    };

    var multipleSourceSelectionEnabled = false;
    DialogFactory.mergeDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimData,
      multipleSourceSelectionEnabled,
      function() {
        vm.claimDetail.update('Unión de documentos solicitada. Cuando termine recibirás un email.');
      }
    );
  }

  function showDocumentSlicingModal() {
    var currentDocuments = vm.documents;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: vm.SEGMENT_PREFIX
    };
    DialogFactory.sliceDocument(currentDocuments, claimData, function() {
      vm.claimDetail.update(
        'Separación de documento solicitada. Cuando termine recibirás un email.'
      );
    });
  }
}
