VCType.$inject = ['$resource', 'serverAddress'];

export default function VCType($resource, serverAddress) {
  return $resource(
    `${serverAddress.getBaseUrl()}api2/v1/videocalltype/:id/`,
    {
      offset: 0,
      limit: 0
    },
    {
      update: {
        method: 'PATCH',
        params: {},
        isArray: false
      }
    }
  );
}
