CommonSalesDashboardCtrl.$inject = [
  '$scope',
  '$http',
  '$route',
  '$injector',
  '$timeout',
  'serverAddress',
  '$window',
  '$q',
  'notifications',
  'ClaimState',
  'SalesClaimState',
  'PanelTools'
];

export default function CommonSalesDashboardCtrl(
  $scope,
  $http,
  $route,
  $injector,
  $timeout,
  serverAddress,
  $window,
  $q,
  notifications,
  ClaimState,
  SalesClaimState,
  PanelTools
) {
  var vm = this;

  vm.busy = true;
  $scope.$on('ngRepeatFinished', $window.rclmng_globals.ux.pepinColFixer.resizeTables);

  vm.layoutSettings = $route.current.$$route.layoutSetting;

  vm.SalesmanService = $injector.get($route.current.$$route.SalesmanService);
  vm.claimListBaseURL = $route.current.$$route.claimListBaseURL;
  vm.salesPeopleCountsURL = $route.current.$$route.salesPeopleCountsURL;
  vm.segmentName = $route.current.$$route.segmentName;
  vm.segmentID = $route.current.$$route.segmentID;

  vm.salesSteps = [
    { name: 'Terminada', filter: 'finished' },
    { name: 'Autorización suplico enviada', filter: 'request_sent' },
    { name: 'Desistido suplico', filter: 'suplico_desist' },
    { name: 'Citado notario', filter: 'summoned_notary' },
    { name: 'Desistido inasistencia notario', filter: 'notary_desist' },
    { name: 'Citado consulado', filter: 'summoned_consulate' },
    { name: 'Desistido inasistencia consulado', filter: 'consulate_desist' },
    { name: 'Pendiente poder forense (PT)', filter: 'pending_forensic_pt' }
  ];

  vm.is_extern = false;
  vm.language = '';

  /* Startup */
  var promises = [
    $http.get(serverAddress.getBaseUrl() + vm.salesPeopleCountsURL),
    vm.SalesmanService.get().$promise,
    SalesClaimState.get({segments__id: vm.segmentID}).$promise
  ];
  if (vm.segmentID == 1) {
    promises.push(PanelTools.getUserSalesStatusClaimCounts(vm));
  } else if (vm.segmentID == 2) {
    promises.push(ClaimState.get({ segments__id: 2 }).$promise);
  }

  $q
    .all(promises)
    .then(
      function(data) {
        vm.counts = data[0].data;
        vm.salesman = data[1];
        vm.sales_states = data[2].objects;

        if (vm.segmentID == 2) {
          // parse Claimstate for banking
          vm.states = data[3].objects;
          vm.bank_sales_states = [];
          //TODO: refactor legalstates for banking out of here
          var validStates = [26, 130, 61, 6, 36, 72, 74, 63, 200];
          for (var i = 0; i < vm.states.length; i++) {
            if (validStates.indexOf(vm.states[i].id) > -1) {
              vm.bank_sales_states.push(vm.states[i]);
            }
          }
        } else {
          vm.bank_sales_states = [];
        }

        // ux update
        vm.busy = false;
        $timeout($window.rclmng_globals.ux.pepinColFixer.init);
      },
      function() {
        notifications.addCurrentView('error', 'Lo sentimos, se produjo un error solicitando la informe.');
        vm.busy = false;
      }
    );
}
