import angular from 'angular';
import $ from 'jquery';

EditNotificationController.$inject = ['$scope', 'NotificationService', 'notifications'];

export default function EditNotificationController($scope, NotificationService, notifications) {
  var vm = this;
  vm.modalId = 'editModal';

  vm.openEditModal = function () {
    vm.tempDocument = {};
    vm.tempDocument = angular.copy(vm.document);
    $('#' + vm.modalId + '-' + vm.index).modal('show');
  };

  vm.saveChanges = function () {
    var dataToSend = {
      type: vm.tempDocument.type.resource_uri,
      comment: vm.tempDocument.comment,
      procedural_phase: vm.tempDocument.procedural_phase ? vm.tempDocument.procedural_phase.resource_uri : null,
      due_date: vm.tempDocument.due_date,
      without_due_date: vm.tempDocument.without_due_date,
      lawyer: vm.tempDocument.lawyer ? vm.tempDocument.lawyer.resource_uri : null
    };

    NotificationService.updateNotification(vm.document.resource_uri, dataToSend)
      .then(function () {
        vm.onDocumentUpdated(`Documento ${vm.document.filename} actualizado`);
      })
      .catch(function (error) {
        notifications.addCurrentView('error', JSON.stringify(error.data));
      });

    $('#' + vm.modalId + '-' + vm.index).modal('hide');
  };
}
