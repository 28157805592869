LegalServicesListCtrl.$inject = ['$scope', '$controller', 'LegalServicesConstants', 'dataLoader'];

export default function LegalServicesListCtrl($scope, $controller, LegalServicesConstants, dataLoader) {
  const vm = this;
  const claimDetailTabs = {};
  LegalServicesConstants.tabConfig.forEach(element => {
    claimDetailTabs[element.indexKey] = element.heading;
  });
  const INITIAL_STATE = {
    ALIAS: LegalServicesConstants.ALIAS,
    claimDetailTabs,

    itemsPath: 'legalservices/list/',
    listPath: 'legalservices-claims/list/filter/',
    listPathNoFIlter: 'legalservices-claims/',
    defaultColumnDef: LegalServicesConstants.defaultColumnDef,
    filterFieldInfo: LegalServicesConstants.filterFieldInfo
  };
  $controller('BaseListCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE);
  /** Initiate List! */
  vm.initBaseList(
    LegalServicesConstants.SEGMENT_ID,
    LegalServicesConstants.ALIAS,
    LegalServicesConstants.permissions
  ).then(() => {
    dataLoader('videocalltype').$promise.then(data => {
      vm.contact_type_list = data.objects;
    });
  });
}
