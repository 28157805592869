AssigmentModalStep2Service.$inject = ['$http', 'serverAddress'];

export default function AssigmentModalStep2Service($http, serverAddress) {
  const BASE_PATH = 'api2/v1/';
  const ENDPOINT = 'legalfile_document_massive_assignation/';

  function postData(data) {
    const url = serverAddress.getBaseUrl() + BASE_PATH + ENDPOINT;
    return $http
      .post(url, data)
      .then(response => response.data)
      .catch(error => {
        console.error('Error en la solicitud:', error);
        throw error;
      });
  }

  return {
    postData
  };
}
