/* miniModal: The smallest posible Modal component
*
* Displays a modal with the transcluded content and the passed options.
* The minimal configuration requires a show parameter to tell the component to
* display the modal and a ok or ko callback to call a method (and close it).
* It also let you set a modal title, nd change the buttons texts.
*/
import $ from 'jquery';
import angular from 'angular';

miniModalCtrl.$inject = ['$attrs', '$document', '$scope'];
function miniModalCtrl($attrs, $document, $scope) {
  let vm = this;

  vm.$onInit = () => {
    if(!$attrs.okCb && !$attrs.koCb) throw new Error('miniModal: At least 1 callback is required to close the modal.');

    vm.showOkCb = !!$attrs.okCb;
    vm.showKoCb = !!$attrs.koCb;
  };

  vm.clickBackdrop = function() {
    if(vm.disableClickBackDrop) return;
    vm.resetModal();
  };

  vm.resetModal = function() {
    if(!vm.showKoCb) return vm.okCb();
    return vm.koCb();
  };

  vm.$onChanges = function (changes) {
    if(vm.disableKeys) return;

    if(changes.show) {
      if(changes.show.currentValue) {
        $document.on('keyup', escapeHandler);
        $('body').addClass('modal-open');
      } else {
        $document.off('keyup', escapeHandler);
        $('body').removeClass('modal-open');
      }
    }
  };

  const escapeHandler = function(e) {
    if (e.key == 'Escape') $scope.$apply(() => vm.resetModal());
  };
}

angular
  .module('reclamador.components.miniModal', [])
  .controller('miniModalCtrl', miniModalCtrl)
  .component('miniModal', {
    bindings:{
      okCb: '&', // Callback for the ok button
      koCb: '&', // Callback for the ko button
      okText: '<', // The ok button text ('Aceptar' by default)
      koText: '<', // The ko button text ('Cancelar' by default)
      titleText: '<', // The title of the modal
      show: '<', // Show or hide the modal
      disableClickBackDrop: '<', // don't launch events
      disableKeys: '<', // don't check key events
      reverseButtons: '<' // display ok button as red
    },
    transclude : true,
    controllerAs: 'vm',
    controller: 'miniModalCtrl',
    template: `
      <div ng-if="vm.show">
        <div class="modal fade"
            ng-class="{'d-block show': vm.show}">
          <div class="modal-dialog" style="z-index:9999"><div class="modal-content">
            <div ng-if="vm.titleText" class="modal-header"><h3 class="mb-0" ng-bind="vm.titleText"></h3></div>
            <div class="modal-body"><ng-transclude></ng-transclude></div>
            <div class="modal-footer">
              <button ng-if="vm.showOkCb && !vm.reverseButtons" ng-click="vm.okCb()" class="btn btn-primary" ng-class="{red: vm.reverseButtons}" ng-bind="vm.okText || 'Aceptar'"></button>
              <button ng-if="vm.showKoCb" ng-click="vm.koCb()" class="btn btn-primary" ng-class="{red: !vm.reverseButtons}" ng-bind="vm.koText || 'Cancelar'"></button>
              <button ng-if="vm.showOkCb && vm.reverseButtons" ng-click="vm.okCb()" class="btn btn-primary" ng-class="{red: vm.reverseButtons}" ng-bind="vm.okText || 'Aceptar'"></button>
            </div>
          </div></div>
          <div class="modal-backdrop fade"
              ng-class="{show: vm.show}"
              ng-click="vm.clickBackdrop()"></div>
        </div>
      </div>`
  });