import { getSegmentPath, getSegmentName } from '../../../lib/Utils';

GenerateNewCase.$inject = [
  'currentDocuments',
  'documentTypes',
  'claimData',
  'dialog',
  'CreateNewClaim',
  'dataLoader',
  'toastr',
  '$scope'
];

export default function GenerateNewCase(
  currentDocuments,
  documentTypes,
  claimData,
  dialog,
  CreateNewClaim,
  dataLoader,
  toastr,
  $scope
) {
  const vm = this;

  const INITIAL_STATE = {
    claimData,
    documentTypes,
    currentDocuments,
    autorizedCurrentDocuments: [],
    sameSegment: null,
    segmentName: '',
    productSelected: null,
    claimTypes: [],
    filteredClaimTypes: [],
    selectedDocuments: [],
    selectedDocumentType: null,
    selectedClaimType: null,
    lawyerComment: null,
    setFormFinished: null,
    legalServicesCategories: [],
    legalServicesCategorySelected: null,

    allClaimsByType: [],

    actionInProgress: false
  };

  const METHODS = {
    closeModal,
    getDocumentsIds,
    createNewClaim,
    disabledCreateButton
  };

  Object.assign(vm, INITIAL_STATE, METHODS);

  /* Init */
  init();

  /* Functions */
  function init() {
    vm.segmentName = getSegmentName(vm.claimData.prefix);

    dataLoader('claimTypesForNewCase')
      .$promise.then(data => {
        vm.claimTypes = data.objects;
        vm.filteredClaimTypes = vm.claimTypes;
      })
      .catch(err => console.error(err));
    vm.autorizedCurrentDocuments = vm.currentDocuments.filter(
      doc => doc.type.allowed_copy_level !== 2
    );

    $scope.$watch('vm.productSelected', function () {
      if (vm.productSelected) {
        // Reset previous values
        vm.legalServicesCategorySelected = null;

        // Check if new case must to be form finished
        vm.setFormFinished = [57, 58, 59].includes(vm.productSelected.id);

        // Check if new case is SG to load category list
        if ([59].includes(vm.productSelected.id)) {
          /* SG catalog */
          dataLoader('videocallinternaltype')
            .$promise.then(data => {
              vm.legalServicesCategories = data.objects.map(el => ({ id: el.id, name: el.name }));
            })
            .catch(err => console.error(err));
        }
      }
    });
    $scope.$watch('vm.sameSegment', function () {
      if (vm.sameSegment === null) {
        vm.filteredClaimTypes = vm.claimTypes;
      } else if (vm.sameSegment) {
        vm.filteredClaimTypes = vm.claimTypes.filter(cT => cT.type === vm.segmentName);
      } else {
        vm.filteredClaimTypes = vm.claimTypes.filter(cT => cT.type !== vm.segmentName);
      }
    });
  }

  /**
   *
   * @param void
   */
  function createNewClaim() {
    vm.actionInProgress = true;
    /**
    claim_id --> current claim_id
    old_claim_type --> current claim type id
    new_claim_type_id --> new claim type id
    set_form_finished -->  # True if form must be finished
    document_list_ids --> document id list
    lawyer_comment --> text comment
    */

    const params = {
      claim_id: vm.claimData.id,
      old_claim_type: vm.claimData.type,
      new_claim_type_id: vm.productSelected.id,
      form_finished: vm.setFormFinished,
      document_ids: vm.getDocumentsIds(),
      lawyer_comment: vm.lawyerComment,
      sg_category: vm.legalServicesCategorySelected && vm.legalServicesCategorySelected.id
    };
    return CreateNewClaim.save(params)
      .$promise.then(result => {
        generateToastWithLink(result);
        vm.actionInProgress = false;
        dismiss(result);
      })
      .catch(error => {
        toastr.error(error);
      });
  }

  function getDocumentsIds() {
    vm.selectedDocuments = vm.currentDocuments.filter(d => d.selected === true);
    return vm.selectedDocuments.map(idsToCopy => idsToCopy.id);
  }

  function closeModal() {
    dialog.close(false); // Do not run callback
  }
  function dismiss(data) {
    dialog.close(data); // Run callback
  }

  function generateToastWithLink(result) {
    const link = getSegmentPath(result.prefix);
    toastr.success(
      `<p class="body"><a href="/#/${link}/${result.id}" target="blank" class="btn btn-success h4">${result.message}</a></p>`,
      'Nuevo caso generado',
      {
        allowHtml: true,
        timeOut: 0
      }
    );
  }

  function disabledCreateButton() {
    const basecondition = vm.productSelected === null || vm.actionInProgress;
    if (basecondition) return true;
    // Only apply to products != Videocall
    return (
      [59].includes(vm.productSelected.id) &&
      vm.claimData.type != 58 &&
      vm.legalServicesCategorySelected === null
    );
  }
}
