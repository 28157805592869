export default function rc() {
  return function(input) {
    var str = String(input).toLowerCase();

    if (str === 'convenio') {
      return 'C';
    }

    if (str === 'reglamento') {
      return 'R';
    }

    return '';
  };
}
