/* eslint-disable angular/di */
import './vendors'; /* vendors */
import $ from 'jquery';
import angular from 'angular';

// Sentry
import * as Sentry from '@sentry/browser';
// import { Integrations } from '@sentry/tracing';
import { Angular as AngularIntegration } from '@sentry/integrations';

import AppVersion from './app.version';
Sentry.init({
  dsn: process.env.___PUBLIC_DSN___,
  environment: process.env.NODE_ENV,
  release: AppVersion.version,
  integrations: [
    new AngularIntegration()
    // new Integrations.BrowserTracing({
    //   tracingOrigins: ['localhost', 'https://yourserver.io/api']
    // })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.6
});

import { init } from './modules/pepinColumnFixer';
import { permission, ngPermission } from 'angular-permission';

import routeProviderConfig from './app.routes';
import { processVersioning, templateRequestInterceptor } from './app.config';

import './modules';
import './components';

import './services';
import './factories';
import './controllers';
import './controllers/modals';
import './directives';
import './filters';

import uiButton from 'ui-bootstrap4/src/buttons';
import uiTooltip from 'ui-bootstrap4/src/tooltip';
import uiDropdown from 'ui-bootstrap4/src/dropdown';

import '../css/index.css';

angular
  .module('reclamador', [
    'ngRoute',
    permission,
    ngPermission,
    'toastr',
    'ngSentry',
    'reclamador.version',
    'ngSanitize',
    'ngResource',
    'ngCookies',
    'ngAnimate',

    uiButton,
    uiTooltip,
    uiDropdown,
    'ui.bootstrap.old',

    'ui.date',
    'ui.select2',
    'ui.select', // This is the good one
    'ngCsvImport',
    'dndLists',
    'angularFileUpload',
    'ng-currency',
    'angular-google-analytics',
    'moment-picker',

    // Components
    'reclamador.components',

    'reclamador.services',
    'reclamador.factories',
    'reclamador.controllers',
    'reclamador.modalControllers',
    'reclamador.controllers.base',
    'reclamador.directives',
    'reclamador.filters',

    // Modules
    'reclamador.Auth',
    'reclamador.dashboard.operations',
    'reclamador.costs',
    'reclamador.worker',
    'reclamador.massive',
    'reclamador.segments',
    'reclamador.client',
    'reclamador.userpanel',
    'reclamador.emailindex',

    'reclamador.itux',

    'reclamador.fcm'
  ])
  .config(['$locationProvider', $locationProvider => $locationProvider.hashPrefix('')])
  .config(['$qProvider', $qProvider => $qProvider.errorOnUnhandledRejections(false)])
  .config([
    '$resourceProvider',
    $resourceProvider => {
      $resourceProvider.defaults.stripTrailingSlashes = false;
    }
  ])
  .config([
    '$httpProvider',
    '$templateRequestProvider',
    ($httpProvider, $templateRequestProvider) => {
      $httpProvider.interceptors.push(['version', '$templateCache', templateRequestInterceptor]);
      $templateRequestProvider.httpOptions({ _isTemplate: true });
    }
  ])
  .config([
    '$compileProvider',
    $compileProvider =>
      $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|callto|dynamicsnav|tel):/)
  ])
  .config(['version', version => processVersioning(version)])
  .config([
    'AnalyticsProvider',
    AnalyticsProvider => {
      AnalyticsProvider.setAccount('UA-33662291-8').trackUrlParams(true);
    }
  ])
  .config([
    'momentPickerProvider',
    function (momentPickerProvider) {
      momentPickerProvider.options({
        /* Picker properties */
        locale: 'es',
        format: 'DD/MM/YYYY HH:mm'
      });
    }
  ])
  .config([
    'uiSelectConfig',
    function (uiSelectConfig) {
      uiSelectConfig.theme = 'bootstrap';
    }
  ])
  .config([
    'toastrConfig',
    toastrConfig => {
      angular.extend(toastrConfig, {
        preventDuplicates: true,
        timeOut: 10000
      });
    }
  ])
  .config(routeProviderConfig)
  .config([
    '$permissionProvider',
    $permissionProvider => {
      $permissionProvider.suppressUndefinedPermissionWarning(true);
    }
  ])
  .run(['$rootScope', '$timeout', '$window', '$document', 'version', 'Analytics', appRun]);

function appRun($rootScope, $timeout, $window, $document, version, Analytics) {
  $window.$ = $;
  init(); // pepinColumnFixer ñap

  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

  Analytics.set('dimension1', process.env.NODE_ENV);

  $rootScope.$on('event:auth-session', (ev, user) => {
    Analytics.set('&uid', user.id);
    // Set user information, as well as tags and further extras
    Sentry.configureScope(scope => {
      scope.setUser({
        email: user.email,
        id: user.id,
        version
      });
    });
  });

  /*
   * On route changes, save and check the time since the last change.
   * It it's bigger than TIME_TO_RELOAD force tab reload.
   */
  $rootScope.$on('$routeChangeStart', () => {
    const TIME_TO_RELOAD = 7200000; // 2 hours

    if (!$rootScope.lastRouteChange) {
      $rootScope.lastRouteChange = new Date().getTime();
    } else {
      const now = new Date().getTime();
      const sessionTime = now - $rootScope.lastRouteChange;
      if (sessionTime > TIME_TO_RELOAD) {
        $window.location.reload();
      }
      $rootScope.lastRouteChange = now;
    }
  });
  // fix table layouts when new content loads
  $rootScope.$on('$viewContentLoaded', () => $timeout($window.rclmng_globals.ux.resize, 0));

  /*
   * Adds classes to the body based on the current route layoutParams
   */
  $rootScope.$on('$routeChangeSuccess', (e, route) => {
    const PREFIX = 'routeClass-';
    const BODY_CLASS_LIST = $document[0].body.classList;
    const CLASSES_ARRAY = Array.from(BODY_CLASS_LIST.values()).filter(e => e.indexOf(PREFIX) == 0);

    if (CLASSES_ARRAY.length) BODY_CLASS_LIST.remove(...CLASSES_ARRAY);
    if (route.$$route && route.$$route.layoutClass)
      BODY_CLASS_LIST.add(PREFIX + route.$$route.layoutClass);
  });

  //Change page title, based on Route informations
  $rootScope.$on('$routeChangeSuccess', function (e, currentRoute) {
    if (currentRoute.$$route && currentRoute.$$route.title) {
      $document[0].title = currentRoute.$$route.title + ' | reclamador.es cerreme';
    }
  });

  const chorrafrases = [
    'Entre Cádiz y Madrid',
    'Los vasos de café en el contenedor de papel',
    'Arriba con los cuerpos',
    'Bebete el zumo que se le van las vitaminas',
    'La natación es el deporte más completo',
    'Dos pantallas',
    'FN fideos negros',
    'Delicateses',
    'Comiendo bistech',
    'Suerte en tu nueva andadura',
    'Todo comunica',
    'Crear pedido',
    'Ay que bueno',
    'Hay dulces en la cocina',
    'Tiki tiki',
    'Titiriti, Titiriti titi',
    'Esto es un pepino',
    'Hojas de salario?!',
    'Teníamos la sala reservada',
    '¿Queda café?',
    '¿Habéis visto cómo está el baño?',
    '¿Está puesto el aire?',
    'Marga, ponme un tercio pls',
    'Al cielo con ella',
    'Dream machine 🌈',
    'Me salgo a fumar',
    'se agradece',
    'golpe de remo',
    'el viernes Techfriday!',
    'Y dale molino...',
    'No seas Adán',
    'La piscina da mucha hambre',
    'Como vaya y lo encuentre...',
    'Trae, que tú no sabes',
    'Y os lo queríais perder...',
    '¿Alguien quiere gazpacho?',
    'Prohibido hidratos',
    'There was a temporary glitch with our UI services',
    'A vuela pluma',
    'Hasta luego, Maricarmen',
    'Se me hace bola',
    'Siempre saludaba',
    'Pero chico eso es básico'
  ];
  $rootScope.chorrafrase = chorrafrases[parseInt(Math.random() * chorrafrases.length)];
}
