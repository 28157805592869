alerts.$inject = ['notifications', '$rootScope'];

export default function alerts(notifications, $rootScope) {
  return {
    link: function($scope) {
      $scope.notifications = notifications.get();

      $rootScope.$on('updateNotifications', () => {
        $scope.notifications = notifications.get();
      });

      // Clear messages broadcasting angular events
      $rootScope.$on('clearNotifications', () => {
        $scope.notifications = [];
      });
    },
    replace: true,
    restrict: 'E',
    template: `
      <div class="alert-wrap">
        <div ng-click="notification.autoremove()" class="no-animate alert alert-{{ notification.type }}" 
            ng-repeat="notification in notifications track by $index" 
            ng-mouseenter="notification.stop()" 
            ng-mouseout="notification.start()">
          <div ng-bind-html="notification.msg"></div>
          <span class="close">×</span>
        </div>
      </div>
    `
  };
}
