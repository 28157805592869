import angular from 'angular';

import newCommentCtrl from './newCommentCtrl';

angular
  .module('reclamador.components.newComment', [])
  .controller('newCommentCtrl', newCommentCtrl)
  .component('newCommentComponent', {
    bindings:{
      objectId: '<',
      objectType: '<',
      sucessCb: '<',
      hasLegalfile: '<'
    },
    controller: newCommentCtrl,
    controllerAs: 'vm',
    template: require('./template.html')
  });
