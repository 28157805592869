routeProviderConfig.$inject = ['$routeProvider'];
export default function routeProviderConfig($routeProvider) {
  $routeProvider.when('/loading', {
    title: 'Cargando datos en la aplicación...',
    template: `
    <div class="splash-page d-flex justify-content-center align-items-center flex-column">
      <div class="splash-loader" title="loading...."></div>
      <div class="splash-message mt-2">{{ chorrafrase }}</div>
    </div>
    `
  });
  $routeProvider.otherwise({ redirectTo: '/user/claims-panel' });
}
