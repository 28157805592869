EmailIndexCtrl.$inject = ['$scope', '$controller'];
export default function EmailIndexCtrl($scope, $controller) {
  const vm = this;

  const INITIAL_STATE = {
    itemsPath: 'email/list/',
    listPath: 'emails/',
    listPathNoFIlter: 'emails/',
    defaultColumnDef: [
      { headerName: 'ID Reclamación', fieldName: 'email_claim_id', sortable: true },
      { headerName: 'Estatus', fieldName: 'status', sortable: true },
      { headerName: 'Fecha de envío', fieldName: 'date_sent', sortable: true },
      { headerName: 'Tipo', fieldName: 'email_claim_type', sortable: true },
      { headerName: 'Cliente', fieldName: 'client', sortable: true },
      { headerName: 'Destino', fieldName: 'to', sortable: true },
      { headerName: 'Remitente', fieldName: 'sender', sortable: true },
      { headerName: 'Email', fieldName: 'subject', sortable: true },
      { headerName: 'Apertura', fieldName: 'open', sortable: true, hidden: true },
      { headerName: 'Click', fieldName: 'clicked', sortable: true }
    ],
    languages: ['es', 'en', 'pt'],
    domains: ['es', 'pt']
  };
  const METHODS = {
    loadEmailDetail,
    getClaimType,
    emailStatusClass,
    isRegistrationEmailRejected
  };
  $controller('BaseListCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE, METHODS);

  vm.initBaseList();

  function loadEmailDetail(email) {
    vm.loadedEmail = email;
  }

  function emailStatusClass(status) {
    if (status && status == 'Entregado') {
      return 'alert alert-primary';
    } else if ((status && status == 'Rechazado') || status == 'Rebotado') {
      return 'alert alert-danger';
    }
    return '';
  }

  function isRegistrationEmailRejected(email) {
    if (email.tags && email.tags.indexOf('bienvenida') > -1 && email.status != 'Entregado') return 'email-info-error';
  }

  function getClaimType() {
    if (vm.loadedEmail != undefined) {
      switch (vm.loadedEmail.type) {
        case 'airline':
          return 'Aerolíneas';
        case 'bank':
          return 'Banca';
        case 'laboral':
          return 'Laboral';
        case 'accident':
          return 'Accidente';
        case 'phone':
          return 'Telefonía';
        case 'so':
          return 'Segunda Oportunidad';
        case 'ta':
          return 'Impuestos';
        case 'foreignerservices':
          return 'Extranjería';
        default:
          return '-';
      }
    }
  }

}
