AccidentClaimDetailCtrl.$inject = [
  '$scope',
  '$controller',
  'notifications',
  'serverAddress',
  'DocumentDownloadFactory',
  'DialogFactory',
  'fileUploaderGenerator',
  'AccidentConstants'
];

export default function AccidentClaimDetailCtrl(
  $scope,
  $controller,
  notifications,
  serverAddress,
  DocumentDownloadFactory,
  DialogFactory,
  fileUploaderGenerator,
  AccidentConstants
) {
  const vm = this;

  const INITIAL_STATE = {
    claim: {
      client: {
        user: {
          is_active: true
        }
      }
    },
    viewCancelledClientPermission: true
  };

  const METHODS = {
    // baseDetail config
    initClaimCB,

    downloadAllAccidentsDocuments,

    showDocumentMergingModal,
    showDocumentSlicingModal,
    showDocumentCopyModal,
    actions: {
      showNewCaseModal
    }
  };


  // GO TIME
  $controller('BaseDetailCtrl', { vm: vm, $scope: $scope });

  Object.assign(vm, INITIAL_STATE, METHODS, AccidentConstants);

  vm.claimDetail.init();

  fileUploaderGenerator({
    vm: vm,
    url: serverAddress.getDocumentUrl() + 'accident/upload/',
    fillItemData: function(item) {
      vm.busy = true;

      item.formData = [
        {
          type_id: item.documenType.id || 0,
          comment: item.comment || '',
          claim_id: vm.claim.id
        }
      ];
    },
    sucessCallback: () => {
      vm.claimDetail.update('Archivo subido con éxito, actualizando reclamación');
    }
  });

  // FROM HERE JUST FUNCTIONS => => => => => => => => => =>
  function initClaimCB() {
    vm.setTabs();
    vm.getDocumentTypes();
  }

  function downloadAllAccidentsDocuments() {
    DocumentDownloadFactory.downloadAllAccidentsDocuments(vm.claim);
  }

  function showDocumentMergingModal() {
    var currentDocuments = vm.documents;
    var documentTypes = vm.documentTypes;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: 'AC'
    };

    var multipleSourceSelectionEnabled = false;
    DialogFactory.mergeDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimData,
      multipleSourceSelectionEnabled,
      function() {
        vm.claimDetail.update('Unión de documentos solicitada. Cuando termine recibirás un email.');
      }
    );
  }

  function showDocumentSlicingModal() {
    var currentDocuments = vm.documents;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: 'AC'
    };
    DialogFactory.sliceDocument(currentDocuments, claimData, function() {
      vm.claimDetail.update('Separación de documento solicitada. Cuando termine recibirás un email.');
    });
  }

  function showDocumentCopyModal(prefix, type) {
    var currentDocuments = null;
    var documentTypes = vm.documentTypes;
    var claimTypes = vm.claimTypes;
    var multipleSourceSelectionEnabled = false;
    var copy_type = type;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: prefix
    };

    // If the claim has no documents, an error is shown
    if (vm.documents.length === 0) {
      notifications.addCurrentView(
        'error',
        'No se ha podido realizar la acción. La reclamación no tiene ningún documento adjunto.'
      );
      return;
    }

    if (type === 'claim') {
      try {
        currentDocuments = DialogFactory.copyDocsToClaimValidation(vm.documents);
      } catch (e) {
        notifications.addCurrentView('error', e);
        return;
      }
    } else {
      try {
        currentDocuments = DialogFactory.copyDocsToLegalfileValidation(vm.documents, vm.claim);
      } catch (e) {
        notifications.addCurrentView('error', e);
        return;
      }
    }

    DialogFactory.copyDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimTypes,
      claimData,
      multipleSourceSelectionEnabled,
      copy_type,
      function() {
        vm.claimDetail.update();
      }
    );
  }

  /**
 * 
 * @param {*} showNewCaseModal 
 */
  function showNewCaseModal() {
    const data = {
      currentDocuments: vm.documents,
      ...[vm.documentType],
      claimData: {
        id: vm.claim.id,
        prefix: vm.SEGMENT_PREFIX,
        type: vm.claim.type.id
      }
    };
    if (!vm.loadedDocuments) {
      vm.fetchDocuments(true)
        .then(() => {
          data.currentDocuments = vm.documentsResponse;
          DialogFactory.generateNewCase(data);
        })
        .catch(() => {
          notifications.addCurrentView('error', `Se produjo un error actualizando los documentos de la ${vm.VERBOSE_NAME.toLowerCase()}`);
        });
    } else {
      DialogFactory.generateNewCase(data);
    }
  }
}
