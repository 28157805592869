Affiliate.$inject = ['$resource', 'serverAddress'];

var AFFILIATE_PATH = 'api2/v1/affiliate/:affiliateId/?format=json';

export default function Affiliate($resource, serverAddress) {
  return $resource(
    serverAddress.getBaseUrl() + AFFILIATE_PATH,
    {},
    {
      get: { method: 'GET' },
      update: { method: 'PUT' }
    }
  );
}
