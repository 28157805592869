import angular from 'angular';

import './styles.css';

import HistoryListCtrl from './HistoryListCtrl';

angular
  .module('reclamador.components.historyList', [])
  .controller('HistoryListCtrl', HistoryListCtrl)
  .component('historyList', {
    bindings: {
      history: '<',
      format: '<'
    },
    controller: 'HistoryListCtrl',
    controllerAs: 'vm',
    template: require('./template.html')
  });