AccidentsRoutes.$inject = ['$routeProvider', 'AccidentConstants'];

export default function AccidentsRoutes($routeProvider, AccidentConstants) {
  $routeProvider.when('/accidents/lawyers/counts', {
    title: 'Panel Abogados Accidentes',
    templateUrl: 'partials/common/panels/lawyers.html',
    data: {
      permissions: {
        only: 'segment-perobject-AC-crm_view_lawyers_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Accidentes',
      iconClass: 'fas fa-road',
      cardHead: 'Panel Abogados Accidentes'
    },
    controller: 'AccidentLawyerPanelCtrl',
    controllerAs: 'vm',
    segmentID: AccidentConstants.SEGMENT_ID
  });

  $routeProvider.when('/accidents/salespeople/counts', {
    title: 'Panel Comerciales Accidentes',
    templateUrl: 'partials/common/panels/sales.html',
    data: {
      permissions: {
        only: 'segment-perobject-AC-crm_view_salespeople_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Accidentes',
      iconClass: 'fas fa-road',
      cardHead: 'Panel Comerciales Accidentes'
    },
    controller: 'CommonSalesDashboardCtrl',
    controllerAs: 'vm',
    claimListBaseURL: '#/accident-claims/list/',
    SalesmanService: 'AccidentSalesman',
    salesPeopleCountsURL: 'accident-claim/salespeople/counts/',
    segmentName: 'Accidentes',
    segmentID: AccidentConstants.SEGMENT_ID
  });

  const permissions = {
    only: 'segment-perobject-AC-crm_view_claims_list',
    redirectTo: 'no-permissions'
  };

  const claimListConfig = {
    title: 'Listado reclamaciones Accidentes',
    templateUrl: 'partials/common/baseList/claim-list.html',
    data: { permissions },
    layoutSetting: {
      contentTitle: 'Accidentes',
      iconClass: 'fas fa-road'
    },
    baseListSettings: {
      typeList: 'accident',
      typeTitle: 'accidentes',
      templateListTable: 'js/modules/segments/accident/partials/claim-list-table.html',
      templateListFilter: 'partials/common/baseList/common-claim-list-filter.html',
      title: 'Reclamaciones Accidentes',
      listResultsText: 'reclamaciones de accidentes'
    },
    controller: 'AccidentClaimListCtrl',
    controllerAs: 'vm',
    segmentID: AccidentConstants.SEGMENT_ID
  };

  $routeProvider.when('/accident-claims', claimListConfig);
  $routeProvider.when('/accident-claims/list/filter/:filter', claimListConfig);

  $routeProvider.when('/accident-claims/:id', {
    title: 'Detalle reclamación Accidente',
    templateUrl: 'js/modules/segments/accident/partials/detail.html',
    data: {
      permissions: {
        only: 'segment-perobject-AC-crm_view_claim_detail',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Accidentes',
      iconClass: 'fas fa-road'
    },
    controller: 'AccidentClaimDetailCtrl',
    controllerAs: 'vm',
    segmentID: AccidentConstants.SEGMENT_ID,
    reloadOnSearch: false
  });
}
