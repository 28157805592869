import { extend, copy } from 'angular';

DialogFactory.$inject = ['$dialog', '$timeout', '$window'];

export default function DialogFactory($dialog, $timeout, $window) {
  const service = {
    changeClaimLawyer,
    deleteDocument,
    deleteLegalFileDocument,
    mergeDocumentsFromSelection,
    copyDocumentsFromSelection,
    generateNewCase,
    sliceDocument,
    copyDocsToClaimValidation,
    copyDocsToLegalfileValidation
  };

  return service;

  function changeClaimLawyer(scopeModel) {
    const changeLawyerDialogOpts = {
      backdrop: true,
      keyboard: true,
      backdropClick: true,
      templateUrl: 'partials/common/modals/case-change-lawyer.html',
      controller: 'ConfirmLawyerChangeCtrl'
    };

    const options = extend(changeLawyerDialogOpts, {
      resolve: {
        legalfileId: function () {
          return scopeModel.legalfile.id;
        },
        lawyer: function () {
          return scopeModel.legalfile.claims_lawyer;
        },
        legalfilePrefix: function () {
          return scopeModel.legalfile.prefix || 'AE';
        },
        parentScope: function () {
          return scopeModel;
        }
      }
    });

    $dialog.dialog(options).open();
  }

  function deleteDocument(doc, controller) {
    $dialog
      .dialog({
        backdrop: true,
        keyboard: true,
        backdropClick: true,
        template: require('../document-manager/delete-document.html'),
        controller,
        resolve: {
          doc() {
            return doc;
          }
        }
      })
      .open();
  }

  function deleteLegalFileDocument(doc, legalfile, controller) {
    $dialog
      .dialog({
        backdrop: true,
        keyboard: true,
        backdropClick: true,
        template: require('../document-manager/delete-document.html'),
        controller,
        resolve: {
          doc() {
            return doc;
          },
          legalfile() {
            return legalfile;
          }
        }
      })
      .open();
  }

  function mergeDocumentsFromSelection(
    currentDocuments,
    documentTypes,
    claimData,
    multipleSourceSelectionEnabled,
    callback
  ) {
    const opts = {
      backdrop: true,
      keyboard: true,
      backdropClick: true,
      templateUrl: 'partials/common/modals/document-builder.html',
      controller: 'DocumentBuilderCtrl as vm',
      dialogClass: 'modal modal-full modal-builder modal-pinned',
      dialogSize: 'large'
    };

    const options = extend(opts, {
      resolve: {
        currentDocuments: function () {
          return copy(currentDocuments);
        },
        documentTypes: function () {
          return copy(documentTypes);
        },
        claimData: function () {
          return copy(claimData);
        },
        multipleSourceSelectionEnabled: function () {
          return copy(multipleSourceSelectionEnabled);
        }
      }
    });

    $timeout(() => {
      const $modal = $window.$('.modal-builder');
      const modalBodyHeight =
        $window.$(window).height() -
        $modal.find('.modal-header').outerHeight() -
        $modal.find('.modal-footer').outerHeight();

      $modal
        .find('.builder-body')
        .css({
          height: modalBodyHeight,
          overflow: 'auto'
        })
        .find('.doc-builder-panel-body')
        .css({
          height: modalBodyHeight - 200
        });
    }, 1000);

    return $dialog
      .dialog(options)
      .open()
      .then(runCallback => {
        if (runCallback) {
          return callback();
        }
        return false;
      });
  }

  function copyDocumentsFromSelection(
    currentDocuments,
    documentTypes,
    claimTypes,
    claimData,
    multipleSourceSelectionEnabled,
    copy_type,
    callback
  ) {
    const opts = {
      backdrop: true,
      keyboard: true,
      backdropClick: true,
      templateUrl:
        copy_type === 'claim'
          ? 'partials/common/modals/copy-documents-to-claim-modal.html'
          : 'partials/common/modals/copy-documents-to-legalfile-modal.html',
      controller: 'CopyDocumentsCtrl as vm',
      dialogClass: 'modal modal-full modal-builder modal-pinned',
      dialogSize: 'large'
    };

    const options = extend(opts, {
      resolve: {
        currentDocuments: function () {
          return copy(currentDocuments);
        },
        documentTypes: function () {
          return copy(documentTypes);
        },
        claimTypes: function () {
          return copy(claimTypes);
        },
        claimData: function () {
          return copy(claimData);
        },
        multipleSourceSelectionEnabled: function () {
          return copy(multipleSourceSelectionEnabled);
        },
        copy_type: function () {
          return copy(copy_type);
        }
      }
    });

    $timeout(() => {
      const $modal = $window.$('.modal-builder');
      const modalBodyHeight =
        $window.$(window).height() -
        $modal.find('.modal-header').outerHeight() -
        $modal.find('.modal-footer').outerHeight();

      $modal
        .find('.builder-body')
        .css({
          height: modalBodyHeight,
          overflow: 'auto'
        })
        .find('.doc-builder-panel-body')
        .css({
          height: modalBodyHeight - 200
        });
    }, 1000);

    return $dialog
      .dialog(options)
      .open()
      .then(runCallback => {
        if (runCallback) {
          return callback();
        }
        return false;
      });
  }

  function sliceDocument(currentDocuments, claimData, callback) {
    const opts = {
      backdrop: true,
      keyboard: true,
      backdropClick: true,
      templateUrl: 'partials/common/modals/documents-to-slice-selector.html',
      controller: 'SelectDocumentsToSliceCtrl',
      dialogClass: 'modal modal-full modal-slicer',
      dialogSize: 'large'
    };

    const options = extend(opts, {
      resolve: {
        currentDocuments: function () {
          return copy(currentDocuments);
        },
        claimData: function () {
          return copy(claimData);
        }
      }
    });

    $timeout(() => {
      const $modal = $window.$('.modal-slicer');
      const modalBodyHeight =
        $window.$(window).height() -
        $modal.find('.modal-header').outerHeight() -
        $modal.find('.modal-footer').outerHeight();

      $modal
        .find('.modal-body')
        .css({
          height: modalBodyHeight,
          overflow: 'auto'
        })
        .find('.table-contents')
        .css({
          height: modalBodyHeight - 150,
          'max-height': modalBodyHeight - 150
        });
    }, 1000);

    return $dialog
      .dialog(options)
      .open()
      .then(runCallback => {
        if (runCallback) {
          return callback();
        }
        return false;
      });
  }

  function copyDocsToClaimValidation(documents) {
    // Only documents with "warning" or "allowed" permission will be loaded
    const currentDocuments = documents.filter(d => d.type.allowed_copy_level !== 0);
    // After filtering by doc permission, if there is no document left, an error is shown
    if (currentDocuments.length === 0) {
      throw new Error(
        'No se ha podido realizar la acción. La reclamación no tiene ningún documento cuya copia a otra reclamación esté permitida.'
      );
    }
    return currentDocuments;
  }

  function copyDocsToLegalfileValidation(documents, claim) {
    // Only validated documents will be loaded
    const currentDocuments = documents.filter(d => d.is_validated);
    if (currentDocuments.length === 0) {
      throw new Error(
        'No se ha podido realizar la acción. No hay ningún documento validado en esta reclamación y sólo se pueden copiar al expediente documentos validados.'
      );
    }
    if (claim.legalfile === null) {
      throw new Error(
        'No se ha podido realizar la acción. La reclamación no tiene ningún expendiente asociado.'
      );
    }
    return currentDocuments;
  }

  function generateNewCase({
    currentDocuments,
    documentTypes,
    claimData
  }) {
    const opts = {
      backdrop: true,
      keyboard: false,
      backdropClick: true,
      templateUrl: 'partials/common/modals/generate-new-case.html',
      controller: 'GenerateNewCaseCtrl as vm',
      dialogClass: 'modal modal-full modal-builder modal-pinned',
      dialogSize: 'large'
    };
    const options = extend(opts, {
      resolve: {
        currentDocuments: function () {
          return copy(currentDocuments);
        },
        documentTypes: function () {
          return copy(documentTypes);
        },
        claimData: function () {
          return copy(claimData);
        }
      }
    });
    return $dialog
      .dialog(options)
      .open();
  }
}
