import angular from 'angular';

SelectDocumentsToSliceCtrl.$inject = [
  '$scope',
  'currentDocuments',
  'claimData',
  'dialog',
  'DocumentTransformationFactory',
  'notifications'
];

export default function SelectDocumentsToSliceCtrl(
  $scope,
  currentDocuments,
  claimData,
  dialog,
  DocumentTransformationFactory,
  notifications
) {
  var DEFAULT_ROTATION_ITEM = {
    id: 'rotation',
    page: '',
    rotation: 0
  };

  /* Functions */
  $scope.dismiss = dismiss;
  $scope.closeModal = closeModal;
  $scope.sliceDocument = sliceDocument;
  $scope.selectedDocument = '';
  $scope.addPageRotationItem = addPageRotationItem;
  $scope.rotateCounterClockwise = rotateCounterClockwise;
  $scope.rotateClockwise = rotateClockwise;

  /* Scope variables */
  $scope.currentDocuments = currentDocuments;
  $scope.pagesToSlice = '';
  $scope.pageRotations = [];
  $scope.numPageRotations = $scope.pageRotations.length;

  /* Function implementations */
  function validateParams() {
    // A document must be selected
    if (!$scope.selectedDocumentId) {
      notifications.addCurrentView('error', 'Por favor, indica qué documento quieres separar');
      return false;
    }

    // Must have specified at least one page or page range
    if (!$scope.pagesToSlice) {
      notifications.addCurrentView('error', 'Por favor, indica al menos una página que quieras extraer');
      return false;
    }

    return true;
  }

  function sliceDocument() {
    // exit process if validation return false
    if (!validateParams()) return;

    /* Prepare pages */
    var rotations = {};
    for (var i = 0; i < $scope.numPageRotations; i++) {
      var rotationItem = $scope.pageRotations[i];
      var pageNumber = rotationItem['page'];
      if (pageNumber && pageNumber != '') {
        rotations[pageNumber] = rotationItem['rotation'];
      }
    }

    var slicingData = {
      doc_to_split_id: $scope.selectedDocumentId,
      pages: ($scope.pagesToSlice || '').split(','),
      rotations: rotations
    };

    DocumentTransformationFactory.sliceDocument(claimData, slicingData).then(function() {
      $scope.dismiss();
    });
  }

  function closeModal() {
    dialog.close(false);
  }

  function dismiss() {
    dialog.close(true);
  }

  /* Rotation logic */
  function addPageRotationItem() {
    var newItemNo = $scope.numPageRotations.length + 1;
    var newRotationItem = angular.copy(DEFAULT_ROTATION_ITEM);
    newRotationItem['id'] = newRotationItem['id'] + newItemNo;
    $scope.pageRotations.push(newRotationItem);
    $scope.numPageRotations += 1;
  }

  function rotateCounterClockwise(pageRotationItem) {
    pageRotationItem.rotation += 90;
  }

  function rotateClockwise(pageRotationItem) {
    pageRotationItem.rotation -= 90;
  }
}
