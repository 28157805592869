AirlineClaimListCtrl.$inject = [
  '$scope',
  '$controller',
  'AirlineConstants',
  'ClaimAgency',
  'ClaimConsolidator'
];

export default function AirlineClaimListCtrl(
  $scope,
  $controller,
  AirlineConstants,
  ClaimAgency,
  ClaimConsolidator
) {
  const vm = this;
  const claimDetailTabs = {};
  AirlineConstants.tabConfig.forEach(element => {
    claimDetailTabs[element.indexKey] = element.heading;
  });
  const INITIAL_STATE = {
    claimDetailTabs,
    ALIAS: AirlineConstants.ALIAS + '-claims',

    itemsPath: 'claim/list/',
    listPath: 'airline-claims/list/filter/',
    listPathNoFIlter: 'airline-claims',
    defaultColumnDef: AirlineConstants.defaultColumnDef,
    filterFieldInfo: AirlineConstants.filterFieldInfo,
    languages: ['es', 'en', 'pt'],
    domains: ['es', 'pt']
  };
  const METHODS = {
    getClaimConsolidator,
    getClaimAgencies
  };
  $controller('BaseListCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE, METHODS);

  /** Initiate List! */
  vm.initBaseList(
    AirlineConstants.SEGMENT_ID,
    AirlineConstants.ALIAS,
    AirlineConstants.permissions
  ).then(() => {
    getClaimConsolidator().then(data => {
      vm.consolidators = data.objects;
    });
    getClaimAgencies().then(data => {
      vm.agencies = data.objects;
    });
  });

  /* Function declaration */
  function getClaimConsolidator() {
    return ClaimConsolidator.get().$promise;
  }
  function getClaimAgencies() {
    return ClaimAgency.get().$promise;
  }
}
