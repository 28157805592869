import LegalfilesConstants from './constants';

LegalFilesListCtrl.$inject = ['$scope', '$controller', 'dataLoader'];

export default function LegalFilesListCtrl($scope, $controller, dataLoader) {
  const vm = this;

  const INITIAL_STATE = {
    lawyersBySegment: {
      ALL: [],
      IM: [],
      AE: [],
      AC: [],
      BA: [],
      LA: [],
      TE: [],
      SO: [],
      TA: [],
      NE: []
    },

    legalfileDetailTabs: {
      resumen: '<i class="fas fa-bullhorn"></i> Resumen',
      reclamaciones: '<i class="fas fa-gavel"></i> Reclamaciones',
      documentacion: '<i class="fas fa-file-alt"></i> Documentacion',
      costas: '<i class="fas fa-money-bill-alt"></i> Costas e intereses',
      historial: '<i class="fas fa-history"></i> Historial'
    },

    segmentToFilter: 'ALL',
    ALIAS: LegalfilesConstants.ALIAS,

    itemsPath: 'files/list/',
    listPath: 'legalfiles/list/filter/',
    listPathNoFIlter: 'legalfiles',

    claimStates: {},
    legalFilesStatisticsGroups: {},

    procuradores: {},
    externalcourtlawyers_forairlines: {},
    externalcourtlawyers_forbanking: {},
    externalcourtlawyers_forlaboral: {},
    externalcourtlawyers_fornegligences: {},
    externalcourtlawyers_forphone: {},
    externalcourtlawyers_foraccidents: {},
    externalcourtlawyers_fortaxes: {},
    costStates: {},
    defaultColumnDef: LegalfilesConstants.defaultColumnDef,
    filterFieldInfo: LegalfilesConstants.filterFieldInfo,
    VALID_NEW_FILTERS: LegalfilesConstants.VALID_NEW_FILTERS
  };

  const METHODS = {
    segmentToFilterHandler,
    clearSegmentDependantFilters,
    fillFilter,
    init
  };

  $controller('BaseListCtrl', { vm, $scope: $scope });

  const requiredPermissions = {
    downloadCSVPermission: 'segment-global-crm_download_legalfiles_csv'
  };
  vm.setRequiredPermissions(requiredPermissions);

  Object.assign(vm, INITIAL_STATE, METHODS);

  $scope.$watch('vm.segmentToFilter', function(newSegmentToFilter, oldSegmentToFilter) {
    if (newSegmentToFilter !== oldSegmentToFilter) {
      vm.segmentToFilterHandler(newSegmentToFilter);
    }
  });

  init();

  vm.initBaseList();
  vm.prepareFilters();

  /* Functions */

  function init() {
    dataLoader('allLawyers')
      .$promise.then(response => {
        var merge = [].concat(
          response['lawyers'].objects,
          response['banklawyers'].objects,
          response['accidentlawyers'].objects,
          response['phonelawyers'].objects,
          response['laborallawyers'].objects,
          response['taxeslawyers'].objects,
          response['negligenceslawyers'].objects,
          response['solawyers'].objects
        );

        vm.lawyersBySegment = {
          ALL: { objects: merge },
          IM: { objects: merge },
          AE: response['lawyers'],
          AC: response['accidentlawyers'],
          BA: response['banklawyers'],
          LA: response['laborallawyers'],
          TE: response['phonelawyers'],
          SO: response['solawyers'],
          TA: response['taxeslawyers'],
          NE: response['negligenceslawyers']
        };
        vm.prepareFilters();
      })
      .catch(error => console.error(error));

    const dictionariesMap = new Map([
      ['claimStates', 'claimstates'],
      ['legalfilestatisticsgroups', 'legalfilestatisticsgroups'],
      ['procuradores', 'procuradores'],
      ['externalcourtlawyers_forairlines', 'externalcourtlawyers_forairlines'],
      ['externalcourtlawyers_forbanking', 'externalcourtlawyers_forbanking'],
      ['externalcourtlawyers_forlaboral', 'externalcourtlawyers_forlaboral'],
      ['externalcourtlawyers_fornegligences', 'externalcourtlawyers_fornegligences'],
      ['externalcourtlawyers_forphone', 'externalcourtlawyers_forphone'],
      ['externalcourtlawyers_foraccidents', 'externalcourtlawyers_foraccidents'],
      ['externalcourtlawyers_fortaxes', 'externalcourtlawyers_fortaxes'],
      ['costStates', 'costsclaimstates']
    ]);

    dataLoader([...dictionariesMap.values()])
      .$promise.then(responses => {
        dictionariesMap.forEach((value, key) => {
          vm[key] = responses[value];
        });
      })
      .catch(error => console.error(error));
  }

  function segmentToFilterHandler(segment) {
    vm.clearSegmentDependantFilters();
    vm.updateSegmentItems(segment);
  }

  // Function implementations
  function clearSegmentDependantFilters() {
    vm.newFilters['claim_lawyer_id'] = '';
    delete vm.currentFilters['claim_lawyer_id'];
  }

  function fillFilter(filter) {
    if (filter === 'view_date__gte') {
      vm.newFilters.advance_hearing_date__gte = vm.newFilters.view_date__gte;
    } else if (filter === 'view_date__lte') {
      vm.newFilters.advance_hearing_date__lte = vm.newFilters.view_date__lte;
    }
  }
}
