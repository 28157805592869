ProcedureListCtrl.$inject = ['$route', '$routeParams', 'constantsFactory', 'dataLoader', 'CostTypes'];

export default function ProcedureListCtrl($route, $routeParams, constantsFactory, dataLoader, CostTypes) {
  const vm = this;
  const segmentTitle = constantsFactory.getSegmentAlias($routeParams.segmentPrefix).title;
  $route.current.$$route.layoutSetting.contentTitle = segmentTitle;

  const columnDef = [
    {
      headerName: 'Id',
      fieldName: 'legalfile_id',
      class: 'cell-bg-secondary',
      type: 'link',
      href: '#/legalfiles',
      sortable: true,
      hidden: false
    },
    {
      headerName: 'Nº Exp',
      fieldName: 'num_file',
      sortable: true,
      hidden: false
    },
    {
      headerName: 'Tipo de reclamación',
      fieldName: 'claim_type',
      sortable: true,
      hidden: false
    },
    {
      headerName: 'Cliente',
      fieldName: 'client_name',
      sortable: true,
      hidden: false
    },
    {
      headerName: 'Tipo de costas',
      fieldName: 'costs_type_name',
      sortable: true,
      hidden: false
    },
    {
      headerName: 'Estado de costas',
      fieldName: 'costs_state_name',
      sortable: true,
      hidden: false
    },
    {
      headerName: 'Estado legal',
      fieldName: 'claim_legal_state',
      sortable: true,
      hidden: false
    },
    {
      headerName: 'Último comentario',
      fieldName: 'last_comment',
      sortable: false,
      hidden: false
    },
    {
      headerName: 'Fecha de creación',
      fieldName: 'date_created',
      sortable: true,
      hidden: false,
      type: 'date'
    },
    {
      headerName: 'Procurador',
      fieldName: 'procurador_name',
      sortable: true,
      hidden: false
    },
    {
      headerName: 'Usuario último comentario',
      fieldName: 'last_comment_user',
      sortable: false,
      hidden: false
    },
    {
      headerName: 'Fecha último comentario',
      fieldName: 'last_comment_date',
      sortable: true,
      hidden: false,
      type: 'date'
    }
  ];

  // Specific issues
  if ($routeParams.segmentPrefix == 'AE') {
    columnDef.splice(2,2);
  }

  const filterFieldInfo = {
    claim_type_id: { label: 'Tipo de producto', type: 'string' },
    cost_type_id: { label: 'Tipo de costa', type: 'string' },
    cost_state_id: { label: 'Estado de la costa', type: 'string' },
    range: { label: 'Rango de tiempo', type: 'string' }
  };

  const filterDic = {
    claim_type_id: [],
    cost_type_id: [],
    cost_state_id: [],
    range: [
      { id: 'r0', name: '' },
      { id: 'r4', name: 'Más de 60 días' },
      { id: 'r3', name: '30-60 días' },
      { id: 'r2', name: '15-30 días' },
      { id: 'r1', name: '0-15 días' }
    ]
  };

  const getDiccionaries$ = new Promise((resolve, reject) => {
    Promise
      .all([
        CostTypes.get(),
        dataLoader(['claimtypes', 'costsclaimstates'], {
          claimtypes: { requestData: { type: constantsFactory.getSegmentAlias($routeParams.segmentPrefix).alias } }
        }).$promise
      ])
      .then(data => {
        vm.filterDic.cost_type_id = data[0];
        vm.filterDic.claim_type_id = data[1]['claimtypes'].objects;
        vm.filterDic.cost_state_id = data[1]['costsclaimstates'].objects;
        resolve(vm.filterDic);
      })
      .catch(err => reject(err));
  });

  const INITIAL_STATE = {
    layoutSettings: $route.current.$$route.layoutSetting,
    filter: $routeParams,
    settings: {
      title: 'Procedimientos de costas',
      enableFiltering: false,
      displaycolumnsFilters: true,
      pageSize: '20',
      resourcePath: 'costsprocedures',
      columnDef,
      filterFieldInfo,
      filterDic$: getDiccionaries$
    },
    filterDic
  };

  Object.assign(vm, INITIAL_STATE);
}
