Client.$inject = ['$resource', 'serverAddress'];
export default function Client($resource, serverAddress) {
  const CLIENT_PATH = `${serverAddress.getBaseUrl()}api2/v1/client/:id/`;
  return $resource(
    CLIENT_PATH,
    {},
    {
      update: {
        method: 'PATCH',
        params: {},
        isArray: false
      },
      countries: {
        method: 'GET',
        url: `${serverAddress.getBaseUrl()}client/countries/`,
        cache: true,
        isArray: true
      },
      languages: {
        method: 'GET',
        url: `${serverAddress.getBaseUrl()}client/languages/`,
        cache: true,
        isArray: true
      }
    }
  );
}
