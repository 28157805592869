DashboardOperationsCtrl.$inject = [
  'Segment',
  '$sce',
  '$interpolate',
  '$timeout',
  '$route',
  'serverAddress'
];
export default function DashboardOperationsCtrl(
  Segment,
  $sce,
  $interpolate,
  $timeout,
  $route,
  serverAddress
) {
  var vm = this;
  vm.layoutSettings = $route.current.$$route.layoutSetting;

  vm.index = 'prod_airline_claims';

  vm.timeGrain = [{ field: 'M', label: 'Mes' }, { field: 'w', label: 'Semana' }, { field: 'd', label: 'Dia' }];

  vm.dateVars = [
    { field: 'date_added', label: 'Leads' },
    {
      field: 'date_added',
      label: 'Leads validos',
      filter: {
        query: '-sales_state_id:%20(17%20OR%2018%20OR%2044%20OR%20212)',
        label: 'Validos'
      }
    },
    {
      field: 'date_finished',
      label: 'Terminadas validas',
      filter: {
        query: '-sales_state_id:%20(17%20OR%2018%20OR%2044%20OR%20212)',
        label: 'Validos'
      }
    },
    { field: 'informe_realizado_state_date', label: 'Informe realizado' },
    { field: 'validated_state_date', label: 'Fecha cliente' },
    { field: 'desisted_state_date', label: 'Desistido' },
    { field: 'prejudicial_enviada_state_date', label: 'Prejudicial enviada' },
    { field: 'demanda_en_redaccion_state_date', label: 'Demanda en redaccion' },
    { field: 'demanda_revision_state_date', label: 'Demanda revision' },
    { field: 'demanda_para_corregir_state_date', label: 'Demanda para corregir' },
    { field: 'demanda_enviada_state_date', label: 'Demanda enviada' },
    { field: 'demanda_presentada_date', label: 'Demanda presentada' },
    { field: 'demanda_admitida_a_tramite_state_date', label: 'Demanda admitida tramite' },
    { field: 'acuerdo_prejudicial_state_date', label: 'Acuerdo prejudicial' },
    { field: 'acuerdo_extrajudicial_state_date', label: 'Acuerdo extrajudicial' },
    { field: 'audiencia_previa_state_date', label: 'Audiencia previa' },
    { field: 'juicio_senalado_state_date', label: 'Juicio señalado' },
    { field: 'sentencias_favorables_state_date', label: 'Sentencia favorable' },
    { field: 'sentencias_desfavorables_state_date', label: 'Sentencia desfavorable' },
    { field: 'sentencias_parcialmente_favorables_state_date', label: 'Sentencia parcialemente desfavorable' },
    { field: 'sentencia_recurrida_state_date', label: 'Sentencia recurrida' },
    { field: 'facturado_state_date', label: 'Facturado' },
    { field: 'pagado_state_date', label: 'Pagado' }
  ];

  vm.splitVars = [
    { field: 'state_name_exact', label: 'Estado Legal' },
    { field: 'sales_state_name_exact', label: 'Estado Comercial' },
    { field: 'bank_exact', label: 'Banco' },
    { field: 'metrics_source', label: 'Mkt Source' },
    { field: 'metrics_medium', label: 'Mkt Medium' },
    { field: 'metrics_campaign', label: 'Mkt Campaign' },
    { field: 'metrics_referer', label: 'Mkt Referer' },
    { field: 'first_claim_segment', label: 'Primer vertical' },
    { field: 'first_claim_type', label: 'Primer producto' }
  ];

  var templateUrl =
    serverAddress.BI_URL + 
    'app/kibana#/visualize/create?embed=true&type=' +
    'histogram&indexPattern={{index}}&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:(from:\'' +
    '{{dateFrom}}T00:00:00.0Z\',mode:absolute,to:\'{{dateTo}}T23:59:59.999Z\'))&_a=(filters:!(),linked:!f,query:' +
    '(query_string:(analyze_wildcard:!t,query:\'*\')),uiState:(),vis:(aggs:!((id:\'1\',params:(),' +
    'schema:metric,type:count),(id:\'2\',params:(customInterval:\'2h\',customLabel:\'{{ selectedDateVarLabel}}\',' +
    'extended_bounds:(),field:{{selectedDateVarField}},interval:{{selectedTimeGrain}},min_doc_count:1),' +
    'schema:segment,type:date_histogram),{{splitBars}}{{splitChart}}listeners:(),params:(addLegend:!t,addTimeMarker:!f,addTooltip:' +
    '!t,defaultYExtents:!f,mode:stacked,scale:linear,setYExtents:!f,shareYAxis:!t,times:!(),yAxis:()),' +
    'title:\'New%20Visualization\',type:histogram))';

  var templatePieUrl =
    serverAddress.BI_URL +
    'app/kibana#/visualize/create?embed=true&type=pie' +
    '&indexPattern={{index}}&_g=(refreshInterval:(display:Off,pause:!f,value:0),time:' +
    '(from:\'{{dateFrom}}T00:00:00.0Z\',mode:absolute,to:\'{{dateTo}}T23:59:59.999Z\'))&_a=' +
    '(filters:!(),linked:!f,query:(query_string:(analyze_wildcard:!t,query:\'*\')),uiState:(),' +
    'vis:(aggs:!((id:\'1\',params:(),schema:metric,type:count),(id:\'3\',params:(field:type_name_exact,' +
    'order:desc,orderBy:\'1\',row:!f,size:5),schema:split,type:terms),(id:\'4\',params:' +
    '(field:metrics_source,order:desc,orderBy:\'1\',size:5),schema:segment,type:terms),' +
    '(id:\'5\',params:(field:metrics_medium,order:desc,orderBy:\'1\',size:5),' +
    'schema:segment,type:terms),(id:\'6\',params:(field:metrics_campaign,order:desc,' +
    'orderBy:\'1\',size:5),schema:segment,type:terms)),listeners:(),params:(addLegend:!t,' +
    'addTooltip:!t,isDonut:!f,shareYAxis:!t),title:\'New%20Visualization\',type:pie))';

  function generateSplitFilter(context) {
    var templateSplitFilter =
      '(id:\'3\',params:(filters:!((input:(query:(query_string:(analyze_wildcard:!t,' +
      'query:\'{{query}}\'))),label:{{label}}))),schema:group,type:filters),';
    return $interpolate(templateSplitFilter)(context);
  }

  function generateSplitSignificantTerms(context) {
    var templateSplitSignificantTerms =
      '(id:\'3\',params:(customLabel:\'{{label}}\',field:{{field}},size:10000),' + 'schema:group,type:significant_terms),';
    return $interpolate(templateSplitSignificantTerms)(context);
  }

  function generateSplitChart(context) {
    //      var templateSplitChart = "(id:'4',params:(customLabel:{{label}},field:{{field}},row:!t,size:100000)," +
    //      "schema:split,type:significant_terms)),"
    var templateSplitChart =
      '(id:\'4\',params:(customLabel:{{label}},field:{{field}},order:desc,orderBy:\'1\',row:!t,size:1000),schema:split,type:terms)),';
    return $interpolate(templateSplitChart)(context);
  }

  function generateUrl() {
    var splitBars = '';

    if (vm.selectedDateVar.filter) {
      splitBars = generateSplitFilter({
        query: vm.selectedDateVar.filter.query,
        label: vm.selectedDateVar.filter.label
      });
    } else {
      if (!vm.selectedSplitVar.label) {
        splitBars = '';
      } else {
        splitBars = generateSplitSignificantTerms({
          field: vm.selectedSplitVar.field,
          label: vm.selectedSplitVar.label
        });
      }
    }
    var splitChart = generateSplitChart({ label: 'Tipo', field: 'type_name_exact' });
    var interpolatedUrl = $interpolate(templateUrl)({
      index: vm.selectedSegment.haystack_index_name,
      dateFrom: vm.dateFrom,
      dateTo: vm.dateTo,
      selectedDateVarLabel: vm.selectedDateVar.label,
      selectedDateVarField: vm.selectedDateVar.field,
      selectedTimeGrain: vm.selectedTimeGrain.field,
      splitBars: splitBars,
      splitChart: splitChart
    });
    return $sce.trustAsResourceUrl(interpolatedUrl);
  }

  function generatePieUrl() {
    var interpolatedUrl = $interpolate(templatePieUrl)({
      index: vm.selectedSegmentPie.haystack_index_name,
      dateFrom: vm.dateFromPie,
      dateTo: vm.dateToPie
    });
    return $sce.trustAsResourceUrl(interpolatedUrl);
  }

  vm.selectedSegment = '';
  vm.selectedSegmentPie = '';
  vm.selectedDateVar = '';
  vm.selectedSplitVar = '';
  vm.selectedTimeGrain = '';
  vm.dashboardUrl = '';
  vm.dashboardPieUrl = '';
  vm.dateFrom = '';
  vm.dateTo = '';
  vm.updateDashboard = updateDashboard;
  vm.dashboardReady = dashboardReady;
  vm.formDateString = formDateString;
  vm.refreshing = false;

  init();

  function init() {
    Segment.get({ query: 'id__gt=0' }).$promise.then(function(response) {
      var initialToDate = new Date();
      var intialTofromDate = new Date();
      intialTofromDate.setMonth(intialTofromDate.getMonth() - 1);
      vm.dateFrom = formDateString(intialTofromDate);
      vm.dateTo = formDateString(initialToDate);
      vm.segmentList = response.objects;
      vm.selectedSegment = vm.segmentList[0];
      vm.selectedTimeGrain = vm.timeGrain[0];
      vm.selectedDateVar = vm.dateVars[0];
      vm.selectedSegmentPie = vm.segmentList[0];
      vm.dateFromPie = formDateString(intialTofromDate);
      vm.dateToPie = formDateString(initialToDate);
      vm.updateDashboard(false);
      vm.updateDashboard(true);
    });
  }
  function updateDashboard(is_pie, refreshing) {
    if (vm.dashboardReady(is_pie)) {
      if (refreshing) {
        vm.refreshing = true;
        $timeout(function() {
          vm.refreshing = false;
        }, 1);
      }
      if (is_pie) {
        vm.dashboardPieUrl = generatePieUrl();
      } else {
        vm.dashboardUrl = generateUrl();
      }
    }
  }

  function dashboardReady(is_pie) {
    if (is_pie) {
      return vm.dateFromPie != '' && vm.dateToPie != '' && vm.selectedSegmentPie != '';
    }
    return vm.dateFrom != '' && vm.dateTo != '' && vm.selectedDateVar != '' && vm.selectedSegment != '';
  }

  function formDateString(date) {
    var dd = date.getDate();
    if (dd < 10) {
      dd = '0' + dd;
    }
    var mm = date.getMonth() + 1;
    if (mm < 10) {
      mm = '0' + mm;
    }
    var yyyy = date.getFullYear();
    return yyyy + '-' + mm + '-' + dd;
  }
}
