/**
 * commonStateChanges
 *
 * Load an array of states ids from the claim endpoint and point them as buttons
 * to fast state change
 */

import angular from 'angular';

import CommonStateChangesCtrl from './CommonStateChangesCtrl';

angular
  .module('reclamador.components.commonStateChanges', [])
  .controller('CommonStateChangesCtrl', CommonStateChangesCtrl)
  .component('commonStateChanges', {
    bindings:{
      entity: '<',
      entityType: '<',
      states: '<',
      changeStatus: '<'
    },
    controller: 'CommonStateChangesCtrl',
    controllerAs: 'vm',
    template: `
      <div class="form-inline justify-content-center"
          ng-if="vm.entity.common_state_changes && vm.entity.common_state_changes.length">
        <label class="col-form-label mr-2">Estados legales sugeridos:</label>
        <button type="button" class="btn green mr-2"
            ng-repeat="state in vm.entity.common_state_changes"
            ng-disabled="vm.entityType == 'claim' && (!vm.entity.lawyer || vm.entity.sales_state)"
            ng-if="vm.getStateById(state)"
            ng-disabled="vm.getStateById(state).name === vm.activeState.name"
            ng-click="vm.setState(state)">
          {{ vm.getStateById(state).name }}
        </button>
      </div>`
  });
