EmailIndexRoutes.$inject = ['$routeProvider'];

export default function EmailIndexRoutes($routeProvider) {
  $routeProvider.when('/emails/:filter?', {
    title: 'Envío de emails',
    templateUrl: 'js/modules/emailindex/partials/email-list.html',
    permission: 'segment-global-crm_view_analytics_section',
    layoutSetting: {
      contentTitle: 'Analítica',
      iconClass: 'fas fa-tachometer-alt'
    },
    baseListSettings: {
      typeList: 'emails',
      typeTitle: 'emails',
      templateListTable: 'js/modules/emailindex/partials/claim-list-table.html',
      templateListFilter: 'js/modules/emailindex/partials/claim-list-filters.html',
      title: 'Listado de métricas',
      listResultsText: 'métricas'
    },
    controller: 'EmailIndexCtrl',
    controllerAs: 'vm'
  });
}
