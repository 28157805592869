import './card-header';
import './page-header';
import './navbar';
import './side-menu';

import './claim';
import './common-state-changes';

import './phases-bar';
import './state-manager';
import './previous-states';
import './new-comment';

import './flight-panels';
import './flight-certificates';

import './document-manager';
import './documents-inbox-counter';
import './pending-documents';

import './tab-links-dropdown';
import './list-actions-dropdown';
import './filter-list';
import './goto';
import './history-list';
import './comment-edit';

import './mini-modal';
import './dialog';
import './calendar-with-due-date';
import './edit-notification';
import './assignment-modal';

import angular from 'angular';

angular.module('reclamador.components', [
  // Layout
  'reclamador.components.pageHeader',
  'reclamador.components.cardHeader',
  'reclamador.components.navbar',
  'reclamador.components.sidemenu',

  // Claim
  'reclamador.components.claim',
  'reclamador.components.commonStateChanges',

  // TODO: Move into top-level component
  'reclamador.components.phaseBar',
  'reclamador.components.stateManager',
  'reclamador.components.previousStates',
  'reclamador.components.newComment',
  'reclamador.components.flightPanels',
  'reclamador.components.flightCertificates',

  // Doc
  'reclamador.components.documentManager',
  'reclamador.components.documentsInboxCounter',
  'reclamador.components.pendingDocuments',

  // Lists
  'reclamador.components.tabLinksDropdown',
  'reclamador.components.listActionsDropdown',
  'reclamador.components.filterList',
  'reclamador.components.goto',
  'reclamador.components.historyList',
  'reclamador.components.commentEdit',

  // Visual elements
  'reclamador.components.miniModal',
  'reclamador.components.dialog',
  'reclamador.components.calendarWithDueDate',
  'reclamador.components.editNotification',
  'reclamador.components.assigmentModal'
]);
