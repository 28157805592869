GenericClaimListCtrl.$inject = ['$scope', '$controller', 'GenericConstants'];

export default function GenericClaimListCtrl($scope, $controller, GenericConstants) {
  const vm = this;
  const claimDetailTabs = {};
  GenericConstants.tabConfig.forEach(element => {
    claimDetailTabs[element.indexKey] = element.heading;
  });
  const INITIAL_STATE = {
    claimDetailTabs,
    ALIAS: GenericConstants.ALIAS + '-claims',

    itemsPath: 'generic-claim/list/',
    listPath: 'generic-claims/list/filter/',
    listPathNoFIlter: 'generic-claims',
    defaultColumnDef: GenericConstants.defaultColumnDef,
    filterFieldInfo: GenericConstants.filterFieldInfo
  };
  $controller('BaseListCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE);

  /** Initiate List! */
  vm.initBaseList(
    GenericConstants.SEGMENT_ID,
    GenericConstants.ALIAS,
    GenericConstants.permissions
  );
}
