BaseLawyerPanelCtrl.$inject = [
  'vm',
  '$scope',
  '$route',
  'dataLoader',
  'notifications',
  '$timeout',
  '$window'
];

export default function BaseLawyerPanelCtrl(
  vm,
  $scope,
  $route,
  dataLoader,
  notifications,
  $timeout,
  $window
) {
  const INITIAL_STATE = {
    busy: true,
    layoutSettings: $route.current.$$route.layoutSetting,
    segmentID: $route.current.$$route.segmentID,
    states: [],
    lawyers: [],
    stats: {
      counts: {},
      totals: {}
    },
    segmentDictKey: null
  };
  const METHODS = {
    init,
    checkIsExternLawyer,
    getLawyerInitials,
    setExternalLink
  };

  Object.assign(vm, INITIAL_STATE, METHODS);

  /** METHODS */

  function init(segmentID, segmentDictKey) {
    vm.segmentDictKey = segmentDictKey;
    const statesCountKey = vm.segmentDictKey + 'StatesCount';
    return dataLoader(['claimstates', vm.segmentDictKey, statesCountKey], {
      claimstates: { requestData: { segments__id: segmentID, costs: false } }
    })
      .$promise.then(data => {
        vm.states = data['claimstates'].objects;
        vm.lawyers = data[segmentDictKey].objects;
        vm.stats = data[statesCountKey];
        vm.isExtern = vm.checkIsExternLawyer(segmentID);
        $timeout($window.rclmng_globals.ux.pepinColFixer.init);
      })
      .catch(err => {
        notifications.addCurrentView(
          'error',
          'Lo sentimos, se produjo un error solicitando la informe.'
        );
        console.error(err);
      })
      .finally(() => {
        vm.busy = false;
      });
  }

  function setExternalLink(baseLink, lawyer, stateId, salesStateId) {
    return `${baseLink}/lawyer=${lawyer}&state_id=${stateId}&sales_state_id=${salesStateId}`;
  }

  function getLawyerInitials(lawyer) {
    return lawyer.first_name ? lawyer.first_name[0] + lawyer.last_name[0] : '';
  }

  function checkIsExternLawyer(id) {
    const data = {
      2: 'AbogadosBancaExt',
      6: 'AbogadosAccidentesExt',
      7: 'AbogadosImpuestosExt',
      8: 'AbogadosNegligencesExt'
    };
    const groups = $scope.user.groups.groupBy('name');
    return groups.hasOwnProperty(data[id]);
  }
}
