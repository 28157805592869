import DialogConfirmCtrl from './dialog.controller';

DialogFactory.$inject = ['$dialog'];
export default function DialogFactory($dialog) {
  /*
    const defaultDialogOptions = {
      backdrop: true,
      backdropClass: 'modal-backdrop',
      backdropClick: true,
      backdropFade: true,
      dialogClass: 'modal',
      dialogFade: false,
      dialogOpenClass: 'modal-open',
      dialogSize: null,
      keyboard: true,
      resolve: {},
      transitionClass: 'fade',
      triggerClass: 'show'
    };
   */
  return {
    confirm,
    info    
  };

  /* Implementation */
  function confirm(args) {
    const defaultTemplate = {
      title: 'Title',
      body: 'Text',
      bodyAsk: 'Text ask',
      confirmText: 'Confirm',
      data: null
    };
    const confirmDialogData = Object.assign(defaultTemplate, args);
    const confirmDialogOptions = {
      template: `
      <div class="modal-header">
        <h3>{{title}}</h3>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
            <div class="row align-items-center justify-content-around">
                <div class="col-md-2 text-center"><i class="fas fa-exclamation fa-3x"></i></div>
                <div class="col-md-10 ml-auto">
                  <div bind-unsafe-html="body"></div>
                  <div bind-unsafe-html="bodyAsk"></div>
                </div>
            </div>
        </div>
      </div>
      <div class="modal-footer">
        <button ng-click="cancel()" class="btn btn-secondary">Cancelar</button>
        <button ng-click="ok()" class="btn btn-primary">{{confirmText}}</button>
      </div>
      `,
      // template: confirmTpl,
      controller: DialogConfirmCtrl,
      locals: confirmDialogData
    };
    return $dialog.dialog(confirmDialogOptions).open();
  }

  function info(args) {
    const defaultTemplate = {
      title: 'Title',
      body: 'Text'
    };
    const confirmDialogData = Object.assign(defaultTemplate, args);
    const confirmDialogOptions = {
      template: `
      <div class="modal-header">
        <h3>{{title}}</h3>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
            <div class="row align-items-center justify-content-around">
                <div class="col-md-2" style="text-align:center"><i class="fas fa-exclamation fa-3x" style="color:red"></i></div>
                <div class="col-md-10 ml-auto">
                  <p ng-bind-html="body"></p>
                </div>
            </div>
        </div>
      </div>
      <div class="modal-footer">
        <button ng-click="cancel()" class="btn btn-secondary">Cerrar</button
      </div>
      `,
      controller: DialogConfirmCtrl,
      locals: confirmDialogData
    };
    return $dialog.dialog(confirmDialogOptions).open();
  }
}
