/* eslint-disable angular/di */
import angular from 'angular';

import LaboralClaimDetailCtrl from './claimDetailCtrl';
import LaboralClaimListCtrl from './claimListCtrl';
import LaboralLawyerPanelCtrl from './lawyerPanelCtrl';

import LaboralClaim from './claimService';
import LaboralSalesman from './salesmanService';
import LaboralLawyer from './lawyerService';

import LaboralRoutes from './routes';

import LaboralConstants from './constants';

angular
  .module('reclamador.segments.laboral', [])

  .controller('LaboralClaimDetailCtrl', LaboralClaimDetailCtrl)
  .controller('LaboralClaimListCtrl', LaboralClaimListCtrl)
  .controller('LaboralLawyerPanelCtrl', LaboralLawyerPanelCtrl)

  .factory('LaboralClaim', LaboralClaim)
  .factory('LaboralSalesman', LaboralSalesman)
  .factory('LaboralLawyer', LaboralLawyer)

  .constant('LaboralConstants', LaboralConstants)

  .config(LaboralRoutes);
