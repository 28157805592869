AssigmentModalStep1Service.$inject = ['$resource', 'serverAddress'];

export default function AssigmentModalStep1Service($resource, serverAddress) {
  const BASE_PATH = 'api2/v1/';

  const ENDPOINTS = {
    PHASE: 'procedural_phase/',
    TYPE: 'documenttype/?format=json&limit=1000&type=aircase',
    LAWYERS: 'procedural_lawyer/',
    TOTAL: 'legalfile_documents_count/'
  };

  function createResource(endpoint) {
    const path = BASE_PATH + endpoint;
    return $resource(serverAddress.getBaseUrl() + path);
  }

  async function getData(endpoint) {
    try {
      const resource = createResource(endpoint);
      const response = await resource.get().$promise;
      return response;
    } catch (error) {
      console.error('Error en la solicitud:', error);
      throw error;
    }
  }

  function getPhaseData() {
    return getData(ENDPOINTS.PHASE);
  }

  function getTypeData() {
    return getData(ENDPOINTS.TYPE);
  }

  function getLawyersData() {
    return getData(ENDPOINTS.LAWYERS);
  }

  function getTotalData(segment, procedural_phase, type, lawyer) {
    let endpoint = `${ENDPOINTS.TOTAL}?`;

    const queryParams = [];
    addQueryParam(queryParams, 'segment', segment);
    addQueryParam(queryParams, 'procedural_phase', procedural_phase);
    addQueryParam(queryParams, 'type', type);
    addQueryParam(queryParams, 'lawyer', lawyer);

    if (queryParams.length > 0) {
      endpoint += queryParams.join('&');
    }

    function addQueryParam(queryParams, paramName, paramValue) {
      if (paramValue !== null && paramValue !== undefined) {
        queryParams.push(`${paramName}=${paramValue}`);
      }
    }

    return getData(endpoint);
  }

  return {
    getPhaseData,
    getTypeData,
    getLawyersData,
    getTotalData
  };
}
