NegligencesClaimListCtrl.$inject = ['$scope', '$controller', 'NegligencesConstants'];

export default function NegligencesClaimListCtrl($scope, $controller, NegligencesConstants) {
  const vm = this;
  const claimDetailTabs = {};
  NegligencesConstants.tabConfig.forEach(element => {
    claimDetailTabs[element.indexKey] = element.heading;
  });
  const INITIAL_STATE = {
    claimDetailTabs,
    ALIAS: NegligencesConstants.ALIAS + '-claims',

    itemsPath: 'negligences-claim/list/',
    listPath: 'negligences-claims/list/filter/',
    listPathNoFIlter: 'negligences-claims',
    defaultColumnDef: NegligencesConstants.defaultColumnDef,
    filterFieldInfo: NegligencesConstants.filterFieldInfo
  };
  $controller('BaseListCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE);

  /** Initiate List! */
  vm.initBaseList(
    NegligencesConstants.SEGMENT_ID,
    NegligencesConstants.ALIAS,
    NegligencesConstants.permissions
  );
}
