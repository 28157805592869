import _each from 'lodash/each';

GenericClaimUploadCtrl.$inject = ['$scope', '$http', 'serverAddress', 'notifications', 'OtherLawyer', '$route'];

export default function GenericClaimUploadCtrl($scope, $http, serverAddress, notifications, OtherLawyer, $route) {
  var vm = this;

  vm.layoutSettings = $route.current.$$route.layoutSetting;

  var claimStructure = {
    volkswagen: {
      labels:
        'nombre cliente, apellidos cliente, email cliente, modelo de coche, año de coche, precio de coche, matrícula de coche, nº de bastidor de coche',
      columns: [
        'client_name',
        'client_surnames',
        'client_email',
        'car_brand',
        'car_model',
        'car_year',
        'car_price',
        'car_license_number',
        'car_frame_number'
      ]
    },
    abengoa: {
      labels: 'nombre cliente, apellidos cliente, email cliente, telefono cliente, tipo de afectado, comentarios',
      columns: [
        'client_name',
        'client_surnames',
        'client_email',
        'client_phone_number',
        'client_affected_group',
        'comments'
      ]
    },
    cooperative: {
      labels: 'nombre cliente, apellidos cliente, email cliente, telefono cliente, comentarios',
      columns: ['client_name', 'client_surnames', 'client_email', 'client_phone_number', 'comments']
    },
    other: {
      labels: 'nombre cliente,  apellidos cliente, email cliente, telefono cliente, causa, descripcion causa',
      columns: ['client_name', 'client_surnames', 'client_email', 'client_phone_number', 'cause_title', 'cause_desc']
    }
  };

  vm.uploadFileInputs = {
    lawyer: '',
    headerLabels: '',
    claimType: ''
  };

  vm.sendButtonEnabled = false;
  vm.fileLoaded = false;

  // Fetch lawyers
  OtherLawyer.get({}).$promise.then(function(response) {
    vm.lawyersList = response.objects;
    vm.sendButtonEnabled = true;
  });

  // ng-to-csv config object
  vm.csv = {
    content: null,
    header: false,
    headerVisible: true,
    separator: ',',
    result: null,
    encoding: 'UTF-8',
    encodingVisible: true,
    accept: '.csv'
  };

  /* Events */
  $scope.$watch('vm.uploadFileInputs.claimType', function(newValue) {
    if (newValue) {
      vm.uploadFileInputs.headerLabels = claimStructure[newValue].labels;
    }
  });

  $scope.$watch('vm.csv.content', function(newValue) {
    if (newValue) {
      vm.res = getCsvClaims(vm.csv.result);
      vm.fileLoaded = true;
    }
  });

  /* Iterate over the resulting CSV in JSON format. The csvContent variable
  * is an object containing arrays as children, each child referring to a single
  * CSV row.
  */
  function getCsvClaims(csvContent) {
    var keys = claimStructure[vm.uploadFileInputs.claimType].columns;
    var data = [];

    csvContent.forEach(row => {
      var item = {};
      _each(keys, function(key, idx) {
        item[key] = sanitizeItem(row[idx]);
      });
      data.push(item);
    });

    return data;
  }

  // Function to send data via ajax-post to the backend (maybe some stuff could be refactored as a service...)
  function postData(data) {
    /* Sample object of what will be sent:
      var dataAjax = {
      "claims":[
        {
          "client_email": "foo@bar.com",
          "car_brand": "Volskwagen",
          ...
        }
      ],
      "lawyer":40759
      }; */

    vm.successOcurred = false;
    vm.errorOcurred = false;
    vm.finishedMessage = '';

    // We had to put Content-Type headers, as described here:
    // http://stackoverflow.com/questions/19254029/angularjs-http-post-does-not-send-data
    $http
      .post(serverAddress.getDocumentUrl() + 'generic-claim/bulk/upload/', data)
      .then(function() {
        var lawyerObj = vm.uploadFileInputs.lawyer;
        notifications.addCurrentView(
          'info',
          'Todo correcto. Se han generado las reclamaciones asignadas a: ' +
            lawyerObj.first_name +
            ' ' +
            lawyerObj.last_name
        );
        $scope.$broadcast('loading-bar:hide');
      })
      .catch(function(response) {
        for (var i = 0; i < response.data.length; i++) {
          notifications.addCurrentView('error', response.data[i]);
        }
        $scope.$broadcast('loading-bar:hide');
      });
  }

  // Button to send all data to the backend
  vm.sendData = sendData;

  function sendData() {
    $scope.$broadcast('loading-bar:show');
    var dataToSend = {};

    dataToSend.claims = vm.res;
    dataToSend.subtype = vm.uploadFileInputs.claimType;
    dataToSend.lawyer = vm.uploadFileInputs.lawyer ? vm.uploadFileInputs.lawyer.id : null;

    vm.dataToSend = dataToSend;
    postData(vm.dataToSend);
  }

  function sanitizeItem(input) {
    if (typeof input === 'string') {
      return input.trim();
    }

    return input;
  }
}
