import $ from 'jquery';

CommentEditCtrl.$inject = ['CommentEditService', 'notifications'];

export default function CommentEditCtrl(CommentEditService, notifications) {
  const vm = this;

  vm.idComment = null;
  vm.oldComment = null;
  vm.originalComment = null;
  vm.newComment = null;

  vm.showCommentEdit = false;
  vm.editInProgress = false;

  vm.saveComment = function () {
    if (vm.editInProgress) return;

    vm.editInProgress = true;

    const payload = {
      comment: vm.newComment
    };

    CommentEditService.editComment(vm.documentUri, payload)
      .then(function () {
        // Some types include default comments that must be maintained after editing the comment
        const startIndex = vm.documentType ? vm.oldComment.indexOf(vm.documentType) : null;
        const documentPrefix = vm.documentType ? vm.oldComment.substring(0, startIndex) : null;

        vm.newComment = vm.newComment.trim();

        if (vm.documentType && vm.newComment) {
          vm.oldComment = documentPrefix + vm.documentType + '  |  ' + vm.newComment;
        }

        if (vm.documentType && !vm.newComment) {
          vm.oldComment = documentPrefix + vm.documentType;
        }

        if (!vm.documentType) {
          vm.oldComment = vm.newComment;
        }

        vm.originalComment = vm.newComment;

        $('#editModal' + vm.idComment).modal('hide');
        notifications.addCurrentView('success', 'Comentario actualizado con éxito');
      })
      .catch(function (error) {
        $('#editModal' + vm.idComment).modal('hide');
        console.error('No se ha podido actualizar el comentario', error.data);
        notifications.addCurrentView('error', 'No se ha podido actualizar el comentario');
      })
      .finally(function () {
        vm.editInProgress = false;
      });
  };
}
