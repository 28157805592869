ConfirmFlightStopDeleteCtrl.$inject = [
  '$http',
  '$scope',
  'serverAddress',
  'dialog',
  'claim_id',
  'stop_nr',
  'flight_code',
  'parentScope',
  'notifications'
];

export default function ConfirmFlightStopDeleteCtrl(
  $http,
  $scope,
  serverAddress,
  dialog,
  claim_id,
  stop_nr,
  flight_code,
  parentScope,
  notifications
) {
  /* Functions */
  $scope.deletestop = deletestop;
  $scope.dismiss = dismiss;
  $scope.deleteallstops = deleteallstops;

  function deletestop() {
    $http
      .post(serverAddress.getBaseUrl() + 'airdata/update-flight-stop/', {
        claim_id: claim_id,
        stop_nr: stop_nr,
        flight_code: flight_code,
        stop_airport: null,
        airline_id: null,
        scheduled_departure: null
      })
      .then(function(response) {
        if (response.status != 200) {
          notifications.addCurrentView('error', 'Ocurrió un error al actualizar la escala. ' + 'Vuelva a intentarlo.');
        } else {
          if (response.data.status == 'ok') {
            notifications.addCurrentView('success', 'Escala eliminada');
            if (response.data.claim_updated == true) {
              parentScope.claimDetail.update();
            } else {
              parentScope.getFlightStops();
            }
          } else {
            notifications.addCurrentView('error', 'No se ha podido modificar la escala');
          }
        }
        dialog.close();
      });
  }
  function dismiss() {
    dialog.close();
  }

  function deleteallstops() {
    $http
      .post(serverAddress.getBaseUrl() + 'airdata/add-flight-stop/', {
        claim_id: claim_id,
        stop_nr: -1
      })
      .then(function(response) {
        if (response.status != 200) {
          notifications.addCurrentView('error', 'Ocurrió un error al borrar las escalas. ' + 'Vuelva a intentarlo.');
        } else {
          if (response.data.stop_deleted) {
            parentScope.claimDetail.update();
            notifications.addCurrentView('success', 'El vuelo ha dejado de ser con escalas');
          } else {
            notifications.addCurrentView('error', 'No se ha podido modificar la escala');
          }
        }
        dialog.close();
      });
  }
}
