SORoutes.$inject = ['$routeProvider', 'SOConstants'];

export default function SORoutes($routeProvider, SOConstants) {
  $routeProvider.when('/segundaoportunidad/lawyers/counts', {
    title: 'Panel Abogados Segunda Oportunidad',
    templateUrl: 'partials/common/panels/lawyers.html',
    data: {
      permissions: {
        only: 'segment-perobject-SO-crm_view_lawyers_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Segunda oportunidad',
      iconClass: 'far fa-thumbs-up',
      cardHead: 'Panel Abogados Segunda Oportunidad'
    },
    controller: 'SOLawyerPanelCtrl',
    controllerAs: 'vm'
  });

  const permissions = {
    only: 'segment-perobject-SO-crm_view_claims_list',
    redirectTo: 'no-permissions'
  };

  const listConfig = {
    title: 'Listado reclamaciones Segunda Oportunidad',
    templateUrl: 'partials/common/baseList/claim-list.html',
    data: { permissions },
    layoutSetting: {
      contentTitle: 'Segunda oportunidad',
      iconClass: 'far fa-thumbs-up'
    },
    baseListSettings: {
      typeList: 'so-claim',
      typeTitle: 'segunda oportunidad',
      templateListTable: 'js/modules/segments/so/partials/claim-list-table.html',
      templateListFilter: 'partials/common/baseList/common-claim-list-filter.html',
      title: 'Reclamaciones Segunda Oportunidad',
      listResultsText: 'reclamaciones de segunda oportunidad'
    },
    controller: 'SOClaimListCtrl',
    controllerAs: 'vm',
    segmentID: SOConstants.SEGMENT_ID
  };

  $routeProvider.when('/so-claims', listConfig);
  $routeProvider.when('/so-claims/list/filter/:filter', listConfig);

  $routeProvider.when('/so-claims/:id', {
    title: 'Detalle reclamación Segunda Oportunidad',
    templateUrl: 'js/modules/segments/so/partials/detail.html',
    data: {
      permissions: {
        only: 'segment-perobject-SO-crm_view_claim_detail',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Segunda oportunidad',
      iconClass: 'far fa-thumbs-up'
    },
    controller: 'SOClaimDetailCtrl',
    controllerAs: 'vm',
    segmentID: SOConstants.SEGMENT_ID,
    reloadOnSearch: false
  });
}
