export default function CostTypes() {
  return {
    get
  };

  //TODO: Harcoded data until endpoint server available
  function get() {
    return Promise.resolve([
      {
        name: '1ª Instancia',
        id: '1_instancia'
      },
      {
        name: '2ª Instancia',
        id: '2_instancia'
      },
      {
        name: 'Ejecución',
        id: 'ejecucion'
      },
      {
        name: 'Incidente',
        id: 'incidente'
      }
    ]);
  }
}
