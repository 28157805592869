import './styles.css';

Answers2Ctrl.$inject = ['$scope', '$http', 'serverAddress'];
export default function Answers2Ctrl($scope, $http, serverAddress) {
  const vm = this;
  vm.getAnswerValue = getAnswerValue;
  vm.EP = serverAddress.FORMS_URL;

  vm.$onInit = function () {};

  $scope.$watch('vm.claim', claim => {
    if (!claim || !claim.id || !vm.segmentid || !vm.productid) return;
    const req = {
      method: 'GET',
      url: `${vm.EP}api/forms/question/${vm.segmentid}/${vm.productid}/${vm.claim.id}`,
      headers: {
        'x-api-key': 'wN2KoN5L8R4KUU9Vhug1O29EDKfvSIUG214DNqW6'
      }
    };

    $http(req).then(response => {
      if (response.data) {
        /* Answer[]: {question, block: [value] } */
        vm._answers = Object.keys(response.data).map(item => {
          const element = response.data[item];
          if (element) {
            const question = element.question;
            const blocks = element.blocks.map(bl => {
              const value = bl.value;
              const options = bl.options.length ? bl.options : null;
              const type = bl.type;
              return {
                value,
                options,
                type
              };
            });

            return {
              question,
              blocks
            };
          }
          return {};
        });
      }
    });
  });

  function getAnswerValue(value, options, type) {
    if (value === null) return '';
    if (type === 'selectized' || type === 'select') {
      const optionMatched = options.find(i => i.value === +value);
      return !!optionMatched ? optionMatched.label : 'Error al mostrar el dato';
    }
    const parseValue = Number(value);
    if (!isNaN(parseValue)) {
      if (value <= 1) return parseValue * 100;
    } else {
      return value;
    }
  }
}
