export default function minutesToDays() {
  return convert;

  function convert(minutes) {
    var days = (minutes / 1440) | 0;
    var hours = ((minutes / 60) % 24) | 0;
    var mins = minutes % 60;

    var timeString = '';

    if (days > 0) {
      timeString = timeString + days + 'd ';
    }

    if (hours > 0) {
      timeString = timeString + hours + 'h ';
    }

    if (mins > 0) {
      timeString = timeString + mins + 'm';
    }

    return timeString;
  }
}
