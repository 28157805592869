ManagementRoutes.$inject = ['$routeProvider', 'ManagementConstants'];

export default function ManagementRoutes($routeProvider, ManagementConstants) {

  const permissions = {
    only: 'segment-perobject-GE-crm_view_claims_list',
    redirectTo: 'no-permissions'
  };

  const listConfig = {
    title: 'Gestiones',
    templateUrl: 'partials/common/baseList/claim-list.html',
    data: { permissions },
    layoutSetting: {
      contentTitle: 'Gestiones',
      iconClass: 'fas fa-id-card',
      primaryPath: '/#/managements-claim'
    },
    baseListSettings: {
      typeList: 'claims',
      title: 'Gestiones',
      listResultsText: 'Gestiones',
      templateListTable: 'js/modules/segments/management/partials/claim-list-table.html',
      templateListFilter: 'js/modules/segments/management/partials/claim-list-filter.html'
    },
    controller: 'ManagementListCtrl',
    controllerAs: 'vm',
    segmentID: ManagementConstants.SEGMENT_ID
  };

  $routeProvider.when('/managements-claim/', listConfig);
  $routeProvider.when('/managements-claim/list/filter/:filter', listConfig);

  $routeProvider.when('/managements/:id', {
    title: 'Gestiones',
    templateUrl: 'js/modules/segments/management/partials/claim.html',
    data: {
      permissions: {
        only: 'segment-perobject-GE-crm_view_claim_detail',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Gestiones',
      iconClass: 'fas fa-id-card',
      primaryPath: '/#/managements-claim',
      secondaryPath: '/#/managements'
    },
    controller: 'ManagementDetailCtrl',
    controllerAs: 'vm',
    segmentID: ManagementConstants.SEGMENT_ID,
    reloadOnSearch: false
  });
}
