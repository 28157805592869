/* eslint-disable angular/di */
import angular from 'angular';

angular
  .module('reclamador.components.pageHeader', [])
  .controller('PageHeaderCtrl', PageHeaderCtrl)
  .component('pageHeader', {
    template: `
      <div ng-show="vm.layoutSettings.contentTitle" class="container-fluid page-header">
        <h2>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0">
              <li class="breadcrumb-item">
                <i ng-show="vm.layoutSettings.iconClass" ng-class="vm.layoutSettings.iconClass" class="mr-2"></i>
                <a href="{{ vm.layoutSettings.primaryPath }}">{{ vm.layoutSettings.contentTitle }}</a>
              </li>
              <li ng-if="vm.layoutSettings.secondaryPath" class="breadcrumb-item active">{{ vm.id }}</li>
            </ol>
          </nav>            
        </h2>
      </div>
      `,
    controller: PageHeaderCtrl,
    controllerAs: 'vm'
  });

PageHeaderCtrl.$inject = ['$scope'];
function PageHeaderCtrl($scope) {
  const vm = this;
  vm.layoutSettings = null;
  vm.id = null;
  $scope.$on('$routeChangeSuccess', function ($event, next) {
    if (next.$$route.layoutSetting) {
      vm.layoutSettings = next.$$route.layoutSetting;
      vm.id = next.params && next.params.id;
    }
  });
}
