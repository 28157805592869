CreateNewClaim.$inject = ['$resource', 'serverAddress'];

export default function CreateNewClaim($resource, serverAddress) {
  /**
    claim_id --> current claim_id
    old_claim_type --> current claim type id
    new_claim_type_id --> new claim type id
    set_form_finished -->  # True if form must be finished
    document_list_ids --> document id list
    lawyer_comment --> text comment
  */
  const PATH = 'common/claim/create-claim-from-claim/';
  return $resource(serverAddress.getBaseUrl() + PATH, {});
}
