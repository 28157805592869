InvestorPortfolio.$inject = ['$resource', 'serverAddress'];

var INVESTORPORTFOLIO_PATH = 'api2/v1/investorportfolio/';

export default function InvestorPortfolio($resource, serverAddress) {
  return $resource(serverAddress.getBaseUrl() + INVESTORPORTFOLIO_PATH, {
    get: {
      isArray: true
    }
  });
}
