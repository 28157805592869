import moment from 'moment';

ManagementDetailCtrl.$inject = [
  '$scope',
  '$controller',
  'fileUploaderGenerator',
  'serverAddress',
  'DialogFactory',
  'ManagementConstants',
  'ProductService',
  'InheritancePhoneQuestions',
  'VCPhoneQuestions',
  'VCServiceTiming',
  'Inheritor',
  'toastr',
  'notifications',
  '$timeout'
];
export default function ManagementDetailCtrl(
  $scope,
  $controller,
  fileUploaderGenerator,
  serverAddress,
  DialogFactory,
  ManagementConstants,
  ProductService,
  InheritancePhoneQuestions,
  VCPhoneQuestions,
  VCServiceTiming,
  Inheritor,
  toastr,
  notifications,
  $timeout
) {
  const vm = this;

  const INITIAL_STATE = {
    displayDocumentsUploader: false,
    formatted_appointment_date: null,
    inheritanceClients: [],
    phoneQuestions: [],
    lawyers: [],
    SERVICE_TIMING: [],
    actions: {
      showNewCaseModal
    }
  };

  const METHODS = {
    initClaimCB,
    preSaveClaimCB,

    showDocumentMergingModal,
    showDocumentSlicingModal,
    showDocumentCopyModal,
    addClient,
    saveClient,
    deleteClient,
    addQuestion,
    editQuestion,
    removeQuestion
  };

  $controller('BaseDetailCtrl', { vm: vm, $scope: $scope });

  Object.assign(vm, INITIAL_STATE, METHODS, ManagementConstants);

  vm.claimDetail.init();

  /* Function declaration */
  /* BaseDetail Callbacks */
  function initClaimCB() {
    vm.getDocumentTypes();

    // Scope data according product
    vm.claimType = {
      id: vm.claim.type.id | 0,
      key: vm.CLAIM_TYPE,
      path: '',
      reportPath: ''
    };
    switch (vm.claimType.id) {
      case 57:
        vm.claimType.path = 'inheritance';
        vm.claimType.reportPath = 'generate-irph-report';
        vm.claimType.key = 'inheritance';
        break;
      case 58:
        vm.claimType.path = 'videocall';
        vm.claimType.reportPath = '';
        vm.claimType.key = 'videocall';
        break;
      default:
        break;
    }
    ProductService.set(vm.claimType.path);

    if (vm.claimType.key) {
      ProductService.get(vm.claim.id).then(response => {
        vm.productDetails = vm.CLAIM_TYPES[vm.claimType.id];
        if (!response.objects.length) {
          vm.logGetClaimError(new Error('Missing product data'));
          return;
        }
        vm[vm.claimType.key] = response.objects[0];
        const appointment_date = vm[vm.claimType.key].appointment_date;
        vm.formatted_appointment_date = appointment_date ? moment(appointment_date) : null;
        const id = vm[vm.claimType.key].id;

        // Other specific info by product
        switch (vm.claimType.id) {
          case 57:
            InheritancePhoneQuestions.get({ claim__id: id }).$promise.then(data => {
              if (data.objects) {
                vm.phoneQuestions = data.objects;
              }
            });
            Inheritor.get({ claim__id: id }).$promise.then(data => {
              vm.inheritanceClients = data.objects;
            });
            break;
          case 58:
            const internal_type = vm.productDetails.find(e => e.id === 'internal_type');
            internal_type.disabled = true;
            VCPhoneQuestions.get({ claim__id: id }).$promise.then(data => {
              if (data.objects) {
                vm.phoneQuestions = data.objects;
              }
            });

            VCServiceTiming.get().$promise.then(data => {
              if (data.objects) {
                vm.SERVICE_TIMING = data.objects;
              }
            });

            function _calculator(value) {
              const el = vm.SERVICE_TIMING.find(it => it.value == value);
              const r = vm[vm.claimType.key].report_for_client
                ? Number(vm[vm.claimType.key].report_price)
                : 0;
              vm[vm.claimType.key].billing_prices = +el.value;
              vm.claim.compensation = r + Number(el.value);
            }

            // Perform watching claim tracking
            $scope.$watch(
              'vm.videocall.service_timing',
              (newV, oldV) => {
                if (newV && newV !== oldV) {
                  _calculator(newV.value);
                }
              },
              true
            );
            $scope.$watch(
              'vm.videocall.report_for_client',
              (newV, oldV) => {
                if (newV !== oldV) {
                  _calculator(vm[vm.claimType.key].billing_prices);
                }
              },
              true
            );

            break;
          default:
            break;
        }
        // Perform watching claim tracking
        $scope.$watch(
          `vm.${vm.claimType.key}`,
          (newV, oldV) => {
            if (oldV !== null && newV !== oldV) {
              vm.claim.productDataDirty = true;
              // TODO: Only for VC
              if (newV && newV.hasOwnProperty('type')) {
                if (newV.type && newV.type.name === 'Otros') {
                  $timeout(() => {
                    const internal_type = vm.productDetails.find(e => e.id === 'internal_type');
                    internal_type.disabled = false;
                  }, 0);
                } else {
                  const internal_type = vm.productDetails.find(e => e.id === 'internal_type');
                  internal_type.disabled = true;
                }
              }
            }
          },
          true
        );
      });
    }
  }

  function preSaveClaimCB() {
    const prodRef = vm[vm.claimType.key];
    const productData = Object.assign({}, prodRef);
    Object.keys(prodRef).forEach(item => {
      if (prodRef[item] instanceof moment) {
        productData[item] = prodRef[item].format();
      } else if (!!prodRef[item] && typeof prodRef[item] === 'object') {
        if (prodRef[item].hasOwnProperty('value')) productData[item] = prodRef[item].value;
        if (prodRef[item].hasOwnProperty('resource_uri'))
          productData[item] = prodRef[item].resource_uri;
      } else {
        productData[item] = prodRef[item] !== undefined ? prodRef[item] : null;
      }
    });
    ProductService.save(prodRef.id, productData);
  }

  fileUploaderGenerator({
    vm: vm,
    url: serverAddress.getDocumentUrl() + 'common/upload/',
    fillItemData: function (item) {
      vm.busy = true;
      item.formData = [
        {
          type_id: (item.documenType && item.documenType.id) || 0,
          comment: item.comment || '',
          claim_id: vm.claim.id,
          segment: vm.DJANGO_MODEL
        }
      ];
    },
    sucessCallback: () => {
      vm.claimDetail.update('Archivo subido con éxito, actualizando reclamación');
    }
  });

  // FROM HERE JUST FUNCTIONS => => => => => => => => => =>

  /* Phone questions management */
  function addQuestion() {
    const newQuestion = {
      id: null,
      claim: '',
      question: '',
      resource_uri: ''
    };
    vm.phoneQuestions.push(newQuestion);
  }

  function editQuestion(item) {
    const id = !!item.id ? item.id : null;
    const method = !!item.id ? 'update' : 'save';
    let typeAPI = null;
    switch (vm.claimType.id) {
      case 57:
        typeAPI = InheritancePhoneQuestions;
        break;
      case 58:
        typeAPI = VCPhoneQuestions;
        break;
      default:
        break;
    }
    typeAPI[method]({ id }, { claim_id: vm[vm.claimType.key].id, ...item })
      .$promise.then(newQ => {
        toastr.info('Consulta añadida con éxito');
        item.id = newQ.id;
      })
      .catch(err => {
        console.error(err);
        toastr.error('No ha sido posible añadir la consulta');
      });
  }

  function removeQuestion(question) {
    const result = window.confirm('¿Estás seguro?');
    if (result) {
      let typeAPI = null;

      switch (vm.claimType.id) {
        case 57:
          typeAPI = InheritancePhoneQuestions;
          break;
        case 58:
          typeAPI = VCPhoneQuestions;
          break;
        default:
          break;
      }
      typeAPI
        .delete({ id: question.id })
        .$promise.then(() => {
          toastr.info('Consulta borrada con éxito');
          vm.phoneQuestions = vm.phoneQuestions.filter(item => item.id !== question.id);
        })
        .catch(err => {
          console.error(err);
          toastr.error('No ha sido posible borrar la consulta');
        });
    }
  }

  /* clients management */
  function addClient() {
    const newClient = {
      id: null,
      account_address: null,
      account_holder: null,
      account_town: null,
      address: '',
      age: null,
      bank_account_number: null,
      bic: null,
      birthday: null,
      claim: '',
      comment: '',
      email: '',
      gender: null,
      id_document: '',
      id_type: null,
      kinship: null,
      name: '',
      paypal_account: null,
      phone1: '',
      phone2: null,
      phone_prefix: null,
      post_code: null,
      resource_uri: '',
      surnames: '',
      town: ''
    };

    vm.inheritanceClients.push(newClient);
  }

  function saveClient(client) {
    const id = !!client.id ? client.id : null;
    const method = !!client.id ? 'update' : 'save';
    Inheritor[method]({ id }, { claim_id: vm[vm.claimType.key].id, ...client })
      .$promise.then(response => {
        toastr.info('Cliente actualiado con éxito');
        client.id = response.id;
      })
      .catch(err => {
        console.error(err);
        toastr.error('No ha sido posible añadir el cliente');
      });
  }

  function deleteClient(client) {
    const result = window.confirm('¿Estás seguro?');
    if (result)
      Inheritor.delete({ id: client.id })
        .$promise.then(() => {
          toastr.info('Cliente borrado con éxito');
          vm.inheritanceClients = vm.inheritanceClients.filter(item => item.id !== client.id);
        })
        .catch(err => {
          console.error(err);
          toastr.error('No ha sido posible borrar el cliente');
        });
  }

  /* Doc helpers */

  function showDocumentMergingModal() {
    var currentDocuments = vm.documents;
    var documentTypes = vm.documentTypes;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: vm.SEGMENT_PREFIX
    };

    var multipleSourceSelectionEnabled = false;
    DialogFactory.mergeDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimData,
      multipleSourceSelectionEnabled,
      function () {
        vm.claimDetail.update('Unión de documentos solicitada. Cuando termine recibirás un email.');
      }
    );
  }

  function showDocumentSlicingModal() {
    var currentDocuments = vm.documents;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: vm.SEGMENT_PREFIX
    };
    DialogFactory.sliceDocument(currentDocuments, claimData, function () {
      vm.claimDetail.update(
        'Separación de documento solicitada. Cuando termine recibirás un email.'
      );
    });
  }

  function showDocumentCopyModal(prefix, type) {
    var currentDocuments = null;
    var documentTypes = vm.documentTypes;
    var multipleSourceSelectionEnabled = false;
    var claimData = {
      claim_id: vm.claim.id,
      claim_prefix: prefix
    };
    var copy_type = type;
    var claimTypes = vm.claimTypes;

    // If the claim has no documents, an error is shown
    if (vm.documents.length === 0) {
      toastr.error(
        'No se ha podido realizar la acción. La reclamación no tiene ningún documento adjunto.'
      );
      return;
    }
    let method = '';

    switch (type) {
      case 'claim':
        method = 'copyDocsToClaimValidation';
        break;
      case 'legalfile':
        method = 'copyDocsToLegalfileValidation';
        break;
      default:
        break;
    }

    try {
      currentDocuments = DialogFactory[method](vm.documents, vm.claim);
    } catch (e) {
      toastr.error(e.message);
      return;
    }

    DialogFactory.copyDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimTypes,
      claimData,
      multipleSourceSelectionEnabled,
      copy_type,
      function () {
        vm.claimDetail.update();
      }
    );
  }

  /**
   *
   * @param {*} showNewCaseModal
   */
  function showNewCaseModal() {
    const data = {
      currentDocuments: vm.documents,
      ...[vm.documentType],
      claimData: {
        id: vm.claim.id,
        prefix: vm.SEGMENT_PREFIX,
        type: vm.claim.type.id,
        client: vm.claim.client
      }
    };
    if (!vm.loadedDocuments) {
      vm.fetchDocuments(true)
        .then(() => {
          data.currentDocuments = vm.documentsResponse;
          DialogFactory.generateNewCase(data);
        })
        .catch(() => {
          notifications.addCurrentView(
            'error',
            `Se produjo un error actualizando los documentos de la ${vm.VERBOSE_NAME.toLowerCase()}`
          );
        });
    } else {
      DialogFactory.generateNewCase(data);
    }
  }
}
