NegligencesRoutes.$inject = ['$routeProvider', 'NegligencesConstants'];

export default function NegligencesRoutes($routeProvider, NegligencesConstants) {
  $routeProvider.when('/negligences/salespeople/counts', {
    title: 'Panel Comerciales Negligencias',
    templateUrl: 'partials/common/panels/sales.html',
    data: {
      permissions: {
        only: 'segment-perobject-NE-crm_view_salespeople_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Negligencias',
      iconClass: 'fas fa-ambulance',
      cardHead: 'Panel Comerciales Negligencias'
    },
    controller: 'CommonSalesDashboardCtrl',
    controllerAs: 'vm',
    claimListBaseURL: '#/negligences-claims/list/',
    SalesmanService: 'NegligencesSalesman',
    salesPeopleCountsURL: 'negligences/salespeople/counts/',
    segmentName: 'Negligencias',
    segmentID: NegligencesConstants.SEGMENT_ID
  });

  $routeProvider.when('/negligences/lawyers/counts', {
    title: 'Panel Abogados Negligencias',
    templateUrl: 'partials/common/panels/lawyers.html',
    data: {
      permissions: {
        only: 'segment-perobject-NE-crm_view_lawyers_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Negligencias',
      iconClass: 'fas fa-ambulance',
      cardHead: 'Panel Abogados Negligencias'
    },
    controller: 'NegligencesLawyerPanelCtrl',
    controllerAs: 'vm',
    segmentID: NegligencesConstants.SEGMENT_ID
  });

  const permissions = {
    only: 'segment-perobject-NE-crm_view_claims_list',
    redirectTo: 'no-permissions'
  };

  const listCofig = {
    title: 'Listado de Reclamaciones de Negligencias',
    templateUrl: 'partials/common/baseList/claim-list.html',
    data: { permissions },
    layoutSetting: {
      contentTitle: 'Negligencias',
      iconClass: 'fas fa-ambulance'
    },
    baseListSettings: {
      typeList: 'negligences',
      typeTitle: 'negligencias',
      templateListTable: 'js/modules/segments/negligences/partials/claim-list-table.html',
      templateListFilter: 'partials/common/baseList/common-claim-list-filter.html',
      title: 'Reclamaciones Negligencias',
      listResultsText: 'reclamaciones de negligencias'
    },
    controller: 'NegligencesClaimListCtrl',
    controllerAs: 'vm',
    segmentID: NegligencesConstants.SEGMENT_ID
  };

  $routeProvider.when('/negligences-claims', listCofig);
  $routeProvider.when('/negligences-claims/list/filter/:filter', listCofig);

  $routeProvider.when('/negligences-claims/:id', {
    title: 'Detalle de reclamación de Negligencia',
    templateUrl: 'js/modules/segments/negligences/partials/detail.html',
    data: {
      permissions: {
        only: 'segment-perobject-NE-crm_view_claim_detail',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Negligencias',
      iconClass: 'fas fa-ambulance'
    },
    controller: 'NegligencesClaimDetailCtrl',
    controllerAs: 'vm',
    segmentID: NegligencesConstants.SEGMENT_ID,
    reloadOnSearch: false
  });
}
