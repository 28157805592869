import angular from 'angular';

import './busy';
import './claim-changes-tracker';
import './picklist';

import alerts from './alerts.directive';
import onFinishRender from './on-finish-render.directive';
import slasPanelBlock from './slas-panel-block.directive';
import tableSelect from './table-select.directive';
import datetimePicker from './datetime-picker.directive';
import dynamicComponent from './dynamic-component.directive';
import shortcutKeys from './shortcut-keys.directive';
import fileread from './fileread.directive';
import disableOnPromise from './disable-on-promise';
import bindUnsafeHtml from './bind-unsafe-html';

angular
  .module('reclamador.directives', [
    'reclamador.directives.busy',
    'reclamador.directives.claimChangesTracker',
    'reclamador.directives.picklist'
  ])
  .directive('alerts', alerts)
  .directive('onFinishRender', onFinishRender)
  .directive('slasPanelBlock', slasPanelBlock)
  .directive('tableSelect', tableSelect)
  .directive('shortcutKeys', shortcutKeys)
  .directive('datetimePicker', datetimePicker)
  .directive('dynamicComponent', dynamicComponent)
  .directive('fileread', fileread)
  .directive('disableOnPromise', disableOnPromise)
  .directive('bindUnsafeHtml', bindUnsafeHtml);
