import angular from 'angular';
import './styles.css';

import DocumentManagerCtrl from './DocumentManagerCtrl';
import DocumentViewerCtrl from './DocumentViewerCtrl';
import DocumentRowCtrl from './DocumentRowCtrl';
import DocumentRenameCtrl from './DocumentRenameCtrl';
import DocTools from './docTools.service';
import documentViewerKeyControls from './document-viewer-key-controls.directive';

angular
  .module('reclamador.components.documentManager', [])
  .factory('DocTools', DocTools)
  .directive('documentViewerKeyControls', documentViewerKeyControls)
  .controller('DocumentManagerCtrl', DocumentManagerCtrl)
  .component('documentManager', {
    bindings: {
      claim: '<',
      claimClass: '<',
      documents: '<',
      documentTypes: '<',
      documentationAnalysis: '<',
      flightDocuments: '<',
      legalfileDocuments: '<',
      legalfileDocumentTypes: '<',
      entityData: '<',
      entityUpdateCb: '<',
      busy: '<'
    },
    controller: 'DocumentManagerCtrl',
    controllerAs: 'vm',
    template: require('./document-manager.html')
  })
  .controller('DocumentViewerCtrl', DocumentViewerCtrl)
  .component('documentViewer', {
    bindings: {
      activeDocument: '<',
      activeCategory: '<',
      activeCategoryDocs: '<',
      categories: '<',
      documentTypes: '<',
      busy: '<'
    },
    controller: 'DocumentViewerCtrl',
    controllerAs: 'vm',
    template: require('./document-viewer.html')
  })
  .controller('DocumentRowCtrl', DocumentRowCtrl)
  .directive('documentRow', () => {
    return {
      scope: {
        document: '<',
        documentTypes: '<',
        documentGroup: '<',
        documentationAnalysis: '<',
        hasViewPermission: '<'
      },
      bindToController: true,
      controllerAs: 'vm',
      restrict: 'A',
      controller: 'DocumentRowCtrl',
      template: require('./document-row.html')
    };
  })
  .controller('DocumentRenameCtrl', DocumentRenameCtrl)
  .component('documentRename', {
    bindings: {
      oldName: '<',
      documentId: '<',
      isLegalfile: '<'
    },
    transclude: true,
    controller: 'DocumentRenameCtrl',
    controllerAs: 'vm',
    template: require('./document-rename.html')
  });
