/*
Recovered filter from jQuery File Upload AngularJS Plugin
https://github.com/blueimp/jQuery-File-Upload
*/
export default function formatFileSizeFilter() {
  const $config = {
    // Byte units following the IEC format
    // http://en.wikipedia.org/wiki/Kilobyte
    units: [{ size: 1000000000, suffix: ' GB' }, { size: 1000000, suffix: ' MB' }, { size: 1000, suffix: ' KB' }]
  };

  return function(bytes) {
    if (!(typeof bytes === 'number')) return '';

    let unit = {};
    let i = 0;
    let suffix = '';

    do {
      unit = $config.units[i];
      suffix = unit.suffix || '';
      if (i === $config.units.length - 1 || bytes >= unit.size) {
        return (bytes / unit.size).toFixed(2) + suffix;
      }
      i += 1;
    } while (unit);
  };
}
