IndexCaseStatesCtrl.$inject = ['$scope', 'Lawyer', 'PanelTools'];

export default function IndexCaseStatesCtrl($scope, Lawyer, PanelTools) {
  $scope.alreadyFiltered = false;
  $scope.domain = '';
  $scope.intervals = [
    {
      name: '<1 mes',
      interval: [30, 0],
      filter: '&date_finished=30-0'
    },
    {
      name: '1-2 meses',
      interval: [30, 60],
      filter: '&date_finished=30-60'
    },
    {
      name: '2-4 meses',
      interval: [60, 120],
      filter: '&date_finished=2-7'
    },
    {
      name: '> 4 meses',
      interval: [120, 0],
      filter: '&date_finished=0-2'
    }
  ];

  $scope.refresh_part_val_domain = function(domain) {
    if (domain !== $scope.domain) {
      if (domain === '' || domain === 'es' || domain === 'pt') {
        $scope.domain = domain;
        PanelTools.getIntervals($scope);
      }
    }
  };

  $scope.toggle_select_box_lawyers = function(val) {
    $scope.showLawyers = val;

    // Reset
    if (!val) {
      $scope.assignedLawyer = '';
      $scope.alreadyFiltered = false;
      PanelTools.getIntervals();
    }
  };

  $scope.filter_by_lawyers = function() {
    PanelTools.getIntervals();
  };

  // Get list of lawyers and check if the  current user is one of them
  $scope.lawyers = Lawyer.get(function(response) {
    var result = response.objects.filter(e => e.username === $scope.currentUser);

    if (result.length > 0) {
      $scope.showLawyers = true;
      $scope.alreadyFiltered = true;
      $scope.assignedLawyer = result[0].username;
    } else {
      $scope.showLayers = false;
      $scope.alreadyFiltered = false;
      $scope.assignedLawyer = '';
    }

    PanelTools.getIntervals();
  });

  $scope.lawyerSelected = function() {
    // Allows the "Filter" button to be shown when we just change a lawyer, selecting a valid option
    if (!$scope.assignedLawyer) {
      $scope.alreadyFiltered = false;
    } else {
      // Just to make the "Filter" button dissapear in this situation
      $scope.alreadyFiltered = true;
    }
  };
}
