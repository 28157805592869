/* eslint-disable angular/di */
import angular from 'angular';
import DashboardOperationsCtrl from './DashboardOperationsCtrl';

angular
  .module('reclamador.dashboard.operations', [])
  .controller('DashboardOperationsCtrl', DashboardOperationsCtrl)
  .config([
    '$routeProvider',
    function($routeProvider) {
      $routeProvider.when('/dashboard/operations', {
        title: 'Dashboard operaciones',
        templateUrl: 'js/modules/dashboard/operations/partials/index.html',
        permission: 'segment-global-crm_view_dashboards_section',
        layoutSetting: {
          contentTitle: 'Dashboards',
          iconClass: 'fas fa-tachometer-alt',
          cardHead: 'Dashboard operaciones'
        },
        controller: DashboardOperationsCtrl,
        controllerAs: 'vm'
      });
    }
  ]);
