ERPmanager.$inject = ['$http', 'serverAddress', 'notifications'];

export default function ERPmanager($http, serverAddress, notifications) {
  return {
    createClaimInERP: createClaimInERP,
    updateClaimInERP: updateClaimInERP
  };

  /* Implementation */
  function createClaimInERP(claimId, claimTypeId) {
    return $http
      .post(serverAddress.getBaseUrl() + 'erp/create-claim-in-erp/', { claim_id: claimId, claim_type_id: claimTypeId })
      .then(() => notifications.addCurrentView('success', 'Pedido creado con éxito'))
      .catch(() => notifications.addCurrentView('error', 'Se produjo un error actualizando el pedido'));
  }

  function updateClaimInERP(claimId, claimTypeId) {
    return $http
      .post(serverAddress.getBaseUrl() + 'erp/update-claim-in-erp/', { claim_id: claimId, claim_type_id: claimTypeId })
      .then(() => notifications.addCurrentView('success', 'Pedido actualizado con éxito'))
      .catch(() => notifications.addCurrentView('error', 'Se produjo un error actualizando el pedido'));
  }
}
