AirlinesLawyersGoalsPanelCtrl.$inject = [
  '$scope',
  '$window',
  '$route',
  'Lawyer',
  'notifications',
  'PanelTools',
  '$q',
  '$timeout'
];

export default function AirlinesLawyersGoalsPanelCtrl(
  $scope,
  $window,
  $route,
  Lawyer,
  notifications,
  PanelTools,
  $q,
  $timeout
) {
  var vm = this;

  const INITIALSTATE = {
    layoutSettings: $route.current.$$route.layoutSetting,
    goal_from: '',
    goal_to: '',
    goal_period: 'month',
    goal_minors: false,
    goalsSteps: [
      { name: 'Reclamaciones entrantes', filter: 'incoming' },
      { name: 'Validadas', filter: 'valid' },
      { name: 'Acuerdo prejudicial', filter: 'agreement' },
      { name: 'Gestionados con demanda enviada', filter: 'filed_lawsuit' },
      { name: 'Gestionados con demanda', filter: 'filed_lawsuit' },
      { name: 'Clientes desistidos', filter: 'desisted' },
      { name: 'Acuerdo extrajudicial', filter: 'extra_agreement' },
      { name: 'Sentencias favorables', filter: 'fav_ruling' },
      { name: 'Sentencias desfavorables', filter: 'unfav_ruling' },
      { name: 'Sentencias parcialmente favorables', filter: 'parc_fav_ruling' },
      { name: 'Facturado', filter: 'invoiced' },
      { name: 'Pagado', filter: 'paid' }
    ],
    busy: true,
    firstTime: true,
    updatePeriod,
    updateMinors
  };
  Object.assign(vm, INITIALSTATE);

  /* Startup */
  $scope.$on('ngRepeatFinished', $window.rclmng_globals.ux.pepinColFixer.resizeTables);
  $scope.$watch('vm.goal_period', newValue => vm.updatePeriod(newValue));
  $scope.$watch('vm.goal_minors', newValue => vm.updateMinors(newValue));

  // TODO: Move to init function
  $q.all([
    Lawyer.get().$promise,
    PanelTools.getUserGoalsStatusClaimCounts({ period: vm.goal_period, minors: vm.goal_minors })
  ])
    .then(([lawyers, claimcounts]) => {
      $timeout($window.rclmng_globals.ux.pepinColFixer.init);
      vm.lawyers = lawyers;
      vm.goalsStatusCounts = claimcounts.data;
      vm.firstTime = false;
    })
    .catch(() => {
      notifications.addCurrentView(
        'error',
        'Lo sentimos, se produjo un error solicitando la informe.'
      );
    })
    .finally(() => {
      vm.busy = false;
    });

  /* Functions */
  function updatePeriod(period, from, to) {
    const params = {};
    if (period === 'custom') {
      if (!from || !to) return;
      params.from = from;
      params.to = to;
    }
    params.period = period;
    params.minors = vm.goal_minors;
    performQuery(params);
  }

  function updateMinors(minors) {
    const params = {
      minors
    };
    performQuery(params);
  }

  function performQuery(params) {
    if (vm.firstTime) return;
    // reset tables
    $window.rclmng_globals.ux.pepinColFixer.clearTables();
    vm.busy = true;
    PanelTools.getUserGoalsStatusClaimCounts(params)
      .then(response => {
        vm.goalsStatusCounts = response.data;
        $timeout($window.rclmng_globals.ux.pepinColFixer.init);
      })
      .catch(() => {
        notifications.addCurrentView(
          'error',
          'Lo sentimos, se produjo un error solicitando la informe.'
        );
      })
      .finally(() => {
        vm.busy = false;
      });
  }
}
