LocalStorage.$inject = ['$window'];

export default function LocalStorage($window) {
  return {
    set: function(key, value) {
      $window.localStorage[key] = value;
    },
    get: function(key, defaultValue) {
      return $window.localStorage[key] || defaultValue || false;
    },
    setObject: function(key, value) {
      $window.localStorage[key] = JSON.stringify(value);
    },
    getObject: function(key, defaultValue) {
      if ($window.localStorage[key] != undefined) {
        return JSON.parse($window.localStorage[key]);
      }
      return defaultValue || false;
    },
    remove: function(key) {
      $window.localStorage.removeItem(key);
    },
    clear: function() {
      $window.localStorage.clear();
    }
  };
}
