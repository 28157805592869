LegalService.$inject = ['$resource', 'serverAddress'];

export default function LegalService($resource, serverAddress) {
  return $resource(
    `${serverAddress.getBaseUrl()}api2/v1/legalservices/:id/`,
    {},
    {
      update: {
        method: 'PATCH',
        params: {},
        isArray: false
      }
    }
  );
}
