DialogConfirmCtrl.$inject = ['$scope', 'dialog'];

export default function DialogConfirmCtrl($scope, dialog) {
  $scope.title = dialog.options.locals.title;
  $scope.body = dialog.options.locals.body;
  $scope.bodyAsk = dialog.options.locals.bodyAsk;
  $scope.confirmText = dialog.options.locals.confirmText;
  $scope.data = dialog.options.locals.data;

  $scope.ok = () => {
    dialog.close();
    return dialog.deferred.resolve($scope.data || true);
  };
  $scope.cancel = () => dialog.close(-1);
}
