import angular from 'angular';

angular
  .module('reclamador.components.cardHeader', [])
  .controller('CardHeaderCtrl', CardHeaderCtrl)
  .component('cardHeader', {
    template: `
    <div ng-if="vm.layoutSettings.cardHead || client.layoutSettings.cardHead" class="card-header d-flex align-items-center">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="h4">{{ vm.layoutSettings.cardHead || client.layoutSettings.cardHead }}</h3>
        </div>
      </div>
    </div>
      `,
    controller: CardHeaderCtrl,
    controllerAs: 'vm'
  });

CardHeaderCtrl.$inject = ['$scope'];
function CardHeaderCtrl($scope) {
  const vm = this;
  vm.layoutSettings = null;
  $scope.$on('$routeChangeSuccess', function($event, next) {
    if (next.$$route.layoutSetting) {
      vm.layoutSettings = next.$$route.layoutSetting;
    }
  });
}
