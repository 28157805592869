FilterListCtrl.$inject = ['$scope', 'serverAddress', '$http', '$location', '$window', 'DownloadCSVService', '$log'];

export default function FilterListCtrl($scope, serverAddress, $http, $location, $window, DownloadCSVService, $log) {
  const vm = this;

  const INITIAL_STATE = {
    baseUrl: '',
    data: {
      totalCount: 0,
      items: []
    },
    settings: {
      title: 'Filter list unnamed',
      enableFiltering: false,
      displaycolumnsFilters: false,
      resourcePath: '',
      pageSize: '20',
      columnDef: [],
      filterFieldInfo: {},
      filterDic$: null // Promise
    },
    currentPage: 0,
    nPages: 0,
    filterText: null,
    filter: {},

    currentFilters: {},
    sortedColumn: {}
  };

  const METHODS = {
    updateItems,
    searchItems,
    clearFilters,
    removeFilter,
    downloadCSV,
    sortColumn,
    prevPage,
    nextPage,
    getQueryData,
    clearTextFilter,
    setCurrentFilters
  };

  Object.assign(vm, INITIAL_STATE, METHODS, { settings: vm.settings });

  vm.$onInit = () => {
    vm.baseUrl = `${serverAddress.getBaseUrl()}${vm.settings.resourcePath}/list/`;
    vm.setCurrentFilters();

    if ($window.rclmng_globals) {
      $scope.$on('ngRepeatFinished', () => $window.rclmng_globals.ux.baseListScroll.init());
    }
  };

  function updateItems() {
    if (!vm.settings.resourcePath) return;

    const pagUrl = `${vm.settings.pageSize}/${vm.currentPage}/json/`;

    var params = vm.getQueryData();

    vm.busy = true;

    $http
      .get(vm.baseUrl + pagUrl, { params })
      .then(res => {
        vm.data.items = sortCols(res.data.objects);
        vm.data.totalCount = res.data.meta.total_count;
        vm.nPages = Math.ceil(vm.data.totalCount / vm.settings.pageSize);
      })
      .catch(error => $log.error(error))
      .finally(() => {
        vm.busy = false;
      });
  }

  function sortCols(data) {
    const sortedData = data.map(row => {
      const sortedRow = {};
      vm.settings.columnDef.map(i => i.fieldName).forEach(item => {
        sortedRow[item] = row[item];
      });
      return sortedRow;
    });
    return sortedData;
  }

  function setCurrentFilters() {
    vm.settings.filterDic$.then(data => {
      Object.keys(vm.filter).forEach(f => {
        if (data.hasOwnProperty(f) && vm.filter.hasOwnProperty(f)) {
          if (Array.isArray(vm.filter[f])) {
            // TODO: Change by filter for arrays when it's supported
            const value = data[f].find(i => vm.filter[f].includes(i.id));
            if (value) vm.currentFilters[f] = value;
          } else {
            const value = data[f].find(i => i.id == vm.filter[f]);
            if (value) vm.currentFilters[f] = value;
          }
        }
      });
      vm.updateItems();
    });
  }

  function getQueryData() {
    const activeColumns = vm.settings.columnDef
      .filter(column => !column.hidden && column.hasOwnProperty('fieldName'))
      .map(aC => aC.fieldName);

    // If the sortedColumn is not in columns_to_show, we don't sort the query
    if (vm.sortedColumn && !activeColumns.includes(vm.sortedColumn.fieldName)) {
      vm.sortedColumn = {};
    }

    /* map filters */
    const fieldInfo = vm.settings.filterFieldInfo || {};
    const filter = Object.keys(vm.currentFilters).reduce((memo, key) => {
      const fieldItem = vm.currentFilters[key];
      if (Array.isArray(fieldItem) && fieldItem.length) {
        memo[key] = Object.assign(fieldInfo[key], fieldItem[0]);
        memo[key].value = memo[key].id;
        // TODO: Replace by map when it's ready
        /*
        memo[key] = fieldItem.map(i => {
          const item = Object.assign(fieldInfo[key], i);
          item.value = item.id;
          return item;
        });
        */
      } else {
        memo[key] = Object.assign(fieldInfo[key], fieldItem);
        memo[key].value = memo[key].id;
      }
      return memo;
    }, {});

    return {
      query: vm.filterText,
      sort: vm.sortedColumn,
      filter,
      columns: [activeColumns],
      segment: vm.filter.segmentPrefix || ''
    };
  }

  function searchItems() {
    vm.filterText = null;
  }

  function clearTextFilter() {
    vm.filterText = null;
    vm.updateItems();
  }

  function clearFilters() {
    const filters = Object.keys(vm.currentFilters);
    if (filters.length) {
      filters.forEach(filKey => {
        vm.removeFilter(filKey);
      });
      vm.currentPage = 0;
      vm.updateItems();
    }
  }

  function removeFilter(key) {
    delete vm.currentFilters[key];
    const currentParamsObj = $location.search();
    delete currentParamsObj[key];
    $location.search(currentParamsObj);
  }

  function downloadCSV() {
    /** Params: 
     // query: vm.filterText,
     // segment: segment,
     // sort: vm.sortedColumn,
     // filter: filter
     */
    const params = Object.assign({}, vm.getQueryData());
    const downloadURL = `${vm.baseUrl}${vm.settings.pageSize}/${vm.currentPage}`;
    DownloadCSVService.fetch(downloadURL, params);
  }

  function sortColumn(column) {
    if (column.sortable == false) return;

    vm.sortedColumn.fieldName = column.fieldName;
    vm.sortedColumn.order = typeof vm.sortedColumn.order === 'boolean' ? !vm.sortedColumn.order : true;

    vm.updateItems();
  }

  function prevPage() {
    if (vm.currentPage > 0) {
      vm.currentPage -= 1;
      vm.updateItems();
    }
  }

  function nextPage() {
    if (vm.currentPage + 1 < vm.nPages) {
      vm.currentPage += 1;
      vm.updateItems();
    }
  }
}
