ForeignerServicesClaimListCtrl.$inject = ['$scope', '$controller', 'ForeignerServicesConstants'];

export default function ForeignerServicesClaimListCtrl($scope, $controller, ForeignerServicesConstants) {
  const vm = this;
  const claimDetailTabs = {};
  ForeignerServicesConstants.tabConfig.forEach(element => {
    claimDetailTabs[element.indexKey] = element.heading;
  });
  const INITIAL_STATE = {
    claimDetailTabs,
    ALIAS: ForeignerServicesConstants.CLAIM_TYPE,

    itemsPath: 'foreignerservices-claim/list/',
    listPath: 'foreignerservices/list/filter/',
    listPathNoFIlter: 'foreignerservices',
    defaultColumnDef: ForeignerServicesConstants.defaultColumnDef,
    filterFieldInfo: ForeignerServicesConstants.filterFieldInfo
  };
  $controller('BaseListCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE);

  /** Initiate List! */
  vm.initBaseList(
    ForeignerServicesConstants.SEGMENT_ID,
    ForeignerServicesConstants.ALIAS,
    ForeignerServicesConstants.permissions
  );
}
