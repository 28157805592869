ManagementListCtrl.$inject = ['$scope', '$controller', 'ManagementConstants', 'dataLoader'];

export default function ManagementListCtrl($scope, $controller, ManagementConstants, dataLoader) {
  const vm = this;
  const claimDetailTabs = {};
  ManagementConstants.tabConfig.forEach(element => {
    claimDetailTabs[element.indexKey] = element.heading;
  });
  const INITIAL_STATE = {
    ALIAS: ManagementConstants.ALIAS,
    claimDetailTabs,

    itemsPath: 'managements-claim/list/',
    listPath: 'managements-claim/list/filter/',
    listPathNoFIlter: 'managements-claim/',
    defaultColumnDef: ManagementConstants.defaultColumnDef,
    filterFieldInfo: ManagementConstants.filterFieldInfo
  };
  $controller('BaseListCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE);

  /** Initiate List! */
  vm.initBaseList(
    ManagementConstants.SEGMENT_ID,
    ManagementConstants.ALIAS,
    ManagementConstants.permissions
  ).then(() => {
    dataLoader('videocalltype').$promise.then(data => {
      vm.contact_type_list = data.objects;
    });
  });
}
