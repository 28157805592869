import angular from 'angular';

import './styles.css';

angular.module('reclamador.components.tabLinksDropdown', []).component('tabLinksDropdown', {
  template: `
    <div class="d-flex {{ vm.dropdownMarginLeft }}">
      <div class="{{ vm.dropdownDirectionIcon }}" uib-dropdown>
        <div
          class="btn-link btn p-0 pr-1 dropdown-toggle"
          uib-dropdown-toggle
        >
        </div>
        <div class="dropdown-menu" uib-dropdown-menu>
          <div
            ng-repeat="(section, name) in vm.tabList track by $index"
            class="dropdown-item"
          >
            <a href="{{ '#/' + vm.baseUrl + '#' + section }}" ng-bind-html="name"></a>
          </div>
        </div>
      </div>
      <a 
        href="{{ '#/' + vm.baseUrl }}"
        ng-bind-html="vm.dropdownTitle" 
        class="dropdown-item-title"
      >
      </a>
    </div>
    `,
  replace: false,
  bindings: {
    dropdownTitle: '<',
    baseUrl: '<',
    tabList: '<',
    dropdownDirectionIcon: '<',
    dropdownMarginLeft: '<'
  },
  controllerAs: 'vm',
  controller: function () {
    this.$onInit = function () {
      this.dropdownDirectionIcon = this.dropdownDirectionIcon || 'dropdown';
    };
  }
});
