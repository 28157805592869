/**
 * state manager Component
 *
 * Common component to manage claim information and handle it's bussines logic:
 * lawyers, salesman, lawyerState  and salesState.
 */
import './index.css';

import angular from 'angular';

import StateManagerCtrl from './StateManagerCtrl';


angular
  .module('reclamador.components.stateManager', [])
  .controller('StateManagerCtrl', StateManagerCtrl)
  .component('stateManager', {
    bindings: {
      parentClaim: '<',
      lawyers: '<',
      salesmen: '<',
      documentTypes: '<',
      legalStates: '<',
      salesStates: '<',
      segmentPrefix: '<',
      statesWithPreviewEmail: '<',
      djangoModel: '<'
    },
    controller: 'StateManagerCtrl',
    controllerAs: 'vm',
    template: require('./partials/base.html')
  })
  .component('privateAreaPreview', {
    bindings:{
      privateAreaInfo: '<',
      claim: '<',
      segmentPrefix: '<',
      documentTypes: '<'
    },
    controllerAs: 'vm',
    template: require('./partials/private-area-preview.html')
  });
