export default function Verticals() {
  var VERTICALS = [
    {
      prefix: 'AE',
      name: 'Aerolíneas',
      claim_path: 'airline-claims'
    },
    {
      prefix: 'BA',
      name: 'Banca',
      claim_path: 'bank-claims'
    },
    {
      prefix: 'AC',
      name: 'Accidentes',
      claim_path: 'accident-claims'
    },
    {
      prefix: 'LA',
      name: 'Laboral',
      claim_path: 'laboral-claims'
    },
    {
      prefix: 'SO',
      name: 'Segunda Oportunidad',
      claim_path: 'so-claims'
    },
    {
      prefix: 'EX',
      name: 'Extranjería',
      claim_path: 'foreignerservices'
    },
    {
      prefix: 'TE',
      name: 'Telefonía',
      claim_path: 'phone-claims'
    },
    {
      prefix: 'TA',
      name: 'Impuestos',
      claim_path: 'taxes-claims'
    },
    {
      prefix: 'NE',
      name: 'Negligencias',
      claim_path: 'accident-claims'
    },
    {
      prefix: 'GE',
      name: 'Gestiones',
      claim_path: 'managements-claims'
    },
    {
      prefix: 'SG',
      name: 'Servicio genérico',
      claim_path: 'legalservices-claims'
    }
  ];

  return {
    get: function() {
      return VERTICALS;
    },

    getSegmentNameFromPrefix: prefix => {
      if(prefix == 'IM') return 'Expediente de impagado';
      const VERTICAL = VERTICALS.find(e => e.prefix == prefix);

      // this should never happen
      if(!VERTICAL) throw new Error(`Unknown prefix ${prefix}`);
      return VERTICAL.name;
    },

    getUrlFromSegmentName: name => {
      const VERTICAL = VERTICALS.find(e => e.name == name);

      // this should never happen
      if(!VERTICAL) throw new Error(`Unknown segment name ${name}`);
      return VERTICAL.claim_path;
    },

    getPrefixFromName: name => {
      const VERTICAL = VERTICALS.find(e => e.name == name);

      // this should never happen
      if(!VERTICAL) throw new Error(`Unknown segment name ${name}`);
      return VERTICAL.prefix;
    }
  };
}
