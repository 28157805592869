import angular from 'angular';
import NavbarCtrl from './NavbarCtrl';

angular
  .module('reclamador.components.navbar', [])
  .controller('NavbarCtrl', NavbarCtrl)
  .component('navbarComponent', {
    template: `
    <nav class="navbar fixed-top">
      <div class="container-fluid">
        <div class="navbar-holder d-flex align-items-center justify-content-between">
          <!-- Navbar Header-->
          <div class="navbar-header">
            <a href="#" id="togle-sidebar" class="btn btn-text">
              <i class="fas fa-bars"></i>
              </button>
              <a href="#" class="navbar-brand">
                <img class="w-100" src="img/logo-reclamador.svg" alt="logo reclamador" />
                <span class="small h-75 d-inline-block align-bottom pl-2">{{vm.version}}</span>
              </a>
          </div>
          <!-- Navbar Menu -->
          <ul class="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
            <li class="nav-item">
              <goto-component></goto-component>
            </li>
            <li class="nav-item dropdown user" uib-dropdown>
              <a href="#" class="dropdown-toggle nav-link" uib-dropdown-toggle>
                <div class="inset">
                  <img ng-src="{{vm.avatar}}">
                  <span>{{ vm.username }}</span>
                </div>
              </a>
              <ul class="dropdown-menu" uib-dropdown-menu>
                <li class="dropdown-item">
                  <a rel="nofollow" href="/#/my-profile">
                    <i class="fas"></i> Mi perfil
                  </a>
                </li>
                <li class="dropdown-item">
                  <a rel="nofollow" ng-click="vm.logout()">
                    <i class="fas fa-sign-out-alt"></i> Cerrar sesión
                  </a>
                </li>
              </ul>
            </li>

          </ul>
        </div>
      </div>
    </nav>
    `,
    controller: NavbarCtrl,
    controllerAs: 'vm'
  });
