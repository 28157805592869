ForeignerServicesRoutes.$inject = ['$routeProvider', 'ForeignerServicesConstants'];

export default function ForeignerServicesRoutes($routeProvider, ForeignerServicesConstants) {

  $routeProvider.when('/foreignerservices/salespeople/counts', {
    title: 'Panel Comerciales de extranjería',
    templateUrl: 'partials/common/panels/sales.html',
    data: {
      permissions: {
        only: 'segment-perobject-EX-crm_view_salespeople_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Extranjería',
      iconClass: 'fas fa-id-card',
      cardHead: 'Panel Comerciales de Extranjería'
    },
    controller: 'CommonSalesDashboardCtrl',
    controllerAs: 'vm',
    claimListBaseURL: '#/foreignerservices/list/',
    SalesmanService: 'ForeignerServicesSalesman',
    salesPeopleCountsURL: 'foreignerservices/salespeople/counts/',
    segmentName: 'Foreignerservices',
    segmentID: ForeignerServicesConstants.SEGMENT_ID
  });

  $routeProvider.when('/foreignerservices/lawyers/counts', {
    title: 'Panel Abogados de extranjería',
    templateUrl: 'partials/common/panels/lawyers.html',
    data: {
      permissions: {
        only: 'segment-perobject-EX-crm_view_lawyers_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Extranjería',
      iconClass: 'fas fa-id-card',
      cardHead: 'Panel Abogados de Extranjería'
    },
    controller: 'ForeignerServicesLawyerPanelCtrl',
    controllerAs: 'vm'
  });

  const permissions = {
    only: 'segment-perobject-EX-crm_view_claims_list',
    redirectTo: 'no-permissions'
  };

  const claimListConfig = {
    title: 'Reclamaciones extranjería',
    templateUrl: 'partials/common/baseList/claim-list.html',
    data: { permissions },
    layoutSetting: {
      contentTitle: 'Extranjería',
      iconClass: 'fas fa-id-card'
    },
    baseListSettings: {
      typeList: 'claims',
      typeTitle: 'gestiones',
      templateListTable: 'js/modules/segments/foreignerservices/partials/claim-list-table.html',
      templateListFilter: 'partials/common/baseList/common-claim-list-filter.html',
      title: 'Gestiones extranjería',
      listResultsText: 'gestiones'
    },
    controller: 'ForeignerServicesClaimListCtrl',
    controllerAs: 'vm',
    segmentID: ForeignerServicesConstants.SEGMENT_ID
  };

  $routeProvider.when('/foreignerservices', claimListConfig);
  $routeProvider.when('/foreignerservices/list/filter/:filter', claimListConfig);

  $routeProvider.when('/foreignerservices/:id', {
    title: 'Detalle gestión extranjería',
    templateUrl: 'js/modules/segments/foreignerservices/partials/claim.html',
    data: {
      permissions: {
        only: 'segment-perobject-EX-crm_view_claim_detail',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Extranjería',
      iconClass: 'fas fa-id-card'
    },
    controller: 'ForeignerServicesClaimDetailCtrl',
    controllerAs: 'vm',
    segmentID: ForeignerServicesConstants.SEGMENT_ID,
    reloadOnSearch: false
  });
}
