MergeModalCtrl.$inject = [
  '$scope',
  '$filter',
  'dialog',
  'Targets',
  'notifications'
];

export default function MergeModalCtrl (
  $scope,
  $filter,
  dialog,
  Targets,
  notifications
) {
  try { // modal factory for some reason don't throw errors, so...
    $scope.vm = Object.assign({}, {
      targets: [],
      outputFileName: getRandomName(),

      // methods
      truncateFilenameFilter: $filter('truncatefilename'),
      ok,
      cancel,
      getRotations,
      rotateItem,
      inititateTargets
    });

    inititateTargets();

    function getRandomName () {
      const NOW = new Date();
      return `archivo-fusionado_${NOW.toISOString().split('T')[0]}.pdf`;
    }

    function rotateItem (item) {
      item.rotation = item.rotation == 270 ? 0 : item.rotation + 90;
    }

    function inititateTargets(dontNotify) {
      if(!dontNotify) {
        const UNKNOWNS = Targets.filter(e => e.type == 'unknown')
          .map(e => $scope.vm.truncateFilenameFilter(e.filename));

        if(UNKNOWNS.length) {
          notifications.addCurrentView(
            'info',
            `Los documentos ${UNKNOWNS.join(', ')} no pueden ser combinados y se eliminaron de la selección`
          );
        }
      }

      $scope.vm.targets = Targets.filter(e => e.type != 'unknown')
        .map(e => Object.assign({rotation: 0}, e));

      if($scope.vm.targets.length < 2) {
        notifications.addCurrentView(
          'error',
          'Ningún documento a combinar. Vuelve a intentarlo con una selección de documentos correctos ' +
          'combinables (docs, imágenes y pdfs).'
        );
        $scope.vm.cancel();
      }
    }

    function getRotations () {
      return $scope.vm.targets.map(e => e.rotation);
    }

    function ok () {
      dialog.close();
      return dialog.deferred.resolve({
        docs: $scope.vm.targets.map(e => e.id),
        rotations: getRotations(),
        filename: $scope.vm.outputFileName
      });
    }

    function cancel () {
      dialog.close();
      return dialog.deferred.reject(null);
    }
  } catch(e){
    console.log(e);
  }
}