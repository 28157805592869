Worker.$inject = ['$http', 'serverAddress'];
export default function Worker($http, serverAddress) {
  const resourceURL = `${serverAddress.getBaseUrl()}api2/v1/worker/`;

  return {
    get: ({ id }) => $http.get(`${resourceURL}${id}/`),
    update: (id, data) => $http.put(`${resourceURL}${id}/`, data),
    getBaseUrl: () => resourceURL
  };
}
