/* eslint-disable angular/di */
import angular from 'angular';
import ItuxCtrl from './ItuxCtrl';

angular
  .module('reclamador.itux', [])
  .controller('ItuxCtrl', ItuxCtrl)
  .config([
    '$routeProvider',
    function($routeProvider) {
      $routeProvider.when('/itux', {
        title: 'Itux',
        templateUrl: 'js/modules/itux/partials/index.html',
        layoutSetting: {
          contentTitle: 'ITUX',
          iconClass: 'fas fa-id-card'
        },
        controller: ItuxCtrl,
        controllerAs: 'vm'
      });
    }
  ]);
