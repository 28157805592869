/*
 Simplify generating a fileUploader object
 Requires a config objet with at leats a url and the $scope vm objet
 The generator save the uploader in the passed scope in vm.fileUploader
 Lots of improvemts possible :p
*/

fileUploaderGenerator.$inject = ['notifications', 'FileUploader', '$window', '$localStorage'];

export default function fileUploaderGenerator(notifications, FileUploader, $window, $localStorage) {
  return function(config) {
    if (!config || !config.url || !config.vm)
      throw new Error('fileUploaderGenerator initiated without proper configuration');

    let vm = config.vm;

    let defaults = {
      alias: 'files[]',
      url: config.url,
      headers: {
        Authorization: `ApiKey ${$localStorage.get('username')}:${$localStorage.get('token')}`
      },
      removeAfterUpload: true,
      onBeforeUploadItem: function(item) {
        // allow to modify the item before sending
        if (config.fillItemData) config.fillItemData(item);
      },
      onErrorItem: function(item, response, status, headers) {
        if (config.errorCallback) {
          config.errorCallback(item, response, status, headers);
          return;
        }
        notifications.addCurrentView('error', 'Se produjo un error subiendo el archivo.');
      },
      onSuccessItem: (item, response, status, headers) => {
        if (config.sucessCallback) {
          config.sucessCallback(item, response, status, headers);
          return;
        };

        notifications.addCurrentView('info', 'Archivo subido con éxito, actualizando reclamación');
      },
      onAfterAddingFile: function() {
        if (config.ignoreDisplayUploader) {
          return;
        }
        vm.displayDocumentsUploader = true;
      },
      onCompleteAll: function() {
        if (vm.fileUploader.queue.length === 0) vm.displayDocumentsUploader = false;
      }
    };

    vm.fileUploader = new FileUploader(Object.assign(defaults, config));

    return vm.fileUploader;
  };
}
