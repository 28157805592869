import claimKeysDict from '../../../data/claimKeysDict.json';
// claimChangesTracker.$inject = ['$location', '$rootScope'];

export default function claimChangesTracker() { // $location, $rootScope
  return {
    restrict: 'A',
    transclude: true,
    scope: {
      claimChanges: '<claimChangesTracker',
      entityType: '<', // for now 'claim' or 'legalfile'
      saveChangesPermission: '<'
    },
    template: require('./claim-changes-tracker.html'),
    link: function(scope) {
      const vm = scope;

      Object.assign(vm, {
        discardChangesModalShow: false,
        discardChangesTargetUrl: null,

        // discardChangesModalOkCb,
        saveChanges,
        discardChanges,
        claimKeysDict
      });

      // let preventRouteChange = $rootScope.$on('$locationChangeStart', e => {
      //   // if there are no pending changes, countinue the redirection
      //   if(!vm.claimChanges || vm.claimChanges.length == 0) return;

      //   // stop redirections and show modal
      //   e.preventDefault();
      //   vm.discardChangesTargetUrl = $location.path();
      //   vm.discardChangesModalShow = true;
      // });

      // function discardChangesModalOkCb () {
      //   if(!vm.discardChangesTargetUrl) return;
      //   preventRouteChange();
      //   $location.path(vm.discardChangesTargetUrl);
      // };

      function saveChanges () {
        if(vm.entityType == 'legalfile') {
          scope.$emit('legalfile:save');
        } else {
          scope.$emit('claim:save');
        }
      };

      function discardChanges () {
        // resseting claim the legalfile is resetted too
        scope.$emit('claim:reset');
      };
    }
  };
}
