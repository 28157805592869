ConfirmDocumentDeleteCtrl.$inject = [
  '$scope',
  '$rootScope',
  '$http',
  'dialog',
  'serverAddress',
  'notifications',
  'doc',
  'legalfile'
];

export default function ConfirmDocumentDeleteCtrl(
  $scope,
  $rootScope,
  $http,
  dialog,
  serverAddress,
  notifications,
  doc,
  legalfile
) {
  Object.assign($scope, {
    doc,
    confirm,
    dismiss,
    legalfile
  });

  /* Function implementations */
  function confirm() {
    $http
      .post(serverAddress.getBaseUrl() + 'document/delete/', {
        document_id: doc.id,
        prefix: legalfile.real_prefix
      })
      .then(() => {
        $rootScope.$broadcast('document:deleted', doc);
      })
      .catch(() => {
        notifications.addCurrentView('error', 'No se pudo eliminar el documento.');
      })
      .finally(() => {
        dialog.close();
      });
  }

  function dismiss() {
    dialog.close();
  }
}
