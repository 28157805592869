const BOOLEAN_TYPE = [
    { value: true, label: 'Sí' },
    { value: false, label: 'No' }
  ],
  NULL_BOOLEAN_TYPE = [
    { value: null, label: 'No lo sabe' },
    { value: true, label: 'Sí' },
    { value: false, label: 'No' }
  ],
  NUMERAL_BOOLEAN_TYPE = [
    { value: 2, label: 'No lo sabe' },
    { value: 1, label: 'Sí' },
    { value: 0, label: 'No' }
  ],
  IRPH_USE = [
    { value: 0, label: 'Para comprar mi Vivienda habitual' },
    { value: 1, label: 'Para comprar una segunda vivienda de uso personal' },
    { value: 2, label: 'Para comprar una vivienda destinada a alquilarla' },
    { value: 3, label: 'No fue para la compra de una vivienda' }
  ],
  IRPH_PAYMENT_STATUS = [
    { value: 0, label: 'No, terminé de pagar la hipoteca' },
    { value: 1, label: 'No, vendí la casa y el comprador se subrogó a mi hipoteca' },
    { value: 2, label: 'No, no puedo pagar la hipoteca y he dejado de pagar al banco' },
    { value: 3, label: 'Si, sigo pagando la hipoteca' }
  ],
  IRPH_HAD_FLOOR_CLAUSE_EXPLANATION = [
    { value: 0, label: 'He puesto una demanda en los juzgados y aún no ha terminado el proceso' },
    { value: 1, label: 'Llegamos a juicio y me la eliminaron' },
    { value: 2, label: 'Llegamos a juicio pero perdí y no me la eliminaron' },
    {
      value: 3,
      label:
        'Presenté una reclamación por escrito al banco y conseguí que me la quitaran y devolvieran el dinero'
    },
    {
      value: 4,
      label:
        'Presenté una reclamación por escrito al banco pero no me la han quitado ni devuelto el dinero'
    },
    {
      value: 5,
      label: 'Llegué a un acuerdo con el banco y me la redujeron o me la cambiaron por un tipo fijo'
    },
    { value: 6, label: 'El banco me la ha eliminado sin tener que hacer nada' }
  ],
  IRPH_SIGNED_AGREEMENT = [
    { value: 0, label: 'No' },
    { value: 1, label: 'No firmé nada, pero el banco me sustituyó el IRPH por un tipo fijo' },
    { value: 2, label: 'Sí pero no tengo una copia de este acuerdo' },
    { value: 3, label: 'Sí, y puedo aportar el documento de este acuerdo' }
  ],
  FLOOR_CLAUSE_BANK_JOB = [
    { value: true, label: 'Sí' },
    { value: false, label: 'No' }
  ],
  FLOOR_CLAUSE_APPLYING = [
    { id: 'Y', value: 'Si' },
    { id: 'N', value: 'No, desde' },
    { id: 'K', value: 'No lo se' }
  ],
  FLOOR_CLAUSE_SIGNED_CHANGE_OR_REMOVAL_AGREEMENT = [
    { id: 'YY', value: 'Sí, y puedo aportaros  el documento de este acuerdo' },
    { id: 'YN', value: 'Sí, pero no tengo una copia de este acuerdo' },
    { id: 'N', value: 'No' }
  ],
  IRPH_MORTGAGE_SUSTITUTIVE_REFERENCE_TYPES = [
    { id: 0, value: 'No hay sustitutivo en la hipoteca' },
    { id: 1, value: 'IRPH Entidades' },
    { id: 2, value: 'IRPH Cajas' },
    { id: 3, value: 'IRPH Bancos' },
    { id: 4, value: 'CECA' },
    { id: 5, value: 'Euribor' },
    { id: 6, value: 'Fijo' }
  ],
  REFERENCE_TYPES = {
    FLOOR_CLAUSE: [
      { id: 0, value: 'EURIBOR' },
      { id: 1, value: 'IRPH' },
      { id: 2, value: 'MIBOR' }
    ],
    IRPH: [
      { id: 3, value: 'IRPH Entidades' },
      { id: 4, value: 'IRPH Cajas' },
      { id: 5, value: 'IRPH Bancos' },
      { id: 6, value: 'CECA' }
    ]
  },
  SUSTITUTIVE_REFERENCE_TYPES = [
    { id: 0, value: 'n.a.' },
    { id: 1, value: 'EURIBOR' }
  ],
  REFERENCE_MONTHS = [
    { id: 1, value: 'Mes anterior' },
    { id: 2, value: 'Dos meses anteriores' },
    { id: 3, value: '3 meses anteriores' }
  ],
  REFERENCE_PERIODS = [
    { id: 1, value: 'Mensual' },
    { id: 3, value: 'Trimestral' },
    { id: 6, value: 'Semestral' },
    { id: 12, value: 'Anual' }
  ],
  FLOOR_CLAUSE_USE = [
    'Vivienda habitual',
    'Segunda vivienda de uso personal',
    'Vivienda destinada a alquilarla',
    'Otros'
  ],
  FLOOR_CLAUSE_MORTGAGE_PAYMENT_STATUS = [
    { id: 'P', value: 'Si, sigo pagando la hipoteca' },
    { id: 'F', value: 'No, terminé de pagar la hipoteca' },
    {
      id: 'S',
      value:
        'No, vendí la casa y otra persona se hizo cargo de la hipoteca (es decir, el comprador se subrogó en mi hipoteca)'
    },
    { id: 'N', value: 'No, no puedo pagar la hipoteca y he dejado de pagar al banco' }
  ],
  FLOOR_CLAUSE_BANK_INFORMED_BEFORE_MORTGAGE = [
    { id: 'YY', value: 'Sí, me informaron y pude negociar el porcentaje de la cláusula suelo' },
    { id: 'YN', value: 'Sí, me informaron pero no pude negociar la cláusula suelo' },
    {
      id: 'N',
      value: 'No, no me informaron y no sabía que la cláusula suelo estaba en las condiciones'
    },
    { id: 'K', value: 'No lo sé / No lo recuerdo' }
  ],
  FLOOR_CLAUSE_PRESENTED_CLAIM_BY_OTHER_MEANS = [
    { value: true, label: 'Sí' },
    { value: false, label: 'No' }
  ],
  FLOOR_CLAUSE_BANK_EXCHANGED_INFO_ALLOWING_FLOOR_CLAUSE_CHANGES = [
    { value: true, label: 'Sí' },
    { value: false, label: 'No' }
  ],
  FLOOR_CLAUSE_BANK_EXCHANGED_INFO_WITHOUT_FLOOR_CLAUSE_CHANGES = [
    { value: true, label: 'Sí' },
    { value: false, label: 'No' }
  ],
  FLOOR_CLAUSE_MISSING_MONTLY_PAYMENTS = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: 'Más de 6' }
  ],
  contract_type = [
    { label: 'Préstamo', value: 'EP' },
    { label: 'Novación', value: 'ER'},
    { label: 'Subrogación', value: 'ES' },
    { label: 'Subrogación y Novación', value: 'SN' },
    { label: 'Cancelación', value: 'EC' }
  ],
  IRPH_CONTRACT_TYPE = [
    { label: 'Préstamo', value: 'EP' },
    { label: 'Novación', value: 'ER' },
    { label: 'Cancelación', value: 'EC' },
    { label: 'Compraventa con subrogación', value: 'ES' }
  ];

export default {
  CLIENT_ANSWERS: {
    // Preferentes
    5: [
      {
        question: '¿Cuánto dinero has invertido en preferentes o deuda subordinada?',
        dictionary: null,
        type: 'raw',
        keyvalue: 'claim.preferred_stock_amoun'
      },
      {
        question: '¿Con qué banco la tienes contratada?',
        dictionary: null,
        type: 'raw',
        keyvalue: 'claim.bank.name'
      },
      {
        question: '¿Solicitaste a tu banco el arbitraje?',
        dictionary: null,
        type: 'raw',
        keyvalue: 'claim.arbitrage_asked'
      },
      {
        question: '¿Te concedieron el arbitraje?',
        dictionary: null,
        type: 'raw',
        keyvalue: 'claim.bank'
      },
      {
        question: '¿Hiciste el canje de preferentes por acciones?',
        dictionary: null,
        type: 'raw',
        keyvalue: 'claim.bank'
      },
      {
        question: '¿En qué provincia se encuentra tu vivienda habitual?',
        dictionary: null,
        type: 'raw',
        keyvalue: 'client_bank.name'
      },
      {
        question: '¿En qué provincia se encuentra la sucursal en la que contrataste el producto?',
        dictionary: null,
        type: 'raw',
        keyvalue: 'claim.branch_town'
      },
      {
        question:
          '¿Tú o alguno de los titulares de la hipoteca trabajáis o habéis trabajado en el sector bancario y/o inmobilario?',
        dictionary: FLOOR_CLAUSE_BANK_JOB,
        type: 'raw',
        keyvalue: 'claim.floor_clause_bank_job'
      },
      {
        question: '¿Tiene cláusula suelo actualmente?',
        dictionary: FLOOR_CLAUSE_APPLYING,
        type: 'raw',
        keyvalue: 'claim.applying_floor_clause'
      }
    ],
    // Clausula suelo
    6: [
      {
        question: '¿En qué porcentaje se establece la cláusula suelo de tu contrato? (%)',
        dictionary: null,
        type: 'raw',
        keyvalue: 'claim.floor_clause_percentage'
      },
      {
        question: '¿Con qué banco la tienes contratada?',
        dictionary: null,
        type: 'raw',
        keyvalue: 'claim.bank.name'
      },
      {
        question: '¿Tiene tu hipoteca cláusula techo?',
        dictionary: null,
        type: 'raw',
        keyvalue: 'claim.has_ceil_clause'
      },
      {
        question: '¿Cuál es el porcentaje de tu cláusula techo? (%)',
        dictionary: null,
        type: 'raw',
        keyvalue: 'claim.ceil_clause_percentage'
      },
      {
        question: '¿Aparece la cláusula suelo en la oferta vinculante previa a la hipoteca?',
        dictionary: null,
        type: 'raw',
        keyvalue: 'claim.clause_in_binding_agreement'
      },
      {
        question:
          '¿Te informó tu banco por escrito, de forma clara y precisa, sobre la cláusula suelo?',
        dictionary: null,
        type: 'raw',
        keyvalue: 'claim.floor_clause_explained'
      },
      {
        question: '¿En qué provincia se encuentra tu vivienda habitual?',
        dictionary: null,
        type: 'raw',
        keyvalue: 'client_bank.name'
      },
      {
        question: '¿En qué provincia se encuentra la sucursal en la que contrataste el producto?',
        dictionary: null,
        type: 'raw',
        keyvalue: 'claim.branch_town'
      },
      {
        question:
          '¿Tú o alguno de los titulares de la hipoteca trabajáis o habéis trabajado en el sector bancario y/o inmobilario?',
        dictionary: FLOOR_CLAUSE_BANK_JOB,
        type: 'raw',
        keyvalue: 'claim.floor_clause_bank_job'
      },
      {
        question:
          '¿Alguno de los titulares ha firmado un acuerdo con el banco para eliminar o reducir la cláusula suelo?',
        dictionary: FLOOR_CLAUSE_SIGNED_CHANGE_OR_REMOVAL_AGREEMENT,
        type: 'raw',
        keyvalue: 'claim.floor_clause_signed_change_or_removal_agreement'
      }
    ],
    // warrants
    7: [
      {
        question:
          '¿Tú o alguno de los titulares de la hipoteca trabajáis o habéis trabajado en el sector bancario y/o inmobilario?',
        dictionary: FLOOR_CLAUSE_BANK_JOB,
        type: 'raw',
        keyvalue: 'claim.floor_clause_bank_job'
      },
      {
        question: '¿Tiene cláusula suelo actualmente?',
        dictionary: FLOOR_CLAUSE_APPLYING,
        type: 'raw',
        keyvalue: 'claim.applying_floor_clause'
      }
    ],
    // hipotecas multidivisa
    8: [
      {
        question:
          '¿Tú o alguno de los titulares de la hipoteca trabajáis o habéis trabajado en el sector bancario y/o inmobilario?',
        dictionary: FLOOR_CLAUSE_BANK_JOB,
        type: 'raw',
        keyvalue: 'claim.floor_clause_bank_job'
      },
      {
        question: '¿Con qué banco la tienes contratada?',
        dictionary: null,
        type: 'raw',
        keyvalue: 'claim.bank.name'
      },
      {
        question: '¿A qué destinaste el dinero del préstamo hipotecario?',
        dictionary: FLOOR_CLAUSE_USE,
        type: 'raw',
        keyvalue: 'claim.floor_clause_property_use'
      },
      {
        question: '¿Ha habido préstamo previo?',
        dictionary: null,
        type: 'raw',
        keyvalue: 'claim.multicurrency_mortgage_previous_loan'
      },
      {
        question: '¿Ha habido cambio de divisa?',
        dictionary: null,
        type: 'raw',
        keyvalue: 'claim.multicurrency_mortgage_currency_exchange'
      }
    ],
    //Bonos convertibles
    9: [
      {
        question:
          '¿Tú o alguno de los titulares de la hipoteca trabajáis o habéis trabajado en el sector bancario y/o inmobilario?',
        dictionary: FLOOR_CLAUSE_BANK_JOB,
        type: 'raw',
        keyvalue: 'claim.floor_clause_bank_job'
      },
      {
        question: '¿Tiene cláusula suelo actualmente?',
        dictionary: FLOOR_CLAUSE_APPLYING,
        type: 'raw',
        keyvalue: 'claim.applying_floor_clause'
      }
    ],
    10: [],
    11: [],
    16: [],
    17: [],
    27: [],

    // IRPH
    30: [
      {
        question: '¿Con qué banco tienes contratada la hipoteca? ',
        dictionary: null,
        type: 'raw',
        keyvalue: 'irph.irph_bank.name'
      },
      {
        question: '¿Tiene o has tenido otra hipoteca?',
        dictionary: BOOLEAN_TYPE,
        type: 'raw',
        keyvalue: 'irph.irph_another_mortgage'
      },
      {
        question: '¿A qué destinaste el dinero del préstamo hipotecario?',
        dictionary: IRPH_USE,
        type: 'raw',
        keyvalue: 'irph.irph_property_use',
        subanswers: {
          2: {
            question: '¿Cuántos inmuebles de tu propiedad tienes destinados al alquiler?',
            dictionary: [
              { value: '0', label: '1 ó 2' },
              { value: '1', label: 'Más de 2' }
            ],
            type: 'raw',
            keyvalue: 'irph.irph_property_use_number'
          },
          3: {
            question: '¿A qué lo destinaste?',
            dictionary: [
              { value: '0', label: 'Para comprar un local comercial o una licencia de taxi' },
              { value: '1', label: 'Otros' }
            ],
            type: 'raw',
            keyvalue: 'irph.irph_property_use_detail_others'
          }
        }
      },
      {
        question: '¿Actualmente sigues pagando la hipoteca?',
        dictionary: IRPH_PAYMENT_STATUS,
        type: 'raw',
        keyvalue: 'irph.irph_mortgage_payment_status',
        subanswers: {
          0: {
            question: 'Aproximadamente, ¿en qué fecha terminaste de pagarla?',
            dictionary: null,
            type: 'date',
            keyvalue: 'irph.irph_had_floor_clause_date'
          },
          1: {
            question: 'Aproximadamente, ¿en qué fecha vendiste la casa?',
            dictionary: null,
            type: 'date',
            keyvalue: 'irph.irph_mortgage_status_date'
          }
        }
      },
      {
        question:
          '¿Tu banco te informó sobre el tipo IRPH antes de la firma de la hipoteca, la diferencia y el coste comparativo con el Euribor, así como de la evolución en el tiempo de ambos índices?',
        dictionary: BOOLEAN_TYPE,
        type: 'raw',
        keyvalue: 'irph.irph_bank_informed'
      },
      {
        question:
          '¿Tú o alguno de los titulares de la hipoteca trabajáis o habéis trabajado en el sector bancario y/o inmobilario?',
        dictionary: BOOLEAN_TYPE,
        type: 'raw',
        keyvalue: 'irph.irph_bank_job',
        subanswers: {
          true: {
            question:
              '¿En tus funciones o las de algún titular de la hipoteca estaba el comercializar productos bancarios?',
            dictionary: [
              { value: true, label: 'Sí, he comercializado este u otros productos bancarios' },
              { value: false, label: 'No, no me dedicaba a comercializar productos bancarios' }
            ],
            type: 'raw',
            keyvalue: 'irph.irph_is_banking_salesman'
          }
        }
      },
      {
        question:
          '¿Tienes contratados productos bancarios de riesgo como fondos de inversión, acciones, etc…?',
        dictionary: NULL_BOOLEAN_TYPE,
        type: 'raw',
        keyvalue: 'irph.irph_has_risk_products',
        subanswers: {
          null: {
            question:
              'Indícanos que tipo de productos bancarios tienes contratados para saber si son de riesgo.',
            dictionary: null,
            type: 'raw',
            keyvalue: 'irph.irph_risk_products_maybe_extra'
          }
        }
      },
      {
        question: '¿Este préstamo ha tenido en algún momento o tiene cláusula suelo?',
        dictionary: BOOLEAN_TYPE,
        type: 'raw',
        keyvalue: 'irph.irph_had_floor_clause',
        subanswers: {
          true: {
            question: '¿Has reclamado la claúsula suelo con Reclamador?',
            dictionary: BOOLEAN_TYPE,
            type: 'raw',
            keyvalue: 'irph.irph_had_floor_clause_reclamador'
          }
        }
      },
      {
        question: '¿Qué pasó con la reclamación de la cláusula suelo?',
        dictionary: IRPH_HAD_FLOOR_CLAUSE_EXPLANATION,
        type: 'raw',
        keyvalue: 'irph.irph_had_floor_clause_explanation',
        subanswers: {
          6: {
            question: 'Aproximadamente, ¿en qué fecha en la que el banco eliminó la cláusula?',
            dictionary: null,
            type: 'date',
            keyvalue: 'irph.irph_bank_cancelled_date'
          }
        }
      },
      {
        question:
          '¿Tú o alguno de los titulares habéis firmado un acuerdo con el banco para cambiar el IRPH por otro índice?',
        dictionary: IRPH_SIGNED_AGREEMENT,
        type: 'raw',
        keyvalue: 'irph.irph_signed_agreement'
      },
      {
        question: '¿Has presentado ya otra reclamación de IRPH por otra vía?',
        dictionary: BOOLEAN_TYPE,
        type: 'raw',
        keyvalue: 'irph.irph_presented_claim_by_other_means',
        subanswers: {
          true: {
            question: '¿Dónde has presentado esta otra reclamación?',
            dictionary: null,
            type: 'raw',
            keyvalue: 'irph.irph_presented_claim_by_other_means_description'
          }
        }
      },
      {
        question: 'Cuéntanos brevemente tu caso',
        dictionary: null,
        type: 'raw',
        keyvalue: 'irph.irph_case_explanation'
      }
    ],
    // formalization expenses
    39: [
      {
        question:
          '¿Alguno de los titulares ha firmado un acuerdo con el banco para eliminar o reducir la cláusula suelo?',
        dictionary: FLOOR_CLAUSE_SIGNED_CHANGE_OR_REMOVAL_AGREEMENT,
        type: 'raw',
        keyvalue: 'claim.floor_clause_signed_change_or_removal_agreement'
      },
      {
        question: '¿Con qué banco la tienes contratada?',
        dictionary: null,
        type: 'raw',
        keyvalue: 'claim.bank.name'
      },
      {
        question: '¿A qué destinaste el dinero del préstamo hipotecario?',
        dictionary: FLOOR_CLAUSE_USE,
        type: 'raw',
        keyvalue: 'claim.floor_clause_property_use'
      },
      {
        question: '¿Actualmente sigues pagando la hipoteca?',
        dictionary: FLOOR_CLAUSE_MORTGAGE_PAYMENT_STATUS,
        type: 'raw',
        keyvalue: 'claim.floor_clause_mortgage_payment_status'
      },
      {
        question: '¿Tiene otras hipotecas?',
        dictionary: BOOLEAN_TYPE,
        type: 'raw',
        keyvalue: 'claim.another_mortgage'
      },
      {
        question: 'Cuéntanos brevemente tu caso',
        dictionary: null,
        type: 'raw',
        keyvalue: 'claim.floor_clause_case_explanation'
      }
    ]
  },

  BOOLEAN_TYPE,
  NULL_BOOLEAN_TYPE,
  NUMERAL_BOOLEAN_TYPE,

  IRPH_USE,
  IRPH_PAYMENT_STATUS,
  IRPH_HAD_FLOOR_CLAUSE_EXPLANATION,
  IRPH_SIGNED_AGREEMENT,

  FLOOR_CLAUSE_USE,
  FLOOR_CLAUSE_APPLYING,
  FLOOR_CLAUSE_BANK_JOB,
  FLOOR_CLAUSE_SIGNED_CHANGE_OR_REMOVAL_AGREEMENT,
  IRPH_MORTGAGE_SUSTITUTIVE_REFERENCE_TYPES,
  REFERENCE_TYPES,
  SUSTITUTIVE_REFERENCE_TYPES,
  REFERENCE_MONTHS,
  REFERENCE_PERIODS,
  FLOOR_CLAUSE_MORTGAGE_PAYMENT_STATUS,
  FLOOR_CLAUSE_BANK_INFORMED_BEFORE_MORTGAGE,
  FLOOR_CLAUSE_PRESENTED_CLAIM_BY_OTHER_MEANS,
  FLOOR_CLAUSE_BANK_EXCHANGED_INFO_ALLOWING_FLOOR_CLAUSE_CHANGES,
  FLOOR_CLAUSE_BANK_EXCHANGED_INFO_WITHOUT_FLOOR_CLAUSE_CHANGES,
  FLOOR_CLAUSE_MISSING_MONTLY_PAYMENTS,
  contract_type,
  IRPH_CONTRACT_TYPE
};
