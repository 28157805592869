/* Common mehtods used by the crm panels */

PanelTools.$inject = ['$http', 'serverAddress', 'notifications'];

export default function PanelTools($http, serverAddress, notifications) {
  return {
    getUserClaimCounts,
    getUserSalesClaimCounts,
    getUserSalesStatusClaimCounts,
    getUserGoalsStatusClaimCounts,
    getSalesmenGoalsCounts,
    getIntervals,
    last12Months
  };

  // Those are the original functions
  function getUserClaimCounts(scopeModel) {
    return $http({
      url: serverAddress.getBaseUrl() + 'claim/state/counts/' + scopeModel.domain,
      method: 'GET',
      params: {
        signaturit: scopeModel.digital_sign_via_signaturit
      }
    })
      .then(response => {
        scopeModel.counts = response.data;
      })
      .catch(e => {
        notifications.addCurrentView('error', 'Error al obtener el informe.' + e);
      });
  }

  function getUserSalesClaimCounts(scopeModel) {
    return $http({
      url: serverAddress.getBaseUrl() + 'claim/salesstate/counts/' + scopeModel.language,
      method: 'GET'
    })
      .then(response => {
        scopeModel.salesCounts = response.data;
      })
      .catch(() => {
        notifications.addCurrentView('error', 'Error al obtener el informe (\'UserSalesClaimCounts\').');
      });
  }

  function getUserSalesStatusClaimCounts(scopeModel) {
    return $http({
      url: serverAddress.getBaseUrl() + 'claim/salesstatus/counts/' + scopeModel.language,
      method: 'GET'
    })
      .then(response => {
        scopeModel.salesStatusCounts = response.data;
      })
      .catch(() => {
        notifications.addCurrentView('error', 'Error al obtener el informe (\'SalesStatusClaimCounts\').');
      });
  }

  function getUserGoalsStatusClaimCounts(params) {
    return $http({
      url: serverAddress.getBaseUrl() + 'claim/goals/counts/',
      method: 'GET',
      params
    });
  }

  function getSalesmenGoalsCounts(scopeModel) {
    return $http({
      url: serverAddress.getBaseUrl() + 'claim/salesgoals/counts/',
      method: 'GET',
      params: {
        goal_period: scopeModel.sales_goal_period
      }
    });
  }
  ///////////////////////////////////////////////////
  function getIntervals(scopeModel) {
    return $http({
      url: serverAddress.getBaseUrl() + 'claim/pending-validation/counts/' + scopeModel.domain,
      method: 'POST',
      data: {
        intervals: JSON.stringify(scopeModel.intervals),
        type_rows: JSON.stringify(scopeModel.type_rows),
        urgent_rows: JSON.stringify(scopeModel.urgent_rows),
        lawyer_name: scopeModel.assignedLawyer
      }
    })
      .then(response => {
        scopeModel.result = JSON.parse(response.data.result);
        scopeModel.urgent = JSON.parse(response.data.urgent);
        scopeModel.totals = JSON.parse(response.data.totals);
      })
      .catch(() => {
        notifications.addCurrentView('error', 'Error al obtener el informe.');
      });
  }

  function last12Months(scopeModel) {
    var theMonths = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ];
    var today = new Date();
    var aMonth = today.getMonth();
    var i;

    scopeModel.select_array = [];
    scopeModel.sales_goal_period = '00-' + (aMonth + 1);

    for (i = 0; i < 12; i++) {
      var pref = (i.toString().length === 1 ? '0' : '') + i + '-';

      if (aMonth > today.getMonth()) {
        scopeModel.select_array.push([pref + (aMonth + 1), theMonths[aMonth] + ' ' + (today.getFullYear() - 1)]);
      } else {
        scopeModel.select_array.push([pref + (aMonth + 1), theMonths[aMonth] + ' ' + today.getFullYear()]);
      }

      if (aMonth === 0) {
        aMonth = 11;
      } else {
        aMonth--;
      }
    }
  }
}
