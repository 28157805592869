serverAddress.$inject = [];
export default function serverAddress() {
  const BASE_URL = process.env.BACK_URL;
  const FORMS_URL = process.env.FORMS_URL;
  const IMPERSONATE_URL = process.env.IMPERSONATE_URL;
  const BI_URL = process.env.BI_URL;

  return {
    getBaseUrl,
    FORMS_URL,
    BI_URL,
    getDocumentUrl,
    getImpersonateUrl
  };

  function getBaseUrl() {
    return BASE_URL;
  }

  function getDocumentUrl() {
    return BASE_URL;
  }

  function getImpersonateUrl() {
    return IMPERSONATE_URL;
  }
}
