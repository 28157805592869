export default function shortcutKeys() {
  return {
    restrict: 'A',
    link: function($scope, $element, $attrs) {
      $element.bind('keypress', function(event) {
        const keyCode = event.which || event.keyCode;
        if (keyCode == $attrs.code) {
          $scope.$apply(function() {
            $scope.$eval($attrs.shortcutKeys, { $event: event });
          });
        }
      });
    }
  };
}
