import angular from 'angular';
import DialogFactory from './dialog.service';
import DialogFactoryV2 from './dialog.factory';
import DialogConfirmCtrl from './dialog.controller';

angular
  .module('reclamador.components.dialog', ['ui.bootstrap.dialog'])
  .factory('DialogFactory', DialogFactory)
  .factory('DialogFactoryV2', DialogFactoryV2)
  .controller('DialogConfirmCtrl', DialogConfirmCtrl);
