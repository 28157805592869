UserPanelRoutes.$inject = ['$routeProvider'];

export default function UserPanelRoutes($routeProvider) {
  $routeProvider.when('/user/claims-panel', {
    title: 'Panel de reclamaciones de usuario',
    templateUrl: 'js/modules/userpanel/index.html',
    layoutSetting: {
      contentTitle: 'Substituted in controller',
      iconClass: 'fas fa-list-alt',
      cardHead: null
    },
    controller: 'UserPanelCtrl',
    controllerAs: 'vm',
    layoutClass: 'fixed-blocks-layout'
  });
}
