CommonStateChangesCtrl.$inject = ['$scope'];

export default function CommonStateChangesCtrl ($scope) {
  const vm = this;

  $scope.$watch('vm.entity', function(e) {
    if(!e) return;
    vm.activeState = e.state ? e.state : e.claims_state;
  }, true);

  $scope.vm.getStateById = function(stateId) {
    if (!vm.states) return null;
    return vm.states.find(e => e.id == stateId);
  };

  $scope.vm.setState = function(stateId) {
    if(vm.entityType == 'claim') {
      $scope.$emit('claim:setNewState', stateId);
    } else {
      // TODO: prepare stateChange for legalfile as event
      if(vm.entity.state) {
        vm.entity.state.name = vm.getStateById(stateId).name;
      } else {
        vm.entity.claims_state = vm.getStateById(stateId).id;
      }

      vm.changeStatus();
    }
  };
}
