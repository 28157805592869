export default function expressorpresumed() {
  return function(input) {
    if (input === true || input === 1) {
      return 'Expresa';
    } else if (input === false || input === 0) {
      return 'Presunta';
    }
    return 'No establecido';
  };
}
