LegalFileFactory.$inject = ['$http', 'serverAddress'];

export default function LegalFileFactory($http, serverAddress) {
  return {
    // common claim detail methods
    saveLegalFile,
    generateLegalFile,
    deleteLegalFile,

    // Legalfile detail page specific methods
    sendLegalFileDocument,
    convertLegalFileDocumentToPDF,
    generateLegalFileAppendix,
    sendLegalFileDocumentsToLawyer,
    sendLegalFileDocumentsToLegalExpert,
    addClaimToLegalFile,
    removeClaim,
    sentenceClaim,
    downloadSplittedLawsuitAppendix,
    addCostsDetailToLegalFile,
    deleteCostsDetailToLegalFile,
    getCostsDetailLegalFile
  };

  function generateLegalFile(segmentPrefix, claimId) {
    const availableSegments = ['AC', 'AE', 'BA', 'LA', 'NE', 'SO', 'TA', 'TE'];
    if(availableSegments.indexOf(segmentPrefix) == -1) throw new Error('Unknown segment for generateLegalFile');

    const URL = `${serverAddress.getBaseUrl()}common/claim/${segmentPrefix}/${claimId}/generate/legalfile/`;
    return $http.post(URL);
  }

  function deleteLegalFile(segmentPrefix, claimId) {
    const availableSegments = ['AE', 'LA', 'NE', 'TA'];
    if(availableSegments.indexOf(segmentPrefix) == -1) throw new Error('Unknown segment for deleteLegalFile');

    const URL = `${serverAddress.getBaseUrl()}common/claim/${segmentPrefix}/${claimId}/delete/legalfileinfo/`;
    return $http.post(URL, { claim_id: claimId });
  }

  function saveLegalFile(segmentPrefix, claimId, data) {
    const segmentPath = {
      AC: 'accident-claim/accidents/',
      // AE: '',
      BA: 'bank-claim/banking/'
      // EX: '',
      // LA: '',
      // NE: '',
      // TE: '',
      // SO: '',
      // TA: ''
    };
    if(!segmentPath[segmentPrefix]) throw new Error('Unknown segment for saveLegalFile');

    const URL = `${serverAddress.getBaseUrl()}${segmentPath[segmentPrefix]}${claimId}/update/legalfileinfo/`;
    return $http.post(URL, { legalfile: data });
  }

  function sendLegalFileDocument(document, legalfile) {
    return $http({
      url: serverAddress.getBaseUrl() + 'legalfile/' + document.type.id + '/send/',
      data: legalfile.id,
      method: 'POST'
    });
  }

  function convertLegalFileDocumentToPDF(document, legalfile) {
    return $http({
      url: serverAddress.getBaseUrl() + 'legalfile/' + document.type.id + '/pdf/',
      data: legalfile.id,
      method: 'POST'
    });
  }

  function generateLegalFileAppendix(legalfile) {
    if (legalfile.prefix === 'TA') {
      return $http({
        url: serverAddress.getBaseUrl() + 'legalfile/taxes/generate-lawsuit-appendix',
        method: 'POST',
        data: {
          legalfile_id: legalfile.id
        }
      });
    }

    return $http({
      url: serverAddress.getBaseUrl() + 'legalfile/generate-appendix?file_id=' + legalfile.id,
      method: 'GET'
    });
  }

  function sendLegalFileDocumentsToLawyer(legalfile) {
    return $http({
      url: serverAddress.getBaseUrl() + 'legalfile/send-docs-to-lawyer/',
      method: 'POST',
      data: {
        legalfile_id: legalfile.id
      }
    });
  }

  function sendLegalFileDocumentsToLegalExpert(legalfile) {
    return $http({
      url: serverAddress.getBaseUrl() + 'legalfile/send-docs-to-legal-expert/',
      method: 'POST',
      data: {
        legalfile_id: legalfile.id
      }
    });
  }

  function addClaimToLegalFile(legalfileId, claimId) {
    return $http({
      url: serverAddress.getBaseUrl() + 'legalfile/add/claimid/',
      method: 'POST',
      data: {
        legalfile_id: legalfileId,
        claim_id: claimId
      }
    });
  }

  function addCostsDetailToLegalFile(legalfileId, costsDetails) {
    return $http({
      url: serverAddress.getBaseUrl() + 'legalfile/add/costsdetails/',
      method: 'POST',
      data: {
        legalfile_id: legalfileId,
        costs_details: costsDetails
      }
    });
  }

  function deleteCostsDetailToLegalFile(legalfileId) {
    return $http({
      url: serverAddress.getBaseUrl() + 'legalfile/remove/costsdetails/',
      method: 'POST',
      data: {
        legalfile_id: legalfileId
      }
    });
  }

  function getCostsDetailLegalFile(legalfileId) {
    return $http({
      url: serverAddress.getBaseUrl() + 'legalfile/get/costsdetails/' + legalfileId,
      method: 'GET'
    });
  }

  function removeClaim(legalfile, claimId) {
    return $http({
      url: serverAddress.getBaseUrl() + 'common/claim/' + legalfile.prefix + '/' + claimId + '/delete/legalfileinfo/',
      method: 'POST',
      data: {
        claim_id: claimId
      }
    });
  }

  function sentenceClaim(claimId, sentence) {
    return $http({
      url: serverAddress.getBaseUrl() + 'claim/' + claimId + '/update/sentenceState/',
      method: 'POST',
      data: {
        claim_id: claimId,
        sentence: sentence
      }
    });
  }

  function downloadSplittedLawsuitAppendix(legalFileId) {
    return $http({
      url: serverAddress.getBaseUrl() + 'legalfile/download-splitted-appendix?legalfile_id=' + legalFileId,
      method: 'GET'
    });
  }
}
