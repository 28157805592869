/* eslint-disable angular/di */
import angular from 'angular';

import LegalServicesDetailCtrl from './LegalServicesDetailCtrl';
import LegalServicesListCtrl from './LegalServicesListCtrl';

import {
  LegalService,
  SGLawyer,
  SGSalesman,
  MicroserviceType,
  MicroserviceHour,
  LegalServiceNotes,
  BudgetCalculator,
  BudgetReport,
  VCType

} from './services';

import LegalServicesConstants from './constants';

import LegalServicesRoutes from './routes';

angular
  .module('reclamador.segments.legalservices', [])
  .controller('LegalServicesDetailCtrl', LegalServicesDetailCtrl)
  .controller('LegalServicesListCtrl', LegalServicesListCtrl)

  .component('legalServiceDetail', {
    templateUrl: 'partials/legalservices/claim.html',
    controller: LegalServicesDetailCtrl
  })
  .component('legalServiceList', {
    templateUrl: 'partials/common/baseList/claim-list.html',
    controller: LegalServicesListCtrl
  })

  .factory('LegalService', LegalService)
  .factory('SGLawyer', SGLawyer)
  .factory('SGSalesman', SGSalesman)
  .factory('MicroserviceType', MicroserviceType)
  .factory('MicroserviceHour', MicroserviceHour)
  .factory('LegalServiceNotes', LegalServiceNotes)
  .factory('BudgetCalculator', BudgetCalculator)
  .factory('BudgetReport', BudgetReport)
  .factory('VCType', VCType)

  .constant('LegalServicesConstants', LegalServicesConstants)

  .config(LegalServicesRoutes);
