FlightFactory.$inject = ['$http', 'serverAddress'];

export default function FlightFactory($http, serverAddress) {
  return {
    getDepartureAirportFlights,
    getArrivalAirportFlights,
    getFlightStops,
    getFlightDetails,
    getAirportsFlightStatistics,
    updateFlightWeather,
    getFlightStatsData,
    updateFlightDocument
  };

  /* Implementation */
  function getDepartureAirportFlights(flightId) {
    return $http({
      url: serverAddress.getBaseUrl() + 'airdata/get-same-date-and-departure-airport-flights/' + flightId + '/',
      method: 'GET'
    });
  }

  function getArrivalAirportFlights(flightId) {
    return $http({
      url: serverAddress.getBaseUrl() + 'airdata/get-same-date-and-arrival-airport-flights/' + flightId + '/',
      method: 'GET'
    });
  }

  function getFlightStops(claimId) {
    return $http({
      url: serverAddress.getBaseUrl() + 'airdata/get-flight-stops/' + claimId + '/',
      method: 'GET'
    });
  }

  function getFlightDetails(flightId) {
    return $http({
      url: serverAddress.getBaseUrl() + 'airdata/flight/' + flightId + '/details/',
      method: 'GET'
    });
  }

  function getAirportsFlightStatistics(flightId) {
    return $http({
      url: serverAddress.getBaseUrl() + 'airdata/statistics/' + flightId + '/',
      method: 'GET'
    });
  }

  function updateFlightWeather(flightId) {
    return $http({
      url: serverAddress.getBaseUrl() + 'airdata/update-flight-weather/',
      method: 'POST',
      data: {
        flightId: flightId
      }
    });
  }

  function getFlightStatsData(flightId) {
    return $http({
      url: serverAddress.getBaseUrl() + 'airdata/flightstats-scraped-data/' + flightId + '/',
      method: 'GET'
    });
  }

  function updateFlightDocument(flightId, screenshot_url) {
    return $http({
      url: serverAddress.getBaseUrl() + 'airdata/update-flight-document/',
      method: 'POST',
      data: {
        flightId: flightId,
        url: screenshot_url
      }
    });
  }
}
