TaxesClaimListCtrl.$inject = ['$scope', '$controller', 'TaxesConstants'];

export default function TaxesClaimListCtrl($scope, $controller, TaxesConstants) {
  const vm = this;
  const claimDetailTabs = {};
  TaxesConstants.tabConfig.forEach(element => {
    claimDetailTabs[element.indexKey] = element.heading;
  });
  const INITIAL_STATE = {
    claimDetailTabs,
    ALIAS: TaxesConstants.ALIAS + '-claims',

    itemsPath: 'taxes-claim/list/',
    listPath: 'taxes-claims/list/filter/',
    listPathNoFIlter: 'taxes-claims',
    defaultColumnDef: TaxesConstants.defaultColumnDef,
    filterFieldInfo: TaxesConstants.filterFieldInfo
  };
  $controller('BaseListCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE);

  /** Initiate List! */
  vm.initBaseList(
    TaxesConstants.SEGMENT_ID,
    TaxesConstants.ALIAS,
    TaxesConstants.permissions
  );
}
