LaboralRoutes.$inject = ['$routeProvider', 'LaboralConstants'];

export default function LaboralRoutes($routeProvider, LaboralConstants) {

  $routeProvider.when('/laboral/salespeople/counts', {
    title: 'Panel Comerciales Laboral',
    templateUrl: 'partials/common/panels/sales.html',
    data: {
      permissions: {
        only: 'segment-perobject-LA-crm_view_salespeople_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Laboral',
      iconClass: 'fas fa-users',
      cardHead: 'Panel Comerciales Laboral'
    },
    controller: 'CommonSalesDashboardCtrl',
    controllerAs: 'vm',
    claimListBaseURL: '#/laboral-claims/list/',
    SalesmanService: 'LaboralSalesman',
    salesPeopleCountsURL: 'laboral-claim/salespeople/counts/',
    segmentName: 'Laboral',
    segmentID: LaboralConstants.SEGMENT_ID
  });

  $routeProvider.when('/laboral/lawyers/counts', {
    title: 'Panel Abogados Laboral',
    templateUrl: 'partials/common/panels/lawyers.html',
    data: {
      permissions: {
        only: 'segment-perobject-LA-crm_view_lawyers_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Laboral',
      iconClass: 'fas fa-users',
      cardHead: 'Panel Abogados Laboral'
    },
    controller: 'LaboralLawyerPanelCtrl',
    controllerAs: 'vm',
    segmentID: LaboralConstants.SEGMENT_ID
  });

  const permissions = {
    only: 'segment-perobject-LA-crm_view_claims_list',
    redirectTo: 'no-permissions'
  };

  const claimListConfig = {
    title: 'Listado de reclamaciones de Laboral',
    templateUrl: 'partials/common/baseList/claim-list.html',
    data: { permissions },
    layoutSetting: {
      contentTitle: 'Laboral',
      iconClass: 'fas fa-users'
    },
    baseListSettings: {
      typeList: 'laboral',
      typeTitle: 'laboral',
      templateListTable: 'js/modules/segments/laboral/partials/claim-list-table.html',
      templateListFilter: 'partials/common/baseList/common-claim-list-filter.html',
      title: 'Reclamaciones Laboral',
      listResultsText: 'reclamaciones de laboral'
    },
    controller: 'LaboralClaimListCtrl',
    controllerAs: 'vm',
    segmentID: LaboralConstants.SEGMENT_ID
  };

  $routeProvider.when('/laboral-claims', claimListConfig);
  $routeProvider.when('/laboral-claims/list/filter/:filter', claimListConfig);

  $routeProvider.when('/laboral-claims/:id', {
    title: 'Detalle de reclamaciones Laboral',
    templateUrl: 'js/modules/segments/laboral/partials/detail.html',
    data: {
      permissions: {
        only: 'segment-perobject-LA-crm_view_claim_detail',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Laboral',
      iconClass: 'fas fa-users'
    },
    controller: 'LaboralClaimDetailCtrl',
    controllerAs: 'vm',
    segmentID: LaboralConstants.SEGMENT_ID,
    reloadOnSearch: false
  });

}
