import angular from 'angular';

DocumentBuilderCtrl.$inject = [
  'currentDocuments',
  'documentTypes',
  'claimData',
  'multipleSourceSelectionEnabled',
  'dialog',
  'DocumentTransformationFactory',
  'DocTemplateGroup',
  'notifications'
];

export default function DocumentBuilderCtrl(
  currentDocuments,
  documentTypes,
  claimData,
  multipleSourceSelectionEnabled,
  dialog,
  DocumentTransformationFactory,
  DocTemplateGroup,
  notifications
) {
  var vm = this;

  /* Scope functions */
  vm.closeModal = closeModal;
  vm.buildDocument = buildDocument;
  vm.rotateCounterClockwise = rotateCounterClockwise;
  vm.rotateClockwise = rotateClockwise;
  vm.setupNewDocumentFromTemplate = setupNewDocumentFromTemplate;
  vm.setupNewDocumentFromType = setupNewDocumentFromType;
  vm.toggleOverlay = toggleOverlay;
  vm.resetPanels = resetPanels;

  /* Scope data */
  vm.documentTypes = [];
  vm.allDocuments = [];
  vm.currentDocuments = [];
  vm.targetDocuments = [];
  vm.templateGroups = [];
  vm.multipleSourceSelectionEnabled = multipleSourceSelectionEnabled;

  vm.validFileFormats = ['pdf', 'jpg', 'jpeg', 'png', 'gif'];
  vm.selectedDocument = null;
  vm.selectedDocumentType = null;
  vm.selectedTemplate = null;
  vm.newDocumentName = null;
  vm.displayOverlay = true;
  vm.canDragItems = null;

  /* Init */
  init();

  /* Functions */
  function init() {
    var claimTypeId = claimData.claim_type ? { claim_types__id__in: claimData.claim_type } : {};
    DocTemplateGroup.get(claimTypeId).$promise.then(function(data) {
      vm.templateGroups = data.objects;
    });
    vm.documentTypes = angular.copy(documentTypes);
    resetPanels();
  }

  function _getValidatedDocuments(sourceDocuments = []) {
    return sourceDocuments.filter(function(doc) {
      return doc.is_validated;
    });
  }

  function _getDocumentsWithValidFormat(sourceDocuments) {
    return sourceDocuments.filter(function(doc) {
      var extension =
        doc.filename
          .split('.')
          .pop()
          .toLowerCase() || '';
      return vm.validFileFormats.includes(extension);
    });
  }

  function _getSortedDocumentTypesFromTemplate(templateDocumentItems = []) {
    return templateDocumentItems.sortBy('position');
  }

  function _matchDocumentsToTemplate(sourceDocuments = [], templateDocItems) {
    var matches = [];
    templateDocItems.forEach(function(templateDocItem) {
      var matchesGroup = sourceDocuments.filter(function(sourceDoc) {
        return sourceDoc.type.id == templateDocItem.document_type.id;
      });
      matches = matches.concat(matchesGroup);
    });

    return matches;
  }

  function _removeSelectedDocuments(documentsToRemove = [], sourceDocuments = []) {
    var documentsToRemoveIds = documentsToRemove.map(function(docToRemove) {
      return docToRemove.id;
    });

    var remainingDocuments = sourceDocuments.filter(function(sourceDoc) {
      return !documentsToRemoveIds.includes(sourceDoc.id);
    });

    return remainingDocuments;
  }

  function _formatDocumentName(name) {
    var hasPdfExtension = name.split('.').pop() === 'pdf';
    if (hasPdfExtension) {
      return name;
    }

    return name + '.pdf';
  }

  function setupNewDocumentFromType(selectedDocumentType) {
    if (!selectedDocumentType) {
      return;
    }

    vm.targetType = 'doctype';

    toggleOverlay();

    // User selected a type for the new document
    vm.currentDocuments = angular.copy(vm.allDocuments);
    vm.targetDocuments = [];
    vm.newDocumentType = selectedDocumentType;
  }

  function setupNewDocumentFromTemplate(template) {
    if (!template) {
      return;
    }

    toggleOverlay();

    // User selected a template group for the new document
    var templateDocItems = template.doctemplateitems;

    // Fetch document types associated to the template group
    var templateDocumentTypes = _getSortedDocumentTypesFromTemplate(templateDocItems);

    // Filter source documents based on validation status: only validated documents should appear
    var validatedDocuments = _getValidatedDocuments(vm.allDocuments);
    vm.currentDocuments = validatedDocuments;

    // Preload documents whose type matches the ones of the template group
    var matchingDocuments = _matchDocumentsToTemplate(validatedDocuments, templateDocumentTypes);
    vm.targetDocuments = matchingDocuments;
    vm.currentDocuments = _removeSelectedDocuments(matchingDocuments, validatedDocuments);

    // Set new document type
    vm.newDocumentType = template.target_doc_type;
  }

  function _validateParams() {
    // Reset error messages list
    var hasErrors = false;

    // Must have specified a name for the new document
    if (!vm.newDocumentName || vm.newDocumentName === '') {
      notifications.addCurrentView('error', 'Por favor, indica un nombre para tu nuevo documento');
      hasErrors = true;
    }

    // Must have at least one document added to right panel
    if (vm.targetDocuments.length === 0) {
      notifications.addCurrentView('error', 'Por favor, añade por lo menos un documento para generar el nuevo.');
      hasErrors = true;
    }

    // Must have specified the new document type
    if (!vm.newDocumentType) {
      notifications.addCurrentView('error', 'Por favor, selecciona el tipo del nuevo documento');
      hasErrors = true;
    }

    return !hasErrors;
  }

  function buildDocument() {
    if (!_validateParams()) return;

    var newDocumentItems = [];
    for (var i = 0; i < vm.targetDocuments.length; i++) {
      var documentItem = vm.targetDocuments[i];
      newDocumentItems.push({
        id: documentItem.id,
        rotation: documentItem.rotation || 0
      });
    }

    var documentNameWithExtension = _formatDocumentName(vm.newDocumentName);
    var newDocumentData = {
      doc_name: documentNameWithExtension,
      doc_type_id: vm.newDocumentType.id,
      doc_comment: `Documento generado de tipo \'${vm.newDocumentType.name}\'`,
      doc_items: newDocumentItems
    };

    DocumentTransformationFactory.mergeDocuments(claimData, newDocumentData).then(function() {
      dismiss();
    });
  }

  function rotateCounterClockwise(docItem) {
    var currentRotation = docItem.rotation || 0;
    var newRotation = currentRotation + 90;
    docItem.rotation = newRotation;
  }

  function rotateClockwise(docItem) {
    var currentRotation = docItem.rotation || 0;
    var newRotation = currentRotation - 90;
    docItem.rotation = newRotation;
  }

  function closeModal() {
    dialog.close(false); // Do not run callback
  }

  function dismiss() {
    dialog.close(true); // Run callback
  }

  function toggleOverlay() {
    if (
      (vm.selectedTemplate && vm.targetType === 'template') ||
      (vm.selectedDocumentType && vm.targetType === 'doctype')
    ) {
      vm.canDragItems = true;

      vm.displayOverlay = false;
    } else {
      notifications.addCurrentView(
        'error',
        'Por favor, indica qué tipo de nuevo documento quieres crear antes de empezar.'
      );
      vm.canDragItems = false;
    }
  }

  function resetPanels() {
    vm.currentDocuments = angular.copy(currentDocuments);
    vm.currentDocuments = _getDocumentsWithValidFormat(vm.currentDocuments);
    vm.allDocuments = angular.copy(currentDocuments);
    vm.allDocuments = _getDocumentsWithValidFormat(vm.allDocuments);
    vm.targetDocuments = [];

    if (vm.selectedTemplate && vm.targetType === 'template') {
      setupNewDocumentFromTemplate(vm.selectedTemplate);
    }

    if (vm.selectedDocumentType && vm.targetType === 'doctype') {
      setupNewDocumentFromType(vm.selectedDocumentType);
    }
  }
}
