GenericRoutes.$inject = ['$routeProvider'];

export default function GenericRoutes($routeProvider) {
  const permissions = {
    only: 'segment-global-crm_view_generic_claims_section',
    redirectTo: 'no-permissions'
  };

  const claimListConfig = {
    title: 'Reclamaciones Genéricas',
    templateUrl: 'partials/common/baseList/claim-list.html',
    data: { permissions },
    layoutSetting: {
      contentTitle: 'Otras reclamaciones',
      iconClass: 'fas fa-comment-alt'
    },
    baseListSettings: {
      typeList: 'claims',
      typeTitle: 'reclamaciones',
      templateListTable: 'js/modules/segments/generic/partials/claim-list-table.html',
      templateListFilter: '',
      title: 'Reclamaciones genéricas',
      listResultsText: 'reclamaciones'
    },
    controller: 'GenericClaimListCtrl',
    controllerAs: 'vm'
  };

  $routeProvider.when('/generic-claims', claimListConfig);
  $routeProvider.when('/generic-claims/list/filter/:filter', claimListConfig);

  $routeProvider.when('/generic-claims/upload', {
    title: 'Carga de otras reclamaciones',
    templateUrl: 'js/modules/segments/generic/partials/upload.html',
    data: { permissions },
    layoutSetting: {
      contentTitle: 'Otras reclamaciones',
      iconClass: 'fas fa-comment-alt'
    },
    controller: 'GenericClaimUploadCtrl',
    controllerAs: 'vm'
  });

  $routeProvider.when('/generic-claims/:id', {
    title: 'Detalle otras reclamaciones',
    templateUrl: 'js/modules/segments/generic/partials/detail.html',
    // data: { permissions },
    layoutSetting: {
      contentTitle: 'Otras reclamaciones',
      iconClass: 'fas fa-comment-alt'
    },
    controller: 'GenericClaimDetailCtrl',
    controllerAs: 'vm',
    reloadOnSearch: false
  });
}
