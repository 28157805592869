/* Returns true if the object don't have any property and false otherwise */
export default function emptyObject() {
  return function(object) {
    for (var property in object) {
      if (object.hasOwnProperty(property)) {
        return false;
      }
    }
    return true;
  };
}
