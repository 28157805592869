import angular from 'angular';

import ClaimsChangeSourceCtrl from './claims-change-source.controller';
import ConfirmAmountChangeCtrl from './confirm-amount-change.controller';
import ConfirmChangeFlightSentencesCtrl from './confirm-change-flight-sentences.controller';
import ConfirmClaimDocumentDeleteCtrl from './confirm-claim-document-delete.controller';
import ConfirmDocumentDeleteCtrl from './confirm-document-delete.controller';
import ConfirmFlightStopDeleteCtrl from './confirm-flight-stop-delete.controller';
import ConfirmLawyerChangeCtrl from './confirm-lawyer-change.controller';
import ConfirmStateChangeCtrl from './confirm-state-change.controller';
import SelectDocumentsToSliceCtrl from './select-documents-to-slice.controller';
import DocumentBuilderCtrl from './document-builder.controller';
import CopyDocumentsCtrl from './copy-documents.controller';
import GenerateNewCaseCtrl from './generate-new-case.controller';

angular
  .module('reclamador.modalControllers', ['ngCookies'])
  .controller('ClaimsChangeSourceCtrl', ClaimsChangeSourceCtrl)
  .controller('ConfirmAmountChangeCtrl', ConfirmAmountChangeCtrl)
  .controller('ConfirmChangeFlightSentencesCtrl', ConfirmChangeFlightSentencesCtrl)
  .controller('ConfirmClaimDocumentDeleteCtrl', ConfirmClaimDocumentDeleteCtrl)
  .controller('ConfirmDocumentDeleteCtrl', ConfirmDocumentDeleteCtrl)
  .controller('ConfirmLawyerChangeCtrl', ConfirmLawyerChangeCtrl)
  .controller('ConfirmStateChangeCtrl', ConfirmStateChangeCtrl)
  .controller('SelectDocumentsToSliceCtrl', SelectDocumentsToSliceCtrl)
  .controller('ConfirmFlightStopDeleteCtrl', ConfirmFlightStopDeleteCtrl)
  .controller('DocumentBuilderCtrl', DocumentBuilderCtrl)
  .controller('CopyDocumentsCtrl', CopyDocumentsCtrl)
  .controller('GenerateNewCaseCtrl', GenerateNewCaseCtrl);
