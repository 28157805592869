export default function fileread(){
  return {
    scope: {
      fileread: '='
    },
    link: function (scope, element) {
      element.bind('change', function (changeEvent) {
        var reader = new FileReader();
        reader.onload = function (loadEvent) {
          const content = loadEvent.currentTarget.result.split(',')[1];
          const bytes = Uint8Array.from(atob(content), c => c.charCodeAt(0));
          scope.$apply(function () {
            scope.fileread = Array.from(bytes);
          });
        };
        reader.readAsDataURL(changeEvent.target.files[0]);
      });
    }
  };
};
