import angular from 'angular';

import './styles.css';

angular
  .module('reclamador.components.phaseBar', [])
  .controller('phaseBarCtrl', phaseBarCtrl)
  .component('phaseBarComponent', {
    bindings:{
      phases: '<'
    },
    controllerAs: 'vm',
    controller: phaseBarCtrl,
    template: `
      <ul class="d-flex flex-grow list-unstyled mb-0">
        <li ng-repeat="phase in vm.phases" class="text-center phase-arrow{{ phase.active ? \' active\' : \'\'}}">
          {{ phase.name }}
        </li>
      </ul>
      <ul class="d-flex flex-grow list-unstyled mb-0">
        <li ng-repeat="phase in vm.phases" class="text-center phase-date{{ phase.active ? \' active\' : \'\'}}">
          <small ng-if="phase.seconds_spent && phase.name != 'Finalizada'">
            <span ng-if="$first">Tiempo transcurrido:</span>
            {{ phase.seconds_spent | secondsToDate }}
          </small>
          <small ng-if="phase.seconds_spent && phase.name == 'Finalizada' && $last">
            <span>Tiempo total:</span> {{ vm.totalTime | secondsToDate }}
          </small>
        </li>
      </ul>`
  });

phaseBarCtrl.$inject = ['$scope'];

function phaseBarCtrl($scope) {
  const vm = this;

  $scope.$watch('vm.phases', phases => {
    if(!phases || !phases.length || phases[phases.length - 1].name !== 'Finalizada') {
      vm.totalTime = 0;
      return;
    }
    vm.totalTime = phases.map(e => e.seconds_spent).reduce((sum, elm) => elm + sum, 0) - phases[phases.length - 1].seconds_spent;
  }, true);
}