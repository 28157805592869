BoughtPortfolio.$inject = ['$resource', 'serverAddress'];

var BOUGHTPORTFOLIO_PATH = 'api2/v1/boughtportfolio/';

export default function BoughtPortfolio($resource, serverAddress) {
  return $resource(serverAddress.getBaseUrl() + BOUGHTPORTFOLIO_PATH, {
    get: {
      isArray: true
    }
  });
}
