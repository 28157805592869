/* eslint-disable angular/di */
import angular from 'angular';

import costsPanelTmpl from './costs-panel.html';

import CostsPanelCtrl from './CostsPanelCtrl';
import ProcedureListCtrl from './ProcedureListCtrl';

angular
  .module('reclamador.costs', [])
  .controller('CostsPanelCtrl', CostsPanelCtrl)
  .controller('ProcedureListCtrl', ProcedureListCtrl)
  .component('costsPanelComponent', {
    template: costsPanelTmpl,
    controller: CostsPanelCtrl,
    controllerAs: 'vm'
  })
  .config([
    '$routeProvider',
    function($routeProvider) {
      /* COST PANEL */
      $routeProvider.when('/costs', {
        title: 'Panel Costas',
        template: '<costs-panel-component></costs-panel-component>',
        data: {
          permissions: {
            only: 'segment-global-crm_view_costs_dashboard',
            redirectTo: 'no-permissions'
          }
        },
        layoutSetting: {
          contentTitle: null,
          iconClass: 'fas fa-briefcase',
          cardHead: 'Panel Costas'
        },
        controller: 'BaseCtrl',
        controllerAs: 'vm',
        reloadOnSearch: false
      });

      $routeProvider.when('/:segmentPrefix/procedures', {
        title: 'Panel procedimientos filtrados',
        template:
          '<filter-list-component filter="vm.filter" settings="vm.settings"></filter-list-component>',
        data: {
          permissions: {
            only: 'segment-global-crm_view_costs_dashboard',
            redirectTo: 'no-permissions'
          }
        },
        layoutSetting: {
          iconClass: 'fas fa-briefcase'
        },
        baseListSettings: {
          title: 'Procedimientos de costas',
          listResultsText: 'Procedimientos de costas'
        },
        controller: 'ProcedureListCtrl',
        controllerAs: 'vm',
        reloadOnSearch: false
      });
    }
  ]);
