BankRoutes.$inject = ['$routeProvider', 'BankConstants'];

export default function BankRoutes($routeProvider, BankConstants) {
  $routeProvider.when('/bank/salespeople/counts', {
    title: 'Panel Comerciales Banca',
    templateUrl: 'partials/common/panels/sales.html',
    data: {
      permissions: {
        only: 'segment-perobject-BA-crm_view_salespeople_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Banca',
      iconClass: 'fas fa-briefcase',
      cardHead: 'Panel Comerciales Banca'
    },
    controller: 'CommonSalesDashboardCtrl',
    controllerAs: 'vm',
    claimListBaseURL: '#/bank-claims/list/',
    SalesmanService: 'BankSalesman',
    salesPeopleCountsURL: 'bank-claim/salespeople/counts/',
    segmentName: 'Banca',
    segmentID: BankConstants.SEGMENT_ID
  });

  $routeProvider.when('/bank/lawyers/counts', {
    title: 'Panel Abogados Banca',
    templateUrl: 'partials/common/panels/lawyers.html',
    data: {
      permissions: {
        only: 'segment-perobject-BA-crm_view_lawyers_dashboard',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Banca',
      iconClass: 'fas fa-briefcase',
      cardHead: 'Panel Abogados Banca'
    },
    controller: 'BankLawyerPanelCtrl',
    controllerAs: 'vm',
    segmentID: BankConstants.SEGMENT_ID
  });

  const permissions = {
    only: 'segment-perobject-BA-crm_view_claims_list',
    redirectTo: 'no-permissions'
  };

  const cliamListConfig = {
    title: 'Listado reclamaciones de Banca',
    templateUrl: 'partials/common/baseList/claim-list.html',
    data: { permissions },
    layoutSetting: {
      contentTitle: 'Banca',
      iconClass: 'fas fa-briefcase'
    },
    baseListSettings: {
      typeList: 'bank',
      typeTitle: 'banca',
      templateListTable: 'js/modules/segments/bank/partials/claim-list-table.html',
      templateListFilter: 'js/modules/segments/bank/partials/claim-list-filters.html',
      title: 'Reclamaciones Banca',
      listResultsText: 'reclamaciones bancarias'
    },
    controller: 'BankClaimListCtrl',
    controllerAs: 'vm',
    segmentID: BankConstants.SEGMENT_ID
  };

  $routeProvider.when('/bank-claims', cliamListConfig);
  $routeProvider.when('/bank-claims/list/filter/:filter', cliamListConfig);

  $routeProvider.when('/bank-claims/:id', {
    title: 'Detalle reclamación de Banca',
    templateUrl: 'js/modules/segments/bank/partials/detail.html',
    data: {
      permissions: {
        only: 'segment-perobject-BA-crm_view_claim_detail',
        redirectTo: 'no-permissions'
      }
    },
    layoutSetting: {
      contentTitle: 'Banca',
      iconClass: 'fas fa-briefcase'
    },
    controller: 'BankClaimDetailCtrl',
    controllerAs: 'vm',
    segmentID: BankConstants.SEGMENT_ID,
    reloadOnSearch: false
  });
}
