PicklistCtrl.$inject = ['$scope', '$http', 'serverAddress'];
export default function PicklistCtrl($scope, $http, serverAddress) {
  $scope.model = {};
  $scope.firstLoad = true;
  $scope.firstLoadDict = true;
  $scope.loadDependentPicklist = function () {
    loadDependentData();
  };

  $scope.$watch('model', val => {
    if (val && val.id && $scope.firstLoad) {
      $scope.firstLoad = false; 
      loadDependentData();
    }
  });

  function loadDependentData() {
    if ($scope.dependentfield) {
      $scope.dependentfield.values = [];
      if (!$scope.model) {
        return;
      }

      $http
        .get(serverAddress.getBaseUrl() + $scope.dependentfield.url + '/' + $scope.model.id + '/')
        .then(values => {
          if (values.hasOwnProperty('data')) {
            $scope.dependentfield.values = values.data;
          }
        })
        .catch(e => console.error(e));
    }
  }
}
