DocTemplateGroup.$inject = ['$resource', 'serverAddress'];

var DOC_TEMPLATE_GROUP_PATH = 'api2/v1/doctemplategroup/?format=json&limit=:limit&offset=:offset&q=:filter&:query';

export default function DocTemplateGroup($resource, serverAddress) {
  return $resource(
    serverAddress.getBaseUrl() + DOC_TEMPLATE_GROUP_PATH,
    {
      offset: 0,
      limit: 0,
      filter: '',
      query: ''
    },
    {
      get: {
        method: 'GET',
        params: {},
        isArray: false
      }
    }
  );
}
