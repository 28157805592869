export default function tableSelect() {
  return {
    link: function(scope, element) {
      scope.selectVisible = false;
      element.find('span').bind('click', function() {
        scope.$apply(function() {
          scope.selectVisible = true;
        });
      });

      element.find('select').bind('change', function() {
        if (element.find('select').val() === '' || element.find('select').val() === undefined) {
          return;
        }
        scope.$apply(function() {
          scope.selectVisible = false;
          scope.value = element.find('select').val();
          scope.update({ claimId: scope.current, name: scope.value });
        });
      });
    },
    replace: true,
    scope: {
      value: '=',
      options: '=',
      update: '&',
      current: '=',
      field: '@'
    },
    restrict: 'A',
    template:
      '<div><span ng-show="!selectVisible" style="cursor: pointer;">{{value || "Ninguno"}} </span><select style="font-size: 11px; max-height: 30px; max-width: 150px" ng-show="selectVisible"><option value="">Selecciona</option><option ng-repeat="s in options.objects">{{s[field]}}</option></select></div>'
  };
}
