ItuxCtrl.$inject = ['$route', '$dialog'];

export default function ItuxCtrl($route, $dialog) {
  var vm = this;
  vm.i = '<i class=fas fa-external-link-alt>';
  vm.layoutSettings = $route.current.$$route.layoutSetting;

  // mini modal samples
  // BASIC minimodal
  vm.basicMiniModalShow = false;


  // NORMAL minimodal
  vm.miniModalShow = false;

  vm.miniModalOk = function() {
    console.log('OK');
    vm.miniModalShow = false;
  };

  vm.miniModalKo = function() {
    console.log('Ko');
    vm.miniModalShow = false;
  };


  // $dialog modal exaples
  var testDialogSettings = {
    template: `
      <div class="modal-header">
          <h1>Hello world</h1>
      </div>
      <div class="modal-body">
          <p>Some content here</p>
      </div>
      <div class="modal-body">
          <p class="text-center"><small>Reclamador @1992-2018</small></p>
      </div>
    `
  };

  vm.openDialogTest = function() {
    $dialog.dialog(testDialogSettings).open();
  };

  // simple modal SAMALL
  vm.openDialogTestSm = function() {
    $dialog.dialog(Object.assign({}, { dialogSize: 'small' }, testDialogSettings)).open();
  };

  // simple modal LARGE
  vm.openDialogTestLg = function() {
    $dialog.dialog(Object.assign({}, { dialogSize: 'large' }, testDialogSettings)).open();
  };

  vm.tabHeader = 'TEMPLATES';

  vm.dropDownElms = [
    {
      href: 'https://twitter.com/',
      text: 'Er tuiter'
    },
    {
      href: 'https://www.facebook.com/',
      text: 'Er feis'
    }
  ];

  vm.bazingaDialog = function() {
    $dialog
      .dialog({
        template:
          '<div class="modal-body"><h1>bazingaDialog</h1><img class="img-fluid" src="https://images.huffingtonpost.com/2015-08-21-1440138242-5865807-bazingasheldon-thumb.jpg" /></div>'
      })
      .open();
  };

  vm.awesomeIcons = [
    {
      name: 'Aceptar',
      html: '<i class="fas fa-check"></i>'
    },
    {
      name: 'Cancelar',
      html: '<i class="fas fa-ban"></i>'
    },
    {
      name: 'Reclamación',
      html: '<i class="fas fa-bullhorn"></i>'
    },
    {
      name: 'Prohibido',
      html: '<i class="fas fa-times-circle"></i>'
    },
    {
      name: 'Warning',
      html: '<i class="fas fa-exclamation-circle"></i>'
    },
    {
      name: 'Previsualizar',
      html: '<i class="fas fa-eye"></i>'
    },
    {
      name: 'Previsualización bloqueada',
      html: '<i class="far fa-eye-slash"></i>'
    },
    {
      name: 'Copy',
      html: '<i class="fas fa-copy"></i>'
    },
    {
      name: 'Save',
      html: '<i class="fas fa-save"></i>'
    },
    {
      name: 'Subir',
      html: '<i class="fas fa-cloud-upload-alt"></i>'
    },
    {
      name: 'Subir OLD',
      html: '<i class="fas fa-upload"></i>'
    },
    {
      name: 'Descargar',
      html: '<i class="fas fa-cloud-download-alt"></i>'
    },
    {
      name: 'Borrar',
      html: '<i class="far fa-trash-alt"></i>'
    },
    {
      name: 'Editar',
      html: '<i class="fas fa-edit"></i>'
    },
    {
      name: 'Más',
      html: '<i class="fas fa-plus"></i>'
    },
    {
      name: 'Menos',
      html: '<i class="fas fa-minus"></i>'
    },
    {
      name: 'Links externos',
      html: '<i class="fas fa-external-link-alt"></i>'
    },
    {
      name: 'bomba',
      html: '<i class="fas fa-bomb"></i>'
    },
    {
      name: 'y por supuesto',
      html: '<i class="far fa-money-bill-alt"></i>'
    }
  ];
}
