PermissionsService.$inject = ['$rootScope', 'PermPermissionStore'];
export default function PermissionsService($rootScope, PermPermissionStore) {
  // Avoid check permissions on invalid session
  $rootScope.$on('$routeChangePermissionStart', event => {
    if (!$rootScope.permissions) {
      event.preventDefault();
    }
  });

  return {
    getAll,
    setAll,
    hasPermission
  };

  function getAll() {
    const userPermissions = $rootScope.userPermissions;
    return userPermissions;
  }
  function setAll(permissions) {
    // Map complex structure from back to plain list of permissions
    const permissionsMap = permissions;

    const permissionsClient = [
      ...new Set(permissionsMap.client.global.map(el => `client-global-${el.codename}`))
    ];

    const permissionsSegmentGlobal = [
      ...new Set(permissionsMap.segment.global.map(el => `segment-global-${el.codename}`))
    ];

    const permissionsSegmentGlobalPerObject = [
      ...new Set(
        permissionsMap.segment.perObject.map(
          el => `segment-perobject-${el.objectId}-${el.codename}`
        )
      )
    ];

    const allPermissions = [
      ...permissionsClient,
      ...permissionsSegmentGlobal,
      ...permissionsSegmentGlobalPerObject
    ];
    $rootScope.permissions = allPermissions;

    PermPermissionStore.defineManyPermissions(
      allPermissions,
      /*@ngInject*/ function (permissionName) {
        return allPermissions.includes(permissionName);
      }
    );
    $rootScope.$broadcast('event:auth:permissions-ready');
  }

  function hasPermission(permission) {
    var permissions = $rootScope.permissions;
    for (var i = 0; i < permissions.length; i++) {
      if (permission === permissions[i]) {
        return true;
      }
    }
    return false;
  }
}
