PhoneClaimDetailCtrl.$inject = [
  '$scope',
  '$controller',
  'notifications',
  'serverAddress',
  'DocumentDownloadFactory',
  'DialogFactory',
  'fileUploaderGenerator',
  'PhoneConstants'
];
export default function PhoneClaimDetailCtrl(
  $scope,
  $controller,
  notifications,
  serverAddress,
  DocumentDownloadFactory,
  DialogFactory,
  fileUploaderGenerator,
  PhoneConstants
) {
  const vm = this;

  const INITIAL_STATE = {
    claim: {
      client: {
        user: {
          is_active: true
        }
      }
    },
    viewCancelledClientPermission: true
  };

  const METHODS = {
    postRefreshClaimCB,

    savePhoneClaim,
    downloadAllPhoneDocuments,
    showDocumentCopyModal
  };

  $controller('BaseDetailCtrl', { vm: vm, $scope: $scope });

  Object.assign(vm, INITIAL_STATE, METHODS, PhoneConstants);

  vm.claimDetail.init();

  // FROM HERE JUST FUNCTIONS => => => => => => => => => =>
  function postRefreshClaimCB() {
    vm.getDocumentTypes();
  }

  fileUploaderGenerator({
    vm: vm,
    url: serverAddress.getDocumentUrl() + 'phone-claim/upload/',
    fillItemData: function(item) {
      vm.busy = true;

      item.formData = [
        {
          type_id: item.documenType.id || 0,
          comment: item.comment || '',
          phone_claim_id: vm.claim_id
        }
      ];
    },
    sucessCallback: () => {
      vm.claimDetail.update('Archivo subido con éxito, actualizando reclamación');
    }
  });

  function savePhoneClaim() {
    vm.claim.compensation = vm.claim.compensation || 0;
    vm.claimDetail.save();
  }

  function downloadAllPhoneDocuments() {
    DocumentDownloadFactory.downloadAllPhoneDocuments(vm.claim);
  }

  function showDocumentCopyModal(prefix, type) {
    var currentDocuments = null;
    var documentTypes = vm.documentTypes;
    var claimTypes = vm.claimTypes;
    var multipleSourceSelectionEnabled = false;
    var copy_type = type;
    var claimData = {
      claim_id: vm.claim_id,
      claim_prefix: prefix
    };

    // If the claim has no documents, an error is shown
    if (vm.documents.length === 0) {
      notifications.addCurrentView(
        'error',
        'No se ha podido realizar la acción. La reclamación no tiene ' + 'ningún documento adjunto.'
      );
      return;
    }

    if (type === 'claim') {
      try {
        currentDocuments = DialogFactory.copyDocsToClaimValidation(vm.documents);
      } catch (e) {
        notifications.addCurrentView('error', e);
        return;
      }
    } else {
      try {
        currentDocuments = DialogFactory.copyDocsToLegalfileValidation(vm.documents, vm.claim);
      } catch (e) {
        notifications.addCurrentView('error', e);
        return;
      }
    }

    DialogFactory.copyDocumentsFromSelection(
      currentDocuments,
      documentTypes,
      claimTypes,
      claimData,
      multipleSourceSelectionEnabled,
      copy_type,
      function() {
        vm.claimDetail.update();
      }
    );
  }
}
