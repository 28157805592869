CostsProceduresService.$inject = ['$http', 'serverAddress'];

export default function CostsProceduresService($http, serverAddress) {
  const baseUrl = `${serverAddress.getBaseUrl()}legalfile/`;
  return {
    add,
    update,
    remove,
    removeDetail,
    get
  };

  /**
   * Add costProcedure
   * @param {object} data
   * * documentType affected -> 23 | 26
   * data = {
   *   legalfile_id: vm.legalfile.id,
   *   document_id: response.doc_id,
   *   prefix: vm.legalfile.real_prefix,
   *   costs_type_id: item.subType,
   *   legal_costs_doc: item.hasCosts
   * };
   */
  function add(data) {
    return $http.post(`${baseUrl}add/costsprocedure/`, data);
  }

  /**
   * Update detail in Procedure
   * @param {object} data
   *
   * * documentType affected -> 208 | 155
   * data = {
   *   legalfile_id: vm.legalfile.id,
   *   document_id: response.doc_id,
   *   prefix: vm.legalfile.real_prefix,
   *   costs_type_id: item.subType,
   *   costs_procedure_id: item.data.associatedProcedure.procedure_id,
   *   costs_state_id: @ONLY on change state
   *   costs_details: [
   *     item.data.mandatoryAmount,
   *     ...item.data.amounts
   *   ],
   *   costs_total
   * };
   */
  function update(data) {
    return $http.post(`${baseUrl}update/costsprocedure/`, data);
  }

  /**
   * Remove sentencia or diligencia
   * @param {number} document_id
   * @param {string} prefix
   */
  function remove(document_id, prefix) {
    return $http.post(`${baseUrl}remove/costsprocedure/`, { document_id, prefix });
  }

  /**
   * Remove minuta or aprobación de costas
   * @param {number} document_id
   */
  function removeDetail(document_id, prefix) {
    return $http.post(`${baseUrl}remove/costsproceduredetail/`, { document_id, prefix });
  }
  /**
   * Get costsProcedure detail
   * @param {number} legalfileId
   */
  function get(legalfileId) {
    return $http.get(`${baseUrl}get/costsprocedure/${legalfileId}/`);
  }
}
