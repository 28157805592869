AirlineOpposingLawyer.$inject = ['$resource', 'serverAddress'];

export default function AirlineOpposingLawyer($resource, serverAddress) {
  const AIRLINE_OPPOSING_LAWYER_PATH = 'api2/v1/airlineopposinglawyer/?airline__in=:airlineId&limit=:limit&offset=:offset&q=:filter&:query';
  return $resource(serverAddress.getBaseUrl() + AIRLINE_OPPOSING_LAWYER_PATH, {
    offset: 0,
    limit: 0,
    filter: '',
    query: ''
  });
}
