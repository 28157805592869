/**
 * filter to fix long filenames
 * TODO: it may fail with stupid values (p.e. maxlength > elipsis.length)
 * Usage:
 *   {{ string | truncatefilename }} - uses defaults 32 length and '..' elipsis'
 *   {{ string | truncatefilename : length }} - set length
 *   {{ string | truncatefilename : length : elipsis }} - set length & elipsis
 * ALSO: import $filter and load it like this: $filter('truncatefilename')(filename, maxlength, elipsis)
 */
export default function truncatefilename() {
  return function(filename, maxlength = 32, elipsis = '..') {
    if(!filename || !(typeof filename === 'string')) return '';
    if(filename.length < maxlength) return filename;
    if(filename.lastIndexOf('.') == -1) return filename.substr(0, maxlength - elipsis.length) + elipsis;

    const EXTENSION = filename.substr(filename.lastIndexOf('.'));

    if(EXTENSION.length > maxlength) return filename.substr(0, maxlength - elipsis.length) + elipsis;

    const NAME = filename.substr(0, maxlength - EXTENSION.length - elipsis.length);

    return NAME + elipsis + EXTENSION;
  };
}
