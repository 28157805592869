const SEGMENT_ID = 7,
  MODEL_PROVIDER = 'MunicipalTaxClaim';

export default {
  SEGMENT_ID,
  MODEL_PROVIDER,
  DJANGO_MODEL: MODEL_PROVIDER,
  SEGMENT_PREFIX: 'TA',
  ALIAS: 'taxes',
  VERBOSE_NAME: 'Reclamación Impuestos',
  permissions: {
    downloadCSVPermission: 'segment-perobject-TA-crm_download_csv'
  },
  CLAIM_DICTIONARIES: {
    users: 'taxeslawyers',
    salesman: 'taxessalesmen',
    sources: 'claimsources',
    courtlawyers: 'courtlawyers',
    externalcourtlawyers: 'externalcourtlawyers_fortaxes',
    claimTypes: {
      entity: 'claimtypes',
      data: { type: 'taxes', is_active: true }
    },
    claimStates: {
      entity: 'claimstates',
      data: { segments__id: SEGMENT_ID, costs: false }
    },
    provinces: 'provinces',
    saleStates: {
      entity: 'salesclaimstates',
      data: { segments__id: SEGMENT_ID }
    },
    statesWithEmail: {
      entity: 'statesWithPreviewEmail',
      data: { django_class: MODEL_PROVIDER }
    },
    trialMunicipalities: 'trialMunicipalities',
    docxtemplates: {
      entity: 'docxtemplates',
      data: { active: true, segments__id: SEGMENT_ID, target_holder: 'claim' }
    }
  },

  REASON_PAID: {
    0: 'He vendido el inmueble',
    1: 'He heredado el inmueble',
    2: 'Me han donado el inmueble'
  },
  MORE_THAN_FOUR_YEARS: {
    0: 'No',
    1: 'Sí'
  },
  PREVIOUS_CLAIM: {
    0: 'No',
    1: 'Sí'
  },
  PREPAYMENT_CHOICES: [
    {'id': 0, 'name': 'Ninguna'},
    {'id': 1, 'name': 'Voluntaria'},
    {'id': 2, 'name': 'Obligatoria'}
  ],
  PAYMENT_TYPE: {
    null: 'Ninguna',
    0: 'Ninguna',
    1: 'Voluntaria',
    2: 'Obligatoria'
  },
  PAYMENT_STATUS: {
    true: 'Pagada',
    false: 'No pagada',
    null: 'No pagada'
  },
  PROCEDURE_TYPE: {
    0: 'Ninguno',
    1: 'Ordinario',
    2: 'Abreviado'
  },

  tabConfig: [
    {
      heading: 'Resumen',
      indexKey: 'resumen',
      template: 'js/modules/segments/taxes/partials/detail/summary.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Indemnización',
      indexKey: 'indemnizacion',
      template: 'js/modules/segments/taxes/partials/detail/amount.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Detalles',
      indexKey: 'detalles',
      template: 'js/modules/segments/taxes/partials/detail/details.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Documentación',
      indexKey: 'documentacion',
      template: 'js/modules/segments/taxes/partials/detail/documentation.html'
    },
    {
      heading: 'Historial',
      indexKey: 'historial',
      template: '/partials/common/detail/historic.html',
      headingBadge: null
    },
    {
      heading: 'Mismo cliente',
      indexKey: 'mismo-cliente',
      template: '/partials/common/detail/same_client/base.html'
    },
    {
      heading: 'Invitaciones',
      indexKey: 'invitaciones',
      template: 'js/modules/segments/taxes/partials/detail/invitations.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Administración',
      indexKey: 'administracion',
      template: 'js/modules/segments/taxes/partials/detail/administration.html',
      select: null,
      headingBadge: null,
      permission: 'segment-global-crm_view_administration_tab'
    }
  ],

  defaultColumnDef: [
    { headerName: 'ID', fieldName: 'id', sortable: true, alwaysVisible: true },
    { headerName: 'Tipo', fieldName: 'type_name', sortable: true },
    { headerName: 'Cliente', fieldName: 'client_surnames', sortable: true },
    { headerName: 'Correo cliente', fieldName: 'client_email', sortable: true, hidden: true },
    {
      headerName: 'Provincia cliente',
      fieldName: 'client_province',
      sortable: true,
      hidden: true
    },
    { headerName: 'Tlfno', fieldName: 'client_phone', sortable: true, hidden: true },
    { headerName: 'Finalizada', fieldName: 'finished', sortable: true },
    { headerName: 'Fecha Finalizada', fieldName: 'date_finished', sortable: true },
    { headerName: 'Abogado', fieldName: 'lawyer', sortable: true },
    { headerName: 'Validado por', fieldName: 'validator_lawyer', sortable: true },
    { headerName: 'Estado', fieldName: 'state_name', sortable: true },
    { headerName: 'Estado C.', fieldName: 'sales_state_name', sortable: true },
    { headerName: 'Fase', fieldName: 'phase_name', sortable: true },
    { headerName: 'Fuente', fieldName: 'source', sortable: true },
    { headerName: 'Cómo nos conoció', fieldName: 'how_met_us', sortable: true },
    { headerName: 'Fuente invitador', fieldName: 'invited', sortable: true, hidden: true },
    { headerName: 'Campaña', fieldName: 'campaign_name', sortable: true, hidden: true },
    { headerName: 'SEM', fieldName: 'has_sem_gclid', sortable: true, hidden: true },
    { headerName: 'SEM (Valor)', fieldName: 'sem_gclid', sortable: true, hidden: true },
    { headerName: 'Network', fieldName: 'sem_network', sortable: true, hidden: true },
    { headerName: 'Placement', fieldName: 'sem_placement', sortable: true, hidden: true },
    { headerName: 'Campaign', fieldName: 'sem_campaign', sortable: true, hidden: true },
    {
      headerName: 'Ultimo cambio estado',
      fieldName: 'laststatechange',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Usuario cambio estado',
      fieldName: 'laststatechangeuser',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Comentario cambio estado',
      fieldName: 'laststatecomment',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Ultimo cambio estado comercial',
      fieldName: 'lastsalesstatechange',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Usuario cambio estado comercial',
      fieldName: 'lastsalesstatechangeuser',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Último comentario',
      fieldName: 'last_comment',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Usuario últ. comentario ',
      fieldName: 'last_comment_user',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha últ. comentario',
      fieldName: 'last_comment_date',
      sortable: true,
      hidden: true
    },
    { headerName: 'Creado', fieldName: 'date_added', sortable: true },
    { headerName: 'Modificado', fieldName: 'date_updated', sortable: true },
    { headerName: 'Tipo plusvalía', fieldName: 'type_name', sortable: true },
    { headerName: 'Importe del impuesto', fieldName: 'compensation', sortable: true },
    { headerName: 'Numero documentos', fieldName: 'num_docs', sortable: true, hidden: true },
    {
      headerName: 'Cantidad reclamada',
      fieldName: 'compensation',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Primer vertical',
      fieldName: 'first_claim_segment',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Primer producto',
      fieldName: 'first_claim_type',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha alta cliente',
      fieldName: 'client_date_added',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha primera reclamación válida',
      fieldName: 'first_claim_date_added',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Tipo de facturación',
      fieldName: 'is_immediate',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha a facturar',
      fieldName: 'billing_date',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Cantidad acuerdo prejudicial',
      fieldName: 'agreement_amount',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Cedida',
      fieldName: 'transfered_to_investor',
      sortable: true
    },
    {
      headerName: 'Fecha cesión',
      fieldName: 'transfered_to_investor_date',
      sortable: true
    },
    {
      headerName: 'Cartera',
      fieldName: 'investor_business_name',
      sortable: true
    },
    {
      headerName: 'Procedimiento',
      fieldName: 'procedure_type',
      sortable: true
    },
    {
      headerName: 'Nombre Obligado tributario',
      fieldName: 'taxpayer_name',
      sortable: true
    },
    {
      headerName: 'DNI Obligado tributario',
      fieldName: 'taxpayer_id_document',
      sortable: true
    },
    {
      headerName: 'Comprada',
      fieldName: 'bought_from_portfolio',
      sortable: true
    },
    {
      headerName: 'Comprado a',
      fieldName: 'bought_portfolio_business_name',
      sortable: true
    },
    {
      headerName: 'Fecha compra',
      fieldName: 'bought_date',
      sortable: true
    },
    {
      headerName: 'Email cartera cliente',
      fieldName: 'email_bought_client',
      sortable: true
    },
    {
      headerName: 'Teléfono cartera cliente',
      fieldName: 'phone_bought_client',
      sortable: true
    }
  ],
  filterFieldInfo: {
    procedure_type: { label: 'Procedimiento', column: 'procedure_type' },
    taxpayer_name: { label: 'Nombre Obligado tributario', column: 'taxpayer_name' },
    taxpayer_id_document: { label: 'DNI Obligado tributario', column: 'taxpayer_id_document' }
  }
};
