DocumentRenameCtrl.$inject = [
  '$scope',
  'notifications',
  'UtilsFactory'
];

export default function DocumentRenameCtrl (
  $scope,
  notifications,
  UtilsFactory
) {
  const vm = this;

  Object.assign(vm, {
    // bindings
    oldName: null,
    documentId: null,
    isLegalfile: false,

    // staet
    renaming: false,
    newName: null,
    renamingInProgress: false,
    showRenameForm: false, // hover

    // method
    resetRenameForm,
    renameDoc
  });

  // Document renaming
  $scope.$on('document:renamed', () => {
    vm.resetRenameForm();
  });

  function renameDoc() {
    if(vm.renamingInProgress) return;

    // Normalize new document name to avoid non-processable characters
    var newDocumentName = UtilsFactory.normalizeString(vm.newName);

    if (newDocumentName === vm.oldName) {
      notifications.addCurrentView('warning', 'El nuevo nombre para el documento es el mismo que tenía.');
      return;
    }

    vm.renamingInProgress = true;

    // delegate renaming to document manager
    $scope.$emit('document:rename', {
      oldName: vm.oldName,
      newName: newDocumentName,
      documentId: vm.documentId,
      isLegalfile: vm.isLegalfile });
  }

  function resetRenameForm() {
    vm.renaming = false;
    vm.newName = null;
    vm.renamingInProgress = false;
  }
}
