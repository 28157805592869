/* Returns 1 if semver1 is greater than semver2
   Returns -1 if semver2 is greater than semver1
   Returns 0 if semver1 is equal to semver2 */
function compareSemVer(semver1, semver2) {
  var splittedSemver1 = semver1.split('.');
  var splittedSemver2 = semver2.split('.');

  // Iterate only over the two first numbers. The second number indicates the development sprint id.
  // Minor number (third position) indicates an iteration of bugfies, which do not usually require
  // refreshing the app's cache.
  var NUMS_TO_ITERATE = 2;

  for (var i = 0; i < NUMS_TO_ITERATE; i++) {
    var num1 = Number(splittedSemver1[i]);
    var num2 = Number(splittedSemver2[i]);

    // non-NaN wins over NaN
    if (!isNaN(num1) && isNaN(num2)) return 1; // semver1 wins
    if (isNaN(num1) && !isNaN(num2)) return -1; // semver2 wins

    if (num1 > num2) return 1;
    if (num2 > num1) return -1;
  }

  return 0;
}

export function processVersioning(currentVersion) {
  var VERSION_KEY = 'version';
  var localStorageVersion = localStorage.getItem(VERSION_KEY);
  if (!localStorageVersion) {
    localStorage.setItem(VERSION_KEY, currentVersion);
    return;
  }

  var compareResults = compareSemVer(localStorageVersion, currentVersion);
  // currentVersion > localStorageVersion
  if (compareResults === -1) {
    clearLocalStorage();
    localStorage.setItem(VERSION_KEY, currentVersion);
  }

  function clearLocalStorage() {
    const EXCLUDE_KEYS = ['username', 'token', 'version', 'loglevel:webpack-dev-server'];

    Object.keys(localStorage).forEach(e => {
      if (!EXCLUDE_KEYS.includes(e)) {
        // keep old claim list column definition and try to fix them later
        if (_check(e)) {
          localStorage.setItem(e + '-OLD', localStorage.getItem(e));
        }
      }
    });
  }

  function _check(str){
    return str.match(/(\w*)claim-list-table-columns$/) !== null;
  }
}

export function templateRequestInterceptor(version, $templateCache) {
  return {
    request: req => {
      const template = $templateCache.get(req.url);
      if (req._isTemplate && !template) req.url += `?v=${version}`;
      return req;
    }
  };
}
