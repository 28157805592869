import debounce from 'lodash/debounce';
import jquery from 'jquery';
const $ = jquery;

window.rclmng_globals = window.rclmng_globals || {
  ux: {
    resize: function() {
      // resize some elements in the page
      var wW = $(window).width();

      $('#main-content, .claimChangesBand').width(wW > 576 ? wW - $('#menu-left').width() : wW);

      if (wW > 576) {
        if ($('body').hasClass('body-mobile'))
          $('body')
            .removeClass('body-mobile')
            .addClass('body-full');
      } else {
        if ($('body').hasClass('body-full'))
          $('body')
            .removeClass('body-full')
            .addClass('body-mobile');
      }
      window.rclmng_globals.ux.baseListScroll.tableResize();

      window.rclmng_globals.ux.fixPepinSizes();
      window.rclmng_globals.ux.pepinColFixer.resizeTables();
    },
    baseListScroll: {
      // implements secondary scroll bar in baseList tables
      init: function() {
        $('.baseListTableTopScrollBarWrap, .baseListTable table').scroll(
          window.rclmng_globals.ux.baseListScroll.scrollEvt
        );
        $(window).resize();
      },

      tableResize: function() {
        var $baseListTable = $('.baseListTable');
        var overFlowClass = '.baseListTableOverFlow';
        var TopScrollBar = '.baseListTableTopScrollBar';

        if ($baseListTable.length == 1) {
          var w = $baseListTable.find('thead').width();
          var wW = $baseListTable.width();

          if (w > wW) {
            if (!$baseListTable.hasClass(overFlowClass)) $baseListTable.addClass(overFlowClass);

            $baseListTable.find(TopScrollBar).width(w);
          } else {
            if ($baseListTable.hasClass(overFlowClass)) $baseListTable.removeClass(overFlowClass);
          }
        }
      },

      scrollEvt: function(e) {
        var $target = $(e.target);

        if ($target.is('table')) {
          $('.baseListTableTopScrollBarWrap').scrollLeft($target.scrollLeft());
        } else {
          $('.baseListTable table').scrollLeft($target.scrollLeft());
        }
      }
    },
    clearModals: function() {
      $('.modal').modal('hide');
      $('.modal-backdrop').remove();
      $('body').removeClass('modal-open');

      return false;
    },

    // table resizing logic so we don't mess with pepin
    // Right now covers 2 know pepin use cases: panel & baselist
    fixPepinSizes: function() {
      $('.fixedCol-pepin').each(function() {
        var $pepin = $(this);
        var $target = $pepin.parents('.fixedCol-pepin-wrap').length ? $pepin.parents('.fixedCol-pepin-wrap') : $pepin;

        var outerHeight =
          $pepin
            .parents('section')
            .first()
            .outerHeight() +
          $('.page-header').outerHeight() +
          $('.navbar').height() -
          $target.outerHeight();
        $pepin.data('height', $(window).height() - outerHeight);
      });
    },

    toggleSidebar: function() {
      var $sidebar = $('#menu-left');

      if ($(window).width() > 576) {
        // reset responsive
        $sidebar.removeClass('shown');
        $('body').removeClass('modal-open');

        if ($sidebar.hasClass('shrinked')) {
          $sidebar
            .removeClass('shrinked')
            .find('ul ul')
            .css('display', 'block');
        } else {
          $sidebar
            .addClass('shrinked')
            .find('ul ul')
            .css('display', '');
        }

        window.rclmng_globals.ux.resize();

        return false;
      }

      if ($sidebar.hasClass('shown')) {
        $sidebar.removeClass('shown');
        $('body').removeClass('modal-open');
      } else {
        $sidebar.addClass('shown').css('height', $(window).height() - $('.navbar').height());
        $('body').addClass('modal-open');
      }

      return false;
    },
    pepinColFixer: {
      $tables: [],

      init: function() {
        window.rclmng_globals.ux.pepinColFixer.$tables = $('.fixedCol-pepin:not(.loaded):not(.secondaryTable)'); // reset $tables
        if (window.rclmng_globals.ux.pepinColFixer.$tables.length == 0) return;

        window.rclmng_globals.ux.pepinColFixer.$tables.each(window.rclmng_globals.ux.pepinColFixer.initTables);
      },

      clearTables: function() {
        $('.fixedCol-pepin.loaded').each(function() {
          var $table = $(this)
            .removeClass('loaded')
            .data('height', null);
          var $wrap = $table.siblings('.fixedCol-pepin-wrap');

          $wrap.remove();
        });
      },

      initTables: function() {
        var $mainTable = $(this);

        if (!$mainTable.find('thead').length || !$mainTable.find('tbody').length) {
          return;
        }

        var $table = $mainTable.clone();
        $mainTable.after($table);
        $mainTable.addClass('loaded');

        // wrapper for all the tables
        var $wrap = $table
          .addClass('secondaryTable')
          .wrap('<div class="fixedCol-pepin-wrap"></div>')
          .parent()
          .append('<div class="barX bar"><div class="barWrap"><div class="barInn"></div></div></div>')
          .append('<div class="barXBottom bar"><div class="barWrap"><div class="barInn"></div></div></div>')
          .append('<div class="barY bar"><div class="barWrap"><div class="barInn"></div></div></div>');

        $wrap
          .find('.barWrap')
          .mousedown(function() {
            $(this).addClass('mouseDown');
          })
          .mouseup(function() {
            $(this).removeClass('mouseDown');
          })
          .mousemove(window.rclmng_globals.ux.pepinColFixer.clickBarEvt)
          .click(window.rclmng_globals.ux.pepinColFixer.clickBarEvt);

        // The original table
        $table.wrap('<div class="fixedCol-pepin-fixHead"></div>');

        // table for the first column fix
        $wrap
          .append('<div class="fixedCol-pepin-fixCorner"><div class="fixedCol-pepin-fixCorner-inn"></div></div>')
          .find('.fixedCol-pepin-fixCorner-inn')
          .append($table.clone());

        // table for the first row fix
        $wrap
          .prepend('<div class="fixedCol-pepin-scroller">')
          .find('.fixedCol-pepin-scroller')
          .on('scroll', window.rclmng_globals.ux.pepinColFixer.scrollEvt)
          .append($table.clone());

        // tabla for the left top orner
        $wrap
          .prepend('<div class="fixedCol-pepin-fixCol"><div class="fixedCol-pepin-fixCol-inn">')
          .find('.fixedCol-pepin-fixCol-inn')
          .append($table.clone());

        window.rclmng_globals.ux.resize();
        $wrap.find('.fixedCol-pepin-scroller').scroll();
        window.rclmng_globals.ux.resize();
      },

      resizeTables: function() {
        if (window.rclmng_globals.ux.pepinColFixer.$tables.length)
          window.rclmng_globals.ux.pepinColFixer.$tables.each(function() {
            window.rclmng_globals.ux.pepinColFixer.resizeTable($(this).siblings('.fixedCol-pepin-wrap'));
          });

        window.rclmng_globals.ux.pepinColFixer.scrollEvt();
      },

      resizeTable: function($wrap) {
        var $table = $wrap.find('.fixedCol-pepin-fixHead table');

        var $scroller = $wrap.find('.fixedCol-pepin-scroller');
        var $tableLeft = $wrap.find('.fixedCol-pepin-fixCol');
        var $tableCorner = $wrap.find('.fixedCol-pepin-fixCorner');
        var $tableCol = $wrap.find('.fixedCol-pepin-fixHead');
        var $barX = $wrap.find('.barX');
        var $barXBottom = $wrap.find('.barXBottom');
        var $barY = $wrap.find('.barY');

        // calculate sizes
        var tableWidth = $table.outerWidth();
        var tableHeight = $table.data('height');

        var headHeight = $table.find('thead').height();
        var fixedColWidth = $table.find('tbody td:eq(0)').outerWidth();

        $scroller.css('height', tableHeight);

        $tableCol.css('height', headHeight);
        $tableCorner.css('height', headHeight);
        $tableLeft.show().css('width', fixedColWidth);
        $tableCorner.css('width', fixedColWidth);
        $barY.css('padding-top', headHeight);
        $barX.css({
          'padding-left': fixedColWidth,
          top: headHeight
        });
        $barXBottom.css('padding-left', fixedColWidth);
        $tableLeft.find('.fixedCol-pepin-fixCol-inn').css('width', tableWidth);
        $tableCorner.find('.fixedCol-pepin-fixCorner-inn').css('width', tableWidth);

        if ($table.width() > $wrap.width()) {
          $wrap.addClass('overFX');
          $scroller.css('margin-bottom', -1 * ($scroller[0].offsetHeight - $scroller[0].clientHeight));
        } else {
          $wrap.removeClass('overFX');
          $scroller.css('margin-bottom', 0);
        }

        if ($table.height() > $wrap.height()) {
          $wrap.addClass('overFY');
          $scroller.css('margin-right', -1 * ($scroller[0].offsetWidth - $scroller[0].clientWidth));
        } else {
          $wrap.removeClass('overFY');
          $scroller.css('margin-right', 0);
        }
      },

      clickBarEvt: function(e) {
        var $bar = $(this);

        if (e.type == 'mousemove' && !$bar.hasClass('mouseDown')) return;

        var $wrap = $(this).parents('.fixedCol-pepin-wrap');
        var $scrollr = $wrap.find('.fixedCol-pepin-scroller');
        var $table = $wrap.find('.fixedCol-pepin-scroller table');

        if ($bar.parent().hasClass('barX') || $bar.parent().hasClass('barXBottom')) {
          var scrollX = (e.pageX - $bar.offset().left) / $bar.width();
          $scrollr.scrollLeft(scrollX * ($table.width() - $wrap.width()));
        } else {
          var scrollY = (e.pageY - $bar.offset().top) / $bar.height();
          $scrollr.scrollTop(scrollY * ($table.height() - $wrap.height()));
        }

        return false;
      },

      scrollEvt: function() {
        // event to sync scrolls

        var $wrap = $(this).parents('.fixedCol-pepin-wrap');
        var $table = $wrap.find('.fixedCol-pepin-fixHead table'); // the original table
        var $scroller = $wrap.find('.fixedCol-pepin-scroller');

        $wrap.find('.fixedCol-pepin-fixCol').scrollTop($scroller.scrollTop());
        $wrap.find('.fixedCol-pepin-fixHead').scrollLeft($scroller.scrollLeft());

        var $barX = $wrap.find('.barX, .barXBottom');
        var $barY = $wrap.find('.barY');

        var barYMaxH = $wrap.height() - $table.find('thead').height();
        var barYH = (barYMaxH * $wrap.height()) / $table.height();

        $barY.find('.barInn').css({
          height: barYH,
          top: (barYMaxH - barYH) * ($scroller.scrollTop() / ($table.height() - $wrap.height()))
        });

        var barXMaxW = $wrap.width() - $table.find('tbody td:eq(0)').outerWidth();
        var barXW = (barXMaxW * $wrap.width()) / $table.width();

        $barX.find('.barInn').css({
          width: barXW,
          left: (barXMaxW - barXW) * ($scroller.scrollLeft() / ($table.width() - $wrap.width()))
        });
      }
    }
  }
};

export const init = () => {
  $('body')
    .on('click', '#togle-sidebar', window.rclmng_globals.ux.toggleSidebar)
    .on('click', '#menu-left a', function() {
      $('#menu-left').removeClass('shown');
      $('body').removeClass('modal-open');
    })

    // sidebar icons
    .on('mouseenter', '#sidebar-wrap > li', function() {
      if ($(window).width() < 576) return;
      if (!$('#menu-left').hasClass('shrinked')) return;

      $(this)
        .children('ul')
        .stop(false, true)
        .show()
        .slideDown(400);
    })
    .on('mouseleave', '#sidebar-wrap > li', function() {
      if ($(window).width() < 576) return;
      if (!$('#menu-left').hasClass('shrinked')) return;

      $(this)
        .children('ul')
        .stop(false, true)
        .hide()
        .slideUp(400);
    });

  // set resize event
  $(window)
    .bind('orientationchange resize', debounce(window.rclmng_globals.ux.resize, 100))
    .resize();
};
