import { element } from 'angular';

dynamicComponent.$inject = ['$compile'];
export default function dynamicComponent($compile) {
  return {
    restrict: 'A',
    link: function(scope, el, attrs) {
      if (attrs.component) {
        const newEl = element(attrs.component);
        let compiledEl = $compile(newEl)(scope);
        el.append(compiledEl);
      }
    }
  };
}
