const SEGMENT_ID = 10,
  MODEL_PROVIDER = 'ManagementsClaim',
  CLAIM_TYPE = 'managements';

const CONTACT_PREFERENCES = [
  { id: 0, value: 0, label: 'Mañana' },
  { id: 1, value: 1, label: 'Tarde' },
  { id: 2, value: 2, label: 'Indiferente' }
];

const PAYMENT_TYPES = [
  { id: 0, value: 0, label: 'Link' },
  { id: 1, value: 1, label: 'Transferencia' }
];

const CONTACT_TYPE = [
  { id: 0, value: 0, label: 'Llamada' },
  { id: 1, value: 1, label: 'Videollamada' }
];

const CUSTOM_NULLBOOLEAN = [
  { id: 1, value: false, label: 'No' },
  { id: 2, value: true, label: 'Si' }
];


export default {
  SEGMENT_ID,
  MODEL_PROVIDER,
  DJANGO_MODEL: MODEL_PROVIDER,
  SEGMENT_PREFIX: 'GE',
  ALIAS: 'managements',
  VERBOSE_NAME: 'Gestiones', // optional
  permissions: {
    downloadCSVPermission: 'segment-perobject-GE-crm_download_csv'
  },
  CONTACT_PREFERENCES,
  CLAIM_TYPE,
  CLAIM_TYPES: {
    57: [
      {
        id: 'date_of_death',
        type: 'date',
        label: 'Fecha del fallecimiento'
      },
      {
        id: 'deceased_last_six_months',
        type: 'numeralboolean',
        label: '¿Han pasado más de 6 meses desde el fallecimiento de tu allegado?'
      },
      {
        id: 'deceased_region_madrid',
        type: 'numeralboolean',
        label:
          '¿Tenía la persona fallecida su residencia habitual dentro de la comunidad de Madrid?',
        disabled: true
      },
      {
        id: 'deceased_region',
        type: 'select',
        label: '¿Donde residía la persona fallecida?',
        service: 'georegions',
        disabled: true
      },
      {
        id: 'deceased_province',
        type: 'select',
        label: '¿En qué provincia tenía su residencia habitual la persona fallecida?',
        service: 'geoprovinces'
      },
      {
        id: 'inheritors_nr',
        type: 'number',
        label: '¿Cuántos herederos sois?'
      },
      {
        id: 'handicapted_or_minor_inheritor',
        type: 'numeralboolean',
        label: '¿Hay algún menor o persona incapacitada entre los herederos?'
      },
      {
        id: 'properties_nr',
        type: 'number',
        label: 'Número de inmuebles'
      },
      {
        id: 'properties_not_in_madrid',
        type: 'numeralboolean',
        label: '¿Hay propiedades fuera de Madrid?'
      },
      {
        id: 'last_will',
        type: 'nullboolean',
        label: '¿Hay testamento?'
      },
      {
        id: 'help_with_docs',
        type: 'nullboolean',
        label: 'Ayuda a la documentación'
      },
      {
        id: 'opened_law_suit',
        type: 'numeralboolean',
        label: '¿Hay abierto algún proceso judicial relacionado con la herencia?'
      },
      {
        id: 'inheritor_has_lawyer',
        type: 'numeralboolean',
        label:
          '¿Alguno de los herederos ha contratado su propio abogado o asesor para esta herencia?'
      }
    ],
    58: [
      {
        id: 'contact_type',
        type: 'customboolean',
        label: 'Tipo de consulta',
        dictionary: CONTACT_TYPE
      },
      {
        id: 'contact_preferences',
        type: 'customboolean',
        label: 'Hora de consulta',
        dictionary: CONTACT_PREFERENCES
      },
      {
        id: 'payment_type',
        type: 'customboolean',
        label: 'Tipo de pago',
        dictionary: PAYMENT_TYPES
      },
      {
        id: 'type',
        type: 'select',
        label: 'Tema de consulta',
        service: 'videocalltype'
      },
      {
        id: 'internal_type',
        type: 'select',
        label: 'Tema de consulta interno',
        service: 'videocallinternaltype',
        disabled: false
      },
      {
        id: 'report_for_client',
        type: 'customboolean',
        label: 'Informe de consulta',
        dictionary: CUSTOM_NULLBOOLEAN
      },
      {
        id: 'service_timing',
        type: 'select',
        search: false,
        label: 'Duración de la consulta',
        service: 'VCServiceTiming'
      }
    ]
  },
  CLAIM_DICTIONARIES: {
    lawyers: 'managementslawyers',
    sources: 'claimsources',
    salesman: 'managementssalesmen',
    provinces: 'provinces',
    claimTypes: {
      entity: 'claimtypes',
      data: { type: CLAIM_TYPE }
    },
    claimStates: {
      entity: 'claimstates',
      data: { segments__id: SEGMENT_ID, costs: false }
    },
    saleStates: {
      entity: 'salesclaimstates',
      data: { segments__id: SEGMENT_ID }
    },
    statesWithEmail: {
      entity: 'statesWithPreviewEmail',
      data: { django_class: 'ManagementsClaim' }
    }
  },

  tabConfig: [
    {
      heading: 'Resumen',
      indexKey: 'resumen',
      template: 'js/modules/segments/management/partials/detail/summary.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Detalles',
      indexKey: 'detalles',
      template: 'js/modules/segments/management/partials/detail/details.html',
      select: null,
      headingBadge: null
    },
    {
      heading: 'Documentación',
      indexKey: 'documentacion',
      template: 'js/modules/segments/management/partials/detail/documentation.html'
    },
    {
      heading: 'Historial',
      indexKey: 'historial',
      template: '/partials/common/detail/historic.html',
      headingBadge: null
    },
    {
      heading: 'Mismo cliente',
      indexKey: 'mismo-cliente',
      template: '/partials/common/detail/same_client/base.html'
    },
    {
      heading: 'Administración',
      indexKey: 'administracion',
      template: 'js/modules/segments/management/partials/administration.html',
      select: null,
      headingBadge: null,
      permission: 'segment-global-crm_view_administration_tab'
    }
  ],
  defaultColumnDef: [
    { headerName: 'ID', fieldName: 'id', sortable: true, alwaysVisible: true },
    { headerName: 'Tipo', fieldName: 'type_name', sortable: true },
    { headerName: 'Cliente', fieldName: 'client_surnames', sortable: true },
    { headerName: 'Correo cliente', fieldName: 'client_email', sortable: true },
    { headerName: 'Tipo de consulta', fieldName: 'type', sortable: true },
    { headerName: 'Tipo de contacto', fieldName: 'contact_type', sortable: true },
    { headerName: 'Preferencia de contacto', fieldName: 'contact_preferences', sortable: true },
    { headerName: 'Fecha de contacto', fieldName: 'appointment_date', sortable: true },
    { headerName: 'Tlfno', fieldName: 'client_phone', sortable: true, hidden: true },
    { headerName: 'Finalizada', fieldName: 'finished', sortable: true },
    { headerName: 'Fecha Finalizada', fieldName: 'date_finished', sortable: true },
    { headerName: 'Abogado', fieldName: 'lawyer', sortable: true },
    { headerName: 'Validado por', fieldName: 'validator_lawyer', sortable: true },
    { headerName: 'Estado', fieldName: 'state_name', sortable: true },
    { headerName: 'Estado C.', fieldName: 'sales_state_name', sortable: true },
    { headerName: 'Fase', fieldName: 'phase_name', sortable: true },
    { headerName: 'Network', fieldName: 'sem_network', sortable: true, hidden: true },
    { headerName: 'Placement', fieldName: 'sem_placement', sortable: true, hidden: true },
    {
      headerName: 'Ultimo cambio estado',
      fieldName: 'laststatechange',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Usuario cambio estado',
      fieldName: 'laststatechangeuser',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Comentario cambio estado',
      fieldName: 'laststatecomment',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Ultimo cambio estado comercial',
      fieldName: 'lastsalesstatechange',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Usuario cambio estado comercial',
      fieldName: 'lastsalesstatechangeuser',
      sortable: true,
      hidden: true
    },
    { headerName: 'Último comentario', fieldName: 'last_comment', sortable: true, hidden: true },
    {
      headerName: 'Usuario últ. comentario ',
      fieldName: 'last_comment_user',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha últ. comentario',
      fieldName: 'last_comment_date',
      sortable: true,
      hidden: true
    },
    { headerName: 'Creado', fieldName: 'date_added', sortable: true },
    { headerName: 'Modificado', fieldName: 'date_updated', sortable: true },
    { headerName: 'Numero documentos', fieldName: 'num_docs', sortable: true, hidden: true },
    {
      headerName: 'Fecha alta cliente',
      fieldName: 'client_date_added',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Fecha primera gestión válida',
      fieldName: 'first_claim_date_added',
      sortable: true,
      hidden: true
    },
    {
      headerName: 'Cedida',
      fieldName: 'transfered_to_investor',
      sortable: true
    },
    {
      headerName: 'Fecha cesión',
      fieldName: 'transfered_to_investor_date',
      sortable: true
    },
    {
      headerName: 'Cartera',
      fieldName: 'investor_business_name',
      sortable: true
    },
    {
      headerName: 'Informe VC',
      fieldName: 'report_for_client',
      sortable: true
    },
    {
      headerName: 'Comprada',
      fieldName: 'bought_from_portfolio',
      sortable: true
    },
    {
      headerName: 'Comprado a',
      fieldName: 'bought_portfolio_business_name',
      sortable: true
    },
    {
      headerName: 'Fecha compra',
      fieldName: 'bought_date',
      sortable: true
    },
    {
      headerName: 'Email cartera cliente',
      fieldName: 'email_bought_client',
      sortable: true
    },
    {
      headerName: 'Teléfono cartera cliente',
      fieldName: 'phone_bought_client',
      sortable: true
    }
  ],
  filterFieldInfo: {
    type: { label: 'Tipo de consulta', select_id: 'contact_type-select' },
    appointment_date__gte: {
      label: 'Fecha cita desde',
      type: 'date',
      column: 'claim.appointment_date',
      order: '>='
    },
    appointment_date__lte: {
      label: 'Fecha cita hasta',
      type: 'date',
      column: 'claim.appointment_date',
      order: '<='
    }
  }
};
