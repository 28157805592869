export default function clickableText() {
  const URL_REGEX = /(\b(https?|ftp):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim;
  const MAIL_REGEX = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim;

  return text => {
    if(!text || typeof text !== 'string') return '';

    let output = text;

    if(output.match(URL_REGEX))
      output = output.replace(URL_REGEX, '<a href=\"$1\" target=\"_blank\">$1</a>');

    if(output.match(MAIL_REGEX))
      output = output.replace(MAIL_REGEX, '<a href=\"mailto:$1\">$1</a>');

    return output;
  };
}
