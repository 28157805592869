DownloadCSVService.$inject = ['$http', 'notifications', '$window'];

export default function DownloadCSVService($http, notifications, $window) {
  return {
    fetch
  };

  /** Trigger CSV file download process */
  function fetch(resourceURL, params) {
    const downloadURL = `${resourceURL}/excel/`;
    /* params */
    // query: vm.filterText,
    // segment: segment,
    // sort: vm.sortedColumn,
    // filter: filter
    $http
      .get(downloadURL, { params })
      .then(response => {
        let msg = '';
        if (response.data === 'BIG_EXCEL_EMAIL') {
          msg =
            'El excel que has solicitaste es demasiado grande. Por está razón, te lo hemos enviado a tu correo. En breve lo recibirás en tu bandeja de entrada. ¡Saludos! (^_^)/';
        } else {
          msg = 'Documento CSV descargado con éxito';
          createDownloader(response.data);
        }
        notifications.addCurrentView('info', msg);
      })
      .catch(error => {
        let isError = false;
        let msg;
        if (error.data && error.data.msg === 'Too many rows') {
          msg =
            'Tu documento tiene demasiadas filas (+1000) y necesitamos un momentito para procesarlo. Te lo enviamos por correo cuando terminemos...';
        } else if (error.data && error.data.msg === 'Num rows exceed') {
          isError = true;
          msg =
            'Tu documento excede de las 65536 filas máximas permitidas por Excel. Por favor, ajusta tu búsqueda e inténtalo de nuevo.';
        } else {
          isError = true;
          msg = 'Error desconocido al generar Excel.';
        }
        notifications.addCurrentView(isError ? 'error' : 'info', msg);
      });
  }
  function createDownloader(resource) {
    const link = $window.document.createElement('a');
    link.href = `${resource}`;
    link.download = resource;
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  }
}
