BankClaimListCtrl.$inject = ['$scope', '$controller', 'BankConstants'];

export default function BankClaimListCtrl($scope, $controller, BankConstants) {
  const vm = this;
  const claimDetailTabs = {};
  BankConstants.tabConfig.forEach(element => {
    claimDetailTabs[element.indexKey] = element.heading;
  });
  const INITIAL_STATE = {
    claimDetailTabs,
    ALIAS: BankConstants.ALIAS + '-claims',
    VEHICLE_TYPES: BankConstants.VEHICLE_TYPES,
    CAUSES: BankConstants.CAUSES,

    itemsPath: 'bank-claim/list/',
    listPath: 'bank-claims/list/filter/',
    listPathNoFIlter: 'bank-claims',
    defaultColumnDef: BankConstants.defaultColumnDef,
    filterFieldInfo: BankConstants.filterFieldInfo
  };
  $controller('BaseListCtrl', { vm: vm, $scope: $scope });
  Object.assign(vm, INITIAL_STATE);

  /** Initiate List! */
  vm.initBaseList(BankConstants.SEGMENT_ID, BankConstants.ALIAS, BankConstants.permissions);
}
