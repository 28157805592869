import angular from 'angular';
import PicklistCtrl from './pickListCtrl';

angular
  .module('reclamador.directives.picklist', [])
  .controller('PicklistCtrl', PicklistCtrl)
  .directive('pickList', pickListDirective);

function pickListDirective() {
  return {
    restrict: 'E',
    transclude: true,
    template: require('./picklist.html'),
    scope: {
      model: '=',
      dependentfield: '=',
      dictionary: '='
    },
    controller: PicklistCtrl,
    link: function () {
      // console.log('directive linked!', $scope);
    }
  };
}
