ReportService.$inject = ['$resource', 'serverAddress'];

export default function ReportService($resource, serverAddress) {
  const REPORT_PATH = 'bank-claim';
  let Report = null;
  return {
    set,
    generate
  };
  function set(claimId, resource) {
    Report = $resource(`${serverAddress.getBaseUrl()}${REPORT_PATH}/${claimId}/${resource}/`);
  }

  function generate() {
    return Report.get().$promise;
  }
}
