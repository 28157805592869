export default {
  permissions: {
    downloadCSVPermission: 'client-global-crm_download_csv'
  },
  defaultColumnDef: [
    { headerName: 'ID', fieldName: 'id', sortable: true, alwaysVisible: true },
    { headerName: 'Nombre', fieldName: 'name', sortable: true, hidden: false },
    { headerName: 'Apellidos', fieldName: 'surnames', sortable: true, hidden: false },
    { headerName: 'Teléfono', fieldName: 'phone1', sortable: true },
    { headerName: 'Email', fieldName: 'email', sortable: true },
    { headerName: 'Reclamaciones', fieldName: 'num_claims_total', sortable: true },
    { headerName: 'Con cuenta bancaria', fieldName: 'client_has_bank_account', sortable: true },
    { headerName: 'Con cuenta Paypal', fieldName: 'client_has_paypal_account', sortable: true },
    { headerName: 'Fecha cuenta bancaria', fieldName: 'date_bank_account', sortable: true },
    { headerName: 'Creado', fieldName: 'date_added', sortable: true },
    { headerName: 'Modificado', fieldName: 'joomla_updated', sortable: true },
    { headerName: 'Agen/Cons/Empr', fieldName: 'is_agency', sortable: true },
    { headerName: 'Nombre agencia', fieldName: 'travelagency_name', sortable: true, hidden: true },
    { headerName: '', fieldName: null, sortable: true, alwaysVisible: true }
  ],
  filterFieldInfo: {
    name: { label: 'Nombre cliente', type: 'text' },
    surnames: { label: 'Apellidos cliente', type: 'text' },
    is_agency: { label: 'Agencia' },
    is_affiliate: { label: 'Empresa Afiliada' },
    travelagency__is_consolidator: { label: 'Consolidador' },
    client_has_bank_account: { label: 'Con cuenta bancaria' },
    client_has_paypal_account: { label: 'Con cuenta Paypal' },
    phone1: { label: 'Telefono' }
  }
};
